import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  ActivitiesList,
  Faq,
  Footer,
  HeaderSticky,
  Partner,
  SellTourysta,
  SupplierNewBanner,
} from "../components";
import { useSelector } from "react-redux";
import SystemSlider from "../components/SupplierNewPage/SystemSlider";
import { asyncGetSupplierNewPageDetails } from "../actions/pageAction";
const SupplierNewPage = () => {
  const [supplierData, setSupplierData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const languageReducer = useSelector((state) => state.languageReducer);

  // UseEffect to get supplier data on component mount or when dependencies change
  useEffect(() => {
    if(languageReducer){
    asyncGetSupplierNewPageDetails(
      setSupplierData,
      setIsLoading,
      languageReducer
    );}
  }, [languageReducer]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 3 }}
      >
        <HeaderSticky />
        <SupplierNewBanner supplierData={supplierData} isLoading={isLoading} />
        <SellTourysta sellTourystaData={supplierData} isLoading={isLoading} />
        <SystemSlider systemSlider={supplierData} isLoading={isLoading} />
        <ActivitiesList
          activitiesListData={supplierData}
          isLoading={isLoading}
        />
        <div className="affiliate_faq">
          <Faq />
        </div>
        <Partner />
        <Footer/>
      </motion.div>
    </>
  );
};

export default SupplierNewPage;
