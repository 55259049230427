import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FsLightbox from "fslightbox-react";
import { images } from "../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { PuffLoader } from "react-spinners";
import validator from "validator";
import { becomeAffiliate } from "../../actions/becomeAffiliateAction";

const BecomeSupplierModal = (props) => {
  const { showSupplierModal, setshowSupplierModal } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  //   useEffect(() => {
  //     if (languageReducer) {
  //       dispatch(
  //         cancelOrderReason(cancelReasonQuery, languageReducer, navigate, setData)
  //       );
  //     }
  //   }, []);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [supplierForm, setsupplierForm] = useState({
    company_name: "",
    company_email: "",
    company_phone: "",
    company_address: "",
    terms_condition: false,
  });
  const [builderformErrors, setbuilderformErrors] = useState({});
  const builder_errors = {};

  const singup_validation = () => {
    if (supplierForm.company_name?.length === 0) {
      builder_errors.company_name = t("_enter_company_name");
    }

    if (supplierForm.company_phone?.length < 5) {
      builder_errors.company_phone = t("_phone_no_minimum_5_digit");
    }
    if (supplierForm.company_phone?.length > 12) {
      builder_errors.company_phone = t("_phone_no_maximum_12_digit");
    }
    if (!validator.isNumeric(supplierForm.company_phone)) {
      builder_errors.company_phone = t("_enter_must_be_numeric");
    } else if (supplierForm.phone_number?.length === 0) {
      builder_errors.company_phone = t("_enter_company_phone_no");
    }

    if (
      !validator.isEmail(supplierForm.company_email) ||
      supplierForm.company_email?.length === 0
    ) {
      builder_errors.company_email = t("_enter_company_valid_email_id");
    }

    if (supplierForm.company_address?.length === 0) {
      builder_errors.company_address = t("_enter_company_address");
    }

    if (supplierForm.terms_condition == "false") {
      builder_errors.terms_condition = t("_accept_terms_condition");
    }

    setbuilderformErrors(builder_errors);
  };

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    const onlyNumbers = /^[0-9]*$/;

    if (name === "company_phone") {
      if (onlyNumbers.test(value) === true) {
        setsupplierForm({ ...supplierForm, [name]: value });
      }
    } else if (name == "terms_condition") {
      setsupplierForm((register) => ({
        ...register,
        [name]: checked ? "true" : "false",
      }));
    } else {
      setsupplierForm({ ...supplierForm, [name]: value });
    }
    // setbuilderformErrors(builder_errors);
  };

  const handleBecomeBuilder = (e) => {
    // e.preventDefault();
    singup_validation();

    if (Object.keys(builder_errors).length === 0) {
      setButtonDisable(true);
      let formdata = supplierForm;
      const become_user = "Partner";
      dispatch(
        becomeAffiliate(
          languageReducer,
          navigate,
          become_user,
          formdata,
          setButtonDisable
        )
      );
    }
  };

  return (
    <div className="login-signup-model">
      <Modal
        className="login_popup"
        show={showSupplierModal}
        onHide={() => {
          //   props.LogincloseModal();
          //   setFormErrors({});
          //   resetSignupForm();
          //   setCountryFlag("")
          setbuilderformErrors({});
          setshowSupplierModal(false);
        }}
      >
        <div className="login_model_main">
          <button
            className="btn_close"
            onClick={() => {
              //   setFormErrors({});
              //   resetSignupForm();
              //   setCountryFlag("")
              setbuilderformErrors({});
              setshowSupplierModal(false);
            }}
          >
            {" "}
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className="login_inner_modal">
            <div className={`login_section new_login_main `}>
              <div className="login_heading">
                <h2>{t("_Become_a_Supplier")}</h2>
              </div>
              <form action="">
                <div className="row signup_all_form">
                  <>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="input_all_login">
                        <label>{t("_company_name")}</label>
                        <input
                          type="text"
                          className={
                            `form-control  ` +
                            (builderformErrors.company_name ? "is-invalid" : "")
                          }
                          onChange={handleInputChange}
                          value={supplierForm.company_name}
                          name="company_name"
                          placeholder={t("_enter_company_name")}
                        />
                        {builderformErrors.company_name && (
                          <div className="invalid-feedback">
                            {builderformErrors.company_name}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="input_all_login">
                        <label>{t("_company_email")}</label>
                        <input
                          type="text"
                          className={
                            `form-control  ` +
                            (builderformErrors.company_email
                              ? "is-invalid"
                              : "")
                          }
                          onChange={handleInputChange}
                          value={supplierForm.company_email}
                          name="company_email"
                          placeholder={t("_enter_company_email")}
                        />
                        {builderformErrors.company_email && (
                          <div className="invalid-feedback">
                            {builderformErrors.company_email}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="input_all_login">
                        <label>{t("_company_phone")}</label>
                        <input
                          type="text"
                          className={
                            `form-control  ` +
                            (builderformErrors.company_phone
                              ? "is-invalid"
                              : "")
                          }
                          onChange={handleInputChange}
                          value={supplierForm.company_phone}
                          name="company_phone"
                          placeholder={t("_enter_company_phone")}
                        />
                        {builderformErrors.company_phone && (
                          <div className="invalid-feedback">
                            {builderformErrors.company_phone}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="input_all_login">
                        <label>{t("_company_address")}</label>
                        <input
                          type="text"
                          className={
                            `form-control  ` +
                            (builderformErrors.company_address
                              ? "is-invalid"
                              : "")
                          }
                          onChange={handleInputChange}
                          value={supplierForm.company_address}
                          name="company_address"
                          placeholder={t("_enter_company_address")}
                        />
                        {builderformErrors.company_address && (
                          <div className="invalid-feedback">
                            {builderformErrors.company_address}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="terms_condition_checkbox_div">
                        <input
                          type="checkbox"
                          className={
                            `terms_condition_checkbox` +
                            (builderformErrors?.terms_condition
                              ? "is-invalid"
                              : "")
                          }
                          onChange={handleInputChange}
                          checked={
                            supplierForm?.terms_condition == "false"
                              ? false
                              : true
                          }
                          name="terms_condition"
                        />{" "}
                        <span className="text-white">
                          {t("_i_accept")}{" "}
                          <NavLink
                            target="_blank"
                            className="terms_condition_link"
                            to="/partner-terms-condition"
                          >
                            {t("_terms_condition")}
                          </NavLink>{" "}
                        </span>
                      </div>
                      {builderformErrors.terms_condition && (
                        <div className="invalid-feedback">
                          {builderformErrors.terms_condition}
                        </div>
                      )}
                    </div>
                  </>
                </div>
              </form>

              <div className="signup_button">
                <button
                  onClick={() => handleBecomeBuilder()}
                  disabled={buttonDisable}
                >
                  {buttonDisable ? (
                    <div className="order-cancelling-loader-btn">
                      <PuffLoader size={30} color="#fff" />
                    </div>
                  ) : (
                    t("_Become_a_Supplier")
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* <ForgotPasswordModal
        forgotOpen={forgotOpen}
        setForgotOpen={setForgotOpen}
        closeForgotPasswordModel={closeForgotPasswordModel}
      /> */}
    </div>
  );
};

export default BecomeSupplierModal;
