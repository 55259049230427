import { useRef, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addReview } from "../../actions/reviewAction";
import { useTranslation } from "react-i18next";
import { errorMsg, images } from "../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/fontawesome-free-regular";
const ReviewModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setisReview, setReview, review, reviewQuery } = props;
  const [errors, setErrors] = useState({});
  const languageReducer = useSelector((state) => state.languageReducer);
  const [buttonDisable, setButtonDisable] = useState(false);
  const ref = useRef();
  const handleClick = (e) => {
    ref.current.click();
  };
  const reactRatings = {
    edit: true,
    activeColor: "#FFAA00",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };
  // <FontAwesomeIcon icon={faStar}/>
  const error = {};
  const [reviewDetail, setReviewDetail] = useState({
    title: "",
    description: "",
    rating: "",
    files: [],
    // image_id: [],
  });

  const handleOnChange = (e) => {
    const { name, value, files } = e.target;
    const allowedImageFormates = ["jpeg", "png", "jpg", "JPEG", "PNG", "JPG"];
    if (name == "files") {
      if (files.length > 3) {
        errorMsg("Maximum three images allowed.")
      } else {
        for (let i = 0; i < files.length; i++) {
          if (files[i] != undefined) {
            let parts = files[i].name.split('.');
            let fileFormate = parts[parts.length - 1];
            if (allowedImageFormates.includes(fileFormate)) {
              setReviewDetail((reviewDetail) => ({
                ...reviewDetail,
                [name]: [...reviewDetail.files, {
                  image: files[i]
                }],
              }));
            }
          }
        }
      }
    } else {
      setReviewDetail({ ...reviewDetail, [name]: value });
    }
  };


  const handleRemoveImage = (id, key) => {
    reviewDetail.files.splice(key, 1);

    setReviewDetail(() => ({
      ...reviewDetail,
      files: reviewDetail.files,
    }));
  };

  const handleRating = (value) => {
    setReviewDetail({ ...reviewDetail, rating: value });
  };

  const resetForm = () => {
    setReviewDetail({
      title: "",
      description: "",
      rating: "",
      files: [],
    });
    setErrors({})
  };
  const validation = () => {
    if (reviewDetail.title === "") {
      error.title = t("_enter_title");
    }
    if (reviewDetail.rating === "") {
      error.rating = t("_select_star_rating");
    }
    if (reviewDetail.description === "") {
      error.description = t("_enter_description");
    }
    if (reviewDetail.files.length > 0 && reviewDetail.files.length > 3) {
      error.files = t("_maximum_three_images_allowed");
    }

    setErrors(error);
  };
  const handleAddReview = async () => {
    try {
      validation();
      if (Object.keys(error).length === 0) {
        // setButtonDisable(true);
        dispatch(addReview(
          languageReducer,
          reviewQuery,
          reviewDetail,
          resetForm,
          setisReview,
          setReview,
          props.onCloseModal,
          setButtonDisable,
          review,
          navigate
        ));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="modal_main">
      <Modal
        className="gift_popup review_modal_"
        show={props.showModal}
        onHide={() => {
          props.onCloseModal(); resetForm()
        }}
      >
        <div className="modal-body">
          <div className="btn-close_main">
            <a type="button" onClick={() => {
              props.onCloseModal(); resetForm()
            }} className="btn-close"
            >
              {" "}
              <svg
                viewport="0 0 12 12"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="1"
                  y1="11"
                  x2="11"
                  y2="1"
                  stroke="#fff"
                  strokeWidth="2"
                />
                <line
                  x1="1"
                  y1="1"
                  x2="11"
                  y2="11"
                  stroke="#fff"
                  strokeWidth="2"
                />
              </svg>
            </a>
          </div>
          <div className="gift_modal_pop">
            <div className="gift_pop_form">
              <h2>{t("_write_a_review")}</h2>
              <div className="row gift_all_frm">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 gift_input_all">
                  <label htmlFor="title" className="form-label">
                    {t("_title")}
                  </label>
                  <input
                    type="text"
                    onChange={handleOnChange}
                    className={
                      `form-control  ` + (errors.title ? "is-invalid" : "")
                    }
                    id="title"
                    name="title"
                    placeholder={t("_enter_title")}
                  />
                  {errors.title && (
                    <div className="invalid-feedback">{errors.title}</div>
                  )}
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 gift_input_all">
                  <label htmlFor="title" className="form-label">
                    {t("_rating")}
                  </label>
                  <ReactStars
                    count={5}
                    onChange={handleRating}
                    name="rating"
                    size={24}
                    {...reactRatings}
                  />
                  {errors.rating && (
                    <div className="invalid-feedback">{errors.rating}</div>
                  )}
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 gift_input_all">
                  <label htmlFor="Description" className="form-label">
                    {t("_write_description")}
                  </label>
                  <textarea
                    type="text"
                    className={
                      `form-control  ` +
                      (errors.description ? "is-invalid" : "")
                    }
                    onChange={handleOnChange}
                    id="description"
                    name="description"
                    placeholder={t("_enter_description")}
                  ></textarea>
                  {errors.description && (
                    <div className="invalid-feedback">{errors.description}</div>
                  )}
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 gift_input_all">
                  <div className="file_section_photo">
                    <div className="file_selection_box">
                      <img src={images["file_main.png"]} alt="" />
                      <p>  {t("_Upload_File")}</p>
                      <button className="btn_select" onClick={handleClick}>
                        {t("_browse_file")}
                      </button>
                      <input
                        ref={ref}
                        multiple={true}
                        type="file"
                        className="d-none"
                        name="files"
                        onChange={handleOnChange}
                        accept="image/jpeg, image/png"
                      />
                      <div><samll className="allowed-image-formate-msg">{t("_Only_jpg_jpeg_or_png_file_allowed")}</samll></div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 gift_input_all">
                  <div className="main_box_img">
                    <div className="row image-div">
                      {reviewDetail.files !== undefined &&
                        reviewDetail.files.length > 0 &&
                        reviewDetail.files.map((item, index) => {
                          return (
                            item.image != undefined && (
                              <div
                                className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 image-single mr-1"
                                key={index}
                              >
                                <div className="image_add_prd cancel-reason-model">
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      handleRemoveImage(item.id, index);
                                    }}
                                    className="text-danger"
                                    icon={faTimesCircle}
                                  />
                                  <img
                                    className="preview"
                                    src={
                                      URL.createObjectURL(item.image)
                                    }
                                    alt={"image-" + index}
                                    key={index}
                                  />
                                </div>
                              </div>
                            )
                          );
                        })}
                    </div>
                    {errors.files && (
                      <div className="invalid-feedback">{errors.files}</div>
                    )}
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 review_btn">
                  <button
                    className="mt-3"
                    onClick={() => {
                      handleAddReview();
                    }}
                    disabled={buttonDisable}
                  >
                    {t("_add_review")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReviewModal;
