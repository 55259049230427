const initialProductState = 0;

const cartCountReducer = (state = initialProductState, action) => {
    switch (action.type) {
        case 'SET_CART_COUNT':
            return action.payload;
        default:
            return state
    }
}


export default cartCountReducer