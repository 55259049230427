import React, { useContext } from 'react';
import { ProductInformation } from '../../context';
import Scrollspy from 'react-scrollspy';
import { useTranslation } from 'react-i18next';
const CultureStickyList = (props) => {
  const { t } = useTranslation();
  const {
    DetailsData,
  } = useContext(ProductInformation);

  const scrollToSection = (sectionId, offset) => {
    const element = document.getElementById(sectionId);
    const yOffset = offset || 0;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <>
      <div className="stickysection_culturedetail animate__animated animate__fadeInDown">
        <div className="stickysection_lists">
          <Scrollspy
            currentClassName="active-item"
            offset={-90}
            // items={itemList}   //Sequence matter.
            items={['overview-div', 'included-div', 'meeting-and-pickup-div', "what-to-expect-div", 'addition_info-div',
              "cancellation-policy-div", "traveller-photos-div", 'review-div'
            ]}

          >
            {
              DetailsData.description.description &&
              <li>
                <a href='#overview-div'
                  className={`floating-nav-btn`}
                  onClick={(e) => { e.preventDefault(); scrollToSection('overview-div', -60); }}
                >{t("_Overview")}
                </a>
              </li>
            }

            {
              (DetailsData.inclusion?.length > 0 || DetailsData.exclusion?.length > 0) &&
              <li>
                <a href='#included-div'
                  className={`floating-nav-btn`}
                  onClick={(e) => { e.preventDefault(); scrollToSection('included-div', -60); }}
                >
                  {t("_What's_Included")}
                </a>
              </li>
            }

            {
              (DetailsData.meeting_point?.length > 0 || DetailsData.pickup_point.length > 0 || DetailsData.pickup_point.length > 0) &&
              <li>
                <a href='#meeting-and-pickup-div'
                  className={`floating-nav-btn`}
                  onClick={(e) => { e.preventDefault(); scrollToSection('meeting-and-pickup-div', -60); }}

                >{t("_Meeting_and_Pickup")}</a>
              </li>
            }

            {
              (DetailsData.what_to_expect?.length > 0 || DetailsData.attraction?.length > 0) &&
              <li>
                <a href='#what-to-expect-div'
                  className={`floating-nav-btn`}
                  onClick={(e) => { e.preventDefault(); scrollToSection('what-to-expect-div', -60); }}
                >
                  {t("_What_to_Expect")}
                </a>
              </li>
            }
            {
              DetailsData.additional_info?.length > 0 &&
              <li >
                <a href='#addition_info-div'
                  className={`floating-nav-btn`}
                  onClick={(e) => { e.preventDefault(); scrollToSection('addition_info-div', -60); }}
                >{t("_Additional_Info")}</a>
              </li>
            }
            {
              DetailsData.policy_type_data &&
              <li>
                <a href="#cancellation-policy-div"
                  className={`floating-nav-btn`}
                  onClick={(e) => { e.preventDefault(); scrollToSection('cancellation-policy-div', -60); }}
                >{t("_Cancellation_Policy")}</a>
              </li>
            }

            {
              DetailsData.traveller_photos?.length > 0 &&
              <li>
                <a href='#traveller-photos-div'
                  className={`floating-nav-btn`}
                  onClick={(e) => { e.preventDefault(); scrollToSection('traveller-photos-div', -60); }}
                >{t("_Traveller_Photos")}</a>
              </li>
            }

            {
              DetailsData.traveller_photos_with_review?.length > 0 &&
              <li>
                <a href='#review-div'
                  className={`floating-nav-btn`}
                  onClick={(e) => { e.preventDefault(); scrollToSection('review-div', -60); }}
                >{t("_Reviews")}</a>
              </li>
            }
          </Scrollspy>
        </div>
      </div>

    </>
  )
}

export default CultureStickyList;