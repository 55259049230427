import React, { useContext, useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/fontawesome-free-solid";
import Modal from "react-bootstrap/Modal";
import { ProductListingInfo } from "../../context";
import { ShimmerCircularImage } from "react-shimmer-effects";
import { useTranslation } from "react-i18next";

const CategoriesListing = () => {
  const { t } = useTranslation();
  const {
    filterListing,
    isFilterDataLoading,
    setIsIntrest,
    isDataLoad,
    isIntrestLoading,
    interestsData,
  } = useContext(ProductListingInfo);
  const [intrestList, setIntrestList] = useState([]);
  const [listData, setListData] = useState([]);
  useEffect(() => {
    if (interestsData) {
      setIntrestList(interestsData);
    }
  }, [interestsData]);
  useEffect(() => {
    if (filterListing?.interests) {
      setListData(filterListing.interests);
    }
  }, [filterListing]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const setCategoryParams = (intrest_id, key = "") => {
    setIsIntrest(intrest_id);
    if (key == "close-model") {
      handleClose();
    }
    // setTimeout(() => {
    //   handleScroll("scroll", 150)
    // }, 50)
  };

  return (
    <>
      {
        // intrestList.length > 0 &&
        <div className="list__page_inner">
          <div className="container">
            <div className="box__list_dis">
              {isIntrestLoading == true ? (
                <>
                  <ShimmerCircularImage className="box_list_img " size={182} />
                  <ShimmerCircularImage className="box_list_img " size={182} />
                  <ShimmerCircularImage className="box_list_img " size={182} />
                  <ShimmerCircularImage className="box_list_img " size={182} />
                  <ShimmerCircularImage className="box_list_img " size={182} />
                  <ShimmerCircularImage className="box_list_img " size={182} />
                  {/* <ShimmerCircularImage className="box_list_img " size={40} /> */}
                </>
              ) : (
                <>
                  {intrestList.slice(0, 6).map((value, index) => {
                    return (
                      <div
                        className="box__list_main not_active_image"
                        key={index}
                      >
                        <button
                          onClick={() => setCategoryParams(value.interest_id)}
                        >
                          {isIntrestLoading == true ? (
                            ""
                          ) : (
                            <div
                              className="box_list_img "
                              style={{
                                backgroundImage: `url(${value.image})`,
                              }}
                            ></div>
                          )}
                          <div className="text-btn-div">
                            <p>{value.title} </p>
                          </div>
                        </button>
                      </div>
                    );
                  })}
                </>
              )}

              {listData.length > 6 && (
                <div className="btn__modal_new">
                  <button type="button" onClick={() => handleShow()}>
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              )}

              <div className="modal-filter-new">
                <Modal
                  centered
                  className="filter__modalMain data_interest__modal"
                  show={show}
                  onHide={handleClose}
                >
                  <div className="btn-close-inner">
                    <button onClick={handleClose}>
                      <img src={images["close_01.png"]} />
                    </button>
                  </div>
                  <Modal.Body>
                    <div className="interest__modal_data">
                      <div class="data_heading">
                        <h2> {t("_Interest")}</h2>
                      </div>

                      <div className="data_inter_listing">
                        <ul>
                          {listData.map((value, index) => {
                            return (
                              <li key={index}>
                                {/* <NavLink to=""> */}
                                <div
                                  className="data_nav_inter cursor-pointer"
                                  onClick={() =>
                                    setCategoryParams(
                                      value.interest_id,
                                      "close-model"
                                    )
                                  }
                                >
                                  <img src={value.image} alt="" />
                                  <h2>{value.title}</h2>
                                </div>
                                {/* </NavLink> */}
                              </li>
                            );
                          })}

                          {/* <li>
                            <NavLink to="">
                              <div className="data_nav_inter">
                                <img src={images["Ct-6.png"]} alt="" />
                                <h2>Interest One</h2>
                              </div>
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="">
                              <div className="data_nav_inter">
                                <img src={images["Ct-6.png"]} alt="" />
                                <h2>Interest One</h2>
                              </div>
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="">
                              <div className="data_nav_inter">
                                <img src={images["Ct-6.png"]} alt="" />
                                <h2>Interest One</h2>
                              </div>
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="">
                              <div className="data_nav_inter">
                                <img src={images["Ct-6.png"]} alt="" />
                                <h2>Interest One</h2>
                              </div>
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="">
                              <div className="data_nav_inter">
                                <img src={images["Ct-6.png"]} alt="" />
                                <h2>Interest One</h2>
                              </div>
                            </NavLink>
                          </li>

                          <li>
                            <NavLink to="">
                              <div className="data_nav_inter">
                                <img src={images["Ct-6.png"]} alt="" />
                                <h2>Interest One</h2>
                              </div>
                            </NavLink>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default CategoriesListing;
