import axios from "../axios";
import { errorMsg, setGetToken } from "./customFn";
import { getLogout } from "./loginAction";

export const asyncProductListing = async (
  languageReducer,
  setListingData,
  navigate,
  setIsDataLoading,
  setPageCount,
  currentPage,
  category,
  location,
  setIsLoading,
  formData,
  setProductCount,
  exrtraData,
  setIntrestTitle,
  setBannerImage,
  setBannerTitle
) => {
  const language = languageReducer.id;
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };
  const currency = JSON.parse(localStorage.getItem("currency"));

  const data = {
    language: language,
    category: category,
    offset: currentPage,
    token: setGetToken(),
    currency: currency.id,
    ...formData,
    ...exrtraData,
  };

  const url = "/product-list";
  await axios
    .post(url, data, options)
    .then((response) => {
      const { data } = response;
      if (data.status) {
        setPageCount(data.page_count);
        setProductCount(data.product_count);
        setListingData(data.data);
        setIntrestTitle(data.intrest_title);
        setBannerImage(data.banner_image);
        setBannerTitle(data.thing_to_do);
        setIsDataLoading(false);
        setIsLoading(true);
      } else {
        setIsDataLoading(false);
        setIsLoading(true);
        // errorMsg("Something Went Wrong !");
      }
    })
    .catch((error) => {
      navigate("/something-went-wrong");
      window.history.pushState(null, "", location.pathname);
    });
  // setTimeout(function () {
  //   setIsDataLoading(false);
  //   setIsLoading(true);
  // }, 1000);
};

// ===========listing-filter========
export const getFilterListing = (
  languageReducer,
  setFilterListing,
  navigate,
  extraParameter,
  setIsfilterListingLoading
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...extraParameter,
      };

      const response = await axios.post("listing", params);
      if (response.data.status === true) {
        setFilterListing(response.data.data.filter);
        if (setIsfilterListingLoading) {
          setIsfilterListingLoading(false);
        }
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};
export const getInterestsListing = (
  languageReducer,
  setIntrestListingLoading,
  setInterests,
  navigate,
  extraParameter
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...extraParameter,
      };
      setIntrestListingLoading(true);
      const response = await axios.post("get-interests", params);
      if (response.data.status === true) {
        if (response.data.data) {
          setInterests(response.data.data);
        }
        setTimeout(() => {
          setIntrestListingLoading(false);
        }, 500);
      } else {
        console.error("API Error:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching interests:", error);
      if (error.response) {
        console.error("Error Response Data:", error.response.data);
      }
    }
  };
};
// ===========listing-filter========

// ==========Search all data=============
export const getAllsearchAllData = async (
  languageReducer,
  setListingData,
  navigate,
  setIsDataLoading,
  setPageCount,
  currentPage,
  slug,
  location,
  setIsLoading,
  formData,
  setProductCount,
  setBannerTitle,
  exrtraData
) => {
  const language = languageReducer.id;
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };
  const currency = JSON.parse(localStorage.getItem("currency"));

  const data = {
    language: language,
    slug: slug,
    offset: currentPage,
    token: setGetToken(),
    currency: currency.id,
    ...formData,
    ...exrtraData,
  };

  const url = "/search-all-data";
  await axios
    .post(url, data, options)
    .then((response) => {
      const { data } = response;
      if (data.status) {
        setPageCount(data.page_count);
        setProductCount(data.product_count);
        setListingData(data.data.products);
        setBannerTitle(data.thing_to_do);
        setIsDataLoading(false);
        setIsLoading(true);
      } else {
        setIsDataLoading(false);
        setIsLoading(true);
        // errorMsg("Something Went Wrong !");
      }
    })
    .catch((error) => {
      navigate("/something-went-wrong");
      window.history.pushState(null, "", location.pathname);
    });
  // setTimeout(function () {
  //   setIsDataLoading(false);
  //   setIsLoading(true);
  // }, 1000);
};
// ==========Search all data=============
