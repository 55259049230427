
const BannerAbout = (props) => {
  const { title, image, descrition } = props.bannerData;
  return (
    <>
      {
        <div className="abt_banner_new">
          <div className="aboutus_section">
            <div
              className="about_bg_img"
              style={{
                backgroundImage: `url(${image})`,
              }}
            >
              <div className="container">
                <div className="aboutus_text">
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  ></h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: descrition,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default BannerAbout;
