import React from "react";
import { ShimmerSectionHeader } from "react-shimmer-effects";
import { ShimmerText } from "react-shimmer-effects";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
const OurJourneyText = ({ ourJourneyData, isLoading }) => {
  return (
    <>
      <div className="our-journey-text">
        <div className="container">
          <div className="heading-top">
            { 
            isLoading ? (
              <>
                  <ShimmerSectionHeader center />
              </>
            ) : (
              <h2>{ourJourneyData?.explore_title_new}</h2>
            )}
          </div>

          <div className="main-text-data">
            {isLoading ? (
              <>
                  <ShimmerText line={10} gap={10} />
              </>
            ) : (
              <>
                <h2>{ourJourneyData?.our_journey_new_title}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: ourJourneyData?.our_journey_new_description,
                  }}
                ></p>
              </>
            )}
          </div>

          <div className="Journeybtm-icon-grid">
            <div className="icon-grid-data row">
              { isLoading ? (
                <>
                   <div className="shimmer-gallery">
                   <ShimmerSimpleGallery
                      imageType="circular"
                      imageHeight={150}
                      col={4}
                      row={1}
                    />
                   </div>
                  </>
              ) : (
                <>
                  {ourJourneyData?.our_new_journey?.length > 0 &&
                    ourJourneyData?.our_new_journey?.map((item, index) => {
                  return (
                    <div className="icon-data-main icon-one col-3" key={index}>
                    <div className="icon-inner">
                      <img src={item?.our_new_logo} alt="Logo" />
                    </div>
                    <div className="text-icon">
                      <p>{item?.our_new_title}</p>
                    </div>
                  </div>
                  )
                    })}
                </>
              )}
            </div>
          </div>

          <div className="image-bottom-new">
            {isLoading ? (
              <>
                  <ShimmerThumbnail height={300} rounded />
              </>
            ) : (
              <>
                <img src={ourJourneyData?.our_journey_new_image} alt="" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurJourneyText;
