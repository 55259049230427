import React from "react";
import {
  AdvertisementBanner,
  Faq,
  Footer,
  GuideDeailsText,
  HeaderSticky,
  NewBanner,
  VideoPlayer,
  WeatherInfo,
} from "../components";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getGuideDetails } from "../actions/guideAction";
import { MetaData } from "../actions/customFn";
import { t } from "i18next";

const GuideDeails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer)
  const [guideDetails, setGuideDetails] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(getGuideDetails(languageReducer, setGuideDetails, navigate, slug));
    }
  }, [languageReducer]);

  let metaData = {
    title: t("_Guide_Details")
  }

  return (
    <>
      {
        metaData &&
        <MetaData data={metaData} />
      }
      {
        Object.keys(guideDetails).length > 0 &&
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3 }}
        >
          <div className="guidedetails">
            <HeaderSticky />
            {/* <GuideBanner/> */}
            <NewBanner
              title={guideDetails.title}
              image={guideDetails.image}
              description={guideDetails.description}
              slider={guideDetails.slider}
            />
            <div className="container">
              <div className="row">
                <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
                  {
                    guideDetails.highlight.length > 0 &&
                    guideDetails.highlight.map((value, index) => {
                      return (
                        <GuideDeailsText
                          title={value.highlight_title}
                          description={value.highlight_description}
                          key={index}
                        />
                      )
                    })
                  }
                </div>
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                  <WeatherInfo />
                  <div className="guidedetails_Advertisement">
                    <AdvertisementBanner
                      bannerData={{
                        side_banner_image: guideDetails.side_banner_image,
                        side_banner_title: guideDetails.side_banner_title,
                        side_banner_description: guideDetails.side_banner_description,
                        side_banner_link: guideDetails.side_banner_link,
                      }}
                    />
                  </div>
                  <div className="guidedetails_videoplayer">
                    <VideoPlayer
                      videoUrl={guideDetails.video_url}
                      videoThumbnail={guideDetails.video_thumbnail_image}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="affiliate_faq">
              <Faq />
            </div>
            <Footer />
          </div>
        </motion.div>
      }
    </>
  );
};

export default GuideDeails;
