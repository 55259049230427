import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getStateList } from "../../actions/stateAction";
import { AddNewCity } from "../../actions/productBuilderAction";
import { LoadingBar } from "../../components";
import { TextField, Autocomplete } from "@mui/material";

const AddCityNewModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    AddCityModalShow,
    setCityModalShow,
    countryList,
    selectedCountryId,
    selectedStateId
  } = props;

  const languageReducer = useSelector((state) => state.languageReducer);

  const [formErrors, setFormErrors] = useState({});
  const [stateList, setStateList] = useState([]);
  const [cityData, setCityData] = useState({
    country_id: selectedCountryId || "",
    state_id: selectedStateId || "",
    city_name: ""
  });

  useEffect(() => {
    // Fetch states whenever the country is selected
    if (cityData?.country_id) {
      dispatch(getStateList(setStateList, cityData.country_id));
    }
  }, [cityData?.country_id, dispatch]);

  // Update cityData when props change
  useEffect(() => {
    setCityData((prevData) => ({
      ...prevData,
      country_id: selectedCountryId || "",
      state_id: selectedStateId || "",
    }));
  }, [selectedCountryId, selectedStateId]);

  const handleModalClose = () => {
    setCityModalShow(false);
    resetForm();
  };

  const resetForm = () => {
    setCityData({
      country_id: selectedCountryId || "",
      state_id: selectedStateId || "",
      city_name: ""
    });
    setFormErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCityData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAutocompleteChange = (field, data) => {
    setCityData((prev) => ({ ...prev, [field]: data?.id || "" }));
  };

  const validateFormData = () => {
    const errors = {};
    if (cityData?.country_id === "") {
      errors.country_id = t("Country is required");
    }
    if (cityData?.state_id === "") {
      errors.state_id = t("State is required");
    }
    if (cityData?.city_name === "") {
      errors.city_name = t("City Name is required");
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFormData()) {
      dispatch(AddNewCity(cityData,setIsLoading,languageReducer));
      handleModalClose();
    }
  };

  return (
    <>
      {isLoading === true ? (
        <div className="blinking-bar-loader-order-detail ">
          <LoadingBar />
        </div>
      ) : (
        <Modal
          show={AddCityModalShow}
          onHide={handleModalClose}
          className="inclusion_pagemodels"
        >
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="inclusion_models">
                <h1>{t("_Add_an_city")}</h1>
                <p>{t("_Please_fill_in_the_details_below_to_add_a_new_city")}</p>

                {/* Country Autocomplete */}
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="search_product_title">
                    <h6>
                      {t("_country")}
                      <span className="required-field">*</span>
                    </h6>
                    {countryList && countryList.length > 0 && (
                      <Autocomplete
                        disablePortal
                        id="country"
                        options={countryList}
                        getOptionLabel={(option) => option.label}
                        value={countryList.find(
                          (obj) => obj.id === cityData.country_id
                        ) || null}
                        onChange={(event, data) =>
                          handleAutocompleteChange("country_id", data)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!formErrors.country_id}
                            helperText={formErrors.country_id}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </div>
                </div>

                {/* State Autocomplete */}
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="search_product_title">
                    <h6>
                      {t("_state")}
                      <span className="required-field">*</span>
                    </h6>
                    {stateList && stateList.length > 0 && (
                      <Autocomplete
                        disablePortal
                        id="state"
                        options={stateList}
                        getOptionLabel={(option) => option.label}
                        value={stateList.find(
                          (obj) => obj.id === cityData.state_id
                        ) || null}
                        onChange={(event, data) =>
                          handleAutocompleteChange("state_id", data)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!formErrors.state_id}
                            helperText={formErrors.state_id}
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </div>
                </div>

                {/* City Name Input */}
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="search_product_title">
                    <h6>
                      {t("_City_Name")}
                      <span className="required-field">*</span>
                    </h6>
                    <TextField
                      variant="outlined"
                      error={!!formErrors.city_name}
                      fullWidth
                      name="city_name"
                      value={cityData.city_name}
                      onChange={handleChange}
                      helperText={formErrors.city_name}
                    />
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="model_lastendbtns">
                  <button
                    className="remove-allsave mr-4"
                    onClick={handleModalClose}
                    type="button"
                  >
                    {t("_cancel")}
                  </button>
                  <button type="submit" className="btnall-save">
                    {t("_Add")}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default AddCityNewModal;
