import { useEffect } from "react";
import {
  AllTags, CategoriesListing,
  FilterBox, Footer,
  HeaderSticky,
  ListingBanner,
  LoadingBar,
  Partner,
  RecommendedThings,
  Testimonial 
} from "../components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import ProductList from "../components/CultureListing/ProductList";
import "react-modern-drawer/dist/index.css";
import { ProductListingInfo } from "../context";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { getFilterListing,getInterestsListing  } from "../actions/listingAction";
import LoadingOverlay from "react-loading-overlay";
import { getAllTagData, getDestinationtListing } from "../actions/destinationListingAction";
import { MetaData, handleScroll, images } from "../actions/customFn";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { useHistory } from "react-router-use-history";

const DestinationListing = () => {
  const [interestsData, setInterests] = useState([]);
  const { t } = useTranslation();
  const languageReducer = useSelector((state) => state.languageReducer);
  const [isDataLoad, setIsDataLoading] = useState(true);
  const [listingData, setListingData] = useState([]);
  const [recommendedThings, setRecommendedThings] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [productCount, setProductCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { id, slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [filterListing, setFilterListing] = useState({});
  const [bannerData, setBannerData] = useState({});
  const [footerData, setFooterData] = useState("");
  const checklogin = useSelector((state) => state.login);

  // Modal All
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filterOn, setFilterOn] = useState(false);
  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerDescription, setBannerDescription] = useState("");
  const [bannerImage, setBannerImage] = useState("")
  const [intrestTitle, setIntrestTitle] = useState("");
  const [isIntrest, setIsIntrest] = useState(0);
  const [date, setDate] = useState("");
  const [filterApplied, setFilterAppleid] = useState(0);
  const [isFilterListingLoading, setIsfilterListingLoading] = useState(true);
  const [isIntrestLoading, setIntrestListingLoading] = useState(true);
  const [isFilterDataLoading, setIsFilterDataLoading] = useState(true);
  const [filterQuery, setFilterQuery] = useState({
    time_filter: [],
    interest_filter: [],
    category_filter: [],
    service_filter: [],
    min_price_filter: "",
    sort: "",
    is_filter: 0,
  });

  const [formData, setFormData] = useState(filterQuery);

  useEffect(() => {
    if (languageReducer != "") {
      setIsDataLoading(true);
      let exrtraData = {
        is_interest: isIntrest,
        date: date,
      };
      getDestinationtListing(
        languageReducer,
        setListingData,
        navigate,
        setIsDataLoading,
        setPageCount,
        currentPage,
        slug,
        location,
        setIsLoading,
        formData,
        setProductCount,
        id,
        exrtraData,
        setIntrestTitle,
        setBannerImage,
        setBannerTitle,
        setBannerDescription
      );
    }
  }, [currentPage, languageReducer, slug, checklogin, filterOn, formData.sort, isIntrest, date]);

  useEffect(() => {
    if (languageReducer !== "") {
      let queryData = {
        page_type: "destination",
        slug: slug,
      };

      dispatch(
        getAllTagData(
          languageReducer,
          navigate,
          queryData,
          setFooterData,
          setIsFilterDataLoading,
          setRecommendedThings
        )
      );
    }
  }, [languageReducer, slug]);

  useEffect(() => {
    if (languageReducer !== "") {
      setIntrestListingLoading(true);
      setIsIntrest(0);
      let extraParameter = {
        is_destination: slug,
      };

      dispatch(
        getInterestsListing(
          languageReducer,
          setIntrestListingLoading,
          setInterests,
          navigate,
          extraParameter,
        )
      );
    }
  }, [slug,languageReducer]);

  useEffect(() => {
    if (formData.is_filter == 1) {
      setCurrentPage(0);
    }
  }, [formData]);

  useEffect(() => {
    if (languageReducer !== "") {
      setIsfilterListingLoading(true);
      setIsIntrest(0);
      let extraParameter = {
        is_destination: slug,
      };

      dispatch(
        getFilterListing(
          languageReducer,
          setFilterListing,
          navigate,
          extraParameter,
          setIsfilterListingLoading
        )
      );
    }
  }, [languageReducer, slug]);

  const handleChange = (event) => {
    setCurrentPage(0);
    setFormData((prev) => ({ ...prev, sort: event.target.value, is_filter: 1 }));
    setFilterQuery((prev) => ({ ...prev, sort: event.target.value, is_filter: 1 }));
    // handleScroll("scroll", 100);
  };

  const resetFilterData = () => {
    if (formData.is_filter) {
      setCurrentPage(0);
      setFormData({
        time_filter: [],
        interest_filter: [],
        category_filter: [],
        service_filter: [],
        min_price_filter: "",
        max_price_filter: "",
        sort: "",
        is_filter: 0,
      });
      setFilterQuery({
        time_filter: [],
        interest_filter: [],
        category_filter: [],
        service_filter: [],
        min_price_filter: "",
        max_price_filter: "",
        sort: "",
        is_filter: 0,
      });
      setFilterOn((status) => !status)               //to call API
      handleScroll("scroll", 150);
    }
  };

  const resetIntrest = () => {
    setIsIntrest(0);
    // setBannerTitle("")
  }

  useEffect(() => {
    let count = formData.category_filter.length + formData.service_filter.length + formData.time_filter.length;
    setFilterAppleid(count);

  }, [formData.category_filter, formData.service_filter, formData.time_filter]);

  let metaData = {
    title: t("_listing")
  }

  return (
    <>
      <LoadingOverlay active={isLoading === false} spinner={<LoadingBar />}>
        {metaData && <MetaData data={metaData} />}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3 }}
        >
          <ProductListingInfo.Provider
            value={{
              isDataLoad,
              setIsDataLoading,
              listingData,
              setPageCount,
              pageCount,
              currentPage,
              setCurrentPage,
              filterQuery,
              setFilterQuery,
              footerData,
              setFormData,
              setFilterOn,
              setShow,
              filterListing,
              interestsData,
              isIntrestLoading,
              isIntrest, setIsIntrest, bannerTitle, bannerImage, setDate, date,
              isFilterListingLoading, filterApplied, isFilterDataLoading,bannerDescription
            }}
          >
            <HeaderSticky />
            <>
              {/* <BannerInner
                bannerData={{
                  title: bannerData.listing_banner_title,
                  image: bannerData.listing_banner_image,
                  description: bannerData.listing_banner_description,
                }}
              /> */}

              <ListingBanner />

              <div className="Culture__page_list new__inner_culture">
                {/* <CategoriesNew /> */}
                <CategoriesListing />
              </div>

              <div className="culture_listing_fillter">
                <div className="container">
                  {/* <div className="culture_listing_top">
                    <div className="row">
                      <div className="col-xl-9 col-lg-6 col-md-7 col-sm-12 col-12">
                        <div className="list_Breadcrumb">
                          <ul>
                            <li>
                              {" "}
                              <NavLink to="/">{t("_home")}/ </NavLink>{" "}
                            </li>
                            <li>
                              {t("_Destination_Listing")}/{slug}{" "}
                              {t("_activities")}{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-5 col-sm-12 col-12">
                        <div className="activities_info">
                          <p>
                            {" "}
                            <span>({productCount})</span>{" "}
                            {t("_activities_found")}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="culture_listing_main">
                    <div className="filter__sort__bar">
                      <div className="list_top_bar sort__innerInfo">
                        <div
                          className="icon_loc"
                        // onClick={() => setLocationModelOpen(true)}
                        >
                          {/* {" "}
                          <FontAwesomeIcon icon={faMapMarkerAlt} />
                          <span> Indore</span> */}
                        </div>

                        <div className="sorting-option-div">
                          {
                            intrestTitle != "" && isIntrest > 0 ?
                              <div className="main-sorting-div">
                                <span className="selected-category-title">
                                  {t("_Selected_Intrest")}
                                </span>
                                <button
                                  className="selected-category-btn"
                                  onClick={() => resetIntrest()}
                                >
                                  {" "}

                                  <span>{intrestTitle ? intrestTitle : ""}</span>
                                  <FontAwesomeIcon icon={faTimes} />
                                </button>
                              </div>
                              : ""
                          }
                          <div className="sort_one">
                            <div className="prd_Sort_by">
                              <span className="new_sort_title">
                                {t("_sort_by")}
                              </span>
                              <div className="sort_select">
                                <Select
                                  labelId="demo-simple-select-label"
                                  className="new_sort_list"
                                  id="demo-simple-select"
                                  label={t("_sort_by")}
                                  value={formData.sort}
                                  onChange={handleChange}
                                >
                                  <MenuItem value="atz">
                                    {t("_a_to_z")}
                                  </MenuItem>
                                  <MenuItem value="zta">
                                    {t("_z_to_a")}
                                  </MenuItem>
                                  <MenuItem value="htl">
                                    {t("_high_to_low")} ({t("_price")})
                                  </MenuItem>
                                  <MenuItem value="lth">
                                    {t("_low_to_high")} ({t("_price")})
                                  </MenuItem>
                                </Select>
                              </div>
                            </div>
                          </div>

                          <div className="sort_two">
                            {
                              filterApplied == 0 ?
                                <button type="button" onClick={() => handleShow()}>
                                  {t("_Apply_Filter")}
                                </button>
                                :
                                <button type="button" onClick={() => handleShow()}>
                                  {t("_Filter_Applied")}({filterApplied})
                                </button>
                            }

                          </div>
                          {
                            (filterApplied > 0 || formData.sort != "") &&
                            <div className="sort_two">
                              <div className="filter_mobile">
                                <button type="button" onClick={() => resetFilterData()}>
                                  {t("_Reset")}
                                </button>
                              </div>
                            </div>
                          }

                        </div>
                      </div>
                    </div>

                    <div className="modal-filter-new">
                      <Modal
                        className="filter__modalMain"
                        show={show}
                        size="md"
                        onHide={handleClose}
                      >
                        <div className="btn-close-inner">
                          <button onClick={handleClose}>
                            <img src={images["close_01.png"]} />
                          </button>
                        </div>
                        <Modal.Body>
                          <div className="page_filter_box">
                            <FilterBox filterListing={filterListing} />
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>

                    <div className="row">
                      {/* <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12 desktop_filter_main">
                        <div className="page_filter_box">
                          <FilterBox filterListing={filterListing} />
                        </div>
                      </div> */}
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 desktop_grid_new">
                        <div className="activities_all_prd" id="scroll">
                          <ProductList />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <RecommendedThings recommendedThings={recommendedThings} />
              <AllTags />
              <Testimonial />
              <Partner />
              {/* <div className="filter_mobile">
                <FilterMobile filterListing={filterListing} />
              </div> */}
              <Footer />
            </>
          </ProductListingInfo.Provider>
        </motion.div>
      </LoadingOverlay>
    </>
  );
};

export default DestinationListing;
