import React from "react";
import { ShimmerSectionHeader } from "react-shimmer-effects";
const TextData = ({ newAboutusData, isLoading }) => {
  return isLoading ? (
    <>
        <ShimmerSectionHeader center />
    </>
  ) : (
    <div className="new-text-data">
      {/* <div className="wave-svg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1920 405" // Keep the viewBox to define the coordinate system
          fill="none"
          style={{ width: "100%", height: "auto" }} // Ensure the SVG takes up the full width
        >
          <path
            d="M406.525 208.897C234.047 128.51 63.6425 181.028 0 217.334V2.30115L1911.42 0V32.983L1920 319.091C1913.99 320.932 1881.03 341.495 1869.59 350.033C1736.58 457.884 1558.53 388.385 1431.96 300.942C1305.39 213.499 1145.56 183.584 988.961 239.579C832.358 295.573 622.123 309.38 406.525 208.897Z"
            fill="url(#paint0_linear_1_568)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1_568"
              x1="955.71"
              y1="405"
              x2="960.9"
              y2="-209.914"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FC5301" stopOpacity="0.21" />
              <stop offset="0.588542" stopColor="#FC5301" stopOpacity="0" />
              <stop offset="0.741697" stopColor="#FC5301" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div> */}
      <div className="container">
        <div className="main-text-data">
          <h2>{newAboutusData?.about_us_new_title}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: newAboutusData?.our_journey_new_description,
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default TextData;
