import { useEffect, useState } from "react";
import {
  LatestBlogsNew,
  NewActivity,
  HeaderSticky,
  Footer,
  BannerAbout,
  LoadingBar,
} from "../components";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBlogData } from "../actions/blogsAction";
import { BlogContext } from "../context";
import LoadingOverlay from "react-loading-overlay";
import { MetaData } from "../actions/customFn";

function Blog() {
  //  Banner All Data Array
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const [blogData, setBlogData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [metaData, setMetaData] = useState("")
  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(getBlogData(languageReducer, setBlogData, navigate, setIsLoading));
    }
  }, [languageReducer]);

  useEffect(() => {
    if (blogData.meta_data) {
      setMetaData(blogData.meta_data)
    }
  }, [blogData]);

  return (
    <>
      {
        Object.keys(blogData).length > 0 &&
        <LoadingOverlay active={isLoading === false} spinner={<LoadingBar />}>
          {
            metaData &&
            <MetaData data={metaData} />
          }
          < motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
          >
            <HeaderSticky />
            <BlogContext.Provider value={blogData}>
              <BannerAbout bannerData={{
                title: blogData.banner_title,
                image: blogData.banner_image,
                descrition: blogData.banner_description
              }}
              />
              <LatestBlogsNew />
              <NewActivity />
            </BlogContext.Provider >
            <Footer />
          </motion.div >
        </LoadingOverlay>

      }
    </>
  );
}

export default Blog;
