import axios from "../axios";
import { setGetToken } from "./customFn";
import { errorMsg, successMsg } from "./customFn";

export const setHome = (data) => {
  return {
    type: "SET_HOMEDATA",
    payload: data,
  };
};

export const setMiddleHome = (data) => {
  return {
    type: "SET_HOMEMIDDELEDATA",
    payload: data,
  };
};

export const setRecomendedTourData = (data) => {
  return {
    type: "SET_RECOMENDED_TOUR_DATA",
    payload: data,
  };
};

export const asyncGetHomeData = (languageReducer, setHomeData, navigate,) => {
  const language = languageReducer.id;
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };
  return (dispatch) => {
    const currency = JSON.parse(localStorage.getItem("currency"));
    const data = {
      language: language,
      token: setGetToken(),
      currency: currency.id,
    };

    const url = "/home";
    axios
      .post(url, data, options)
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setHomeData(data.data);
          dispatch(setHome(data.data));
        } else {
          // errorMsg("Something Went Wrong !");
        }
      })
      .catch((error) => {
        navigate("/something-went-wrong");
        window.history.pushState(null, "", "/");
      });
  };
};

export const asyncGetHomeMiddleData = (
  languageReducer,
  setHomeMiddleData,
  navigate
) => {
  const language = languageReducer.id;
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };
  return (dispatch) => {
    const currency = JSON.parse(localStorage.getItem("currency"));
    const data = {
      language: language,
      token: setGetToken(),
      currency: currency.id,
    };

    const url = "/home-middle";
    axios
      .post(url, data, options)
      .then((response) => {
        const { data } = response;
        if (data.status) {
          setHomeMiddleData(data.data);
          dispatch(setMiddleHome(data.data));
        } else {
          // errorMsg("Something Went Wrong !");
        }
      })
      .catch((error) => {
        navigate("/something-went-wrong");
        window.history.pushState(null, "", "/");
      });
  };
};


export const asyncGetRecomendedTourData = (
  languageReducer,
  navigate,
  slug,
  setIsCategoryLoading
) => {

  const language = languageReducer.id;
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };
  return (dispatch) => {
    const currency = JSON.parse(localStorage.getItem("currency"));
    const data = {
      language: language,
      token: setGetToken(),
      currency: currency.id,
      slug: slug
    };

    const url = "/home-middle-recommended-tours";
    axios
      .post(url, data, options)
      .then((response) => {
        const { data } = response;
        if (data.status) {
          dispatch(setRecomendedTourData(data.data));
          if (slug != "recommended_tours") {
            setIsCategoryLoading(false);
          }
        } else {
          // errorMsg("Something Went Wrong !");
        }
      })
      .catch((error) => {
        navigate("/something-went-wrong");
        window.history.pushState(null, "", "/");
      });
  };
};

// Get Details for Supplier New Page
export const asyncGetSupplierNewPageDetails = async (setSupplierData, setIsLoading, languageReducer) => {
  try {
    setIsLoading(true);
    const language = languageReducer.id;
    const response = await axios.post("suppliers", { language });
    const {data} = response;
    if (data && data?.status === true) {
      setIsLoading(false);
      setSupplierData(data.data);
    } else {
      setIsLoading(false);
      errorMsg("Something Went Wrong !");
    }
  } catch (error) {
    setIsLoading(false);
  }
};


// Get Details for New About us Page
export const asyncGetNewAboutusPageDetails = async(setAboutusData, setIsLoading, languageReducer) => {
  try{
    setIsLoading(true);
    const language = languageReducer.id;
    const url = "/about-us";
    const response = await axios.post(url,{language});
    const {data} = response;
    if (data && data?.status === true) {
      setIsLoading(false);
      setAboutusData(data.data);
    } else {
      setIsLoading(false);
      errorMsg("Something Went Wrong !");
    }
  }
  catch(errors){
    setIsLoading(false);
  }
}

// Get Details for New affiliate-new Page
export const asyncGetNewAffiliatePageDetails = async(setAffiliateNewData, setIsLoading, languageReducer) => {
  try{
    setIsLoading(true);
    const language = languageReducer.id;
    const url = "/affiliate-new";
    const response = await axios.post(url,{language});
    const {data} = response;
    if (data && data?.status === true) {
      setIsLoading(false);
      setAffiliateNewData(data.data);
    } else {
      setIsLoading(false);
      errorMsg("Something Went Wrong !");
    }
  }
  catch(errors){
    setIsLoading(false);
  }
}