import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const MessageModal = (props) => {
  const { t } = useTranslation();
  const { messageModal, setMessageModal, isFor } = props;
  

  return (
    <div className="modal_main">
      <Modal
        className="payment_confirmation_modal"
        show={messageModal}
        onHide={() => setMessageModal(false)}
        centered
      >
        <div className="modal-body">
          <div className="gift_modal_pop p-2">
            <div className="gift_pop_form">
              <h4 className="text_heading">
                {isFor == "signup"
                  ? t(
                      "_mail_sent_to_your_registered_email_address_dont_miss_to_check_spam_box_too"
                    )
                  : t(
                      "_verfication_link_was_expired_new_link_send_to_your_mail"
                    )}
              </h4>
              <div className="modal_button">
                <button
                  className="remove"
                  onClick={() => setMessageModal(false)}
                >
                  {t("_ok")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MessageModal;
