import { useEffect, useState } from "react";

const GuideDeailsText = (props) => {
  const [bannerData, setBannerData] = useState({});

  useEffect(() => {
    setBannerData({
      title: props.title,
      description: props.description,
    });
  }, [props]);

  return (
    <>
      {
        Object.keys(bannerData).length > 0 &&
        <div className="GuideDeailsText_main">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="GuideDeailsText_inner">
                <div className="GuideDeailsText_data">
                  <h2>{bannerData.title}</h2>
                  <div className="GuideDeailsText_data_inner">
                    {/* <h3>Historical Significance</h3> */}
                    <p dangerouslySetInnerHTML={{ __html: bannerData.description }}>
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default GuideDeailsText;
