import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addWishlistActivity } from "../../actions/wishlistAction";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const WishlistModel = (props) => {
  const { t } = useTranslation();
  const {
    openWishlistModel,
    closeModel,
    wishlistParameter,
    setOpenNewListModel,
    setWishLishedArray,
    activityList,
    setIsWishlished,
    isWishlished,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const languageReducer = useSelector((state) => state.languageReducer);

  const addActivity = (activity_id) => {
    let data = {
      product_id: wishlistParameter.newId,
      country: wishlistParameter.country,
      activity_id: activity_id,
    };
    dispatch(
      addWishlistActivity(
        data,
        languageReducer,
        navigate,
        location,
        closeModel,
        wishlistParameter,
        setWishLishedArray,
        setIsWishlished,
        isWishlished
      )
    );
  };

  return (
    <div className="modal_main">
      <Modal
        className="gift_popup review_modal_"
        show={openWishlistModel}
        onHide={closeModel}
      >
        <div className="modal-body">
          <div className="btn-close_main">
            <a type="button" onClick={closeModel} className="btn-close">
              {" "}
              <svg
                viewport="0 0 12 12"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="1"
                  y1="11"
                  x2="11"
                  y2="1"
                  stroke="#fff"
                  strokeWidth="2"
                />
                <line
                  x1="1"
                  y1="1"
                  x2="11"
                  y2="11"
                  stroke="#fff"
                  strokeWidth="2"
                />
              </svg>
            </a>
          </div>
          <div className="gift_modal_pop view_review wishlist-modal main">
            <div className="gift_pop_form">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="gift_modal_heading">
                    <h2> {t("_Select_List")}</h2>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="gift_modal_text">
                    <h2
                      className="new-list"
                      onClick={() => {
                        closeModel();
                        setOpenNewListModel(true);
                      }}
                    >
                      {" "}
                      {t("_New_List")}
                    </h2>
                  </div>
                </div>
              </div>
              {/* <hr /> */}
              {/* <h2>View Review</h2> */}
              <div className="gift_all_frm_main">
                {activityList.length > 0 &&
                  activityList.map((value, index) => {
                    return (
                      <div
                        className="gift_input_all"
                        onClick={() => addActivity(value.id)}
                        key={index}
                      >
                        <div className="customer_rating">
                          <div className="customer_descb_main">
                            {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 activity-list-img"> */}
                            <div className="customer_modal_img">
                              <img src={value.image} alt="" />
                              {/* <img src={images["logo.png"]} alt="" /> */}
                            </div>
                            {/* </div> */}
                            {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 "> */}
                            <div className="customer_modal_text">
                              <h6>{value.country}</h6>
                              <p>
                                {value.activity_count}
                                {t("_Activity")}
                              </p>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                        {/* <div className="border_bottom">
                                                    <hr />
                                                </div> */}
                      </div>
                    );
                  })}
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 review_btn"></div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WishlistModel;
