import React, { useState, useEffect, useContext } from "react";
import { ShimmerSectionHeader, ShimmerSimpleGallery, ShimmerButton } from "react-shimmer-effects";
import LocaleContext from "../LocaleContext";
import { useSelector } from "react-redux";
import LoginModal from "../Modal/LoginModal";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { becomeAffiliate } from "../../actions/becomeAffiliateAction";
import { useLocation, useNavigate } from "react-router-dom";
import BecomeSupplierModal from "../Modal/BecomeSupplierModal";

const SellTourysta = ({ sellTourystaData, isLoading }) => {
  const [sellData, setSellData] = useState(sellTourystaData);
  const { SetLoginKey, loginKey } = useContext(LocaleContext);
  const [modalLogin, setModalLogin] = useState(false);
  const checkLogin = useSelector((state) => state.login);
  const { t } = useTranslation();
  const [showSupplierModal,setshowSupplierModal] = useState(false);
  // Show Login Modal
  const showModal = (status) => {
    SetLoginKey(status);
    setModalLogin(true);
  };
  const closeLoginModal = () => {
    setModalLogin(false);
  };

  useEffect(() => {
    setSellData(sellTourystaData);
  }, [sellTourystaData]);

//==============
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const profileReducer = useSelector((state) => state.profileReducer);
  const become_user = "Partner";
  const applyForSupplier = () => {
    Swal.fire({
      title: "Are your sure ?",
      text: "You will become an supplier user and your data as customer will be no longer available...!!!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(155 176 199)",
      cancelButtonColor: "rgb(227 46 46)",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.isConfirmed === true) {
        dispatch(becomeAffiliate(languageReducer, navigate, become_user));
      }
    });
  };

  return (
    <div className="sell-tourysta-section">
      <div className="container">
        {isLoading ? (
          // Show the shimmer effect while loading
          <>
            <div className="shimmer-header">
              <ShimmerSectionHeader center />
              <ShimmerSectionHeader center />
              <ShimmerSimpleGallery
                col="3"
                row="1"
                colimageHeight={100}
              />
            </div>
            <div className="shimmer-header shimmer-bt">
              <ShimmerButton size="lg" mode="light" center />
            </div>
          </>
        ) : (
          <>
            <div className="data-top-main mt-5">
              <h2>{sellData?.suppliers_why_sell_title}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: sellData?.suppliers_why_sell_description,
                }}
              ></p>
            </div>

            <div className="card-data-main">
              <h2>{sellData?.selling_activities_heading}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: sellData?.selling_activities_description,
                }}
              ></p>

              <div className="card-data-list">
                <div className="card-data-grid">
                  {sellData?.activity_journey?.map((value, index) => (
                    <div className="card-one card-main" key={index}>
                      <img src={value?.activity_logo} alt="Logo" />
                      <p>{value?.activity_title}</p>
                    </div>
                  ))}
                </div>

                <div className="btn-bottom-data">
                  {checkLogin === false ? (
                    <button
                      onClick={() => showModal("login")}
                      aria-label={t("_Let's_Start")}
                    >
                      {t("_Let's_Start")}
                    </button>
                  ) : profileReducer?.user_type == "Customer" ? (
                    <button
                      aria-label="Become a Supplier"
                      onClick={() => setshowSupplierModal(true)}
                    >
                      {" "}
                      {t("_Let's_Start")}{" "}
                    </button>
                  ) : profileReducer?.user_type == "Partner" ? (
                    <button aria-label={t("_already_a_supplier")}>
                      {" "}
                      {/* {t("_Become_a_Supplier")}{" "} */}
                      {/* You are already a Supplier */}
                      {t("_already_a_supplier")}
                    </button>
                  ): profileReducer?.user_type == "Affiliate" ? (
                   ''
                  ) : ( ''
                    // <button aria-label="Become a Supplier">
                    //   {" "}
                    //   {t("_Become_a_Supplier")}{" "}
                    // </button>
                  ) }
                </div>
              </div>
            </div>
            <LoginModal
              loginInfo={loginKey}
              SetLoginKey={SetLoginKey}
              LoginModal={modalLogin}
              LogincloseModal={closeLoginModal}
              userType={"Partner"}
            />
            {showSupplierModal && 
            <BecomeSupplierModal showSupplierModal ={showSupplierModal} setshowSupplierModal={setshowSupplierModal} />
            }
          </>
        )}
      </div>
    </div>
  );
};

export default SellTourysta;
