import { useContext, useEffect, useState } from "react";
import FsLightbox from "fslightbox-react";
import { ProductInformation } from "../../context";
import { getWishlistActivityList, removeFromWishlist } from "../../actions/wishlistAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../Modal/LoginModal";
import { useTranslation } from "react-i18next";
import WishlistModel from "../Modal/WishlistModel"; 
import CreateNewListModel from "../Modal/CreateNewListModel";
import LocaleContext from "../LocaleContext";
import ReviewImageModal from "../Modal/ReviewImageModal";
const ImageBanner = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const location = useLocation();
  const {
    SetLoginKey,
    loginKey,
  } = useContext(LocaleContext);
  const languageReducer = useSelector((state) => state.languageReducer);
  const profileReducer = useSelector((state) => state.profileReducer)
  const checklogin = useSelector((state) => state.login);
  const [ModalLogin, setModalLogin] = useState(false);
  const [productIndex, setProductIndex] = useState(0);
  const { DetailsData } = useContext(ProductInformation);
  const [isWishlished, setIsWishlished] = useState(DetailsData.is_wishlist);
  const [toggler, setToggler] = useState(false);

  const [wishlistParameter, setWishlistParameter] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [openWishlistModel, setOpenWishlistModel] = useState(false);
  const [openNewListModel, setOpenNewListModel] = useState(false);
  const closeWishlistModel = () => {
    setOpenWishlistModel(false);
  };
  const closeNewListModel = () => {
    setOpenNewListModel(false);
  };

  const addWishList = () => {
    if (checklogin) {
      setWishlistParameter({
        id: id,
        newId: DetailsData.product_id,
        country: DetailsData.country
      });

      // if (wishLishedArray.includes(newId)) {
      if (isWishlished == 1) {
        dispatch(removeFromWishlist(
          id,
          DetailsData.product_id,
          languageReducer,
          navigate,
          location,
          "",
          "",
          "",
          // setWishLishedLoader,
          // setWishLishedArray,
          // wishLishedArray
          setIsWishlished,
          isWishlished
        ));
      } else {
        // setOpenWishlistModel(true);
        dispatch(getWishlistActivityList(
          setActivityList,
          languageReducer,
          navigate,
          location,
          setOpenWishlistModel,
          setOpenNewListModel
        ));
      }
    } else {
      setModalLogin(true);
      SetLoginKey("login");
    }

  }

  const closeLoginModal = () => {
    setModalLogin(false);
  };

  let firstDiv = "col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12";
  let secondDiv = "col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12";
  let displayNone = ""
  if (DetailsData.images.length > 0) {
    if (DetailsData.images.length == 2) {
      firstDiv = "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12";
      secondDiv = "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12";
      displayNone = "d-none"
    }
    if (DetailsData.images.length == 1) {
      firstDiv = "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12";
      secondDiv = "d-none";
    }
  };

    // Image Show Modal
    const closeReviewModal = () => {
      setToggler(false);
    };
  


  return (
    <div className="image_slide_show">
      {DetailsData.images.length > 0 && (
        <div className="container">
          <div className="row">
            <div className={firstDiv}>
              <div className="image_slider_main">
                <div className="main_image_banner">
                  <img
                    src={DetailsData.images[0]}
                    alt=""
                    onClick={() => { setToggler(!toggler); setProductIndex(0) }}
                  />

                  <div className="btn_show_more">
                    {
                      DetailsData.image_count > 0 &&
                      <button onClick={() => { setToggler(!toggler); setProductIndex(3) }}>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_197_131)">
                              <path
                                d="M9.7522 9.1875C9.7522 9.63505 9.57441 10.0643 9.25794 10.3807C8.94147 10.6972 8.51225 10.875 8.0647 10.875C7.61714 10.875 7.18792 10.6972 6.87145 10.3807C6.55499 10.0643 6.3772 9.63505 6.3772 9.1875C6.3772 8.73995 6.55499 8.31072 6.87145 7.99426C7.18792 7.67779 7.61714 7.5 8.0647 7.5C8.51225 7.5 8.94147 7.67779 9.25794 7.99426C9.57441 8.31072 9.7522 8.73995 9.7522 9.1875Z"
                                fill="white"
                              />
                              <path
                                d="M5.2522 4.125C4.65546 4.125 4.08316 4.36205 3.66121 4.78401C3.23925 5.20597 3.0022 5.77826 3.0022 6.375V17.625C3.0022 18.2217 3.23925 18.794 3.66121 19.216C4.08316 19.6379 4.65546 19.875 5.2522 19.875H18.7522C19.3489 19.875 19.9212 19.6379 20.3432 19.216C20.7651 18.794 21.0022 18.2217 21.0022 17.625V6.375C21.0022 5.77826 20.7651 5.20597 20.3432 4.78401C19.9212 4.36205 19.3489 4.125 18.7522 4.125H5.2522ZM18.7522 5.25C19.0506 5.25 19.3367 5.36853 19.5477 5.5795C19.7587 5.79048 19.8772 6.07663 19.8772 6.375V13.6875L15.6281 11.4971C15.5226 11.4443 15.4031 11.4259 15.2866 11.4447C15.1701 11.4635 15.0625 11.5184 14.9789 11.6017L10.8052 15.7755L7.8127 13.782C7.70465 13.7101 7.57505 13.6777 7.44588 13.6904C7.3167 13.7031 7.1959 13.7601 7.10395 13.8517L4.1272 16.5V6.375C4.1272 6.07663 4.24572 5.79048 4.4567 5.5795C4.66768 5.36853 4.95383 5.25 5.2522 5.25H18.7522Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_197_131">
                                <rect
                                  width="18"
                                  height="18"
                                  fill="white"
                                  transform="translate(3 3)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        {t("_more")} {DetailsData.image_count} {t("_images")}
                      </button>
                    }
                  </div>

                  {
                    profileReducer.user_type !== "Hotel" && location.pathname.split('/')[1] !== "product-preview" &&
                    <div className="btn_wishlist">
                      <button
                        // onClick={() => addToWishlist()}
                        onClick={() => addWishList()}
                      >
                        <span className="header_menu_icon">
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_148_1058)">
                              <path
                                d="M17.5 1.91699C16.3739 1.93451 15.2724 2.24885 14.3067 2.82826C13.341 3.40768 12.5453 4.23166 12 5.21699C11.4546 4.23166 10.6589 3.40768 9.6932 2.82826C8.7275 2.24885 7.62601 1.93451 6.49996 1.91699C4.7049 1.99498 3.01366 2.78025 1.79574 4.10122C0.577818 5.4222 -0.0677922 7.17152 -4.17093e-05 8.96699C-4.17093e-05 13.514 4.78596 18.48 8.79996 21.847C9.69618 22.6001 10.8293 23.013 12 23.013C13.1706 23.013 14.3037 22.6001 15.2 21.847C19.214 18.48 24 13.514 24 8.96699C24.0677 7.17152 23.4221 5.4222 22.2042 4.10122C20.9863 2.78025 19.295 1.99498 17.5 1.91699ZM13.915 20.317C13.3789 20.7684 12.7007 21.0159 12 21.0159C11.2992 21.0159 10.621 20.7684 10.085 20.317C4.94696 16.006 1.99996 11.87 1.99996 8.96699C1.9316 7.70171 2.36632 6.46072 3.20932 5.51469C4.05232 4.56866 5.23519 3.99434 6.49996 3.91699C7.76472 3.99434 8.9476 4.56866 9.7906 5.51469C10.6336 6.46072 11.0683 7.70171 11 8.96699C11 9.23221 11.1053 9.48656 11.2929 9.6741C11.4804 9.86164 11.7347 9.96699 12 9.96699C12.2652 9.96699 12.5195 9.86164 12.7071 9.6741C12.8946 9.48656 13 9.23221 13 8.96699C12.9316 7.70171 13.3663 6.46072 14.2093 5.51469C15.0523 4.56866 16.2352 3.99434 17.5 3.91699C18.7647 3.99434 19.9476 4.56866 20.7906 5.51469C21.6336 6.46072 22.0683 7.70171 22 8.96699C22 11.87 19.053 16.006 13.915 20.313V20.317Z"
                                fill={isWishlished === 0 ? "#232A35" : "#FC5301"}
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_148_1058">
                                <rect width="24" height="24" fill="white"></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        {isWishlished == 0
                          ? t("_add_to_wishlist")
                          :
                          t("_remove_to_wishlist")}

                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className={`${secondDiv} mobile_div_image`}>
              <div className={DetailsData.images.length === 2 ? "image_slider_main" : "slider_list_img"}>
                <div className="image_grid_1">
                  <img
                    src={DetailsData.images[1]}
                    alt=""
                    onClick={() => { setToggler(!toggler); setProductIndex(1) }}
                  />
                </div>
                <div className={`image_grid_1 ${displayNone}`}>
                  <img
                    src={DetailsData.images[2]}
                    alt=""
                    onClick={() => { setToggler(!toggler); setProductIndex(2) }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="fslight_box_main">
            <FsLightbox
              toggler={toggler}
              sources={DetailsData.images}
              sourceIndex={productIndex}
            />
          </div> */}
        </div>
      )
      }
        <ReviewImageModal
        onCloseModal={closeReviewModal}
        showModal={toggler}
        productIndex={productIndex}
        activeKey="provider"
      />
      <LoginModal
        LoginModal={ModalLogin}
        LogincloseModal={closeLoginModal}
      />

      {
        wishlistParameter &&
        <>
          <WishlistModel
            openWishlistModel={openWishlistModel}
            closeModel={closeWishlistModel}
            setOpenNewListModel={setOpenNewListModel}
            wishlistParameter={wishlistParameter}
            setWishLishedArray={""}
            activityList={activityList}
            setIsWishlished={setIsWishlished}
            isWishlished={isWishlished}
          />
          <CreateNewListModel
            openNewListModel={openNewListModel}
            closeModel={closeNewListModel}
            wishlistParameter={wishlistParameter}
            setWishLishedArray={""}
            setIsWishlished={setIsWishlished}
            isWishlished={isWishlished}
          />
        </>
      }
    </div >
  );
};

export default ImageBanner;
