import { useEffect, useState } from "react";
import {
  BannerAbout,
  Footer,
  HeaderSticky,
  LoadingBar,
  Partner,
  Testimonial,
} from "../components";
import { MetaData, handleScroll, images } from "../actions/customFn";
import { motion } from "framer-motion";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGuideData, getGuideList } from "../actions/guideAction";
import LoadingOverlay from "react-loading-overlay";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";

const Guide = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer)
  const [guideData, setGuideData] = useState({});
  const [guideList, setGuideList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [metaData, setMetaData] = useState("")

  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(getGuideData(languageReducer, setGuideData, navigate, setIsLoading));
    }
  }, [languageReducer]);


  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(getGuideList(languageReducer, setGuideList, navigate, setPageCount, currentPage));
    }
  }, [languageReducer, currentPage]);

  useEffect(() => {
    if (guideData.meta_data) {
      setMetaData(guideData.meta_data)
    }
  }, [guideData]);

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
    handleScroll("scroll", 100);
  };


  var offerData = [
    { image: "offer-img1.png", discount: "25", name: "Weekly Flash Sale" },
    { image: "offer-img2.png", discount: "20", name: "Summer exclusive" },
    { image: "offer-img3.png", discount: "30", name: "season Flash Sale" },
  ];

  return (
    <>
      {
        Object.keys(guideData).length > 0 &&
        <LoadingOverlay active={isLoading === false} spinner={<LoadingBar />}>
          {
            metaData &&
            <MetaData data={metaData} />
          }
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
          >
            <HeaderSticky />
            <BannerAbout bannerData={{
              title: guideData.banner_title,
              image: guideData.banner_image,
              descrition: guideData.banner_description
            }}
            />
            <div className="travelguide" id="scroll">
              <div className="container">
                <div className="destination_top">
                  <h2>{guideData.top_destination_heading}</h2>
                  <div className="row">
                    {
                      guideList.length > 0 &&
                      guideList.map((value, index) => {
                        return (
                          <div
                            className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-4"
                            key={index}
                          >
                            <NavLink to={`/guide-deails/${value.slug}`} target="_blank">
                              <div
                                className="guide-desg"
                                style={{
                                  backgroundImage: `url(${value.image})`,
                                }}
                              >
                                <div className="over-img1">
                                  <h4 className="desg-txt">{value.title}</h4>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        );
                      })}
                  </div>
                  {
                    pageCount > 1 &&
                    <div className="paginate_all">
                      <ReactPaginate
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        containerClassName={"navigationButtons"}
                        previousLinkClassName={"previousButton"}
                        nextLinkClassName={"nextButton"}
                        disabledClassName={"navigationDisabled"}
                        activeClassName={"active"}
                        pageRange={2}
                        marginPagesDisplayed={2}
                        nextLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7.172"
                            height="14"
                            viewBox="0 0 7.172 14"
                          >
                            <path
                              id="fi-rr-angle-small-down"
                              d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                              transform="translate(7.172) rotate(90)"
                              fill="#000"
                            />
                          </svg>
                        }
                        previousLabel={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7.172"
                            height="14"
                            viewBox="0 0 7.172 14"
                          >
                            <path
                              id="fi-rr-angle-small-down"
                              d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                              transform="translate(15.086 -5) rotate(90)"
                              fill="#232a35"
                            />
                          </svg>
                        }
                      />
                    </div>
                  }

                </div>

                <div className="special-offer">
                  <h2>{t('_special_offers')}</h2>
                  <div className="row">
                    {offerData.map(function (offers, index) {
                      return (
                        <div
                          className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 "
                          key={index}
                        >
                          <div className="offer-card">
                            <div className="leftpart-offer">
                              <div className="offer-txt">
                                <h4>{offers.name}</h4>
                                <h6>{t("_up_to")} {offers.discount}% {t("_off")}</h6>
                                <button className="moreoffer">{t("_view_detail")}</button>
                              </div>
                            </div>
                            <div className="rightpart-offer">
                              <img
                                className="offer-mimg"
                                src={images[offers.image]}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <Testimonial />
            <Partner />
            <Footer />
          </motion.div>
        </LoadingOverlay>

      }
    </>
  );
};

export default Guide;
