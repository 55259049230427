import { images } from "../../actions/customFn";

import { useTranslation } from "react-i18next";

const SubscribeNewsletter = () => {
  const { t } = useTranslation();
  return (
    <div className="subscribe_info_main">
      <div className="">
        <div
          className="subscribe_info_outer"
          style={{ backgroundImage: `url(${images["subscribe_info1.png"]})` }}
        >
          <h2>{t("_dream_plan_travel")} !</h2>

          <div className="subscription_fields_part">
            <input type="text" placeholder={t("_subscribe_email")} />
            <button>{t("_email_subscribe")}</button>
          </div>

          <p>{t("_subscribe_newsleter_content")}</p>
        </div>
      </div>
    </div>
  );
};

export default SubscribeNewsletter;
