import { useEffect, useState } from "react";
import { HeaderSticky } from "../../components";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getHotelCommissionDetails } from "../../actions/hotelUserAction";
import { MetaData } from "../../actions/customFn";

const HotelUserCommissionDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const languageReducer = useSelector((state) => state.languageReducer);
    const [commissionDetails, setCommissionDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (languageReducer !== "") {
            setIsLoading(true);
            dispatch(
                getHotelCommissionDetails(
                    languageReducer,
                    setCommissionDetails,
                    navigate,
                    setIsLoading,
                    id
                )
            );
        }
    }, [languageReducer]);

    let metaData = {
        title: t("_commission_details")
    }
    return (
        <>
            {
                metaData &&
                <MetaData data={metaData} />
            }
            {Object.keys(commissionDetails).length > 0 && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 3 }}
                >
                    <HeaderSticky />
                    <div className="upcoming_detail">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                    <div className="list_Breadcrumb">
                                        <ul>
                                            <li>
                                                <NavLink to="/"> {t("_home")} / </NavLink>
                                            </li>
                                            {/* <li>
                        <NavLink to="/affiliate-user"> Affiliate User / </NavLink>
                      </li> */}
                                            <li>
                                                <NavLink to="/hotel-user-commission-history">
                                                    {" "}
                                                    {t("_commission_history")} /{" "}
                                                </NavLink>
                                            </li>
                                            <li> {t("_commission_details")}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 go-back-btn">
                                    <NavLink
                                        className="home-btn"
                                        to={`${location.state.prevPath}`}
                                    // to="/hotel-user-commission-history"

                                    >
                                        {t("_go_back")}
                                    </NavLink>
                                </div>
                            </div>

                            <div className="order_details_main">
                                <div className="order_inner">
                                    <div className="order_all_icon">
                                        <div className="order_heading">
                                            <h2>{t("_order_detail")}</h2>
                                        </div>
                                        <div className="order_all_left_info">
                                            <div className="date-inner-details">
                                                <span>{t("_order_id")}</span>
                                                <h2>{commissionDetails.order_id}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="order_main_info">
                                        <div
                                        // className="order_status"
                                        ></div>
                                        <div className="order-date-info">
                                            <span>{t("_order_date")}</span>
                                            <p>{commissionDetails.date}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="order_details_table">
                                <div className="booking_top_info">
                                    <h2>{t("_booking_detail")}</h2>
                                </div>

                                <div className="booking_table_info">
                                    <div className="Upcoming_booking_table table-responsive affilliate_commission_details">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>{t("_product_name")}</th>
                                                    <th>{t("_amount")}</th>
                                                    <th>{t("_commission")}</th>
                                                    <th className="float-right">{t("_commission")} {t("_amount")}</th>
                                                    {/* <th>{t("_Remark")}</th> */}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {commissionDetails.product_json &&
                                                    commissionDetails.product_json.length > 0 &&
                                                    commissionDetails.product_json.map((value, index) => {
                                                        return (
                                                            <tr
                                                                className={value.is_cancel == 1 ? "order-cancelled" : ""}
                                                                key={index}
                                                            >
                                                                <td>
                                                                    <div className="all-prd-info">
                                                                        <div className="prd-info-detail">
                                                                            <p className="tag_p_table">
                                                                                {" "}
                                                                                {value.product_name}{" "}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <span className="icon_price">$</span>{" "}
                                                                    {value.product_amount}
                                                                </td>
                                                                <td>
                                                                    <span className="icon_price"></span>{" "}
                                                                    {value.commission} %
                                                                </td>
                                                                <td className="">
                                                                    <div >
                                                                        <span className="icon_price ">$</span>{" "}
                                                                        {value.commission_amount}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="order_cart_inner">
                                        {commissionDetails.amount &&
                                            commissionDetails.amount.length > 0 &&
                                            commissionDetails.amount.map((value, index) => {
                                                return (
                                                    <div className="total_main_price mb-1" key={index}>
                                                        <span>{value.new_title}</span>
                                                        <p>
                                                            {" "}
                                                            <span className="iscon_price"></span>{" "}
                                                            {value.amount}
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </>
    );
};

export default HotelUserCommissionDetails;
