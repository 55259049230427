import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { t } from "i18next";
import moment from "moment";
import "flatpickr/dist/flatpickr.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/fontawesome-free-solid";
import { images } from "../../../actions/customFn";

const NameOptionModal = (props) => {

    const [selectedItem, setSelectedItem] = useState();
    const handleClicked = (id, data) => {
    //   setHideLoder(true);
    //   if (selectedItem == id) {
    //     setSelectedItem(-1);
    //   } else {
    //     setSelectedItem(id);
    //   }
  
    //   setAvilableOption({
    //     ...avilableOption,
    //     option_id: data.option_id,
    //     time_slot:
    //       data.option_id == avilableOption.option_id
    //         ? avilableOption.time_slot
    //         : "", // to insure that time slot is selected for correct option id.
    //   });
    };

  return (
    <div className="modal_main">
    <Modal
      className="nameoption_modalmain"
      show={props.eyemodal}
      onHide={props.handleCloseEyemodal}
      centered
    >
      <div className="modal-body">
        <div className="btn-close_main">
          <button type="button" onClick={props.handleCloseEyemodal} className="btn-close">
            {" "}
            <svg
              viewport="0 0 12 12"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="11"
                x2="11"
                y2="1"
                stroke="#fff"
                strokeWidth="2"
              />
              <line
                x1="1"
                y1="1"
                x2="11"
                y2="11"
                stroke="#fff"
                strokeWidth="2"
              />
            </svg>
          </button>
        </div>
        <div className="name_optionmodalsection">
          
        <div
                  className={`new_accorian `}
                //   key={index}
                >
                  <div className="show_availabillity  new_accorian">
                    <div
                      className={
                        true ? "orange_border" : "gray_border"
                      }
                    >
                      <Accordion
                        activeKey={1}
                        // onClick={() => {
                        //   value.require_participants &&
                        //     handleClicked(index, value);
                        // }}
                        key={1}
                      >
                        <Accordion.Item eventKey={1}>
                          <Accordion.Header>
                            <div className="tag_span"> 
                             
                                <div className="accordiansign">
                                  {selectedItem == 1 ? (
                                    <>
                                      <p>{t("_close")}</p>
                                      <FontAwesomeIcon icon={faAngleUp} />
                                    </>
                                  ) : (
                                    <>
                                      <p>{t("_open")}</p>
                                      <FontAwesomeIcon icon={faAngleDown} />
                                    </>
                                  )}
                                </div>
                               
                            </div>
                            <h2>Name this option</h2>
                            <p className="option_description">
                            Option Description
                            </p>
                        
                              {/* <div className="availability_location">
                                <div className="location">
                                  <img
                                    src={images["repeat.png"]}
                                    className="repeat_clock"
                                    alt="repeat"
                                  />
                                  <span>656757657</span>
                                </div>
                              </div> */}
                         

                            <div className="meeting_points sub_sec">
                              
                                <div className="option_address-div">
                                  <div className="mt-2">
                                    <p className="address-location">
                                      {" "}
                                      Portugal
                                      {/* {value.option_address} */}
                                    </p>
                                  </div>
                                  <div className="map_link">
                                    <span
                                      className="google-map-link"
                                    //   onClick={() =>
                                    //     window.open(
                                    //       `//google.com/maps/?q=${value.option_latitude},${value.option_longitude}`,
                                    //       "_blank"
                                    //     )
                                    //   }
                                    >
                                      {t("_Open_in_Map")}
                                    </span>
                                  </div>
                                </div>
                         
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            {/* {value.require_participants && ( */}
                              <>
                                <div className="timesshow_flex">
                                  <div className="calnce_main_box">
                                    {/* <div className="calnce_box">
                                      {value.cancel_msg != "" && (
                                        <img
                                          className="cale"
                                          src={images["cale.svg"]}
                                          alt="cale"
                                        />
                                      )}
                                      {value.cancel_msg}
                                    </div> */}
                                  </div>

                                  {/* s */}

                                  <div className="availability_info">
                                    {/* <h2>{t("_price_reakdown")}</h2> */}

                                   
                                          {/* <div
                                            className="price_box_number"
                                            key={index}
                                          > 
                                            <div className="price_audult">
                                              <div> {pb.new_title} </div>
                                              <div className="price_adult_number">
                                                {pb.totalParticipants}{" "}
                                                <div>X </div>
                                              </div>
                                              <div>
                                                {pb.title === "Participants"
                                                  ? (
                                                    pb.pricePerPerson /
                                                    pb.totalParticipants
                                                  ).toFixed(2)
                                                  : pb.pricePerPerson}{" "}
                                              </div>
                                            </div> 
                                            <div className="price_main_number">
                                              {pb.formattedValue}
                                            </div>
 
                                          </div> */}
                                        
                                  </div>
                                </div>
                              </>
                            {/* // )} */}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      
                        <>
                          <div className="starting_time_main">
                            <div className="price_box">
                              <span>{t("_total_price")}</span>
                              {/* {value.deal != "" ? ( */}
                                <p className="deal">
                                <span>$25</span>
                                  {/* <span className="deal_details">
                                    <span className="original_price">
                                      22500
                                    </span>
                                    <span className="percentage">
                                      {" "}
                                      -66%
                                    </span>
                                  </span> */}
                                </p>
                            {/* //   ) : (
                            //     <p>
                            //       {value.formatTotalAmount} 
                            //     </p>
                            //   )} */}

                              <span className="text_all">
                                {t("_all_taxes_and_fees_included")}
                              </span>
                            </div> 
                           
                              <div className="timesshow_data">
                                <div className="availability_info">
                                  <h2 className="mb-3">{t("_Time_Slot")}</h2>
                                  <div className="wrapper">
                                  <label
                                            htmlFor={"11"}
                                            // key={timekey}
                                          >
                                            <input
                                              type="radio"
                                            //   id={value.id + "_" + timekey}
                                            id="11"
                                            //   value={timeValue}
                                              className="peer radio"
                                            //   checked={
                                            //     avilableOption.time_slot ==
                                            //       timeValue &&
                                            //       value.option_id ==
                                            //       avilableOption.option_id &&
                                            //       timeSlotIndex == timekey
                                            //       ? "checked"
                                            //       : ""
                                            //   }
                                            //   onChange={(e) => {
                                            //     handleTimeSloatChecked(
                                            //       value.option_id,
                                            //       timeValue
                                            //     );
                                            //     setTimeSlotIndex(timekey); 
                                            //   }}
                                            />
                                            <div
                                              className={`icon   "active_radio"
                                                
                                                `}
                                            >
                                              9:00 AM
                                            </div>
                                          </label>
                                  </div>
                                </div>
                              </div>
                            
                          </div>
                           
                            {/* <div className="timeslot-available_msg">
                              <span className="text-danger">
                               22:00
                              </span>
                            </div> */}
                          

                     
                              <div className="btn_bottom">

                                <div>
                                  
                                      <button
                                        className="book-now"
                                        // onClick={() => bookNow(value)}
                                      >
                                        {t("_book_now")}
                                      </button>
                                  
                                </div>
                                
                                 
                                    <div>
                                      <button
                                        // onClick={() => addProductToCart(value)}
                                        className="add-to-cart"
                                        // disabled={buttonDisable}
                                      >
                                        {t("_add_to_cart")}
                                      </button>
                                    </div>
                                 
                              </div>
                          
                        </>
                   
                        {/* // <div className="require_participants_msg">
                        //   <span className="text-danger">
                        //     {value.require_participants_msg}
                        //   </span>
                        // </div> */}
                       


                    </div>
                  </div>
                </div>

        </div>
      </div>
    </Modal>
  </div>
  )
}

export default NameOptionModal;