import * as React from "react";
import PropTypes from "prop-types";
// import SwipeableViews from 'react-swipeable-views';
import { useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

const subcategory_listdata = [
  {
    topic: "Create a new product",
    inner_listdata: [
      {
        question: "Lorem ipsum",
        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac metus vel lorem bibendum accumsan et utmi. Aliquam convallis libero risus, eget consectetur nisl blandit ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus aliquam.",
      },
      {
        question: "Lorem ipsum2",
        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac metus vel lorem bibendum accumsan et utmi. Aliquam convallis libero risus, eget consectetur nisl blandit ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus aliquam.",
      },
    ],
  },
  {
    topic: "Edit a product",
    inner_listdata: [
      {
        question: "Lorem ipsum",
        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac metus vel lorem bibendum accumsan et utmi. Aliquam convallis libero risus, eget consectetur nisl blandit ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus aliquam.",
      },
      {
        question: "Lorem ipsum2",
        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac metus vel lorem bibendum accumsan et utmi. Aliquam convallis libero risus, eget consectetur nisl blandit ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus aliquam.",
      },
    ],
  },
  {
    topic: "Connecting via API",
    inner_listdata: [
      {
        question: "Lorem ipsum",
        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac metus vel lorem bibendum accumsan et utmi. Aliquam convallis libero risus, eget consectetur nisl blandit ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus aliquam.",
      },
      {
        question: "Lorem ipsum2",
        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac metus vel lorem bibendum accumsan et utmi. Aliquam convallis libero risus, eget consectetur nisl blandit ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus aliquam.",
      },
    ],
  },
];

export default function AboutProductSubCategory(props) {
  const {helptabsdata} = props;

    const [expanded, setExpanded] = React.useState(false);

    const handleExpand = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
    {
      helptabsdata != undefined &&
      helptabsdata != '' ?
      <div className="sub-categories help_category_topaccordian">
        { helptabsdata.map((value, index) => {
          return (
            <Accordion className="sub-cat-item ">
              <AccordionSummary
                expandIcon={<KeyboardDoubleArrowDownOutlinedIcon />}
                className="sub-cat-item-title"
              >
                {value.title}
              </AccordionSummary>
              <AccordionDetails className="que-ans-sec">
                {value.data != undefined && value.data != '' && value.data.map((v, i) => {
                    return(
                        <div className="inner_helpquestionaccord" >   
                  <Accordion expanded={expanded === `panel${i}${index}`} onChange={handleExpand(`panel${i}${index}`)} >
                    <AccordionSummary expandIcon={expanded === `panel${i}${index}` ? <HorizontalRuleOutlinedIcon/> : <KeyboardDoubleArrowRightOutlinedIcon/>} className="question-title">
                    {v.question}
                    </AccordionSummary>
                    <AccordionDetails className="answer">
                      <p  dangerouslySetInnerHTML={{
                      __html: v.answer,
                    }} >
                       {/* {v.answer} */}
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  </div>
                    )
                })}
                {/* <Accordion>
                  <AccordionSummary className="question-title">
                    Lorem ipsum
                  </AccordionSummary>
                  <AccordionDetails className="answer">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum ac metus vel lorem bibendum accumsan et ut mi.
                      Aliquam convallis libero risus, eget consectetur nisl
                      blandit ac. In tempus arcu vitae ipsum ullamcorper, id
                      vehicula tellus aliquam. Duis luctus ac lacus quis
                      hendrerit. Quisque vehicula felis et risus facilisis
                      efficitur. Cras sollicitudin justo eget ultrices
                      tincidunt. Etiam volutpat, felis at interdum auctor, nibh
                      purus fermentum ipsum, eget porttitor mauris massa ut
                      orci.
                    </p>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary className="question-title">
                    Lorem ipsum
                  </AccordionSummary>
                  <AccordionDetails className="answer">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum ac metus vel lorem bibendum accumsan et ut mi.
                      Aliquam convallis libero risus, eget consectetur nisl
                      blandit ac. In tempus arcu vitae ipsum ullamcorper, id
                      vehicula tellus aliquam. Duis luctus ac lacus quis
                      hendrerit. Quisque vehicula felis et risus facilisis
                      efficitur. Cras sollicitudin justo eget ultrices
                      tincidunt. Etiam volutpat, felis at interdum auctor, nibh
                      purus fermentum ipsum, eget porttitor mauris massa ut
                      orci.
                    </p>
                  </AccordionDetails>
                </Accordion> */}
              </AccordionDetails>
            </Accordion>
          );
        })}

        {/* <Accordion className="sub-cat-item">
          <AccordionSummary expandIcon="+" className="sub-cat-item-title">
            Edit a product
          </AccordionSummary>
          <AccordionDetails className="que-ans-sec">
            <Accordion>
              <AccordionSummary className="question-title">
                Lorem ipsum
              </AccordionSummary>
              <AccordionDetails className="answer">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum ac metus vel lorem bibendum accumsan et ut mi.
                  Aliquam convallis libero risus, eget consectetur nisl blandit
                  ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus
                  aliquam. Duis luctus ac lacus quis hendrerit. Quisque vehicula
                  felis et risus facilisis efficitur. Cras sollicitudin justo
                  eget ultrices tincidunt. Etiam volutpat, felis at interdum
                  auctor, nibh purus fermentum ipsum, eget porttitor mauris
                  massa ut orci.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary className="question-title">
                Lorem ipsum
              </AccordionSummary>
              <AccordionDetails className="answer">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum ac metus vel lorem bibendum accumsan et ut mi.
                  Aliquam convallis libero risus, eget consectetur nisl blandit
                  ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus
                  aliquam. Duis luctus ac lacus quis hendrerit. Quisque vehicula
                  felis et risus facilisis efficitur. Cras sollicitudin justo
                  eget ultrices tincidunt. Etiam volutpat, felis at interdum
                  auctor, nibh purus fermentum ipsum, eget porttitor mauris
                  massa ut orci.
                </p>
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion className="sub-cat-item">
          <AccordionSummary expandIcon="+" className="sub-cat-item-title">
            Connecting via API
          </AccordionSummary>
          <AccordionDetails className="que-ans-sec">
            <Accordion>
              <AccordionSummary className="question-title">
                Lorem ipsum
              </AccordionSummary>
              <AccordionDetails className="answer">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum ac metus vel lorem bibendum accumsan et ut mi.
                  Aliquam convallis libero risus, eget consectetur nisl blandit
                  ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus
                  aliquam. Duis luctus ac lacus quis hendrerit. Quisque vehicula
                  felis et risus facilisis efficitur. Cras sollicitudin justo
                  eget ultrices tincidunt. Etiam volutpat, felis at interdum
                  auctor, nibh purus fermentum ipsum, eget porttitor mauris
                  massa ut orci.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary className="question-title">
                Lorem ipsum
              </AccordionSummary>
              <AccordionDetails className="answer">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum ac metus vel lorem bibendum accumsan et ut mi.
                  Aliquam convallis libero risus, eget consectetur nisl blandit
                  ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus
                  aliquam. Duis luctus ac lacus quis hendrerit. Quisque vehicula
                  felis et risus facilisis efficitur. Cras sollicitudin justo
                  eget ultrices tincidunt. Etiam volutpat, felis at interdum
                  auctor, nibh purus fermentum ipsum, eget porttitor mauris
                  massa ut orci.
                </p>
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion> */}
      </div>  : <h4 className="record_noth6">No Data Found</h4>
      }
    </>
  );
}
