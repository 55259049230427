import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { PrincingScheduleContext } from "../../../context";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditDiscount from "./EditDiscount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/fontawesome-free-regular";

const GroupAddProductPricing = ({
    setisopen,
}) => {
    const { t } = useTranslation();
    const [isShow, setIsShow] = useState(false);
    const { tour_id, option_id } = useParams();
    const languageReducer = useSelector((state) => state.languageReducer);
    //   const { getData, setDataLoad, dataLoad } = useContext(TourFilesContext);
    const [optionPricingData, setData] = useState("");
    const [ageGroup, setAgeGroup] = useState([]);
    const [selectedAgeGroup] = useState(["adult"]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { formData,
        setFormData,
        // handleChange,
        formErrors,
        setSlidePages,
        CloseAddOPtionModel,
        submitData,
        scrollToDiv
    } = useContext(PrincingScheduleContext);

    //   useEffect(() => {
    //     if (optionPricingData) {
    //       const { prices } = optionPricingData.product_data;

    //       setFormData({
    //         pricing_id: pricingId,
    //         price_name: optionPricingData.product_data.title,
    //         minimum_participant: optionPricingData.product_data.minimum_participant,
    //         step: "option_pricing",
    //         tourId: tour_id,
    //         optionId: option_id,
    //         age_group: optionPricingData.product_data.age_group,
    //         prices: optionPricingData.product_data.prices,
    //         pricing_type: pricingType,
    //       });

    //       setAgeGroup(optionPricingData.product_data.age_group);
    //       // setDataLoad(false)
    //     }
    //   }, [optionPricingData]);

    //   useEffect(() => {
    //     let data = {
    //       option_pricing_id: pricingId,
    //       pricing_type: pricingType,
    //     };

    //     getData("add_edit_option_pricing", setData, data);
    //     setDataLoad(false);
    //   }, []);

    const handleChanges = (e) => {
        const { name, value, checked } = e.target;
        if (name == "is_discount") {
            setFormData((prev) => ({ ...prev, [name]: checked ? "yes" : "no" }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const HandleChangeData = (e, PriceType) => {
        const { name, value } = e.target;
        const new_obj = {
            ...formData,
            prices: {
                ...formData["prices"],
                [PriceType]: { ...formData["prices"][PriceType], [name]: value },
            },
        };

        setFormData(new_obj);
    };

    const setupPriceTier = (e, PriceType) => {
        let from_no_people = 0;
        let to_no_people = 51;
        const no_of_people = formData["prices"][PriceType]["no_of_people"];
        let lastElement = no_of_people[no_of_people.length - 1];
        if (lastElement !== undefined) {
            from_no_people = lastElement.to + 1;
            to_no_people = from_no_people + 50;
        }

        let no_of_people_obj = {
            from: from_no_people,
            to: to_no_people,
        };

        const new_obj = {
            ...formData,
            prices: {
                ...formData["prices"],
                [PriceType]: {
                    ...formData["prices"][PriceType],
                    no_of_people: [
                        ...formData["prices"][PriceType]["no_of_people"],
                        no_of_people_obj,
                    ],
                    commission: [...formData["prices"][PriceType]["commission"], 30],
                    payout_per_person: [
                        ...formData["prices"][PriceType]["payout_per_person"],
                        0,
                    ],
                    retail_price: [...formData["prices"][PriceType]["retail_price"], 0],
                },
            },
        };

        setFormData(new_obj);
    };

    const AppendPriceType = (e) => {
        let PriceNewObj = {};
        let new_adult_obj = {};

        let new_age_from = 0;
        let new_age_to = 27;
        if (e.target.value == "infant") {
            if (formData["prices"]["child"] !== undefined) {
                new_age_to = 9;
                new_adult_obj = {
                    child: {
                        ...formData["prices"]["child"],
                        age_from: 10,
                        age_to: 27,
                    },
                    adult: {
                        ...formData["prices"]["child"],
                        age_from: 28,
                        age_to: 99,
                    },
                };
            } else {
                new_adult_obj = {
                    ...formData["prices"],
                    adult: {
                        ...formData["prices"]["adult"],
                        age_from: 10,
                        age_to: 99,
                    },
                };
            }
        } else {
            if (formData["prices"]["infant"] !== undefined) {
                new_age_from = 10;
                new_adult_obj = {
                    infant: {
                        ...formData["prices"]["infant"],
                        age_from: 0,
                        age_to: 9,
                    },
                    adult: {
                        ...formData["prices"]["adult"],
                        age_from: 28,
                        age_to: 99,
                    },
                };
            } else {
                new_adult_obj = {
                    ...formData["prices"],

                    adult: {
                        ...formData["prices"]["adult"],
                        age_from: 10,
                        age_to: 99,
                    },
                };
            }
        }

        let price_obj = {
            [e.target.value]: {
                age_from: new_age_from,
                age_to: new_age_to,
                booking_category: "standard",
                no_of_people: [
                    {
                        from: 1,
                        to: 51,
                    },
                ],
                retail_price: [0],
                commission: [30],
                payout_per_person: [0],
            },
        };
        PriceNewObj = { ...price_obj, ...new_adult_obj };

        const new_obj = {
            ...formData,
            prices: PriceNewObj,
        };
        setFormData(new_obj);
        setAgeGroup((ageGroup) => ageGroup.filter((val) => val !== e.target.value));

        // }
    };

    const HandleNoChange = (e, key, PriceType, type = "") => {
        const value = formData["prices"][PriceType]["no_of_people"][key]["to"];
        let LastVal = 2;
        if (type == "decrement") {
            LastVal = 3;
        }
        var newArr = [];
        const no_of_people_data = formData["prices"][PriceType]["no_of_people"];
        if (value >= LastVal) {
            no_of_people_data.map((Newvalue, index) => {
                let PeopleArr = {};
                if (type == "increment") {
                    if (index > 0) {
                        if (index >= key) {
                            if (index > key) {
                                PeopleArr["from"] = parseInt(Newvalue.from) + 1;
                            } else {
                                PeopleArr["from"] = parseInt(Newvalue.from);
                            }

                            PeopleArr["to"] = parseInt(Newvalue.to) + 1;
                        } else {
                            PeopleArr["from"] = parseInt(Newvalue.from);
                            PeopleArr["to"] = parseInt(Newvalue.to);
                        }
                    } else {
                        if (key == 0 && index == 0) {
                            PeopleArr["from"] = 1;
                            PeopleArr["to"] = parseInt(value) + 1;
                        } else {
                            PeopleArr["from"] = parseInt(Newvalue.from);
                            PeopleArr["to"] = parseInt(Newvalue.to);
                        }
                    }
                } else {
                    if (index > 0) {
                        if (index >= key) {
                            if (index > key) {
                                PeopleArr["from"] = parseInt(Newvalue.from) - 1;
                            } else {
                                PeopleArr["from"] = parseInt(Newvalue.from);
                            }
                            PeopleArr["to"] = parseInt(Newvalue.to) - 1;
                        } else {
                            PeopleArr["from"] = parseInt(Newvalue.from);
                            PeopleArr["to"] = parseInt(Newvalue.to);
                        }
                    } else {
                        if (key == 0 && index == 0) {
                            PeopleArr["from"] = 1;
                            PeopleArr["to"] = parseInt(value) - 1;
                        } else {
                            PeopleArr["from"] = parseInt(Newvalue.from);
                            PeopleArr["to"] = parseInt(Newvalue.to);
                        }
                    }
                }

                newArr.push(PeopleArr);
            });

            const new_obj = {
                ...formData,
                prices: {
                    ...formData["prices"],
                    [PriceType]: {
                        ...formData["prices"][PriceType],
                        no_of_people: [...newArr],
                    },
                },
            };

            setFormData(new_obj);
        }
    };

    const CalculatePayout = (e, key, PriceType) => {
        const { value } = e.target;

        let percentage = formData["prices"][PriceType]["commission"][key];
        var calculatedPercentage = (percentage / 100) * value;
        var finalValue = parseFloat(value) - parseFloat(calculatedPercentage);

        let newStateS = [...formData["prices"][PriceType]["payout_per_person"]];
        if (newStateS[key] != undefined) {
            newStateS[key] = finalValue;
        }

        let newStateRetailPrice = [
            ...formData["prices"][PriceType]["retail_price"],
        ];
        if (newStateRetailPrice[key] != undefined) {
            newStateRetailPrice[key] = value;
        }

        const new_obj = {
            ...formData,
            prices: {
                ...formData["prices"],
                [PriceType]: {
                    ...formData["prices"][PriceType],

                    payout_per_person: newStateS,
                    retail_price: newStateRetailPrice,
                },
            },
        };

        setFormData(new_obj);
    };

    // const submitData = () => {
    //     if (languageReducer !== "") {
    //         let nextRoute = "option-pricing";
    //         dispatch(
    //             uplodProduct(
    //                 languageReducer,
    //                 "",
    //                 navigate,
    //                 formData,
    //                 nextRoute,
    //                 "",
    //                 "",
    //                 setisopen
    //             )
    //         );
    //         //   setIsOpen(true);
    //     }
    // };

    const removePriceTier = (item, index) => {

       

        let maxLimit = formData.prices[item[0]].no_of_people.length;
        let from = formData.prices[item[0]].no_of_people[index].from;
        let to = formData.prices[item[0]].no_of_people[index].to;

        for (let i = index + 1; i < maxLimit; i++) {
            //keep the sequence same as it is
            formData.prices[item[0]].no_of_people[i].from = from;
            formData.prices[item[0]].no_of_people[i].to = to;
            from = from + 51;
            to = to + 51;
        }

        formData.prices[item[0]].commission.splice(index, 1);
        formData.prices[item[0]].no_of_people.splice(index, 1);
        formData.prices[item[0]].payout_per_person.splice(index, 1);
        formData.prices[item[0]].retail_price.splice(index, 1);

        setFormData((prev) => ({ ...prev, formData }));
    };

    const cancelOption = () => {
        if (setisopen !== "") {
            setisopen(false);
        }
        navigate(`/tours-files/option-pricing/${tour_id}/${option_id}`);
    };


    return (
        <>
            {/* <div className="newpricing_section"> */}
            <div className="newpricing">
                <h2>{t("_pricing")}</h2>
            </div>
            {isShow == false && (
                <>
                    <div className="newpricing_section">
                        <div className="newpricing">
                            <p>{t("_name")} </p>
                            <div className="tourfile_input">
                                <input
                                    type="text"
                                    name="price_name"
                                    value={formData.price_name}
                                    onChange={handleChanges}
                                />
                            </div>
                        </div>

                        <div className="number_pricing">
                            <p>{t("_minimum_groups_per_time_slot")}</p>
                            <input
                                className="form-control w-10"
                                type="number"
                                name="minimum_participant"
                                value={formData.minimum_participant}
                                onChange={handleChanges}
                                min="0"
                            />
                        </div>

                        <div className="group_box new-group-main">
                            <h6>{t("_prices_per_group")}</h6>
                            {formData.prices &&
                                Object.entries(formData.prices).map((item, index) => {
                                    return (
                                        <section className="group_main_inner_box" key={index}>
                                            <div className="group_main_inner_main">
                                                <div className="group-heading-new">
                                                    <h5>{item[0]}</h5>
                                                </div>

                                                <div className="no_of_people">
                                                    <div className="new-no-people">
                                                        <div className="header_number_price_main d-flex ">
                                                            <div className="header_number_price new-dis-box text-upper-head ">
                                                                <p>{t("_number_of_people")}</p>
                                                            </div>
                                                            <div className="header_number_heading_text new-dis-box text-upper-head">
                                                                <p> {t("_retail_price")}</p>
                                                            </div>

                                                            <div className="header_Commission_text new-dis-box text-upper-head">
                                                                <p> {t("_commission")}</p>
                                                            </div>
                                                            <div className="header_payout new-dis-box text-upper-head">
                                                                <p> {t("_payout_per_person")}</p>
                                                            </div>
                                                        </div>

                                                        {item[1].no_of_people.length > 0 &&
                                                            item[1].no_of_people.map((value, index) => {
                                                                return (
                                                                    <div
                                                                        className="number_price_main mb-10 new-number-price position-relative"
                                                                        key={index}
                                                                    >
                                                                        <div className="number_price">
                                                                            <div className="mobile-heading-text">
                                                                                <p>{t("_number_of_people")}</p>
                                                                            </div>
                                                                            <div className="number_text_product_new new-dis-box">
                                                                                <span>
                                                                                    {item[1].no_of_people[index].from}-
                                                                                </span>

                                                                                <div className="box-data-show">
                                                                                    <div className="data-text">
                                                                                        <span>
                                                                                            {item[1].no_of_people[index].to}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="data-btn">
                                                                                        <div className="btn-fir">
                                                                                            <button
                                                                                                onClick={(e) =>
                                                                                                    HandleNoChange(
                                                                                                        e,
                                                                                                        index,
                                                                                                        item[0],
                                                                                                        "increment"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                +
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="btn-sec">
                                                                                            <button
                                                                                                onClick={(e) =>
                                                                                                    HandleNoChange(
                                                                                                        e,
                                                                                                        index,
                                                                                                        item[0],
                                                                                                        "decrement"
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                -
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="number_heading_text new-dis-box">
                                                                                <div className="mobile-heading-text">
                                                                                    <p>{t("_retail_price")}</p>
                                                                                </div>
                                                                                <div className="number_text_product">
                                                                                    <input
                                                                                        className="form-control w-40"
                                                                                        type="number"
                                                                                        onChange={(e) =>
                                                                                            CalculatePayout(e, index, item[0])
                                                                                        }
                                                                                        value={
                                                                                            item[1].retail_price[index]
                                                                                        }
                                                                                    />
                                                                                    {/* <span>MRP</span> */}
                                                                                </div>
                                                                            </div>

                                                                            {item[1].booking_category == "standard" ||
                                                                                formData.pricing_type == "group" ? (
                                                                                <>
                                                                                    <div className="number_heading_commission_text new-dis-box">
                                                                                        <div className="mobile-heading-text">
                                                                                            <p>{t("_commission")}</p>
                                                                                        </div>
                                                                                        <div className="Commission_text">
                                                                                            <div className="number_text_product">
                                                                                                <span>
                                                                                                    {item[1].commission[index]}%
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="number_heading_commission_text new-dis-box">
                                                                                        <div className="mobile-heading-text">
                                                                                            <p>{t("_payout_per_person")}</p>
                                                                                        </div>
                                                                                        <div className="Commission_text">
                                                                                            <div className="number_text_product">
                                                                                                <input
                                                                                                    className="form-control w-40"
                                                                                                    type="number"
                                                                                                    disabled
                                                                                                    value={
                                                                                                        item[1].payout_per_person[
                                                                                                        index
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                                {/* <span>INR</span> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {index > 0 && (
                                                                                <span
                                                                                    className="cursor-pointer remove_price_tier"
                                                                                    onClick={() =>
                                                                                        removePriceTier(
                                                                                            item,
                                                                                            index,
                                                                                            item[0]
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        icon={faTimesCircle}
                                                                                    />
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}

                                                        <div className="number_navlink">
                                                            {/* <NavLink>set up price tiers</NavLink> */}
                                                            <button
                                                                onClick={(e) => setupPriceTier(e, item[0])}
                                                            >
                                                                {t("_add_additional_group_size")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    );
                                })}
                        </div>
                    </div>
                    {/* {optionPricingData && (
                        <div className="save_continue_btn">
                            <button onClick={submitData}>{t("_save_and_continue")}</button>
                            <button onClick={() => cancelOption()}>{t("_cancel")}</button>{" "}
                        </div>
                    )} */}


                    <div className="checkbox_product_title">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="is_discount"
                            checked={formData.is_discount === "yes" ? true : false}
                            onChange={handleChanges}
                        />
                        <span>{t("_Is_Discount_available")}</span>
                    </div>
                    {
                        formData.is_discount == 'yes' &&
                        <EditDiscount />
                    }

                    {/* <div className='pricebelow_allbutnsection'>
                        <button
                            className="remove-allcancel mr-4"
                            onClick={() => {
                                setSlidePages(2);
                                scrollToDiv()
                            }}
                        >
                            Back
                        </button>

                        <div className='slide_circles'>
                            <div className='circle_one'></div>
                            <div className='circle_one'></div>
                            <div className='circle_one darkcircle'></div>
                        </div>
                        <button
                            onClick={submitData}
                            className="btnall-savenext " >Submit
                        </button>
                    </div> */}
                </>
            )}
        </>
    );
};
export default GroupAddProductPricing;
