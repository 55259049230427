import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingBar from "../Loading/LoadingBar";
import { useEffect } from "react";
import { getTransactionHistory } from "../../actions/transactionHistoryAction";
import ReactPaginate from "react-paginate";
import RecordNotFound from "../../pages/RecordNotFound";
import FsLightbox from "fslightbox-react";
import { MetaData } from "../../actions/customFn";

const ProviderTransactionHistory = () => {
  const languageReducer = useSelector((state) => state.languageReducer);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordStatus, setRecordStatus] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [image, setImage] = useState("");

  useEffect(() => {
    if (languageReducer !== "") {
      setIsLoading(true);
      dispatch(
        getTransactionHistory(
          languageReducer,
          setTransactionData,
          navigate,
          setIsLoading,
          setRecordStatus,
          setPageCount,
          currentPage
        )
      );
    }
  }, [languageReducer, currentPage]);

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
  };

  const showImage = (value, type) => {
    if (value.payment_proof_extension == "pdf" || value.invoice_extension == "pdf") {
      if (type == "payment_proof_img") {
        window.open(value.invoice_link, '_blank');
      }
      if (type == "invoice_img") {
        window.open(value.payment_proof_link, '_blank');
      }
    } else {
      setIsShow((state) => !state)
      setImage(value.payment_proof_img);
    }
  }

  let metaData = {
    title: t("_transaction_history")
  }

  return (
    <div className="dashboard_section">
      {
        metaData &&
        <MetaData data={metaData} />
      }
      <div className="text_banner">
        <span>{t("_transaction_history")}</span>{" "}
      </div>
      {isLoading === true ? (
        <div className="blinking-bar-loader">
          <LoadingBar />
        </div>
      ) : (
        <>
          {transactionData && (
            <>
              <div className="upper_box_deshboard">
                <div className="row">
                  {transactionData.header_data?.length > 0 &&
                    transactionData.header_data?.map((value, index) => {
                      return (
                        <div
                          className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                          key={index}
                        >
                          <div className="dashboard_section_main" key={index}>
                            <div className="dashboard_img_text">
                              <h5>{value.amount}</h5>
                              <p>{value.text}</p>
                            </div>
                            {/* <div className="dashboard_img">
                              <img src={value.image} alt=""/>
                            </div> */}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </>
          )}
          <div className="recent_order_section">
            <div className="recent_main_box">
              <div className="recent_order">
                <h3>{t("_recent_transaction")}</h3>
              </div>
            </div>
            <div className="Upcoming_booking_table table-responsive">
              {transactionData.transaction_history.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t("_amount")}</th>
                      <th>{t("_Tax")}</th>
                      <th>{t("_Paid_Ammount")}</th>
                      <th>{t("_Payment_Mode")}</th>
                      <th>{t("_Amount_Type")}</th>
                      <th>{t("_Payment_Date")}</th>
                      <th>{t("_Payment_Proof")}</th>
                      <th>{t("_Invoice")}</th>
                      <th>{t("_Remark")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionData.transaction_history.map((value, index) => {
                      return (
                        <tr
                          className={value.trans_type == "Credited" ? "credited-background" : value.trans_type == "Debited" ? "debited-background" : ""}
                          key={index}
                        >
                          <td>{currentPage == 0 ? index + 1 : currentPage * 10 + index + 1}</td>
                          <td>{value.paid_amount}</td>
                          <td>{value.tax}</td>
                          <td>{value.paid_amount}</td>
                          <td>{
                            value.amount_type == "bank_transfer" ? t("_bank_transfer") :
                              value.amount_type.charAt(0).toUpperCase() + value.amount_type.slice(1)}
                          </td>
                          <td
                          >
                            <span
                              // className="debited-title"
                              className={value.trans_type == "Credited" ? "credited-title" : value.trans_type == "Debited" ? "debited-title" : ""}
                            >
                              {value.trans_type}
                            </span>
                          </td>
                          <td>{value.date}</td>
                          <td className="payment-proof-img">
                            <img
                              className="cursor-pointer"
                              src={value.payment_proof_img} alt=""
                              onClick={() => showImage(value, "payment_proof_img")}
                            />
                          </td>
                          <td className="invoice-img">
                            <img
                              className="cursor-pointer"
                              src={value.invoice_img} alt=""
                              onClick={() => showImage(value, "invoice_img")}
                            />
                          </td>
                          <td>{value.description}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

              ) : (
                <RecordNotFound />
              )}
            </div>
            <div className="fslight_box_main">
              <FsLightbox
                toggler={isShow}
                sources={[image]}
              // sourceIndex={0}
              />
            </div>
          </div>

          <div className="paginate_all">
            {transactionData.transaction_history.length > 0 &&
              pageCount > 1 && (
                <ReactPaginate
                  pageCount={pageCount}
                  forcePage={currentPage}
                  onPageChange={handlePageChange}
                  containerClassName={"navigationButtons"}
                  previousLinkClassName={"previousButton"}
                  nextLinkClassName={"nextButton"}
                  disabledClassName={"navigationDisabled"}
                  activeClassName={"active"}
                  pageRange={2}
                  marginPagesDisplayed={2}
                  nextLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.172"
                      height="14"
                      viewBox="0 0 7.172 14"
                    >
                      <path
                        id="fi-rr-angle-small-down"
                        d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                        transform="translate(7.172) rotate(90)"
                        fill="#000"
                      />
                    </svg>
                  }
                  previousLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.172"
                      height="14"
                      viewBox="0 0 7.172 14"
                    >
                      <path
                        id="fi-rr-angle-small-down"
                        d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                        transform="translate(15.086 -5) rotate(90)"
                        fill="#232a35"
                      />
                    </svg>
                  }
                />
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default ProviderTransactionHistory;
