import React, { useEffect } from "react";
import { MetaData } from "../../actions/customFn";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { setAffiliatePaypalId } from "../../actions/affiliateAction";
import { useTranslation } from "react-i18next";
import LoadingBar from "../Loading/LoadingBar";
import { setProviderPaymentDetails } from "../../actions/providerAction";
import SupplierPaymentConfirmation from "../Modal/SupplierPaymentConfirmation";

const SupplierPayment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileReducer = useSelector((state) => state.profileReducer);
  const languageReducer = useSelector((state) => state.languageReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [showPaymentFields, setPaymentFields] = useState(true);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [formData, setFormData] = useState({
    bank_name: "",
    swift: "",
    iban: "",
    user_type: "Partner",
  });

  const [formErrors, setFormError] = useState({});
  const errors = {};

  useEffect(() => {
    if (profileReducer && profileReducer.is_account_setup > 0) {
      const { bank_name, swift, iban } = profileReducer;
      if (bank_name === "" || swift === "" || iban === "") {
        setPaymentFields(true); // Show Submit button
      } else {
        setPaymentFields(false); // Hide Submit button
      }
      setFormData((prev) => ({
        ...prev,
        bank_name: bank_name,
        swift: swift,
        iban: iban,
      }));
    }
  }, [profileReducer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateFormData = () => {
    if (formData.bank_name === "") {
      errors.bank_name = "Enter bank name";
    }

    if (formData.swift === "") {
      errors.swift = "Enter SWIFT number";
    }

    if (formData.iban === "") {
      errors.iban = "Enter IBAN number";
    }
    setFormError(errors);
  };

  const submit = () => {
    if (languageReducer) {
      validateFormData();
      if (Object.keys(errors).length === 0) {
        setConfirmationModalShow(true);
        // setIsLoading(true);
        // dispatch(
        //   setProviderPaymentDetails(
        //     languageReducer,
        //     formData,
        //     navigate,
        //     setIsLoading
        //   )
        // );
      }
    }
  };

  let metaData = {
    title: t("_payment_method"),
  };

  return (
    <>
      {metaData && <MetaData data={metaData} />}
      <div className="affiliate_profile_main">
        <div className="text_banner">
          <span>{t("_payment_method")} </span>
        </div>
        {isLoading === true ? (
          <div className="blinking-bar-loader">
            <LoadingBar />
          </div>
        ) : showPaymentFields ? (
          <div className="user_edit_main">
            <div className="input_all_edit">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main">
                  <div className="heder_new_search_affiliate">
                    <div className="heder_new_search">
                      <div className="input_search">
                        <label htmlFor="">{t("_Enter_Bank_Name")}</label>
                        <input
                          className={
                            `form-control mt-2  ` +
                            (formErrors.bank_name ? "is-invalid" : "")
                          }
                          type="text"
                          name="bank_name"
                          placeholder={t("_Enter_Bank_Name")}
                          onChange={(e) => handleChange(e)}
                          value={formData.bank_name}
                        />
                        {formErrors.bank_name && (
                          <div className="invalid-feedback">
                            {formErrors.bank_name}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main">
                  <div className="heder_new_search_affiliate">
                    <div className="heder_new_search">
                      <div className="input_search">
                        <label htmlFor="">{t("_IBAN")}</label>
                        <input
                          className={
                            `form-control mt-2  ` +
                            (formErrors.iban ? "is-invalid" : "")
                          }
                          type="text"
                          name="iban"
                          placeholder={t("_Enter_IBAN_number")}
                          onChange={(e) => handleChange(e)}
                          value={formData.iban}
                        />
                        {formErrors.iban && (
                          <div className="invalid-feedback">
                            {formErrors.iban}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                  <div className="heder_new_search_affiliate">
                    <div className="heder_new_search">
                      <div className="input_search">
                        <label htmlFor="">{t("_SWIFT")}</label>
                        <input
                          className={
                            `form-control mt-2  ` +
                            (formErrors.swift ? "is-invalid" : "")
                          }
                          type="text"
                          name="swift"
                          placeholder={t("_Enter_SWIFT_number")}
                          onChange={(e) => handleChange(e)}
                          value={formData.swift}
                        />
                        {formErrors.swift && (
                          <div className="invalid-feedback">
                            {formErrors.swift}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                  <button className="paypal-set-btn" onClick={(e) => submit()}>
                    {t("_submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="user_edit_main">
              <div className="input_all_edit">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main">
                    <div className="heder_new_search_affiliate">
                      <div className="heder_new_search">
                        <div className="input_search">
                          <label htmlFor="">{t("_Enter_Bank_Name")}</label>
                          <input disabled value={formData.bank_name} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main">
                    <div className="heder_new_search_affiliate">
                      <div className="heder_new_search">
                        <div className="input_search">
                          <label htmlFor="">{t("_IBAN")}</label>
                          <input value={formData.iban} disabled />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                    <div className="heder_new_search_affiliate">
                      <div className="heder_new_search">
                        <div className="input_search">
                          <label htmlFor="">{t("_SWIFT")}</label>
                          <input disabled value={formData.swift} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main mt-2"></div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 search_header_main mt-4 supplier-payment-form-div">
                    <div
                      className="alert alert-danger supplier-payment-form"
                      role="alert"
                    >
                      {t("_Warning_message")}{" "}
                      <NavLink to={"/supplyhelp-center"}>
                        <span className="payment-method-alert-msg">
                          {t("_Click_here")}
                        </span>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <SupplierPaymentConfirmation
          confirmationModalShow={confirmationModalShow}
          setConfirmationModalShow={setConfirmationModalShow}
          setIsLoading={setIsLoading}
          formData={formData}
        />
      </div>
    </>
  );
};

export default SupplierPayment;
