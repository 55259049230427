import { Suspense, useEffect } from "react";
import "./assets/vendor/css/bootstrap.min.css";
import "./assets/vendor/css/style.css";
import "./assets/vendor/css/responsive.css";
import "./assets/vendor/dist/assets/owl.carousel.css";
import "./assets/vendor/dist/assets/owl.theme.default.min.css";
import { GetRoutes, getTimeone, useScript } from "./actions/customFn";
import { Routes, useLocation } from "react-router-dom";
import RouteArr from "./hooks/GetRoutes";
import $ from "jquery";
import { LoadingBar } from "./components";
import { useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { ThemeProvider } from "react-bootstrap";
import i18n from "./i18n";
import LocaleContext from "./components/LocaleContext";
import { Helmet } from "react-helmet";
import {  setLogin, setLogout } from "./actions/loginAction.js";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetLanguagesList,
  asyncSetLanguage,
} from "./actions/languageList";
import { setLanguage } from "./actions/languageAction";
import { asyncGetCurrencyList, asyncSetCurrency } from "./actions/currencyList";
import { useNavigate } from "react-router-dom/dist";
import { asyncGetHomeData } from "./actions/pageAction";
import { Toaster } from "react-hot-toast";
import { profileData } from "./actions/profileAction";
import { getCartData } from "./actions/cartAction";
import { getPhoneCodeList } from "./actions/phoneCodeAction.js";
import { useTranslation } from "react-i18next";
import ReadPolicyModal from "./components/Modal/ReadPolicyModal.js";

 
$(document).ready(function () {
  $(window).on("load", function () {
    $(".montian_image_main").addClass("new_anim_img");
    $(".banner_div_text").addClass("new_text_size");
    $(".accordion-collapse").addClass("animate__animated animate__fadeInDown");
  });
});

function App() {
  LoadingOverlay.propTypes = undefined;
  const { t } = useTranslation();
  const languageReducer = useSelector((state) => state.languageReducer);
  const checklogin = useSelector((state) => state.login);
  const [isDataLoad, setIsDataLoading] = useState(true);

  // useDispatch
  const dispatch = useDispatch();
  // Language Code
  const [locale, setLocale] = useState({});
  var llocal_language = localStorage.getItem("language");
  var llocal_currency = localStorage.getItem("currency");
  // Language Code End

  const [isLoading, setIsLoading] = useState(true);
  const [homeData, setHomeData] = useState();
  const [cartData, setCartData] = useState({});
  const [recordStatus, setRecordStatus] = useState(true);
  const [cartLoader, setCartLoader] = useState(false);
  const [loginKey, SetLoginKey] = useState("");
  const [ModalLogin, setModalLogin] = useState(false);
  const [redirectingUrl, setRedirectingUrl] = useState("");
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isReviewModal, setIsReviewModal] = useState(false);

  //Setting googleKey in script form map script initial load
  useScript(`https://maps.googleapis.com/maps/api/js?key=${homeData?.settings?.google_key}&libraries=places`);

  // Top To Scroll
  const routePath = useLocation();
  const navigate = useNavigate();
  const onTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onTop();
    getTimeone();
  }, [routePath]);

  useEffect(() => {
    setTimeout(function () {
      setIsLoading(false);
    }, 2500);
  }, []);

  // set Language
  useEffect(() => {
    setLocale(languageReducer);
  }, [dispatch, locale, languageReducer]);

  useEffect(() => {
    // document.title = "MyTket";
    if (languageReducer !== "") {
      dispatch(asyncGetLanguagesList());
      dispatch(asyncGetCurrencyList());
      if (localStorage.getItem("password")) {
        dispatch(setLogin());
        dispatch(profileData(languageReducer, navigate));
      } else {
        dispatch(setLogout());
      }
    }
  }, [languageReducer, checklogin]);

  // =============Setting web title and favicon starts==========
  const favicon = localStorage.getItem("mytket-favicon");
  if (favicon) {
    document.getElementById("favicon").href = favicon;
  };

  useEffect(() => {
    if (homeData) {
      if (favicon !== homeData.settings.favicon) {
        document.getElementById("favicon").href = homeData.settings.favicon;
        localStorage.setItem("mytket-favicon", homeData.settings.favicon);
      };

      // if (homeData.settings.web_title) {
      //   document.getElementById("web_title").innerHTML =
      //     homeData.settings.web_title;
      // }
    }
  }, [homeData]);

  // =============Setting web title and favicon ends==========

  const profileReducer = useSelector((state) => state.profileReducer);

  useEffect(() => {
    if (languageReducer != "") {
      dispatch(asyncGetHomeData(languageReducer, setHomeData, navigate, ""));
      // dispatch(getCartData(languageReducer, setCartData, navigate, setRecordStatus, ""));
      dispatch(getPhoneCodeList());
    }
  }, [dispatch, languageReducer]);

  useEffect(() => {
    if (languageReducer != "" && profileReducer != "") {
      dispatch(
        getCartData(languageReducer, setCartData, navigate, setRecordStatus, "")
      );
      if (profileReducer?.is_read_policy == 0) {
        setIsReviewModal(true);
      }
    }
  }, [profileReducer]);

  useEffect(() => {
    if (llocal_language === null) {
      dispatch(asyncSetLanguage({ language: "deafult", currency: "deafult" },navigate));
    } else {
      dispatch(setLanguage(JSON.parse(llocal_language)));
    }
  }, [dispatch, llocal_language]);

  useEffect(() => {
    if (llocal_currency === null) {
      dispatch(asyncSetCurrency({ language: "deafult", currency: "deafult" }));
    } else {
      // dispatch(setCurrency(JSON.parse(llocal_currency)));
    }
  }, [dispatch, llocal_currency]);

  let isViewTransition =
    "Opss, Your browser doesn't support View Transitions API";
  if (document.startViewTransition) {
    isViewTransition = "Yess, Your browser support View Transitions API";
  }

  // =====To show same user data in different tab in incogniti mode=========
  // if (loggedInUser != localStorage.getItem("userId") && languageReducer !== "") {
  //   if (localStorage.getItem("userId") != null) {
  //     dispatch(profileData(languageReducer, navigate, "", setLoggedInUser));
  //   }
  // }
  // =====To show same user data in different tab in incogniti mode=========

  // useEffect(() => {
  //   getGMTTimeFromNTPServer();

  //   let timeString = "04/29/2024 2:00 AM"
  //   const isoFormat = moment(timeString, "MM/DD/YYYY hh:mm A").toISOString();
  //   const comparisonDate = new Date(isoFormat);
  //   let inputTimestamp = comparisonDate.getTime();

  //   console.log("inputTimestamp", inputTimestamp);

  // }, [])

  // const getGMTTimeFromNTPServer = async () => {
  //   try {
  //     const response = await fetch('http://worldtimeapi.org/api/timezone/Etc/UTC');
  //     const data = await response.json();
  //     const datetime = data.datetime;
  //     const utcTimestamp = Date.parse(datetime);
  //     console.log("Current UTC time:", utcTimestamp);
  //   } catch (error) {
  //     console.error("Error fetching UTC time:", error);
  //   }
  // }

  // useEffect(() => {
  //   console.log("test", Date.now());
  // }, [])

  return (
    <div className="App">
      <LoadingOverlay active={false} spinner={<LoadingBar />}>
        <LocaleContext.Provider
          value={{
            locale,
            setLocale,
            isDataLoad,
            setIsDataLoading,
            cartLoader,
            setCartLoader,
            setHomeData,
            homeData,
            loginKey,
            SetLoginKey,
            ModalLogin,
            setModalLogin,
            redirectingUrl,
            setRedirectingUrl,
            loggedInUser,
            setLoggedInUser,
          }}
        >
          <Suspense>
            <Helmet
              htmlAttributes={{
                lang: locale,
                dir: locale.direction === "ltr" ? "ltr" : "ltr",
              }}
            />
            <ThemeProvider dir={locale.short_code === "en" ? "ltr" : "ltr"}>
              {/* <AnimatePresence mode="wait" > */}
              <Toaster position="top-center" reverseOrder={false} />
              {/* <Routes key={Date.now()}>{GetRoutes(RouteArr)}</Routes> */}
              <Routes>{GetRoutes(RouteArr)}</Routes>
              {/* </AnimatePresence> */}
              {isReviewModal && (
                  <ReadPolicyModal
                    isReviewModal={isReviewModal}
                    setIsReviewModal={setIsReviewModal}
                  />
                )}
            </ThemeProvider>
          </Suspense>
        </LocaleContext.Provider>

        {/* <Footer /> */}
      </LoadingOverlay>
    </div>
  );
}

export default App;
