import { readFile } from './helpers/cropImage';
import { useImageCropContext } from './providers/ImageCropProvider';
import Button from './base/Button';
import Cropper from './cropper/Cropper';
import { RotationSlider, ZoomSlider } from './cropper/Sliders';

const ImageCropModalContent = ({ handleDone, handleClose }) => {
    const { setImage } = useImageCropContext();

    const handleFileChange = async ({ target: { files } }) => {
        const file = files && files[0];
        const imageDataUrl = await readFile(file);
        setImage(imageDataUrl);
    };

    return (
        <div className="image-modal">
            <h5 className="text-top">Edit profile picture</h5>
            <div className="inner-all-data">
                <div className="image-main-crop crop-container">

                    <Cropper />

                </div>
                <div className='image-slider-both my-3'>
                    <ZoomSlider className="" />
                    <RotationSlider className="" />
                </div>

                <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    className="d-none"
                    id="avatarInput"
                    accept="image/*"
                />

                <div className='btn-bottom-all'>
                    <button className="btn btn-link font-weight-bold">
                        <label htmlFor="avatarInput">Upload Another Picture</label>
                    </button>
                    <div className="flex gap-2">
                        <button className="btn btn-danger mr-2" onClick={handleClose}>
                            Cancel
                        </button>
                        <button className="btn btn-primary w-100" onClick={handleDone}>
                            Done & Save
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ImageCropModalContent;