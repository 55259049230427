import { useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";

const Testimonial = () => {
  const homeData = useSelector((state) => state.home);
  const [testimonialData, setTestimonialData] = useState([]);

  useEffect(() => {
    if (homeData) {
      setTestimonialData(homeData.testimonials);
    }
  }, [homeData, testimonialData]);

  const testimonial = {
    loop: true,
    margin: 0,
    nav: false,
    dots: true,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };

  return (
    <div className="testimonial_main">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
            <div className="testimonial_left_side">
              {homeData.title && (
                <>
                  <h2>{homeData.title.travel_experiences_title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: homeData.title.travel_experiences_desc,
                    }}
                  ></p>
                </>
              )}
            </div>
          </div>
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
            <div className="testimonial_right_side">
              <div className="testimonial_slider">
                <OwlCarousel
                  key={`carousel_${Date.now()}`}
                  {...testimonial}
                  className="owl-carousel owl-theme slider_main"
                >
                  {testimonialData?.length > 0 &&
                    testimonialData.map((value, index) => {
                      return (
                        <div className="testimonial_box" key={index}>
                          <div className="quote_img">
                            <img src={images["quote.png"]} alt="" />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: value.description,
                              }}
                            ></div>
                          </div>
                          <div className="author_detail">
                            <img src={value.usericon} className="" alt="" />
                            <div className="author_name">
                              <h6>{value.title}</h6>
                              <p>{value.destination}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
