import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { ShimmerSectionHeader, ShimmerSimpleGallery } from "react-shimmer-effects";

const SystemSlider = ({ systemSlider, isLoading }) => {
  const [sliderData, setSliderData] = useState(systemSlider || {});

  // Update slider data when `systemSlider` prop changes
  useEffect(() => {
    if(systemSlider){
      setSliderData(systemSlider);
    }
  }, [systemSlider]);

  const testimonial = {
    loop: true,
    margin: 0,
    nav: false,
    dots: true,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };

  return (
    <div className="system-slider-main">
      <div className="container">
        { isLoading ? (
          // Show shimmer header while loading
          <div className="shimmer-header">
            <ShimmerSectionHeader center />
            <ShimmerSimpleGallery col="3" row="1" colimageHeight={100} />
          </div>
        ) : (
          <>
            <div className="data-top-main">
              <h2>{sliderData?.booking_system_heading}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: sliderData?.booking_system_description,
                }}
              ></p>
            </div>
            <div className="slider-inner-data">
              {
                sliderData?.booking_images?.length > 0 && (
                  <OwlCarousel
                  {...testimonial}
                  className="owl-carousel owl-theme slider_main"
                >
                  {
                    sliderData?.booking_images.map((value, index) => (
                      <div className="systm-data-main" key={index}>
                        <div className="system_img">
                          <img
                            src={value.booking_image}
                            alt="Booking Image"
                          />
                        </div>
                      </div>
                    ))}
                </OwlCarousel>
                )
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SystemSlider;
