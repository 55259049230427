import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ImageBanner from "../components/CultureDetails/ImageBanner";
import { ProductInformation } from "../context";
import {
    AdvertisementBanner,
    CheckAvailabillity, CustomerReviews,
    Footer,
    HeaderSticky,
    LoadingBar,
    Newsletter, VideoPlayer, 
    SubscribeNewsletter,
    WeatherInfo
} from "../components";
import { motion } from "framer-motion";
import { getProductPreview } from "../actions/detailsAction";
import { useSelector } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { t } from "i18next";
import { MetaData } from "../actions/customFn";
import TravellerPhotos from "../components/CultureDetails/TravellerPhotos";
import CultureStickyList from "../components/CultureDetails/CultureStickyList";
import ProductDetailsNew from "../components/CultureDetails/ProductDetailsNew";

const ProductPreviewNew = () => {
    const { id, user_id, user_type } = useParams();
    const languageReducer = useSelector((state) => state.languageReducer);
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [DetailsData, setDetailsData] = useState();
    const [filterLoading, setFilterLoading] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [hideLoder, setHideLoder] = useState(false);
    const [affiliateLink, setAffiliateLink] = useState("");
    const [generateLink, setGenerateLink] = useState(false);
    const [metaData, setMetaData] = useState("");
    const [avalableOptionFlag, setAvailabeOptionFlag] = useState(true);
    const [avilableOption, setAvilableOption] = useState({
        adult: 1,
        child: 0,
        infant: 0,
        total: 0,
        date: "",
        time_slot: "",
        option_id: 0,
        product_id: id,
        selectedDate: "",
        duration_text: "",
        meeting_point: '',
        pickup_point: '',
        end_point: '',
        location: "",
        end_point_details: ""
    });

    const [formErrors, setFormError] = useState({});
    const errors = {};
    const { code } = useParams();

    useEffect(() => {
        if (code) {
            let expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString();   // 1 day = 24 * 60 * 60 *1000
            document.cookie =
                `affilliate_code=${code}; expires=` + expires + ";path=/;";
        }
    }, []);

    useEffect(() => {
        if (DetailsData) {
            setAvilableOption((prev) => ({
                ...prev,
                location: (DetailsData.city + "," + DetailsData.state + "," + DetailsData.country),
                end_point_details: DetailsData.end_point_details ? DetailsData.end_point_details : ""
            }))
        }
    }, [DetailsData])
    useEffect(() => {
        if (languageReducer !== "") {
            setIsLoading(true);
            getProductPreview(
                id,
                languageReducer,
                navigate,
                setDetailsData,
                location,
                setIsLoading,
                setMetaData,
                user_id,
                user_type
            );
        };
    }, [languageReducer, id]);

    // get scroll height postion 
    const myRef1 = useRef(null);
    const checkRef2 = useRef(null);

    const executeScroll = () => {
        myRef1.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const executeCheckAScroll = () => {
        checkRef2.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // =========================To setActive section starts===============================
    const [activeSection, setActiveSection] = useState("hidefloating-nav-div");
    const [isClicked, setIsClicked] = useState(false);
    const [isEndOfPage, setIsEndOfPage] = useState(false);

    useEffect(() => {
        const handleScrolling = () => {
            const sections = document.querySelectorAll('[data-section]');
            const scrollPosition = window.scrollY;
            sections.forEach((section) => {
                const top = section.offsetTop;
                const height = section.offsetHeight;

                if (scrollPosition >= top && scrollPosition < top + height) {
                    setActiveSection(section.id);
                }
            });
        };

        // =============For to show bottom floatin navbar==========
        if (window.innerHeight + document.documentElement.scrollTop > document.documentElement.offsetHeight - 500) {
            setIsEndOfPage(true);
        } else {
            setIsEndOfPage(false);
        }
        // =============For to show bottom floatin navbar==========

        window.addEventListener('scroll', handleScrolling);
        return () => {
            window.removeEventListener('scroll', handleScrolling);
        };
    }, []);

    return (
        <LoadingOverlay active={isLoading === true} spinner={<LoadingBar />}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 3 }}
            >
                <ProductInformation.Provider
                    value={{
                        DetailsData,
                        setDetailsData,
                        avilableOption,
                        setAvilableOption,
                        filterLoading,
                        setFilterLoading,
                        isShow,
                        setIsShow,
                        hideLoder,
                        setHideLoder,
                        setAffiliateLink,
                        generateLink,
                        setGenerateLink,
                        affiliateLink,
                        avalableOptionFlag,
                        setAvailabeOptionFlag,
                        formErrors, setFormError, errors, activeSection, setActiveSection,
                        isClicked, setIsClicked, checkRef2
                    }}
                >
                    {DetailsData != undefined && (
                        <>
                            {
                                metaData &&
                                <MetaData data={metaData} />
                            }
                            <div className="new-header-page">
                                <HeaderSticky />
                            </div>
                            {
                                activeSection !== "hidefloating-nav-div" ? <CultureStickyList executeScroll={executeScroll} /> : <div style={{ display: 'none' }} ></div>
                            }
                            <div className="page_details_main">
                                <div className="container">
                                    <div className="list_Breadcrumb">
                                        <ul>
                                            <li>
                                                <NavLink to="/"> {t("_home")} / </NavLink>
                                            </li>
                                            <li>
                                                {" "}
                                                <NavLink to="/culture-listing">
                                                    {t("_culture_listing")} /{" "}
                                                </NavLink>
                                            </li>
                                            <li>
                                                {" "}
                                                <NavLink to="/culture-details">{t("_details")}</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <ImageBanner />
                                <div className="product_info_inner" >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12" ref={checkRef2}>
                                                {/* <AboutActivity
                          aboutActivity={DetailsData.about_activity}/> */}
                                                {/* <HighlightsTab /> */}
                                                {/* <AlsoLikes /> */}
                                                <ProductDetailsNew />
                                                {/* <CultureAvailability /> */}
                                                <TravellerPhotos myRef1={myRef1} />
                                                {
                                                    DetailsData?.traveller_photos_with_review.length > 0 &&
                                                    <CustomerReviews />
                                                }
                                            </div>
                                            <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
                                                <div className="sticky_sidebar">
                                                    <CheckAvailabillity />
                                                    <VideoPlayer
                                                        videoUrl={DetailsData.video_url}
                                                        videoThumbnail={DetailsData.video_thumbnail_image}
                                                    />
                                                    <AdvertisementBanner
                                                        bannerData={{
                                                            side_banner_image: DetailsData.side_banner_image,
                                                            side_banner_title: DetailsData.side_banner_title,
                                                            side_banner_description: DetailsData.side_banner_description,
                                                            side_banner_link: DetailsData.side_banner_link,
                                                        }}
                                                    />
                                                    <WeatherInfo WeatherInfo={DetailsData.weather_details} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <Newsletter /> */}
                                        <SubscribeNewsletter />
                                    </div>
                                </div>
                                {/* <AllTags /> */}
                            </div>
                            <Footer />
                            {isEndOfPage == true &&
                                <div className="sticky_footeravailibilty animate__animated animate__fadeInUp">
                                    <div className="checkavility_stickyflex">
                                        <h2> {DetailsData.description.title} </h2>
                                        <button onClick={() => executeCheckAScroll()}>
                                        {t("_Check_Availability")}
                                        </button>
                                    </div>
                                </div>
                            }
                        </>
                    )}
                </ProductInformation.Provider>
            </motion.div>
        </LoadingOverlay>
    );
};

export default ProductPreviewNew;
