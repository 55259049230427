import React from "react";
import { motion } from "framer-motion";
import { HeaderSticky } from "../components";
import { images } from "../actions/customFn";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RecordNotFoundWishlist = () => {
    const { t } = useTranslation();

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 3 }}
            >
                <div className="div-404-new">
                    <div className="container">
                        <div className="images-page-unknow">
                            <img src={images["empty3.jpg"]} alt="" />
                        </div>

                        <div className="page-4content">
                            {/* <h2>{t("_record_not_found")}</h2> */}
                            <h2> {t("_This_list_is_empty")}</h2>
                            <p>
                                {t(
                                    "_add_activities_by_clicking_the_heart_icon_and_then_selecting_the_product."
                                )}
                            </p>
                            <NavLink to="/"> {t("_Find_Things_to_do")}</NavLink>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    );
};

export default RecordNotFoundWishlist;
