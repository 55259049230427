import axios from "../axios";

export const getStateList = (setStateList,country) => {
    const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
    };
    return (dispatch) => {
        const url = "/get-states";
        const data = {
            language: "EN",
            country: country
        };
        axios
            .post(url, data, options)
            .then((response) => {
                const { data } = response;
                if (data.status) {
                    setStateList(data.data)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
};
