import React,  { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ShimmerSectionHeader,ShimmerSimpleGallery,ShimmerButton } from "react-shimmer-effects";

import LocaleContext from "../LocaleContext";
import { useSelector } from "react-redux";
import LoginModal from "../Modal/LoginModal";
import { useLocation, useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { becomeAffiliate } from "../../actions/becomeAffiliateAction"; 

const GrowMedia = ({ growMediaData, isLoading }) => {
  const { t } = useTranslation();

  const { SetLoginKey, loginKey } = useContext(LocaleContext);
  const [ModalLogin, setModalLogin] = useState(false);
  const checklogin = useSelector((state) => state.login);
  // Show Login Modal
  const showModal = (status) => {
    SetLoginKey(status);
    setModalLogin(true);
  };
  const closeLoginModal = () => {
    setModalLogin(false);
  };


    //==============
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const languageReducer = useSelector((state) => state.languageReducer);
    const profileReducer = useSelector((state) => state.profileReducer);
    const become_user = "Affiliate";
    const applyForAffiliate = () => {
      Swal.fire({
        title: "Are your sure ?",
        text: "You will become an affiliate user and your data as customer will be no longer available...!!!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "rgb(155 176 199)",
        cancelButtonColor: "rgb(227 46 46)",
        confirmButtonText: "Yes",
      }).then((res) => {
        if (res.isConfirmed === true) {
          dispatch(becomeAffiliate(languageReducer, navigate, become_user));
        }
      });
    };

  return  <> { isLoading ? (
    
    <>
      <div className="shimmer-header">
        <ShimmerSectionHeader center />
      </div>
      <div className="shimmer-header shimmer-header-card">
        <ShimmerSimpleGallery col={3} row={1} imageHeight={300} />
      </div>
      <div className="shimmer-header shimmer-bt">
        <ShimmerButton size="lg" mode="light" center />
      </div>
    </>
  ) : (
    <div className="grow-media-section">
      <div className="sell-tourysta-section grow_media_content">
        <div className="container">
          <div className="card-data-main">
            <h2>{growMediaData?.grow_your_social_media_title}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: growMediaData?.grow_your_social_media_description,
              }}
            ></p>

            <div className="card-data-list">
              <div className="card-data-grid">
                {growMediaData?.grow_social_media?.length > 0 &&
                  growMediaData?.grow_social_media.map((data, index) => (
                    <div className="card-one card-main" key={index}>
                      <img src={data?.icon} alt={data?.icon_title || "Icon"} />
                      <p>{data?.icon_title}</p>
                    </div>
                  ))}
              </div>
              <div className="btn-bottom-data">
              {checklogin === false ?
                <button  onClick={() => {
                  showModal("login");
                }} >{t("_Let's_Start")}</button> : profileReducer?.user_type == "Customer" ? (
                  <button
                    aria-label="Become a Affiliate"
                    onClick={() => applyForAffiliate()}
                  >
                    {" "}
                    {t("_Let's_Start")}
                  </button>
                ) : profileReducer?.user_type == "Affiliate" ? (
                  <button aria-label={t("_already_an_affiliate")}>
                    {" "}
                    {/* {t("_Become_a_Supplier")}{" "} */}
                    {t("_already_an_affiliate")}
                  </button>
                ): profileReducer?.user_type == "Partner" ? (
                 ''
                ) : ( ''
                  // <button aria-label="Become a Supplier">
                  //   {" "}
                  //   {t("_Become_a_Supplier")}{" "}
                  // </button>
                )
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

  <LoginModal
        loginInfo={loginKey}
        SetLoginKey={SetLoginKey}
        LoginModal={ModalLogin}
        LogincloseModal={closeLoginModal}
        userType={"Affiliate"}
      />

  </>
};

export default GrowMedia;
