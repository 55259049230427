import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import { Modal } from "react-bootstrap";
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FsLightbox from "fslightbox-react";
const ViewReviewModal = (props) => {
  const { viewReviewDetail } = props;
  const [toggler, setToggler] = useState(false);
  const [productIndex, setProductIndex] = useState(0);

  const reactRatings = {
    edit: false,
    activeColor: "#FFAA00",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  // =========for lightbox===========
  let imageArray = [];                                  //As lightbox only accept array of images
  viewReviewDetail.review_image.forEach((item) => imageArray.push(item.image));
  const openLightBox = (index) => {
    setToggler(!toggler);
    setProductIndex(index);
  }
  // =========for lightbox===========

  return (
    <>
      <div className="modal_main">
        <Modal
          className="gift_popup review_modal_"
          show={props.viewReviewModal}
          onHide={props.onCloseViewModal}
        >
          <div className="modal-body">
            <div className="btn-close_main">
              <a
                type="button"
                onClick={props.onCloseViewModal}
                className="btn-close"
              >
                {" "}
                <svg
                  viewport="0 0 12 12"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="1"
                    y1="11"
                    x2="11"
                    y2="1"
                    stroke="#fff"
                    strokeWidth="2"
                  />
                  <line
                    x1="1"
                    y1="1"
                    x2="11"
                    y2="11"
                    stroke="#fff"
                    strokeWidth="2"
                  />
                </svg>
              </a>
            </div>
            <div className="gift_modal_pop view_review">
              <div className="gift_pop_form">
                <h2>{t("_View_Review")}</h2>
                <div className="row gift_all_frm">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 gift_input_all">
                    <div className="customer_rating">
                      <div className="customer_descb">
                        <h6>{viewReviewDetail.title}</h6>
                        <ReactStars
                          size={16}
                          value={Number(viewReviewDetail.rating)}
                          {...reactRatings}
                        />
                        <p>{viewReviewDetail.description}</p>
                      </div>
                    </div>
                    <div className="border_bottom">
                      <hr />
                    </div>
                    <div className="border_bottom">
                      <div className="main_box_img">
                        <div className="row">
                          {viewReviewDetail.review_image !== undefined &&
                            viewReviewDetail.review_image.length > 0 &&
                            viewReviewDetail.review_image.map((item, index) => {
                              return (
                                item.image != undefined && (
                                  <div
                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 "
                                    key={index}
                                  >
                                    <div className="image_add_prd cancel-reason-model">
                                      <img
                                        onClick={() => openLightBox(index)}
                                        className="preview cursor-pointer"
                                        src={
                                          (item.id == "" || item.id == undefined)
                                            ? URL.createObjectURL(item.image)
                                            : item.image
                                        }
                                        alt={"image-" + index}
                                        key={index}
                                      />
                                    </div>
                                  </div>
                                )
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 review_btn"></div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div className="fslight_box_main">
          <FsLightbox
            toggler={toggler}
            sources={imageArray}
            sourceIndex={productIndex}
          />
        </div>
      </div>
    </>
  );
};

export default ViewReviewModal;
