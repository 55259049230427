import { useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import "animate.css";
import { NavLink } from "react-router-dom";
import TextLoop from "react-text-loop";
import { TypeAnimation } from "react-type-animation";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "./Header";

const Banner = () => {
  const { t } = useTranslation();
  const homeData = useSelector((state) => state.home);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (homeData) {
      setCategories(homeData.categories);
    }
  }, [homeData, categories]);
  const text_loop = "Discover Experiences Adventures United Ocean";

  return (
    <>
      <Header />
      <div
        className="banner_main_bg"
        style={{
          backgroundImage: `url(${homeData.title.home_banner_image})`,
        }}
      >
        <div className="banner_all_info">
          {/* <div
            className="banner_top_new"
            style={{
              backgroundImage: `url(${images["top-cloud(2).webp"]})`,
            }}
          ></div> */}
          <div className="text_page_main">
            <div className="container">
              <div className="page__heading">
                <h2>
                  {homeData.title.homepage_banner_heading} <br />
                  {/* {homeData.banner_title[0]} */}
                  {homeData.title.homepage_banner_sub_heading}
                </h2>
              </div>
            </div>
          </div>
          {/* <div className="banner_div_text">
            <h2 id="size">{t("_discover")}</h2>
            <div className="new_animation_bestof desktop_animation">
              <TypeAnimation
                className="text_new_heading"
                preRenderFirstString={true}
                sequence={homeData.banner_title}
                speed={300}
                repeat={Infinity}
              />
            </div>

            <div className="new_animation_bestof mobile_animation">
              <TextLoop
                className={
                  text_loop.split(" ").shift().length > 15
                    ? "div_inner_main"
                    : "div_inner_text"
                }
                springConfig={{ stiffness: 50, damping: 30 }}
              >
                {text_loop !== undefined && text_loop !== ""
                  ? text_loop.split(" ").map((value, index) => {
                    return (
                      <h2 className="title_animation" key={index}>
                        {" "}
                        {value}
                      </h2>
                    );
                  })
                  : ""}
              </TextLoop>
            </div>
          </div> */}

          {/* <div
            className="montian_image_main"
            style={{
              backgroundImage: `url(${homeData.title.home_banner_image})`,
            }}
          ></div> */}

          {/* ============old banner with mountain starts=========== */}

          {/* <div
            className="montian_image_main animate__animated animate__slideInUp"
            style={{
              backgroundImage: `url(${images["image-2.png"]})`,
            }}
          ></div>

          <div
            className="mountain_img_sec"
            style={{
              backgroundImage: `url(${images["image-1.webp"]})`,
            }}
          ></div>

          <div
            className="mountain_cloud_btn animate__animated animate__slideInUp"
            style={{
              backgroundImage: `url(${images["image-3.png"]})`,
            }}
          ></div> */}
          {/* ============old banner with mountain ends=========== */}
        </div>

        {/* <div className="banner_menu_btn">
          <div className="banner_all_tab">
            {categories?.length > 0 &&
              categories.map((value, key) => {
                return (
                  <NavLink to={`/culture-listing/${value.slug}`} key={key}>
                    <span className="icon_menu">
                      <img src={value.image} alt="" />
                    </span>
                    {value.title}
                  </NavLink>
                );
              })}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Banner;
