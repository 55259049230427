const initialProductState = [];

const currencyListReducer = (state = initialProductState, action) => {
    switch (action.type) {
        case "CURRENCY_LIST":
            return action.payload;
        default:
            return state;
    }
};

export default currencyListReducer;
