import { useState } from 'react';
import ImageModal from './base/ImageModal';
import { readFile } from './helpers/cropImage';
import ImageCropModalContent from './ImageCropModalContent';
import { useImageCropContext } from './providers/ImageCropProvider';
import { images } from '../../actions/customFn';

const ImageCrop = ({ profileImage, setProfileImage, setMyProfile }) => {
    const [openModal, setOpenModal] = useState(false);
    // const [preview, setPreview] = useState(images['user_1.png']);

    const { getProcessedImage, setImage, resetStates } = useImageCropContext();

    const handleDone = async () => {
        const avatar = await getProcessedImage();
        setProfileImage(window.URL.createObjectURL(avatar));
        setMyProfile((prev) => ({ ...prev, 'image': avatar }));
        resetStates();
        setOpenModal(false);
    };

    const handleFileChange = async ({ target: { files } }) => {
        const file = files && files[0];

        const imageDataUrl = await readFile(file);
        setImage(imageDataUrl);
        setOpenModal(true);
    };

    return (
        <div className="bg-gray-100 h-screen flex justify-center items-center">
            <input
                type="file"
                onChange={handleFileChange}
                className="d-none"
                id="avatarInput"
                accept="image/*"
            />


            <label htmlFor="avatarInput" className="cursor-pointer pro_inner_img">
                <img
                    src={profileImage}
                    height={192}
                    width={192}
                    className="object-cover rounded-full h-48 w-48"
                    alt=""
                />
            </label>

            <ImageModal open={openModal} handleClose={() => setOpenModal(false)}>
                <ImageCropModalContent handleDone={handleDone} handleClose={() => setOpenModal(false)} />
            </ImageModal>
        </div>
    );
};

export default ImageCrop;