import React, { useContext, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uplodProductNew } from "../../actions/productBuilderAction";
import { ProductBuilderContext } from "../../context";
import { characterLengthCheck, errorMsg, images, startSpinLoader, validateField, } from "../../actions/customFn";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";



const LanguageTitle = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const languageList = useSelector((state) => state.languageList);

  const TITLE_MAX_LENGTH = 65;
  const { tour_id } = useParams();
  const languageReducer = useSelector((state) => state.languageReducer);
  const {
    setTourId,
    getData,
    setSpinLoader,
    productInfo,
    setProductInfo,
    exitRoute,
    saveAndExit,
    languageIdSelected,
    setLanguageIdSelected,
    isConfirmationModal,
    setIsConfirmationModal
  } = useContext(ProductBuilderContext);
  const { changeTab } = props;
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [titledata, setData] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    reference_code: "",
    // language: productInfo.productLanguageId,         //default
    language: languageIdSelected
      ? languageIdSelected
      : productInfo.productLanguageId, //default
    step: "one",
    tourId: tour_id,
    isChange: 0,
    is_approved: 0,
    status: false
    // category_text: [""],
  });

  const [cursorPosition, setCursorPosition] = useState(0);

  // ==========Get Product Details=======
  useEffect(() => {
    if (languageReducer != "" && tour_id != undefined) {
      getData("one", setData);
    } else {
      setIsConfirmationModal(true);
    }
  }, [languageReducer, languageIdSelected]);

  useEffect(() => {
    if (titledata) {
      setFormData({
        title: titledata.product_data.title ? titledata.product_data.title : "",
        status: titledata.status ? titledata.status : false,
        is_approved: titledata.is_approved ? titledata.is_approved : "Not approved",
        overview: titledata.product_data.overview
          ? titledata.product_data.overview
          : "",
        reference_code: titledata.product_data.reference_code
          ? titledata.product_data.reference_code
          : "",
        step: "one",
        tourId: tour_id,
        isChange: titledata?.product_data?.isChange,
        language: languageIdSelected
          ? languageIdSelected
          : productInfo.productLanguageId,
      });
      setIsConfirmationModal(false);
    }
  }, [titledata]);

  // ==========Get Product Details=======

  const [lengthErrorMsg, setlengthErrorMsg] = useState("");
  const textareaRef = useRef(null);
  const handleChange = (e, key = "") => {
    let name = "";
    let value = "";
    
    if (key == "overview") {
      name = key;
      value = e;
      setFormData((prev) => ({ ...prev, [name]: value }));
    } else {
      const { name, value } = e.target;
      if (name != "language") {
        setFormData((prev) => ({ ...prev, ["isChange"]: 1 }));
        setIsConfirmationModal(true);
      }
      if (name == "title") {
        const newPosition = e.target.selectionStart; // Get the new cursor position where user wants
        setCursorPosition(newPosition);

        characterLengthCheck(
          value.substring(0, TITLE_MAX_LENGTH),
          TITLE_MAX_LENGTH,
          t
        )
          .then((response) => {
            setlengthErrorMsg(response);
          })
          .catch((err) => {
            errorMsg(err);
          });
        setFormData((prev) => ({
          ...prev,
          [name]: value.substring(0, TITLE_MAX_LENGTH),
        }));
      } else if (name == "language") {
        setLanguageIdSelected(value);
        setFormData((prev) => ({ ...prev, [name]: value }));
      } else {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    }
  };

  const SubmitDataFn = (exit)=>{
    startSpinLoader("cover-spin");
    let nextRoute = exit || "categorization";
    if (languageReducer !== "") {
      dispatch(
        uplodProductNew(
          productInfo,
          setTourId,
          navigate,
          formData,
          nextRoute,
          "",
          "",
          "",
          "",
          "",
          "",
          setSpinLoader,
          "",
          setProductInfo,
          languageList
        )
      );
    }
  }

  const submit = (exit) => {
    let validate = {
      title: "Required|",
      overview: "RequiredNoBorder|",
      language: "Required|",
    };
 
    validateField(validate, { ...formData }).then((res) => {
      if (res == true) {
        if (isConfirmationModal) {
          Swal.fire({
            title: t('_are_you_sure'),
            text:  t('_your_product_modification_require_approval_can_take_up_to_24_hours_for_revision_your_product'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "rgb(155 176 199)",
            cancelButtonColor: "rgb(227 46 46)",
            confirmButtonText: "Yes",
          }).then((res) => {
            if (res.isConfirmed === true) {
              SubmitDataFn(exit);
            }
          });
        }else{
          SubmitDataFn(exit);
        }
      } else {
        setSpinLoader((status) => !status);
      }
    });
  };

  console.log("formData",formData);
  useEffect(() => {
    if (isFirstRender == false) {
      submit(exitRoute);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <>
      <div className="meet-pickup-main">
        <div className="languagetitle_comptpage">
          <h1> {t("_Let's_get_started")}</h1>

          <h6>{t("_Product_language")}</h6>
          <p>{t("_Product_will_be_saved_in_the_selected_language.")}</p>
          <div className="box-nt-ul-selcet">
            <select
              className="form-select cursor-pointer"
              aria-label="Default select example"
              name="language"
              onChange={handleChange}
              value={formData.language}
            >
              {languageList.length > 0 &&
                languageList.map((value, index) => {
                  return (
                    <option
                      key={index}
                      value={value.id}
                      className="cursor-pointer"
                    >
                      {value.title}
                    </option>
                  );
                })}
            </select>
          </div>

          <h6>
            {t("_What_is_your_product_title?")}
            <span className="required-field">*</span>
          </h6>
          <p>
            {" "}
            {t("_A_great_title")}{" "}
            {t(
              "_will_help_you_attract_the_right_travelers,_in_all_the_right_places."
            )}
          </p>

          <div className="productaddTooltipExample">
            <input
              type="text"
              // className='borderinpt'
              className={`borderinpt `}
              id="title"
              name="title"
              onChange={handleChange}
              value={formData.title}
              maxLength={TITLE_MAX_LENGTH}
              ref={(textarea) => {
                if (textarea !== null) {
                  textarea.selectionStart = cursorPosition;
                  textarea.selectionEnd = cursorPosition;
                }
              }}
            />
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <HtmlTooltip
                  open={open}
                  title={
                    <React.Fragment className="">
                      {/* <Typography color="inherit">Tooltip with HTML</Typography> */}
                      <div className="example_tooltipdata">
                        <div className="flex_divs">
                          {" "}
                          <img src={images["check_tick.png"]} alt="" />{" "}
                          <span>
                            Albufeira: Highlights Tour with Tasting & Sunset in
                            Praia da Santa Eulália
                          </span>
                        </div>

                        <div className="flex_divs">
                          <img src={images["tool_cross.png"]} alt="" />
                          <span>unforgetable tour of Paris</span>
                        </div>
                        {/* <em>{"And here's"}</em> <b>{"some"}</b>{" "}
                      <u>{"amazing content"}</u>. {"It's very engaging. Right?"} */}
                      </div>
                    </React.Fragment>
                  }
                >
                  <button
                    type="button"
                    className="tooltip_example_button "
                    onClick={handleTooltipOpen}
                  >
                    {t("_examples")}
                  </button>
                </HtmlTooltip>
              </div>
            </ClickAwayListener>
          </div>
          <div className="product-info-box">
            <div className="icon-svg-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xlink="http://www.w3.org/1999/xlink"
                width="27"
                height="27"
                x="0"
                y="0"
                viewBox="0 0 512 512"
                space="preserve"
                class=""
              >
                <g>
                  <path
                    d="M223.718 124.76c-48.027 11.198-86.688 49.285-98.494 97.031-11.843 47.899 1.711 96.722 36.259 130.601C173.703 364.377 181 383.586 181 403.777V407c0 13.296 5.801 25.26 15 33.505V467c0 24.813 20.187 45 45 45h30c24.813 0 45-20.187 45-45v-26.495c9.199-8.245 15-20.208 15-33.505v-3.282c0-19.884 7.687-39.458 20.563-52.361C376.994 325.87 391 292.005 391 256c0-86.079-79.769-151.638-167.282-131.24zM286 467c0 8.271-6.729 15-15 15h-30c-8.271 0-15-6.729-15-15v-15h60v15zm44.326-136.834C311.689 348.843 301 375.651 301 403.718V407c0 8.271-6.729 15-15 15h-60c-8.271 0-15-6.729-15-15v-3.223c0-28.499-10.393-55.035-28.513-72.804-26.89-26.37-37.409-64.493-28.141-101.981 9.125-36.907 39.029-66.353 76.184-75.015C299.202 137.964 361 189.228 361 256c0 28.004-10.894 54.343-30.674 74.166zM139.327 118.114 96.9 75.688c-5.857-5.858-15.355-5.858-21.213 0-5.858 5.858-5.858 15.355 0 21.213l42.427 42.426c5.857 5.858 15.356 5.858 21.213 0 5.858-5.858 5.858-15.355 0-21.213zM76 241H15c-8.284 0-15 6.716-15 15s6.716 15 15 15h61c8.284 0 15-6.716 15-15s-6.716-15-15-15zM497 241h-61c-8.284 0-15 6.716-15 15s6.716 15 15 15h61c8.284 0 15-6.716 15-15s-6.716-15-15-15zM436.313 75.688c-5.856-5.858-15.354-5.858-21.213 0l-42.427 42.426c-5.858 5.857-5.858 15.355 0 21.213 5.857 5.858 15.355 5.858 21.213 0l42.427-42.426c5.858-5.857 5.858-15.355 0-21.213zM256 0c-8.284 0-15 6.716-15 15v61c0 8.284 6.716 15 15 15s15-6.716 15-15V15c0-8.284-6.716-15-15-15z"
                    fill="#fc4a14"
                    opacity="1"
                    data-original="#000000"
                    class=""
                  ></path>
                  <path
                    d="M256 181c-6.166 0-12.447.739-18.658 2.194-25.865 6.037-47.518 27.328-53.879 52.979-1.994 8.041 2.907 16.175 10.947 18.17 8.042 1.994 16.176-2.909 18.17-10.948 3.661-14.758 16.647-27.5 31.593-30.989 3.982-.933 7.962-1.406 11.827-1.406 8.284 0 15-6.716 15-15s-6.716-15-15-15z"
                    fill="#fc4a14"
                    opacity="1"
                    data-original="#000000"
                    class=""
                  ></path>
                </g>
              </svg>
            </div>

            <div className="meet-box-content">
              <p>{t("_start_with_the_main_location")}</p>
            </div>
          </div>

          {lengthErrorMsg && (
            <div className="invalid-feedback title-length-error-msg">
              {lengthErrorMsg.message}
            </div>
          )}

          <h6>
            {t("_Product_reference_code")} <span>({t("_optional")})</span>
          </h6>
          <p>
            {" "}
            {t(
              "_Assign_a_reference_code_to_your_product_for_your_internal_use."
            )}
          </p>
          <div className="optionalinput">
            <input
              className="borderinpt"
              type="text"
              id="reference_code"
              name="reference_code"
              onChange={handleChange}
              value={formData.reference_code}
            />
          </div>

          <h6>
            {t("_Product_Overview")} <span className="required-field">*</span>{" "}
            <Tooltip title="Help Center" placement="right">
              <button className="togle_questionbtn">
                <img src={images["question_togle.png"]} alt="" />
              </button>
            </Tooltip>
          </h6>
          <p> {t("_describe_every_aspect")}</p>
          <div
            style={{
              // display: "grid",
              justifyContent: "center",
            }}
          >
            <ReactQuill
              className="text-black"
              theme="snow"
              modules={modules}
              formats={formats}
              value={formData.overview}
              onChange={(e) => handleChange(e, "overview")}
              style={{ height: "250px", color: "#fff" }}
            ></ReactQuill>
          </div>
          <div>
            <div className="mt-5" name="overview"></div>
          </div>

          {/* Button Last Save */}
          <div className="btn-last-save">
            {/* <button onClick={() => changeTab(1)} className="btn-all-save"> Save & continue</button> */}
            <button onClick={() => submit()} className="btn-all-save">
              {" "}
              {t("_Save_&_continue")}
            </button>
          </div>
          {/* <button  className='productsave_buldbutn'>Save & continue</button> */}
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default LanguageTitle;

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};

var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];
