import axios from "../axios";

export const getCityList = (setCityList, city) => {
    const options = {
        headers: { "content-type": "application/x-www-form-urlencoded" },
    };
    return (dispatch) => {
        const url = "/get-city";
        const data = {
            language: "EN",
            state: city
        };
        axios
            .post(url, data, options)
            .then((response) => {
                const { data } = response;
                if (data.status) {
                    setCityList(data.data)
                } else {
                    setCityList([]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
};
