import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const AdvertisementBanner = (props) => {
  const { t } = useTranslation();

  return (
    <>
      {
        props.bannerData?.side_banner_image &&
        <div
          className="advertisement-banner"
          style={{
            backgroundImage: `url(${props.bannerData.side_banner_image})`,
          }}
        >
          <div className="advertisement-content">
            {
              props.bannerData.side_banner_title &&
              <h2>{props.bannerData.side_banner_title}</h2>
            }
            {
              props.bannerData.side_banner_description &&
              <p dangerouslySetInnerHTML={{ __html: props.bannerData.side_banner_description }}></p>
            }
            {/* <button>{t("_check_in")} {t("_more_details")</button> */}
            {
              props.bannerData.side_banner_link != "" ?
                // <NavLink to={`//${props.bannerData.side_banner_link}`}>
                <NavLink to={props.bannerData.side_banner_link}>
                  {t("_check_in")} {t("_more_details")}
                </NavLink> :
                <NavLink to="#">
                  {t("_check_in")} {t("_more_details")}
                </NavLink>
            }
          </div>
        </div>
      }
    </>
  );
};

export default AdvertisementBanner;
