import { BannerAbout, Footer, HeaderSticky, LoadingBar } from "../components";
import { MetaData, images } from "../actions/customFn";
import { motion } from "framer-motion";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { contactUs, getContactUsData } from "../actions/contactUsAction";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const [contactUsData, setContactUsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [metaData, setMetaData] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormError] = useState({});
  const errors = {};

  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(
        getContactUsData(
          languageReducer,
          setContactUsData,
          navigate,
          setIsLoading
        )
      );
    }
  }, [languageReducer]);

  useEffect(() => {
    if (contactUsData.meta_data) {
      setMetaData(contactUsData.meta_data);
    }
  }, [contactUsData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  //=================Form Validation starts===============
  const validateFormData = () => {
    if (formData.name === "") {
      errors.name = t("_enter_name");
    }

    if (formData.email === "") {
      errors.email = t("_enter_email");
    }

    if (formData.message === "") {
      errors.message = t("_enter_message");
    }

    setFormError(errors);
  };
  //=================Form Validation ends===============

  const submit = async () => {
    try {
      validateFormData();
      if (Object.keys(errors).length === 0) {
        dispatch(contactUs(languageReducer, formData, setFormData, navigate));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {Object.keys(contactUsData).length > 0 && (
        <LoadingOverlay active={isLoading === false} spinner={<LoadingBar />}>
          {metaData && <MetaData data={metaData} />}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
          >
            <HeaderSticky />
            <BannerAbout
              bannerData={{
                title: contactUsData.banner.banner_title,
                image: contactUsData.banner.banner_image,
                descrition: contactUsData.banner.banner_description,
              }}
            />
            <div className="conatct_new_info">
              <div className="ContactInfo_main">
                <div className="container">
                  <div className="ContactInfo_inner">
                    <div className="SendUs_message_left">
                      <div className="send_us_inner">
                        <h2>{t("_send_us_message")}</h2>
                        <p>
                          {t(
                            "_lorem_ipsum_is_simply_dimmy_text_of_the_printing_typesetting_industry."
                          )}
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="send_us_form">
                            <div className="row">
                              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                {/* <form action=""></form> */}
                                <div className="send_user_name">
                                  <span>{t("_full_name")}</span>

                                  <input
                                    className="send_user_input"
                                    type="text"
                                    placeholder={t("_enter_your_full_name")}
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                  />
                                  {formErrors.name && (
                                    <div className="invalid-feedback">
                                      {formErrors.name}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="user_name_input">
                                  <span>{t("_email")}</span>

                                  <input
                                    className="send_user_input"
                                    type="text"
                                    placeholder={t("_enter_your_email")}
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                  />
                                  {formErrors.email && (
                                    <div className="invalid-feedback">
                                      {formErrors.email}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className="user_name_input_message">
                                  <span>{t("_message")}</span>
                                  <textarea
                                    className="user_name_message"
                                    type="text"
                                    placeholder={t("_enter_your_message")}
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                  ></textarea>
                                  {formErrors.message && (
                                    <div className="invalid-feedback">
                                      {formErrors.message}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div className="send_user_message_btn">
                                  <button
                                    className="send_user_nav"
                                    onClick={submit}
                                  >
                                    {t("_send_message")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="Contact_info_right">
                      <div
                        className="Contact_info_right_bg"
                        style={{
                          backgroundImage: `url(${images["Contact_info_bg1.png"]})`,
                        }}
                      >
                        <div className="Contact_right_bg_color">
                          <h2>{contactUsData.banner.contact_info_title}</h2>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                contactUsData.banner.contact_info_description,
                            }}
                          ></div>

                          <div className="contactinfo_box">
                            {/* <div className="contactinfo_box_no">
                              <div className="contactinfo_no_mg">
                                <img
                                  src={images["Contact_info_bg1_phone.png"]}
                                  alt=""
                                />
                              </div>
                       
                              <span>{contactUsData.setting.contact_no}</span>
                          
                            </div> */}

                            <div className="contactinfo_box_no">
                              <div className="contactinfo_no_mg">
                                <img
                                  src={images["Contact_info_bg1_location.png"]}
                                  alt=""
                                />
                              </div>
                              {/* <div className="contactinfo_box_no"> */}
                              <span>{contactUsData.setting.contact_email}</span>
                              {/* </div> */}
                            </div>

                            <div className="contactinfo_box_no">
                              <div className="contactinfo_no_mg">
                                <img
                                  src={images["Contact_info_bg1_email.png"]}
                                  alt=""
                                />
                              </div>
                              {/* <div className="contactinfo_box_no"> */}
                              <span>
                                {contactUsData.setting.contact_address}
                              </span>
                              {/* </div> */}
                            </div>
                          </div>

                          <div className="contactinfo_social_icon">
                            <ul>
                              <li>
                                <NavLink
                                  to={contactUsData.setting.facebook_link}
                                  target="_blank"
                                >
                                  <img
                                    src={images["Contact_social_link_1.png"]}
                                    alt=""
                                  />
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to={contactUsData.setting.instagram_link}
                                  target="_blank"
                                >
                                  <img
                                    src={images["Contact_social_link_2.png"]}
                                    alt=""
                                  />
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to={contactUsData.setting.twitter_link}
                                  target="_blank"
                                >
                                  <img
                                    src={images["Contact_social_link_3.png"]}
                                    alt=""
                                  />
                                </NavLink>
                              </li>
                              <li>
                                <NavLink
                                  to={contactUsData.setting.linkedin_link}
                                  target="_blank"
                                >
                                  <img
                                    src={images["Contact_social_link_4.png"]}
                                    alt=""
                                  />
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="map_conatact">
              <div className="contact_map_main">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"
                  width="100%"
                  height="650"
                  frameBorder="0"
                ></iframe>
              </div>
            </div>

            <Footer />
          </motion.div>
        </LoadingOverlay>
      )}
    </>
  );
};

export default ContactUs;
