import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { useSelector } from "react-redux";
import { ProductBuilderContext } from "../../../context";
import { errorMsg } from "../../../actions/customFn";
import { useTranslation } from "react-i18next";
import { Input, TextField } from "@mui/material";

const InclusionModal = (props) => {
  const { t } = useTranslation();
  const languageReducer = useSelector((state) => state.languageReducer);
  const { getData, languageIdSelected } = useContext(ProductBuilderContext);
  const {
    inclusionModalShow,
    setInclusionModalShow,
    getCategory,
    getInclusionKey,
    setInclusionKey,
    getInclusion,
    setInclusion,
    completeList,
    categoryAddModal,
    setCategoryAddModal,
    inclusionData,
    setInclusionData,
    setNewCategoryType,
    setIsChange,setIsConfirmationModal
  } = props;
  const [formErrors, setFormError] = useState({});
  const errors = {};
  const [getChildCategory, setChildCategory] = useState([]);

  const handleModalClose = () => {
    setFormError({});
    setInclusionModalShow(false);
    setInclusionKey("");
    setInclusionData({
      inclusion_id: "",
      inclusion_parent: "",
      inclusion_parent_title: "",
      inclusion_title: "",
      inclusion_child_title: "",
      is_edit: 1,
    });
  };

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inInclusion = false;
    let inExclusion = false;
    completeList.forEach((item) => {
      if (item.inclusion_title == value) {
        inInclusion = true;
      }
      if (item.exclusion_title == value) {
        inExclusion = true;
      }
    });

    if (inInclusion == true) {
      errors.inclusion_title = t("_this_item_is_already_selected_in_inclusion");
    } else if (inExclusion == true) {
      errors.inclusion_title = t("_this_item_is_already_selected_in_exclusion");
    } else {
      errors.inclusion_title = "";
    }

    setFormError(errors);
    setInclusionData((prev) => ({ ...prev, [name]: value }));
    
    if (name == "inclusion_parent") {
      setInclusionData((prev) => ({ ...prev, inclusion_title: "" }));
      setInclusionData((prev) => ({ ...prev, inclusion_child_title: "" }));
    }
  };

  const validateFormData = () => {
    let errorMessage = t("_parent_field_required");
    if (inclusionData.inclusion_parent === "") {
      errors.inclusion_parent = errorMessage;
    }

    if (
      getChildCategory?.inclusion_exclusion_child != undefined &&
      getChildCategory?.inclusion_exclusion_child.length > 0
    ) {
      if (inclusionData.inclusion_title == "") {
        errors.inclusion_title = t("_sub_Child_field_required");
      }
    }
    setFormError(errors);
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    validateFormData();
    if (Object.keys(errors).length == 0) {
      let newUpdateData = [];
      if (typeof getInclusionKey === "number") {
        newUpdateData = [...getInclusion];
        newUpdateData[getInclusionKey] = inclusionData;
        setInclusion(newUpdateData);
      } else {
        setInclusion([...getInclusion, inclusionData]);
      }
      setIsChange(1)
      setIsConfirmationModal(true)
      props.setMainFormError({});
      handleModalClose();
    }
  };

  useEffect(() => {
    if (languageReducer != "" && inclusionData?.inclusion_parent != "") {
      getData("inclusion_exclusion_child", setChildCategory, {
        get_child: inclusionData.inclusion_parent,
      });
    } else {
      setChildCategory([]);
    }
  }, [inclusionData?.inclusion_parent, languageReducer, languageIdSelected]);

  useEffect(() => {
    if (getInclusion != undefined) {
      if (typeof getInclusionKey === "number") {
        if (getInclusion[getInclusionKey] != undefined) {
          setInclusionData(getInclusion[getInclusionKey]);
        } else {
          setChildCategory([]);
        }
      }
    }
  }, [getInclusionKey]);

  const handleOpenCategoryModal = () => {
    setCategoryAddModal(true);
    setNewCategoryType("inclusion");
    handleModalClose();
  };

  return (
    <Modal
      show={inclusionModalShow}
      onHide={() => {
        setInclusionModalShow(false);
      }}
      className="inclusion_pagemodels"
    >
      <Modal.Body>
        <div className="close_modelbutn">
          <button
            onClick={() => {
              handleModalClose();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z"
                fill="#4f4d4d"
              />
            </svg>
          </button>{" "}
        </div>

        <form
          onSubmit={(e) => {
            handelSubmit(e);
          }}
        >
          <div className="inclusion_models">
            <h1>{t("_Add_an_inclusion")}</h1>
            <p>
              {t(
                "_make_your_list_precise_so_travelers_understand_the_value_of_this_experience._Don’t_add_more_than_10_items_and_avoid_duplicates."
              )}
            </p>

            <h4>{t("_category")}</h4>

            <div className="inclusion_modelselector mb-5">
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                error={formErrors.inclusion_parent ? true : false}
              >
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  placeholder="Select a Category"
                  value={inclusionData?.inclusion_parent}
                  name="inclusion_parent"
                  label="inclusion_parent"
                  onChange={handleChange}
                >
                  {getCategory != undefined &&
                    getCategory.length > 0 &&
                    getCategory.map((value, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={value.id}
                          onClick={(e) => {
                            setInclusionData((prev) => ({
                              ...prev,
                              inclusion_parent_title: value.title,
                            }));
                          }}
                        >
                          {value.title}
                        </MenuItem>
                      );
                    })}
                </Select>
                {formErrors?.inclusion_parent && (
                  <p className="form_error">{formErrors?.inclusion_parent}</p>
                )}
              </FormControl>
              <span>
                {t("_dont_found_category")}{" "}
                <span className="add_new_cat" onClick={handleOpenCategoryModal}>
                  {t("_add_new_category")}
                </span>
              </span>
            </div>

            {getChildCategory?.inclusion_exclusion_child != undefined &&
            getChildCategory?.inclusion_exclusion_child.length > 0 ? (
              <div>
                <h4>{t("_sub_category")}</h4>

                <div className="inclusion_modelselector mb-5">
                  <FormControl
                    sx={{ m: 1, minWidth: 120 }}
                    error={formErrors.inclusion_title ? true : false}
                  >
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      name="inclusion_title"
                      value={inclusionData.inclusion_title}
                      onChange={handleChange}
                    >
                      {getChildCategory?.inclusion_exclusion_child.map(
                        (value, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={value.id}
                              onClick={(e) => {
                                setInclusionData((prev) => ({
                                  ...prev,
                                  inclusion_child_title: value.title,
                                }));
                              }}
                            >
                              {value.title}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>

                    {formErrors?.inclusion_title && (
                      <p className="form_error">
                        {formErrors?.inclusion_title}
                      </p>
                    )}
                  </FormControl>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="model_lastendbtns">
              <button
                type="button"
                className="remove-allsave mr-4"
                onClick={() => {
                  handleModalClose();
                }}
              >
                {t("_cancel")}
              </button>

              {formErrors?.inclusion_title ? (
                <span className="btnall-save disabled-save-btn">Add</span>
              ) : (
                <button type="submit" className="btnall-save ">
                  {t("_Add")}
                </button>
              )}
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default InclusionModal;
