import React, { useEffect } from "react";
import { MetaData } from "../../actions/customFn";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAffiliatePaypalId } from "../../actions/affiliateAction";
import { useTranslation } from "react-i18next";
import LoadingBar from "../Loading/LoadingBar";

const AffiliatePayment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileReducer = useSelector((state) => state.profileReducer);
  const languageReducer = useSelector((state) => state.languageReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [payPalId, setPaypalId] = useState('');

  const submit = () => {
    if (languageReducer) {
      setIsLoading(true);
      dispatch(
        setAffiliatePaypalId(
          languageReducer,
          payPalId,
          navigate,
          setIsLoading
        )
      );
    }
  };

  useEffect(() => {
    if (profileReducer && profileReducer.is_paypal_account !== undefined &&
      profileReducer.is_paypal_account != null && profileReducer.is_paypal_account != '') {
      setPaypalId(profileReducer.is_paypal_account)
    }
  }, [profileReducer])

  let metaData = {
    title: t("_payment_method")
  }

  return (
    <>
      {
        metaData &&
        <MetaData data={metaData} />
      }
      <div className="affiliate_profile_main">
        <div className="text_banner">
          <span>{t("_payment_method")} </span>
        </div>
        {
          isLoading === true ?
            <div className="blinking-bar-loader">
              <LoadingBar />
            </div>
            :
            <div className="user_edit_main">
              <div className="input_all_edit">
                <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 search_header_main">
                    <div className="heder_new_search_affiliate">
                      <div className="heder_new_search">
                        <div className="input_search">
                          <label htmlFor="">{t("_enter_your_paypal_id")}</label>
                          <input
                            className="mt-2"
                            type="text"
                            placeholder={t("_enter_your_paypal_id")}
                            onChange={(e) => setPaypalId(e.target.value)}
                            value={payPalId}
                          >
                          </input>
                          <button
                            className="paypal-set-btn"
                            onClick={(e) => submit(e.target.value)}
                          >
                            {t("_submit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  );
};

export default AffiliatePayment;
