import axios from "../axios";
import { successMsg } from "./customFn";
import { setLanguage } from "./languageAction";
import i18n from "i18next";

export const setLanguageList = (data) => {
  return {
    type: "LANGUAGE_LIST",
    payload: data,
  };
};


export const asyncGetLanguagesList = (deafult) => {
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };
  return (dispatch) => {
    const url = "/get_languages";
    const data = {
      deafult: deafult,
    };
    axios
      .post(url, data, options)
      .then((response) => {
        const { data } = response;
        if (data.status) {
          dispatch(setLanguageList(data.data));
        } else {
          // notifyDanger(data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const asyncSetLanguage = (data,navigate) => {
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };
  return (dispatch) => { 
    const url = "/set_language";
    axios
      .post(url, data, options)
      .then((response) => {
        const { data } = response;
        if (data.status) {
          dispatch(setLanguage(data.data));
          localStorage.setItem("language", JSON.stringify(data.data));
          i18n.changeLanguage(data.data.short_code);
        } else {
          // notifyDanger(data.message);
        }
      })
      .catch((error) => {
        navigate("/something-went-wrong");
        window.history.pushState(null, "", "/");
        console.log(error);
      });
  };
};
