import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const RecommendedThings = (props) => {
  const { t } = useTranslation();
  const { recommendedThings } = props;
  const [showMoreId, setShowMoreId] = useState("");
  const { slug } = useParams();

  return (
    <>
      {
        recommendedThings.length > 0 &&
        <div className="recommended_things_bg">
          <div className="container">
            <div className="recommended_things_heading">
              <h2>{t("_Our_most_recommended_things_to_do_in")}&nbsp;{slug}</h2>
            </div>
            <div className="recommended_things_list">
              <div className="row">
                {
                  recommendedThings.map((value, index) => {
                    return (
                      <div
                        // className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                        className={showMoreId === index ? "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" : "col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"}
                        key={index}
                      >
                        <div className="recommended_list_info">
                          <div className="recommended_list_img">
                            <img src={value.image} alt="" />
                          </div>
                          <div className="recommended_list_content">
                            <h2>
                              {" "}
                              <span>{`${index + 1 + "."}`}</span>{" "}{value.title}
                            </h2>
                            {/* {showMoreId == "" && showMoreId == index
                            ? 
                            <p dangerouslySetInnerHTML={{
                              __html: value.description.length > 360 ? value.description.substring(0, 370) + "..." : value.description
                            }}></p>
                            : 
                            <p dangerouslySetInnerHTML={{
                              __html:  value.description
                            }}></p>
                            } */}
                            <p dangerouslySetInnerHTML={{
                              __html: (value.description.length > 360 && showMoreId !== index) ? value.description.substring(0, 370) + "..." : value.description
                            }}></p>



                            {
                              showMoreId === index ?
                                <button onClick={() => setShowMoreId("")}>{t("_See_less")}</button>
                                :
                                // value.description.length > 360 &&
                                <button onClick={() => setShowMoreId(index)}>{t("_See_more")}</button>
                            }
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default RecommendedThings;
