import React from "react";
import { HeaderSticky } from "../components";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import HotelUserAccount from "../components/HotelUser/HotelUserAccount";
import HotelUserSidebar from "../components/HotelUser/HotelUserSidebar";
import HotelUserDashboard from "../components/HotelUser/HotelUserDashboard";
import HotelUserTransactionHistory from "../components/HotelUser/HotelUserTransactionHistory";
import HotelUserCommissionHistory from "../components/HotelUser/HotelUserCommissionHistory";
import HotelUserCommissionDetails from "../components/HotelUser/HotelUserCommissionDetails";
import Analytics from "../components/ProviderAccount/Analytics";
const HotelUser = () => {
    const location = useLocation();
    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 3 }}
            >
                <HeaderSticky />
                <div className="userprofie_main">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                                <HotelUserSidebar />
                            </div>
                            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
                                {location.pathname == "/hotel-user-dashboard" ? (
                                    <HotelUserDashboard />
                                ) : location.pathname == "/hotel-user-transaction-history" ? (
                                    <HotelUserTransactionHistory />
                                ) : location.pathname == "/hotel-user-commission-history" ? (
                                    <HotelUserCommissionHistory />
                                ) : location.pathname == "/hotel-user-commission-details" ? (
                                    <HotelUserCommissionDetails />
                                ) : location.pathname == "/hotel-user-analytics" ? (
                                    <Analytics />
                                ) : (
                                    <HotelUserAccount />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footer /> */}
            </motion.div>
        </>
    );
};

export default HotelUser;
