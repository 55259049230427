import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = (props) => {
  return (
    <>
      {
        props.videoUrl && props.videoUrl !== undefined &&
        <div className="video_player">
          <ReactPlayer
            url={props.videoUrl}
            playing={true}
            controls={true}
            loop={true}
            width="100%"
            // height="100%"
            height="300px"
            muted={true}
            // playIcon={<button>  <img src={images['Blog_bg_main.png']} alt="" /></button>}
            light={
              <img
                src={props.videoThumbnail}
                alt=""
                style={{ width: "100%", height: "100%", borderRadius: "8px" }}
              />
            }
            playsinline={true}
          />
        </div>
      }
    </>
  );
};

export default VideoPlayer;
