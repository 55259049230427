import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg, successMsg } from "./customFn";
//   ===================API call for Transaction-history starts=================

export const getTransactionHistory = (
  languageReducer,
  setDashBoardData,
  navigate,
  setIsLoading,
  setRecordStatus,
  setPageCount,
  currentPage
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        offset: currentPage,
      };

      const response = await axios.post("transaction-history", params);
      if (response.data.status === true) {
        setDashBoardData(response.data.data);

        setPageCount(response.data.page_count);
        setRecordStatus(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        errorMsg("Somthing went wrong...");
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};
// ===================API call for Transaction-history ends===================
