import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SupplierSubCategory from "./SupplierSubCategory";
import AboutProductSubCategory from "./AboutProductSubCategory";
import PaymentSubCategory from "./PaymentSubCategory";
import MoreHelpSec from "./MoreHelpSec";

import { useDispatch, useSelector } from "react-redux";
// import { getAboutUsData } from "../actions/aboutUsAction";
import { useNavigate } from "react-router-dom";
import {
  getHelpCanterTabList,
  getHelpTabsdata,
} from "../../actions/helpCenterAction";
import LoadingBar from "../Loading/LoadingBar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function CategoryTabs() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  // For Load state
  const [isLoading, setIsLoading] = useState(true);
  const [categorytab, setCategoryTab] = useState();

  const [helptabsdata, setHelpTabdata] = useState();

  const [value, setValue] = useState(25);

  const [searchdata, setSearchData] = useState("");
  const [searchItem, setSearchItem] = useState();
  const [showCrossBtn, setShowCrossValue] = useState(false);
  const handleSearch = (e) => {
    e.preventDefault();
    setSearchData(e.target.value);
    // Show the cross button
      setShowCrossValue(e.target.value.length > 0);
  };
  // Remove search value
  const handleonRemoveSearch = () => {
    setSearchData("");
    setSearchItem("");
    setShowCrossValue(false); // Hide cross button
      dispatch(
        getHelpTabsdata(
          languageReducer,
          setHelpTabdata,
          value,
          "",
          checklogin,
          setIsLoading
        )
      );
  };

  const handleonSearchClick = (e) => {
    e.preventDefault();
    setSearchItem(searchdata);
  };

  // Set Default tab
  useEffect(() => {
    if (categorytab?.length > 0) {
      setValue(categorytab[0]?.id);
    }
  }, [categorytab]);

  // Set Category tab index
  const handleChangeTabs = (event, category_id) => {
    setValue(category_id);
  };
  // Check if user login or not
  const checklogin = useSelector((state) => state.login);
  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(
        getHelpTabsdata(
          languageReducer,
          setHelpTabdata,
          value,
          searchItem,
          checklogin,
          setIsLoading
        )
      );
    }
  }, [languageReducer, searchItem, value, checklogin, dispatch]);
 // Trigger API call on tab change
 useEffect(() => {
  if (languageReducer !== "" && value !== null) {
    dispatch(
      getHelpTabsdata(
        languageReducer,
        setHelpTabdata,
        value,
        searchItem,
        checklogin,
        setIsLoading
      )
    );
  }
}, [value, dispatch, languageReducer, searchItem, checklogin]);
  //   ----- tab list ----
  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(
        getHelpCanterTabList(languageReducer, setCategoryTab, setIsLoading)
      );
    }
  }, [languageReducer]);

  return (
    <Box className="categories help_categorytab">
      <div className="container">
        <AppBar position="static" className="category-tab-sec">
          <Tabs
            value={value}
            onChange={handleChangeTabs}
            className="tab-titles"
            aria-label="Category Tabs"
          >
            {categorytab != undefined &&
              categorytab?.map((v, i) => {
                return (
                  <Tab key={v.id} label={v.title && v.title} value={v.id} />
                );
              })}
          </Tabs>
        </AppBar>
        {isLoading === true ? (
          <div className="tab-content center-loader">
            <LoadingBar />
          </div>
        ) : (
          <>
            {value !== 27 && (
              <form action="" className="cat-search">
                {showCrossBtn && (
                  <button
                    type="button"
                    className="search_remove_btn cross-button"
                    onClick={handleonRemoveSearch}
                  >
                    <span>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="red"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.27916 12.7208C1.45797 12.8996 1.70044 13 1.95327 13C2.2061 13 2.44857 12.8996 2.62737 12.7208L7 8.34821L11.3726 12.7208C11.5514 12.8996 11.7939 13 12.0467 13C12.2996 13 12.542 12.8996 12.7208 12.7208C12.8996 12.542 13 12.2996 13 12.0467C13 11.7939 12.8996 11.5514 12.7208 11.3726L8.34821 7L12.7208 2.62737C12.8996 2.44857 13 2.2061 13 1.95327C13 1.70044 12.8996 1.45797 12.7208 1.27916C12.542 1.10041 12.2996 1 12.0467 1C11.7939 1 11.5514 1.10041 11.3726 1.27916L7 5.65179L2.62737 1.27916C2.44857 1.10041 2.2061 0.999999 1.95327 0.999999C1.70044 0.999999 1.45797 1.10041 1.27916 1.27916C1.10042 1.45796 1 1.70044 1 1.95327C1 2.20609 1.10042 2.44857 1.27916 2.62737L5.65179 7L1.27916 11.3726C1.10041 11.5514 1 11.7939 1 12.0467C1 12.2996 1.10041 12.542 1.27916 12.7208Z"
                          fill="white"
                          stroke="white"
                          strokeWidth="0.5"
                        ></path>
                      </svg>
                    </span>
                  </button>
                )}

                <input
                  type="text"
                  name=""
                  value={searchdata}
                  placeholder="Search for a topic"
                  onChange={handleSearch}
                />
                <button
                  className="helpbtn_search"
                  onClick={handleonSearchClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#FFFFFF"
                  >
                    <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
                  </svg>
                </button>
              </form>
            )}
            {categorytab?.length > 0 && (
              <div className="tab-content ">
                <TabPanel value={value} index={25} dir={theme.direction}>
                  <SupplierSubCategory helptabsdata={helptabsdata} />
                </TabPanel>
                <TabPanel value={value} index={26} dir={theme.direction}>
                  <AboutProductSubCategory helptabsdata={helptabsdata} />
                </TabPanel>
                {/* <TabPanel value={value} index={12} dir={theme.direction}>
                   <PaymentSubCategory helptabsdata={helptabsdata} />
              </TabPanel> */}
                <TabPanel value={value} index={27} dir={theme.direction}>
                  <MoreHelpSec morelist={categorytab} moreHelpId={27} />
                </TabPanel>
              </div>
            )}
          </>
        )}
      </div>
    </Box>
  );
}
