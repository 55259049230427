import Modal from "react-bootstrap/Modal";
import React from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProviderPaymentDetails } from "../../actions/providerAction";

const SupplierPaymentConfirmation = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {confirmationModalShow,setConfirmationModalShow,setIsLoading,formData} = props;
    const languageReducer = useSelector((state) => state.languageReducer);

    const handleSubmit = ()=>{
        setIsLoading(true);
        dispatch(
          setProviderPaymentDetails(
            languageReducer,
            formData,
            navigate,
            setIsLoading
          )
        );
        setConfirmationModalShow(false)

    }
  return (
    <div className="modal_main">
    <Modal
      className="payment_confirmation_modal"
      show={confirmationModalShow}
      onHide={()=>setConfirmationModalShow(false)}
      centered
    >
      <div className="modal-body">
        <div className="row">
          <div className="entry_cross_btn cursor-pointer">
            <span  onClick={()=>setConfirmationModalShow(false)}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="red"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.27916 12.7208C1.45797 12.8996 1.70044 13 1.95327 13C2.2061 13 2.44857 12.8996 2.62737 12.7208L7 8.34821L11.3726 12.7208C11.5514 12.8996 11.7939 13 12.0467 13C12.2996 13 12.542 12.8996 12.7208 12.7208C12.8996 12.542 13 12.2996 13 12.0467C13 11.7939 12.8996 11.5514 12.7208 11.3726L8.34821 7L12.7208 2.62737C12.8996 2.44857 13 2.2061 13 1.95327C13 1.70044 12.8996 1.45797 12.7208 1.27916C12.542 1.10041 12.2996 1 12.0467 1C11.7939 1 11.5514 1.10041 11.3726 1.27916L7 5.65179L2.62737 1.27916C2.44857 1.10041 2.2061 0.999999 1.95327 0.999999C1.70044 0.999999 1.45797 1.10041 1.27916 1.27916C1.10042 1.45796 1 1.70044 1 1.95327C1 2.20609 1.10042 2.44857 1.27916 2.62737L5.65179 7L1.27916 11.3726C1.10041 11.5514 1 11.7939 1 12.0467C1 12.2996 1.10041 12.542 1.27916 12.7208Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.5"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <div className="gift_modal_pop p-2">
          <div className="gift_pop_form">
            <h4 className="text_heading">{t("_are_you_sure_your_payment_details_are_correct")}</h4>    
            <h4 className="text-center">{t("_after_you_submit_you_can_not_edit")}</h4>    
            <div className="modal_button">

            <button type="button" onClick={handleSubmit}  className="save ">
                  {t("_yes")}
                </button>
              
              
              <button
                className="remove"
                onClick={()=>setConfirmationModalShow(false)}
              >
                {t("_no")}
              </button>

             
              
            </div>
          </div>
        </div>
      </div>    
    </Modal>
  </div>
  )
}

export default SupplierPaymentConfirmation
