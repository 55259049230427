import FacebookLogin from 'react-facebook-login';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { socialLogin } from '../../actions/loginAction';
import { useTranslation } from 'react-i18next';
const FacebookLoginComponent = (props) => {
    const { t } = useTranslation();
    const { LogincloseModal } = props;
    const facebookAppId = "3275814556046425";
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const [facebookAppId, setFacebookAppId] = useState("")
    // const homeReducer = useSelector((state) => state.homeReducer);

    // useEffect(() => {
    //     if (homeReducer) {
    //         setFacebookAppId(homeReducer.settings.facebook_app_Id)
    //     }
    // }, [homeReducer])


    const responseFacebook = async (data) => {
        try {
            window.FB.logout();   // prevent login in actual facebook account when usser logged in this app
            if (data.accessToken) {
                const loginData = {
                    email: data.email,
                    user_name: data.name,
                    image: data.picture.data.url,
                    user_type: "Customer",
                    login_type: "facebook"
                };

                dispatch((socialLogin(loginData, LogincloseModal, navigate)))
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <>
            {
                facebookAppId !== "" &&
                <FacebookLogin
                    appId={facebookAppId}
                    autoLoad={false}
                    fields="email,name,picture"
                    callback={responseFacebook}
                    scope="public_profile,email,user_friends"
                    textButton={
                        // <img
                        //     src={images["facebook-login-button.png"]}
                        //     alt=""
                        // />
                        t("_login_with_facebook")
                    }
                    icon="fa-facebook"
                // cssClass=""
                />
            }
        </>
    )
}

export default FacebookLoginComponent;
