import React from "react";
import {
  HeaderSticky,
  Footer,
  VideoPlayer,
  AdvertisementBanner,
  TopPost,
  LoadingBar,
} from "../components";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { images } from "../actions/customFn";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getBlogDetails } from "../actions/blogsAction";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";

const BlogDetails = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer)
  const [blogDetails, setBlogDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { slug } = useParams();

  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(getBlogDetails(languageReducer, setBlogDetails, navigate, setIsLoading, slug));
    }
  }, [languageReducer]);

  return (
    <LoadingOverlay active={isLoading === false} spinner={<LoadingBar />}>
      {
        Object.keys(blogDetails).length > 0 &&
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3 }}
        >
          <HeaderSticky />
          {/* <Technology /> */}

          <div className="technology_section new_blog_details">
            <div className="container">
              <div className="blog_details_top_list">
                <div className="list_Breadcrumb">
                  <ul>
                    <li>
                      <NavLink to="/"> {t("_home")} / </NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink to="/blogs"> {t("_Blog")} / </NavLink>
                    </li>

                    <li>
                      {" "}
                      {t("Blog_Details")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
                  <div className="blog_details_main_img">
                    <img src={blogDetails.image} alt="" />
                  </div>
                  <div className="blog_details_icon">
                    <div className="latestblogs_right_profile1">
                      <div className="right_profile_left1">
                        <div className="right_profile_left_bg">
                          {/* <img src={images["LatestBlogs_4.png"]} alt="" /> */}
                        </div>
                        {/* <span>Earl L Hogue</span> */}
                      </div>
                      <div className="right_profile_right1">
                        <div className="right_profile_right1_bg">
                          <img src={images["TopPost5.png"]} alt="" />
                        </div>
                        <span>{blogDetails.date}</span>
                      </div>
                    </div>
                  </div>
                  <div className="blog_details_content">
                    <h1>{blogDetails.title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: blogDetails.description }}></p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                  <TopPost topPost={blogDetails.top_post} />
                  <VideoPlayer
                    videoUrl={blogDetails.video_url}
                    videoThumbnail={blogDetails.video_thumbnail_image}
                  />

                  <div className="blog_advertisement">
                    <AdvertisementBanner
                      bannerData={{
                        side_banner_image: blogDetails.side_banner_image,
                        side_banner_title: blogDetails.side_banner_title,
                        side_banner_description: blogDetails.side_banner_description,
                        side_banner_link: blogDetails.side_banner_link,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </motion.div>
      }
    </LoadingOverlay>
  );
};
export default BlogDetails;
