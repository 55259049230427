import { useContext, useEffect } from "react";
import { useState } from "react";
import { images } from "../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/fontawesome-free-solid";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

import { ProductListingInfo } from "../../context";
import RecordNotFound from "../../pages/RecordNotFound";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getWishlistActivityList,
  removeFromWishlist,
} from "../../actions/wishlistAction";
import { PuffLoader } from "react-spinners";
import LoginModal from "../Modal/LoginModal";
import WishlistModel from "../Modal/WishlistModel";
import CreateNewListModel from "../Modal/CreateNewListModel";
import { ShimmerPostItem } from "react-shimmer-effects";

const ProductList = () => {
  const { t } = useTranslation();
  const { attraction_slug, category } = useParams();
  const {
    isDataLoad,
    listingData,
    setCurrentPage,
  } = useContext(ProductListingInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const checklogin = useSelector((state) => state.login);
  const [loginKey, SetLoginKey] = useState("");
  const [ModalLogin, setModalLogin] = useState(false);
  const [wishLishedLoader, setWishLishedLoader] = useState(false);
  const [wishLishedId, setWishLishedId] = useState("");
  const [wishLishedArray, setWishLishedArray] = useState([]);
  const languageReducer = useSelector((state) => state.languageReducer);

  const [activityList, setActivityList] = useState([]);
  const [wishlistParameter, setWishlistParameter] = useState("");
  const [openWishlistModel, setOpenWishlistModel] = useState(false);
  const [openNewListModel, setOpenNewListModel] = useState(false);
  const closeWishlistModel = () => {
    setOpenWishlistModel(false);
  };
  const closeNewListModel = () => {
    setOpenNewListModel(false);
  };

  useEffect(() => {
    if (listingData.length > 0) {
      listingData.forEach((key1) => {
        if (key1.is_wishlist == 1) {
          setWishLishedArray((state) => [...state, key1.id]);
        }
      });
    }
  }, [listingData, checklogin]);

  const addWishList = (id, newId = "", country) => {
    if (checklogin) {
      setWishlistParameter({
        id: id,
        newId: newId,
        country: country,
      });

      if (newId != "") {
        if (wishLishedArray.includes(newId)) {
          dispatch(
            removeFromWishlist(
              id,
              newId,
              languageReducer,
              navigate,
              location,
              setWishLishedLoader,
              setWishLishedArray,
              wishLishedArray
            )
          );
        } else {
          // setOpenWishlistModel(true);
          dispatch(
            getWishlistActivityList(
              setActivityList,
              languageReducer,
              navigate,
              location,
              setOpenWishlistModel,
              setOpenNewListModel
            )
          );
        }
      }
    } else {
      setModalLogin(true);
      SetLoginKey("login");
    }
  };

  const closeLoginModal = () => {
    setModalLogin(false);
  };

  const reactRatings = {
    edit: false,
    activeColor: "rgb(252 83 1)",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };


  return (
    <>
      <div className="prd_list_main" id="prd_main_show">
        {isDataLoad == true ? (
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
              <ShimmerPostItem card title text cta></ShimmerPostItem>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
              <ShimmerPostItem card title text cta></ShimmerPostItem>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
              <ShimmerPostItem card title text cta></ShimmerPostItem>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
              <ShimmerPostItem card title text cta></ShimmerPostItem>
            </div>
          </div>
          // <div className="rotating-circle-main">
          //   <LoadingBar />
          // </div>
        ) : (
          <div className="all_prd_show ">
            {listingData.length > 0 ? (
              <div className="product-listing">
                <div className="row">
                  {listingData.map(function (value, index) {
                    return (
                      <div
                        className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4"
                        key={index}
                      >
                        {/* {isDataLoad === true ? (
                          <ShimmerPostItem card title text cta></ShimmerPostItem>
                        ) : ( */}
                        <div className="product_card_inner">
                          <button
                            className="wishlist-icon icon-new-wishlist"
                            onClick={() =>
                              addWishList(value.slug, value.id, value.country)
                            }
                          >
                            {wishLishedLoader == true &&
                              wishLishedId == value.id ? (
                              <PuffLoader size={20} color="#fff" />
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="17"
                                viewBox="0 0 18 17"
                                fill="none"
                              >
                                <path
                                  d="M13.125 0.437744C12.2805 0.45088 11.4543 0.686634 10.7301 1.1212C10.0058 1.55576 9.40902 2.17375 9 2.91274C8.59098 2.17375 7.9942 1.55576 7.26993 1.1212C6.54565 0.686634 5.71954 0.45088 4.875 0.437744C3.5287 0.496237 2.26028 1.08518 1.34684 2.07592C0.433394 3.06665 -0.0508137 4.37864 -7.64383e-07 5.72524C-7.64383e-07 9.13549 3.5895 12.86 6.6 15.3852C7.27216 15.9501 8.12201 16.2598 9 16.2598C9.87798 16.2598 10.7278 15.9501 11.4 15.3852C14.4105 12.86 18 9.13549 18 5.72524C18.0508 4.37864 17.5666 3.06665 16.6532 2.07592C15.7397 1.08518 14.4713 0.496237 13.125 0.437744Z"
                                  fill={
                                    wishLishedArray.includes(value.id)
                                      ? "#FC5301"
                                      : "#C4C7CC"
                                  }
                                />
                              </svg>
                            )}
                          </button>
                          <NavLink
                            to={`/culture-details/${value.slug}`}
                            target="_blank"
                          >
                            <div
                              className="prd_crd_img"
                              style={{
                                backgroundImage: `url(${value.image})`,
                              }}
                            >
                              {/* -- ashu-- */}
                              <div className="bg_top_info imagetxt_earthimg">
                                {/* {value.image_text ? (
                                                <span>{value.image_text}</span>
                                              ) : (
                                                ""
                                              )} */}
                                {/* <img src={images["earth_text.png"]} alt="" /> */}
                              </div>

                              {value.original_badge == "yes" && (
                                <div className="originalinfo_badge ">
                                  <img
                                    src={images["Cultural-Tag-1.png"]}
                                    alt=""
                                  />
                                </div>
                              )}

                              {/* {value.show_badge && (
                                  <div className="bg_bottom_info-badge" dangerouslySetInnerHTML={
                                    { __html: value.show_badge }
                                  }>
                                  </div>
                                )}
                                {value.likely_to_sell_out ==
                                  "yes" && (
                                    <div className="bg_bottom_info">
                                      <span>
                                        {t("_likely_to_sell_out")}
                                      </span>
                                    </div>
                                  )} */}
                            </div>

                            <div className="product_all_info">
                              <div className="prd_crd_heading">
                                <h2>{value.title.charAt(0).toUpperCase() + value.title.slice(1)}</h2>
                              </div>
                              <div className="prd_crd_loaction">
                                <div className="prd_location_main">
                                  <div className="icon_loc">
                                    {value.location && (
                                      <>
                                        <FontAwesomeIcon
                                          icon={faMapMarkerAlt}
                                        />
                                        {value.location}
                                      </>
                                    )}
                                  </div>
                                  <div className="trip_details">
                                    {value.trip_details}
                                  </div>
                                </div>
                                <p>{value.duration_text}</p>
                              </div>
                              {/* --ashu-- */}
                              {/* {value.activity_text && (
                                  <div className="prd_crd_tags">
                                    <span>{value.activity_text}</span>
                                  </div>
                                )} */}
                              {value.likely_to_sell_out == "yes" ? (
                                <div className="likelytosell_prdcard ">
                                  <span>{t("_likely_to_sell_out")}</span>
                                </div>
                              ) : (
                                <div className="withouttag_likelysellout"></div>
                              )}

                              <div className="prd_btm_rating">
                                <div className="prd_star_rating">
                                  <ul>
                                    <li>
                                      <ReactStars
                                        style={{ cursor: "pointer !important" }}
                                        classNames="star-class"
                                        size={16}
                                        value={parseInt(value.ratings)}
                                        {...reactRatings}
                                      />
                                    </li>
                                  </ul>
                                  <p>
                                    ( {value.total_review} {t("_reviews")} )
                                  </p>
                                </div>
                                <div
                                  className="prd_main_price"
                                  dangerouslySetInnerHTML={{
                                    __html: value.price,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </NavLink>
                        </div>
                        <div className="product_card_inner_a"></div>
                        {/* )} */}
                      </div>
                    );
                  })}
                </div>

                {
                  listingData.length > 80 &&
                  <div className="show_more-product-listing">
                    <button
                      onClick={() => setCurrentPage((prev) => 1 + prev)}
                    >
                      {t("_show_more")}
                    </button>
                  </div>
                }

                {/* {pageCount > 1 && (
                  <div className="paginate_all">
                    <ReactPaginate
                      pageCount={pageCount}
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      containerClassName={"navigationButtons"}
                      previousLinkClassName={"previousButton"}
                      nextLinkClassName={"nextButton"}
                      disabledClassName={"navigationDisabled"}
                      activeClassName={"active"}
                      pageRange={2}
                      marginPagesDisplayed={2}
                      nextLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7.172"
                          height="14"
                          viewBox="0 0 7.172 14"
                        >
                          <path
                            id="fi-rr-angle-small-down"
                            d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                            transform="translate(7.172) rotate(90)"
                            fill="#000"
                          />
                        </svg>
                      }
                      previousLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7.172"
                          height="14"
                          viewBox="0 0 7.172 14"
                        >
                          <path
                            id="fi-rr-angle-small-down"
                            d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                            transform="translate(15.086 -5) rotate(90)"
                            fill="#232a35"
                          />
                        </svg>
                      }
                    />
                  </div>
                )} */}
              </div>
            ) : (
              <RecordNotFound />
            )}
          </div>
        )}
      </div>
      <LoginModal
        loginInfo={loginKey}
        SetLoginKey={SetLoginKey}
        LoginModal={ModalLogin}
        LogincloseModal={closeLoginModal}
      />

      {wishlistParameter && (
        <>
          <WishlistModel
            openWishlistModel={openWishlistModel}
            closeModel={closeWishlistModel}
            setOpenNewListModel={setOpenNewListModel}
            wishlistParameter={wishlistParameter}
            setWishLishedArray={setWishLishedArray}
            activityList={activityList}
            setIsWishlished={""}
            isWishlished={""}
          />
          <CreateNewListModel
            openNewListModel={openNewListModel}
            closeModel={closeNewListModel}
            wishlistParameter={wishlistParameter}
            setWishLishedArray={setWishLishedArray}
            setIsWishlished={""}
            isWishlished={""}
          />
        </>
      )}
    </>
  );
};

export default ProductList;
