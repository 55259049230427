import React, { useContext, useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import Header from "./Header";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { HomeContext } from "../../context";
import { NavLink } from "react-router-dom";
import RotatingCircle from "../Loading/RotatingCircle";

const HomeNewBanner = () => {
  const { t } = useTranslation();
  const data = useSelector((state) => state.recomededTourReducer);
  const { isCategoryLoading, searchListLoader, searchRef, searchList, setSearchValue } = useContext(HomeContext);
  const [bannerData, setBannerData] = useState({
    title: "",
    image: ""
  });

  useEffect(() => {
    if (data) {
      setBannerData({
        heading: data.recommended_tours.banner_heading,
        title: data.recommended_tours.banner_text,
        image: data.recommended_tours.image,
      })
    }
  }, [data]);

  return (
    <>
      <div className="main-banner-img">
        <div className="header__page_new">
          <Header />
          {/* <div className="search-list home-main-newlist">
            {searchListLoader === true
              ? (
                <ul className="list-group" ref={searchRef}>
                  <li className="list-group-item search-list-item">
                    <div className="rotating-circle">
                      <RotatingCircle />
                    </div>
                  </li>
                </ul>
              )
              :
              (
                searchList.length > 0 && (
                  <ul className="list-group" ref={searchRef}>
                    {searchList.map((option, index) => {
                      return (
                        <li
                          className="list-group-item search-list-item"
                          key={index}
                        >
                          <NavLink onClick={() => setSearchValue(option)}>
                            <div className="search-main-div">
                              <div className="search-img">
                                <img
                                  className={
                                    option.image == "" ? "change_img" : ""
                                  }
                                  src={
                                    option.image == ""
                                      ? images["location-icon-grey.png"]
                                      : option.image
                                  }
                                  style={{ height: "50px" }}
                                  alt=""
                                />
                              </div>
                              <div className="search-title">
                                {option.title}
                              </div>
                            </div>
                          </NavLink>
                        </li>
                      );
                    })}
                  </ul>
                )
              )}
          </div> */}
        </div>
        {
          isCategoryLoading == true ?
            <ShimmerThumbnail rounded /> :
            <div
              className="page-banner-bg"
              style={{
                backgroundImage: `url(${bannerData.image
                  ? bannerData.image
                  : images["top-cloud(2).webp"]
                  })`,
              }}
            >
              <div className="text_page_main">
                <div className="container">
                  <div className="page__heading">
                    <h2>
                      {bannerData.heading} <br />
                      {bannerData.title}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  );
};

export default HomeNewBanner;
