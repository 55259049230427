import * as React from "react";
import PropTypes from "prop-types";
// import SwipeableViews from 'react-swipeable-views';
import { useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";

import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";

const become_supplierdata = [
  {
    topic: "Register",
    inner_listdata: [
      {
        question: "Lorem ipsum",
        answer:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac metus vel lorem bibendum accumsan et utmi. Aliquam convallis libero risus, eget consectetur nisl blandit ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus aliquam.",
      },
      {
        question: "Lorem ipsum2",
        answer:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac metus vel lorem bibendum accumsan et utmi. Aliquam convallis libero risus, eget consectetur nisl blandit ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus aliquam.",
      },
    ],
  },
  {
    topic: "Login Issues",
    inner_listdata: [
      {
        question: "Lorem ipsum",
        answer:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac metus vel lorem bibendum accumsan et utmi. Aliquam convallis libero risus, eget consectetur nisl blandit ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus aliquam.",
      },
      {
        question: "Lorem ipsum2",
        answer:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac metus vel lorem bibendum accumsan et utmi. Aliquam convallis libero risus, eget consectetur nisl blandit ac. In tempus arcu vitae ipsum ullamcorper, id vehicula tellus aliquam.",
      },
    ],
  },
];

export default function SupplierSubCategory(props) {
  const { helptabsdata } = props;

  const [expandedsupp, setExpandedSupp] = React.useState(false);

  const handleExpandSupp = (panel) => (event, isExpanded) => {
    setExpandedSupp(isExpanded ? panel : false);
  };

  return (
    <>
      {helptabsdata != undefined && helptabsdata != "" ? (
        <div className="sub-categories help_category_topaccordian">
          {helptabsdata?.map((value, index) => {
            return (
              <Accordion className="sub-cat-item">
                <AccordionSummary
                  expandIcon={<KeyboardDoubleArrowDownOutlinedIcon />}
                  className="sub-cat-item-title"
                >
                  {value.title}
                </AccordionSummary>
                <AccordionDetails className="que-ans-sec">
                  {value.data != undefined &&
                    value.data != "" &&
                    value.data?.map((v, i) => {
                      return (
                        <div>
                          <Accordion
                            expanded={expandedsupp === `supp${i}${index}`}
                            onChange={handleExpandSupp(`supp${i}${index}`)}
                          >
                            <AccordionSummary
                              expandIcon={
                                expandedsupp === `supp${i}${index}` ? (
                                  <HorizontalRuleOutlinedIcon />
                                ) : (
                                  <KeyboardDoubleArrowRightOutlinedIcon />
                                )
                              }
                              className="question-title"
                            >
                              {v.question}
                            </AccordionSummary>
                            <AccordionDetails className="answer">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: v.answer,
                                }}
                              >
                                {/* {v.answer} */}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      ) : (
        <h4 className="record_noth6">No Data Found</h4>
      )}
    </>
  );
}
