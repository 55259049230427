import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt} from "@fortawesome/fontawesome-free-solid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncAddToWishlist, getWishlist } from "../../actions/wishlistAction";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import RecordNotFound from "../../pages/RecordNotFound";
import LoadingBar from "../Loading/LoadingBar";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";

const ActivityList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    // const params = useParams();
    const { activityId } = useParams();
    const languageReducer = useSelector((state) => state.languageReducer);
    const [wishListData, setWishListData] = useState([]);
    const checklogin = useSelector((state) => state.login);
    const [isWishlished, setIsWishlished] = useState(0);
    const [recordStatus, setRecordStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (languageReducer) {
            setIsLoading(true);
            dispatch(
                getWishlist(
                    setWishListData,
                    languageReducer,
                    navigate,
                    location,
                    setRecordStatus,
                    setIsLoading,
                    activityId
                )
            );
        }
    }, []);

    const addToWishlist = async (id) => {
        if (checklogin) {
            if (languageReducer) {
                dispatch(
                    asyncAddToWishlist(
                        id,
                        languageReducer,
                        setIsWishlished,
                        isWishlished,
                        navigate,
                        location
                    )
                );
            }
        }
    };

    const reactRatings = {
        edit: false,
        activeColor: "rgb(252 83 1)",
        isHalf: true,
        emptyIcon: <i className="fas fa-star" />,
        halfIcon: <i className="fa fa-star-half-alt" />,
        filledIcon: <i className="fa fa-star" />,
    };

    return (
        <>
            <div className="text_banner">
                <span>{t("_my_wishlist")}</span>
            </div>
            {isLoading === true ? (
                <div className="blinking-bar-loader">
                    <LoadingBar />
                </div>
            ) : wishListData.length > 0 ? (
                <div className="Wishlist_main_componemt">
                    <div className="wishlist_main_product">
                        {wishListData.map(function (value, index) {
                            return (
                                <div className="inner_list_main" key={index}>
                                    <div className="row">
                                        <div className="entry_cross_btn cursor-pointer">
                                            <span onClick={() => addToWishlist(value.slug)}>
                                                <svg
                                                    width="14"
                                                    height="14"
                                                    viewBox="0 0 14 14"
                                                    fill="red"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.27916 12.7208C1.45797 12.8996 1.70044 13 1.95327 13C2.2061 13 2.44857 12.8996 2.62737 12.7208L7 8.34821L11.3726 12.7208C11.5514 12.8996 11.7939 13 12.0467 13C12.2996 13 12.542 12.8996 12.7208 12.7208C12.8996 12.542 13 12.2996 13 12.0467C13 11.7939 12.8996 11.5514 12.7208 11.3726L8.34821 7L12.7208 2.62737C12.8996 2.44857 13 2.2061 13 1.95327C13 1.70044 12.8996 1.45797 12.7208 1.27916C12.542 1.10041 12.2996 1 12.0467 1C11.7939 1 11.5514 1.10041 11.3726 1.27916L7 5.65179L2.62737 1.27916C2.44857 1.10041 2.2061 0.999999 1.95327 0.999999C1.70044 0.999999 1.45797 1.10041 1.27916 1.27916C1.10042 1.45796 1 1.70044 1 1.95327C1 2.20609 1.10042 2.44857 1.27916 2.62737L5.65179 7L1.27916 11.3726C1.10041 11.5514 1 11.7939 1 12.0467C1 12.2996 1.10041 12.542 1.27916 12.7208Z"
                                                        fill="white"
                                                        stroke="white"
                                                        strokeWidth="0.5"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
                                            <NavLink
                                                to={`/culture-details/${value.slug}`}
                                                target="_blank"
                                            >
                                                <div
                                                    className="product_img"
                                                    style={{
                                                        backgroundImage: `url(${value.image})`,
                                                    }}
                                                >
                                                    {value.image_text && <span>{value.image_text}</span>}
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12">
                                            <div className="wish_lsit_info">
                                                <NavLink
                                                    to={`/culture-details/${value.slug}`}
                                                    target="_blank"
                                                >
                                                    <h2>
                                                        {/* Skip-the-Line Palace of Versailles and Gardens Tour */}
                                                        {value.title}
                                                    </h2>
                                                </NavLink>

                                                <div className="prd_crd_loaction">
                                                    <div className="prd_location_main">
                                                        {value.location && (
                                                            <span className="icon_loc">
                                                                {" "}
                                                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                                {value.location}
                                                            </span>
                                                        )}
                                                        <span className="trip_details">
                                                            {value.trip_details}
                                                        </span>
                                                    </div>

                                                    <p>{value.duration_text}</p>
                                                </div>

                                                <div className="prd_star_rating">
                                                    <ul>
                                                        <li>
                                                            <ReactStars
                                                                style={{
                                                                    cursor: "pointer !important",
                                                                }}
                                                                classNames="star-class"
                                                                size={16}
                                                                value={value.ratings}
                                                                {...reactRatings}
                                                            />
                                                        </li>
                                                    </ul>
                                                    <p>( {value.total_review} {t("_reviews")} )</p>
                                                    {value.likely_to_sell_out === "yes" && (
                                                        <div className="span_tag">
                                                            <span>{t("_likely_to_sell_out")}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="div_last_main">
                                                <div className="product_price">
                                                    {/* <div className="save_price">
                              <span>$550</span>
                            </div> */}

                                                    <div
                                                        className="price_main_tex"
                                                        dangerouslySetInnerHTML={{
                                                            __html: value.price,
                                                        }}
                                                    ></div>
                                                </div>

                                                {/* <div className="remove_btn">
                              <button onClick={() => addToWishlist(value.slug)}>
                                {" "}
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                  >
                                    <g
                                      id="fi-sr-heart 1"
                                      clipPath="url(#clip0_485_3487)"
                                    >
                                      <path
                                        id="Vector"
                                        d="M13.1247 1.43774C12.2802 1.45088 11.4541 1.68663 10.7298 2.1212C10.0055 2.55576 9.40874 3.17375 8.99972 3.91274C8.5907 3.17375 7.99392 2.55576 7.26965 2.1212C6.54538 1.68663 5.71926 1.45088 4.87472 1.43774C3.52843 1.49624 2.26 2.08518 1.34656 3.07592C0.433119 4.06665 -0.0510883 5.37864 -0.000275423 6.72524C-0.000275423 10.1355 3.58922 13.86 6.59972 16.3852C7.27189 16.9501 8.12174 17.2598 8.99972 17.2598C9.87771 17.2598 10.7276 16.9501 11.3997 16.3852C14.4102 13.86 17.9997 10.1355 17.9997 6.72524C18.0505 5.37864 17.5663 4.06665 16.6529 3.07592C15.7394 2.08518 14.471 1.49624 13.1247 1.43774Z"
                                        fill="#FF1E1E"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_485_3487">
                                        <rect width="18" height="18" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>{" "}
                                Remove from Wishlist
                              </button>
                            </div> */}
                                                <div className="book_now">
                                                    <NavLink
                                                        to={`/culture-details/${value.slug}`}
                                                        target="_blank"
                                                    >
                                                        {t("_book_now")}
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : (
                recordStatus && <RecordNotFound /> //To avoide showing of record not found for miliseconds when page load first time
            )}
        </>
    );
};

export default ActivityList;
