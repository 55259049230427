import classNames from 'classnames';
import { Modal } from 'react-bootstrap';

const ImageModal = ({ open, children, handleClose }) => {
    return (
        <div
            className={`fixed z-10 overflow-y-auto top-0 w-full left-0`}
            id="modal"
        >
            <Modal
                show={open}
            // onHide={handleClose}
            >

                <div className="new-modal">{children}</div>

            </Modal>
        </div>
    );
};

export default ImageModal;