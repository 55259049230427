import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { MetaData, getUrlParams } from "../../actions/customFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faMapMarkerAlt } from "@fortawesome/fontawesome-free-solid";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteToursData, getMyToursData } from "../../actions/myToursAction";
import RecordNotFound from "../../pages/RecordNotFound";
import ReactPaginate from "react-paginate";
import LoadingBar from "../Loading/LoadingBar";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import ReactStars from "react-rating-stars-component";
import { uplodProduct } from "../../actions/tourFilesAction";
import { useHistory } from "react-router-use-history";
import { uplodProductNew } from "../../actions/productBuilderAction";

const MyTours = () => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(false);
  const handleShowDate = () => setIsShow((wasOpened) => !wasOpened);

  const [myToursData, setMyToursData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const history = useHistory();
  let location = useLocation(); // keep it let as it is changing later
  const locationParams = getUrlParams(location.search);
  const queryParams = new URLSearchParams(location.search);
  const languageReducer = useSelector((state) => state.languageReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [recordStatus, setRecordStatus] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [filterData, setFilterData] = useState({
    is_approved: "",
    is_status: "",
    is_title: "",
    category_filter: [],
  });
  const [isFilter, setIsFilter] = useState(0);
  const [categoryFilterValue, setCategoryFilterValue] = useState([]);
  const [approvalArray, setApprovalArray] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  // const currentUrl = window.location.href;
  // const params = ["is_approved", "is_status", "category_filter", "currentPage", "isShow"];
  // const { apiQuery, getDefaultParamValue, handleSelectFilter } = useUrlFilter(params);

  // useEffect(() => {
  //   if (locationParams.category_filter && myToursData?.categories) {
  //     //for category
  //     const targetIdsString = locationParams.category_filter;
  //     const targetIds = targetIdsString.split(',').map(id => parseInt(id));
  //     const filteredArrayObject = myToursData.categories.filter(item => targetIds.includes(item.id));

  //     setFilterData({ ...locationParams, "category_filter": targetIds });
  //     setCategoryFilterValue(filteredArrayObject);
  //   }
  //   //for category

  //   if (locationParams.currentPage) {
  //     setCurrentPage(Number(locationParams.currentPage))
  //   } else {
  //     setCurrentPage(0);

  //   }
  //   setIsShow(true);
  // }, [myToursData, location.search]);

  const setParams = (key, value, isRemove = "") => {
    if (isRemove) {
      queryParams.delete(key);
    } else {
      queryParams.set(key, value);
    }
    history.push({ search: queryParams.toString() });
  };

  useEffect(() => {
    if (queryParams.size > 0) {
      queryParams.forEach((value, key) => {
        if (key == "category_filter_value") {
          setCategoryFilterValue(JSON.parse(value));
        } else if (key == "is_title") {
          // const value = e?.target?.value;
          // const value = value;
          if (value) {
            setFilterData((prev) => ({ ...prev, is_title: value }));
            setParams(key, value);
          } else {
            setFilterData((prev) => ({ ...prev, is_title: "" }));
            setParams(key, "", "remove");
          }
        } else if (key == "category_filter") {
          if (value) {
            let targetIdsString = value;
            let targetIds = targetIdsString
              .split(",")
              .map((id) => parseInt(id));
            setFilterData((filterData) => ({
              ...filterData,
              category_filter: targetIds,
            }));
          } else {
            setFilterData((filterData) => ({
              ...filterData,
              category_filter: [],
            }));
          }
        } else if (key == "currentPage") {
          setCurrentPage(Number(value));
        } else {
          setFilterData((filterData) => ({ ...filterData, [key]: value }));
        }
      });
      setIsFilter(1);
      setIsShow(true);
      setTimeout(() => {
        setToggle(!toggle);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (languageReducer !== "") {
      setIsLoading(true);
      dispatch(
        getMyToursData(
          languageReducer,
          setMyToursData,
          navigate,
          setIsLoading,
          setRecordStatus,
          setPageCount,
          currentPage,
          filterData,
          isFilter
        )
      );
    }
  }, [languageReducer, currentPage, toggle]);

  const deleteTour = (tourId) => {
    Swal.fire({
      title: t("_are_you_sure_to_delete"),
      type: "warning",
      showCancelButton: true,
      cancelButtonText: t("_cancel"),
      confirmButtonColor: "rgb(155 176 199)",
      cancelButtonColor: "rgb(227 46 46)",
      confirmButtonText: t("_yes_delete_it"),
    }).then((res) => {
      if (res.isConfirmed === true) {
        dispatch(deleteToursData(languageReducer, navigate, tourId, setToggle));
      }
    });
  };

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
    // handleSelectFilter("currentPage", selectedObject.selected);
    setParams("currentPage", selectedObject.selected);
  };

  const approvalOption = [
    { label: t("_approved"), value: "Approved" },
    { label: t("_not_approved"), value: "Not approved" },
  ];

  const statusOption = [
    { label: t("_active"), value: "Active" },
    { label: t("_Draft"), value: "Draft" },
    { label: t("_Deactive"), value: "Deactive" },
  ];

  const handleFilter = (e, item, key) => {
    if (key === "category_filter") {
      if (item?.length > 0) {
        let idArray = [];
        item.forEach((value) => {
          idArray.push(value.id);
        });
        setFilterData((prev) => ({ ...prev, category_filter: idArray }));
        setCategoryFilterValue(item);
        // handleSelectFilter(key, idArray)
        setParams(key, idArray);
        setParams("category_filter_value", JSON.stringify(item));
      } else {
        setFilterData((prev) => ({ ...prev, category_filter: [] }));
        setCategoryFilterValue(item);
        // handleSelectFilter(key, idArray)
        setParams(key, "", "remove");
        setParams("category_filter_value", "", "remove");
      }
    } else {
      if (item) {
        setFilterData((prev) => ({ ...prev, [key]: item.value }));
        // handleSelectFilter(key, item.value)
        setParams(key, item.value);
      } else {
        setFilterData((prev) => ({ ...prev, [key]: "" }));
        // handleSelectFilter(key, "")
        setParams(key, "", "remove");
      }
    }
  };
  // Filter Title
  const handleTitleChange = (value,key) => {
    if (value) {
      setFilterData((prev) => ({ ...prev, is_title: value }));
      setParams("is_title", value);
    } else {
      setFilterData((prev) => ({ ...prev, is_title: "" }));
      setParams("is_title", "", "remove");
    }
  };
  
  const resetFilter = () => {
    setFilterData({
      is_approved: "",
      is_status: "",
      is_title: "",
      category_filter: [],
    });
    setCategoryFilterValue([]);
    setIsFilter(0);
    setToggle(!toggle);
    history.push({
      pathname: window.location.pathname,
      search: "",
    });
  };

  useEffect(() => {
    if (
      filterData.is_approved !== "" ||
      filterData.is_status !== "" ||
      filterData.category_filter?.length > 0 ||
      filterData.is_title != ""
    ) {
      setIsFilter(1);
    } else {
      setIsFilter(0);
    }
  }, [filterData]);

  const reactRatings = {
    edit: false,
    activeColor: "rgb(252 83 1)",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  const sendForApproval = (tour_id) => {
    setIsLoading(true);
    let formData = {
      tourId: tour_id,
      step: "send_for_approval",
      language: languageReducer.id,
    };
    if (languageReducer !== "") {
      dispatch(
        uplodProductNew(
          languageReducer,
          "",
          navigate,
          formData,
          "",
          "",
          "",
          "",
          setApprovalArray
        )
      );
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  let metaData = {
    title: t("_my_tours"),
  };
  // Remove title field text 
  const handleonRemoveSearch = () => {
    setFilterData({
      is_title: "",
    });

    setParams("is_title", "", "remove");

  };
  return (
    <>
      {metaData && <MetaData data={metaData} />}
      {myToursData && (
        <div className="provider_my_tour">
          <div className="text_banner">
            <div className="text_banner">
              {/* <span>{t("_my_tours")}</span> */}
              <span>{t("_My_Products")}</span>
            </div>
            <div className="banner_button_main">
              <button
                className="add_icon_button"
                // onClick={() => {
                //   handleOnClick();
                // }}
                // onClick={() => navigate("/tours-files")}
                onClick={() =>
                  navigate("/product-bulider/product-language-title")
                }
              >
                {" "}
                <span>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g id="add">
                      <g id="Group">
                        <path
                          id="Vector"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4Z"
                          fill="white"
                        />
                        <path
                          id="Vector_2"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13 7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H11V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V13H17C17.2652 13 17.5196 12.8946 17.7071 12.7071C17.8946 12.5196 18 12.2652 18 12C18 11.7348 17.8946 11.4804 17.7071 11.2929C17.5196 11.1054 17.2652 11 17 11H13V7Z"
                          fill="white"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                {t("_add_new_tour")}{" "}
              </button>

              <button className="filter_btn" onClick={handleShowDate}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g id="filter">
                      <path
                        id="Vector"
                        d="M21 5.46258C20.9998 5.08433 20.853 4.72089 20.5904 4.44865C20.3278 4.17641 19.9699 4.01656 19.5919 4.0027V4H4.53993V4.00405C4.51383 4.0027 4.48795 4 4.46162 4C4.15915 4.0002 3.86417 4.09417 3.61732 4.26898C3.37047 4.44379 3.18389 4.69083 3.08328 4.97608C2.98267 5.26134 2.97297 5.57077 3.05553 5.86176C3.13808 6.15275 3.30883 6.41099 3.54425 6.60091L10.3261 13.3825L10.3259 17.5675V17.6022H10.3293C10.3349 17.7312 10.3733 17.8567 10.4407 17.9669C10.5081 18.0771 10.6024 18.1684 10.7147 18.2322L10.7127 18.2358L12.484 19.2585C12.4988 19.2689 12.5153 19.2763 12.5308 19.2855L12.5371 19.2891C12.65 19.3544 12.7796 19.3944 12.9196 19.3944C13.0205 19.3944 13.1205 19.3746 13.2138 19.336C13.307 19.2973 13.3918 19.2407 13.4632 19.1694C13.5346 19.098 13.5912 19.0132 13.6298 18.92C13.6684 18.8267 13.6883 18.7267 13.6882 18.6258C13.6882 18.6055 13.6837 18.5864 13.6824 18.5666H13.6882V13.3888L20.5324 6.54488L20.5257 6.53813C20.6751 6.40166 20.7945 6.23553 20.8762 6.05034C20.9578 5.86515 21 5.66497 21 5.46258ZM8.00534 6.92515H8.00849L8.00871 6.92853L8.00534 6.92515Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </div>
          </div>
          {isShow && (
            <div className="wishlist_filter">
              <h2 className="heading_filter_name">{t("_filter")}</h2>
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-3">
                <div className="customer-name filter_drop_down">
                  <p>{t("_title")}</p>
                  <input
                  className="title_input_filter"
                    type="text"
                    placeholder={t("_enter_title")}
                    value={filterData?.is_title}
                    onChange={(e) => {
                      handleTitleChange(e.target.value, "is_title");
                    }}
                  />
                  {filterData?.is_title && ( 
                      <button
                        type="button"
                       className="title_cross_btn"
                        onClick={() => {
                          handleonRemoveSearch(); 
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="red"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.27916 12.7208C1.45797 12.8996 1.70044 13 1.95327 13C2.2061 13 2.44857 12.8996 2.62737 12.7208L7 8.34821L11.3726 12.7208C11.5514 12.8996 11.7939 13 12.0467 13C12.2996 13 12.542 12.8996 12.7208 12.7208C12.8996 12.542 13 12.2996 13 12.0467C13 11.7939 12.8996 11.5514 12.7208 11.3726L8.34821 7L12.7208 2.62737C12.8996 2.44857 13 2.2061 13 1.95327C13 1.70044 12.8996 1.45797 12.7208 1.27916C12.542 1.10041 12.2996 1 12.0467 1C11.7939 1 11.5514 1.10041 11.3726 1.27916L7 5.65179L2.62737 1.27916C2.44857 1.10041 2.2061 0.999999 1.95327 0.999999C1.70044 0.999999 1.45797 1.10041 1.27916 1.27916C1.10042 1.45796 1 1.70044 1 1.95327C1 2.20609 1.10042 2.44857 1.27916 2.62737L5.65179 7L1.27916 11.3726C1.10041 11.5514 1 11.7939 1 12.0467C1 12.2996 1.10041 12.542 1.27916 12.7208Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="0.5"
                          />
                        </svg>
                      </button>
                    )}
                </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-3">
                  <div className="filter_drop_down">
                    <p>{t("_approval_status")}</p>
                    <Autocomplete
                      className="new_filter_select"
                      disablePortal
                      options={approvalOption}
                      value={filterData.is_approved}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("_select_approval_status")}
                        />
                      )}
                      onChange={(e, value) =>
                        handleFilter(e, value, "is_approved")
                      }
                    />
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-3">
                  <div className="filter_drop_down">
                    <p>{t("_status")}</p>
                    <Autocomplete
                      className="new_filter_select"
                      disablePortal
                      options={statusOption}
                      value={filterData.is_status}
                      renderInput={(params) => (
                        <TextField {...params} label={t("_select_status")} />
                      )}
                      onChange={(e, value) =>
                        handleFilter(e, value, "is_status")
                      }
                    />
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-3">
                  <div className="filter_drop_down">
                    <p>{t("_category")}</p>
                    <Autocomplete
                      multiple
                      // className="new_filter_select"
                      className={`${
                        categoryFilterValue?.length > 2
                          ? "new_filter_select my-tour-category-autocomplete"
                          : "new_filter_select "
                      }`}
                      disablePortal
                      value={categoryFilterValue}
                      options={myToursData.categories}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField {...params} label={t("_select_category")} />
                      )}
                      onChange={(e, value) =>
                        handleFilter(e, value, "category_filter")
                      }
                    />
                  </div>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-3">
                  <div className="btn_filter_main">
                    <button
                      className="btn_ filter_one"
                      onClick={() => setToggle(!toggle)}
                    >
                      {" "}
                      <span>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <g id="filter">
                            <path
                              id="Vector"
                              d="M10.125 15.75H7.875C7.57663 15.75 7.29048 15.6315 7.0795 15.4205C6.86853 15.2095 6.75 14.9234 6.75 14.625V10.3556L2.58188 6.1875C2.37061 5.97749 2.25126 5.69226 2.25 5.39438V3.375C2.25 3.07663 2.36853 2.79048 2.5795 2.5795C2.79048 2.36853 3.07663 2.25 3.375 2.25H14.625C14.9234 2.25 15.2095 2.36853 15.4205 2.5795C15.6315 2.79048 15.75 3.07663 15.75 3.375V5.39438C15.7487 5.69226 15.6294 5.97749 15.4181 6.1875L11.25 10.3556V14.625C11.25 14.9234 11.1315 15.2095 10.9205 15.4205C10.7095 15.6315 10.4234 15.75 10.125 15.75ZM3.375 3.375V5.39438L7.875 9.89437V14.625H10.125V9.89437L14.625 5.39438V3.375H3.375Z"
                              fill="white"
                            />
                          </g>
                        </svg>
                      </span>{" "}
                      {t("_filter")}{" "}
                    </button>

                    <button
                      className="btn_erase_one"
                      onClick={() => resetFilter()}
                    >
                      {" "}
                      <span>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <g id="erase">
                            <path
                              id="Vector"
                              d="M3.9375 15.1875H16.875V16.3125H3.9375V15.1875Z"
                              fill="#FF1E1E"
                            />
                            <path
                              id="Vector_2"
                              d="M15.4015 5.9119L10.9409 1.4569C10.8364 1.3523 10.7123 1.26932 10.5757 1.21271C10.4392 1.15609 10.2928 1.12695 10.1449 1.12695C9.99709 1.12695 9.85069 1.15609 9.71412 1.21271C9.57755 1.26932 9.45347 1.3523 9.34899 1.4569L1.47399 9.3319C1.36939 9.43638 1.28641 9.56046 1.2298 9.69703C1.17318 9.8336 1.14404 9.97999 1.14404 10.1278C1.14404 10.2757 1.17318 10.4221 1.2298 10.5586C1.28641 10.6952 1.36939 10.8193 1.47399 10.9238L4.01087 13.5H9.40524L15.4015 7.50378C15.5061 7.39929 15.5891 7.27522 15.6457 7.13865C15.7023 7.00207 15.7314 6.85568 15.7314 6.70784C15.7314 6.56 15.7023 6.4136 15.6457 6.27703C15.5891 6.14046 15.5061 6.01638 15.4015 5.9119ZM8.93837 12.375H4.50024L2.25024 10.125L5.79962 6.57565L10.2602 11.0307L8.93837 12.375ZM11.0534 10.26L6.59837 5.7994L10.1252 2.25003L14.6252 6.71065L11.0534 10.26Z"
                              fill="#FF1E1E"
                            />
                          </g>
                        </svg>
                      </span>{" "}
                      {t("_erase")}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="provider_all_product">
            {isLoading === true ? (
              <div className="blinking-bar-loader">
                <LoadingBar />
              </div>
            ) : (
              <>
                <div className="wishlist_main_product Wishlist_main_componemt">
                  {myToursData.products?.length > 0
                    ? myToursData.products.map(function (item, index) {
                        return (
                          <div className="inner_list_main" key={index}>
                            <div className="row">
                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                {item.reason && (
                                  <div className="alert alert-default background-danger incomplete_danger_msg mb-0">
                                    <span> {t("_Not_Approved_Reason")}: </span>
                                    <span className="font-weight-bold ">
                                      {" "}
                                      {item.reason}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                {item.message && (
                                  <div className="incomplete_msg">
                                    <span>{item.message}</span>
                                  </div>
                                )}
                              </div>
                              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                                <NavLink
                                  to={`/culture-details/${item.slug}`}
                                  target="_blank"
                                >
                                  <div
                                    className="product_img"
                                    style={{
                                      backgroundImage: `url(${item.image})`,
                                    }}
                                  >
                                    {item.image_text && (
                                      <span>{item.image_text}</span>
                                    )}
                                  </div>
                                </NavLink>
                              </div>
                              <div className="col-xl-6 col-lg-8 col-md-6 col-sm-6 col-12">
                                <div className="wish_lsit_info">
                                  <NavLink
                                    to={`/culture-details/${item.slug}`}
                                    target="_blank"
                                  >
                                    <h2>
                                      {item.title.charAt(0).toUpperCase() +
                                        item.title.slice(1)}
                                      <span className="mytour_title_span">
                                        {" "}
                                        ({`ID: ${item.unique_id}`})
                                      </span>
                                    </h2>
                                  </NavLink>
                                  <div className="prd_crd_loaction">
                                    <div className="prd_location_main">
                                      {item.location && (
                                        <span className="icon_loc">
                                          {" "}
                                          <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                          />
                                          {item.location}
                                        </span>
                                      )}
                                      <span className="trip_details">
                                        {item.trip_details}
                                      </span>
                                      <span className="active_tag">
                                        <span>{item.status}</span>
                                      </span>
                                    </div>

                                    <p>{item.duration_text}</p>
                                  </div>

                                  <div className="prd_star_rating">
                                    <ul>
                                      <li>
                                        <ReactStars
                                          style={{
                                            cursor: "pointer !important",
                                          }}
                                          classNames="star-class"
                                          size={16}
                                          value={Number(item.ratings)}
                                          {...reactRatings}
                                        />
                                      </li>
                                    </ul>
                                    <p>
                                      ({item.total_review} {t("_reviews")} )
                                    </p>
                                    {item.likely_to_sell_out == "yes" && (
                                      <div className="span_tag_new">
                                        <span>{t("_likely_to_sell_out")}</span>
                                      </div>
                                    )}
                                    {/* <div className="span_tag_active"> */}
                                    <div
                                      className={
                                        item.is_approved === "Approved"
                                          ? "span_tag_active_new"
                                          : "span_tag_not_approved_new"
                                      }
                                    >
                                      <span className="active_span">
                                        {item.is_approved}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="div_last_main">
                                  <div className="product_price">
                                    <div
                                      className="price_main_tex"
                                      dangerouslySetInnerHTML={{
                                        __html: item.price,
                                      }}
                                    ></div>
                                    <h6 className="starting-from">
                                      {t("_Starting_From")}
                                    </h6>
                                  </div>

                                  <div className="btn_list">
                                    <button
                                      className="btn_edit"
                                      onClick={() =>
                                        navigate(
                                          // `/tours-files/title/${item.id}`
                                          `/product-bulider/product-language-title/${item.id}`
                                        )
                                      }
                                    >
                                      {" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <g id="note-edit-line">
                                          <path
                                            id="Vector"
                                            d="M14 15H3V4H9.61L10.61 3H3C2.73478 3 2.48043 3.10536 2.29289 3.29289C2.10536 3.48043 2 3.73478 2 4V15C2 15.2652 2.10536 15.5196 2.29289 15.7071C2.48043 15.8946 2.73478 16 3 16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15V7.5L14 8.5V15Z"
                                            fill="#232A35"
                                          />
                                          <path
                                            id="Vector_2"
                                            d="M16.7652 2.92009L15.0802 1.23509C15.0054 1.16011 14.9166 1.10062 14.8188 1.06002C14.721 1.01943 14.6161 0.998535 14.5102 0.998535C14.4043 0.998535 14.2995 1.01943 14.2017 1.06002C14.1038 1.10062 14.015 1.16011 13.9402 1.23509L7.08522 8.13009L6.53022 10.5351C6.50658 10.6517 6.50906 10.772 6.5375 10.8875C6.56594 11.0031 6.61962 11.1108 6.69469 11.2031C6.76975 11.2954 6.86433 11.3699 6.97163 11.4212C7.07893 11.4726 7.19627 11.4995 7.31522 11.5001C7.3767 11.5069 7.43874 11.5069 7.50022 11.5001L9.92522 10.9651L16.7652 4.06009C16.8402 3.98531 16.8997 3.89647 16.9403 3.79866C16.9809 3.70085 17.0018 3.59599 17.0018 3.49009C17.0018 3.38419 16.9809 3.27934 16.9403 3.18152C16.8997 3.08371 16.8402 2.99487 16.7652 2.92009ZM9.40522 10.0401L7.57522 10.4451L8.00022 8.63009L13.1602 3.43509L14.5702 4.84509L9.40522 10.0401ZM15.1352 4.28009L13.7252 2.87009L14.5002 2.08009L15.9202 3.50009L15.1352 4.28009Z"
                                            fill="#232A35"
                                          />
                                        </g>
                                      </svg>
                                      <span>{t("_edit")}</span>
                                    </button>

                                    <button
                                      className="btn_delete"
                                      onClick={() => deleteTour(item.id)}
                                    >
                                      {" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <g id="delete">
                                          <path
                                            id="Vector"
                                            d="M6.75 6.75H7.875V13.5H6.75V6.75Z"
                                            fill="#FF1E1E"
                                          />
                                          <path
                                            id="Vector_2"
                                            d="M10.125 6.75H11.25V13.5H10.125V6.75Z"
                                            fill="#FF1E1E"
                                          />
                                          <path
                                            id="Vector_3"
                                            d="M2.25 3.375V4.5H3.375V15.75C3.375 16.0484 3.49353 16.3345 3.7045 16.5455C3.91548 16.7565 4.20163 16.875 4.5 16.875H13.5C13.7984 16.875 14.0845 16.7565 14.2955 16.5455C14.5065 16.3345 14.625 16.0484 14.625 15.75V4.5H15.75V3.375H2.25ZM4.5 15.75V4.5H13.5V15.75H4.5Z"
                                            fill="#FF1E1E"
                                          />
                                          <path
                                            id="Vector_4"
                                            d="M6.75 1.125H11.25V2.25H6.75V1.125Z"
                                            fill="#FF1E1E"
                                          />
                                        </g>
                                      </svg>
                                      <span>{t("_delete")}</span>
                                    </button>
                                  </div>
                                  {item.is_approved == "Approved" &&
                                    item.status === "Active" && (
                                      <div className="btn_list view_product_btn_list">
                                        <NavLink
                                          target="_blank"
                                          className="btn_edit view_product"
                                          to={`/culture-details/${item.slug}`}
                                        >
                                          {" "}
                                          <FontAwesomeIcon icon={faEye} />
                                          <span> {t("_view_product")}</span>
                                        </NavLink>
                                      </div>
                                    )}

                                  <div className="btn_list view_product_btn_list">
                                    <NavLink
                                      target="_blank"
                                      className="btn_edit view_product"
                                      to={`/product-preview/${item.slug}`}
                                    >
                                      {" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="16"
                                        width="16"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                                      </svg>
                                      <span>{t("_Preview")}</span>
                                    </NavLink>
                                  </div>
                                  {item.for_approvel === "0" &&
                                    !approvalArray.includes(item.id) &&
                                    item.is_approved == "Not approved" && (
                                      <div className="btn_list view_product_btn_list">
                                        <button
                                          className="btn_edit view_product send-for-approval"
                                          onClick={() =>
                                            sendForApproval(item.id)
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-send"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                          </svg>
                                          <span>{t("_Send_For_Approval")}</span>
                                        </button>
                                      </div>
                                    )}

                                  {(item.for_approvel === "1" ||
                                    approvalArray.includes(item.id)) &&
                                    item.is_approved == "Not approved" && (
                                      <div className="btn_list view_product_btn_list">
                                        <button
                                          className="btn_edit view_product waiting-for-approval"
                                          style={{ cursor: "default" }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-clock"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                          </svg>
                                          {t("_Waiting_For_Approval")}
                                        </button>
                                      </div>
                                    )}
                                  {/* 
                            <div className="button_duplicate">
                              <button>
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                  >
                                    <g id="bx-duplicate">
                                      <path
                                        id="Vector"
                                        d="M8.25 7.5H6.75V9.75H4.5V11.25H6.75V13.5H8.25V11.25H10.5V9.75H8.25V7.5Z"
                                        fill="white"
                                      />
                                      <path
                                        id="Vector_2"
                                        d="M3 16.5H12C12.8273 16.5 13.5 15.8273 13.5 15V6C13.5 5.17275 12.8273 4.5 12 4.5H3C2.17275 4.5 1.5 5.17275 1.5 6V15C1.5 15.8273 2.17275 16.5 3 16.5ZM3 6H12L12.0015 15H3V6Z"
                                        fill="white"
                                      />
                                      <path
                                        id="Vector_3"
                                        d="M15 1.5H6V3H15V12H16.5V3C16.5 2.17275 15.8273 1.5 15 1.5Z"
                                        fill="white"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                Duplicate
                              </button>
                            </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : recordStatus && ( //To avoide showing of record not found for miliseconds when page load first time
                        <RecordNotFound />
                      )}
                </div>
                {pageCount > 1 && myToursData.products?.length > 0 && (
                  <div className="paginate_all">
                    <ReactPaginate
                      pageCount={pageCount}
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      containerClassName={"navigationButtons"}
                      previousLinkClassName={"previousButton"}
                      nextLinkClassName={"nextButton"}
                      disabledClassName={"navigationDisabled"}
                      activeClassName={"active"}
                      pageRange={2}
                      marginPagesDisplayed={2}
                      nextLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7.172"
                          height="14"
                          viewBox="0 0 7.172 14"
                        >
                          <path
                            id="fi-rr-angle-small-down"
                            d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                            transform="translate(7.172) rotate(90)"
                            fill="#000"
                          />
                        </svg>
                      }
                      previousLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="7.172"
                          height="14"
                          viewBox="0 0 7.172 14"
                        >
                          <path
                            id="fi-rr-angle-small-down"
                            d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                            transform="translate(15.086 -5) rotate(90)"
                            fill="#232a35"
                          />
                        </svg>
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MyTours;
