import { useEffect, useState } from "react";
import { MetaData, images } from "../actions/customFn";
import {
//   Choose,
  Footer,
  HeaderSticky,
  CategoryTabs,
//   Adventure,
//   Service,
//   OurMap,
//   Testimonial,
//   OurStory,
    BannerHelpCenter,
//   OurTeam,
  LoadingBar,
} from "../components";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
// import { getAboutUsData } from "../actions/aboutUsAction";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";



const HelpCenter = () => {

//  Banner All Data Array
const dispatch = useDispatch();
const navigate = useNavigate();
const languageReducer = useSelector((state) => state.languageReducer);
const [isLoading, setIsLoading] = useState(false);


// useEffect(() => {
//   if (languageReducer !== "") {
//     dispatch(
//       getAboutUsData(
//         languageReducer,
//         navigate,
//         setIsLoading,
//       )
//     );
//   }
// }, [languageReducer]);
  return (
    <>
        {/* <LoadingOverlay active={isLoading === false} spinner={<LoadingBar />}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
          > */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
          >  
            <HeaderSticky />
              <BannerHelpCenter
                bannerData={{
                  title: "How Can I",
                  image: images["help-banner.png"],
                  descrition: "Help You?"
                }}
              />
             
            < CategoryTabs/>
            <Footer />

            </motion.div>
          {/* </motion.div>
        </LoadingOverlay> */}
  
    </>
  );
};
export default HelpCenter;
 