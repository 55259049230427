import { useEffect, useState } from "react";
import { MetaData } from "../../actions/customFn";
import { useNavigate } from "react-router-dom";

import { ArcElement } from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { getAnalyticsData, getPartnerProductList } from "../../actions/providerAction";
import LoadingBar from "../Loading/LoadingBar";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
ChartJS.register(ArcElement, Tooltip, Legend);
const Analytics = () => {
    const { t } = useTranslation();
    let metaData = { title: "Analytics" };
    const languageReducer = useSelector((state) => state.languageReducer);
    const profileReducer = useSelector((state) => state.profileReducer);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [analyticsData, setAnalyticsData] = useState();
    const [yearList, setYearList] = useState([])
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const [productList, setProductList] = useState([]);
    const [formData, setFormData] = useState({
        year: currentYear,
        product_id: "",
    });

    useEffect(() => {
        const years = Array.from({ length: 50 }, (_, index) => currentYear - index);
        setYearList(years);
    }, []);

    useEffect(() => {
        if (languageReducer && profileReducer && profileReducer.user_type == "Partner") {
            let newEntry = { "id": 0, "title": t("_All_products") };
            dispatch(getPartnerProductList(languageReducer, navigate, setProductList, newEntry));
        }
    }, [languageReducer, profileReducer]);

    useEffect(() => {
        if (languageReducer !== "") {
            setIsLoading(true);
            dispatch(
                getAnalyticsData(
                    languageReducer,
                    setAnalyticsData,
                    navigate,
                    setIsLoading,
                    formData
                )
            );
        };
    }, [languageReducer, toggle, formData]);

    const labels = [
        t("_january"),
        t("_february"),
        t("_march"),
        t("_april"),
        t("_may"),
        t("_june"),
        t("_july"),
        t("_august"),
        t("_september"),
        t("_october"),
        t("_november"),
        t("_december")];
    const data = {
        labels,
        datasets: analyticsData?.dataset,
        // datasets: [
        //     {
        //         label: '2022',
        //         data: [234, 578, 892, 325, 740, 200, 500],
        //         backgroundColor: 'rgba(255, 99, 132, 0.5)',
        //     },
        //     {
        //         label: '2023',
        //         data: [125, 876, 452, 689, 298, 100, 150],
        //         backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //     },
        //     {
        //         label: '2024',
        //         data: [100, 476, 252, 489, 450, 120, 300],
        //         backgroundColor: 'rgba(230, 50, 235, 0.5)',
        //     },
        // ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },

        },
    };

    const resetFilter = () => {
        setFormData((prev) => ({
            year: currentYear,
            product: "",
        }))
        // setToggle(!toggle);
    };


    const handleChange = (key, value) => {
        if (key == 'year') {
            setFormData((prev) => ({ ...prev, [key]: value ? value : "" }))
        }
        if (key == 'product_id') {
            setFormData((prev) => ({ ...prev, [key]: value ? value.id : "" }))
        }
    };

    return (
        <>
            {
                metaData &&
                <MetaData data={metaData} />
            }

            <div className="dashboard_section">
                <div className="text_banner">
                    <span>{t("_Analytics")}</span>{" "}
                    <div className="banner_button_main">
                        <button className="filter_btn"
                            onClick={() => setIsShow(!isShow)}
                        >
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <g id="filter">
                                        <path
                                            id="Vector"
                                            d="M21 5.46258C20.9998 5.08433 20.853 4.72089 20.5904 4.44865C20.3278 4.17641 19.9699 4.01656 19.5919 4.0027V4H4.53993V4.00405C4.51383 4.0027 4.48795 4 4.46162 4C4.15915 4.0002 3.86417 4.09417 3.61732 4.26898C3.37047 4.44379 3.18389 4.69083 3.08328 4.97608C2.98267 5.26134 2.97297 5.57077 3.05553 5.86176C3.13808 6.15275 3.30883 6.41099 3.54425 6.60091L10.3261 13.3825L10.3259 17.5675V17.6022H10.3293C10.3349 17.7312 10.3733 17.8567 10.4407 17.9669C10.5081 18.0771 10.6024 18.1684 10.7147 18.2322L10.7127 18.2358L12.484 19.2585C12.4988 19.2689 12.5153 19.2763 12.5308 19.2855L12.5371 19.2891C12.65 19.3544 12.7796 19.3944 12.9196 19.3944C13.0205 19.3944 13.1205 19.3746 13.2138 19.336C13.307 19.2973 13.3918 19.2407 13.4632 19.1694C13.5346 19.098 13.5912 19.0132 13.6298 18.92C13.6684 18.8267 13.6883 18.7267 13.6882 18.6258C13.6882 18.6055 13.6837 18.5864 13.6824 18.5666H13.6882V13.3888L20.5324 6.54488L20.5257 6.53813C20.6751 6.40166 20.7945 6.23553 20.8762 6.05034C20.9578 5.86515 21 5.66497 21 5.46258ZM8.00534 6.92515H8.00849L8.00871 6.92853L8.00534 6.92515Z"
                                            fill="white"
                                        />
                                    </g>
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="Wishlist_main_componemt Upcoming_booking_main">
                    {
                        isShow &&
                        <div className="wishlist_filter filter_drop_down ">
                            <div className="row ">
                                <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12 mt-3">
                                    <div className="filter_drop_down input_location">
                                        <p>{t("_Select_Year")}</p>
                                        <Autocomplete
                                            name="product"
                                            className="new_filter_select"
                                            disablePortal
                                            options={yearList}
                                            value={formData.year}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("_Select_Year")}
                                                />
                                            )}
                                            onChange={(e, item) => handleChange("year", item)}
                                        />
                                    </div>
                                </div>
                                {
                                    profileReducer && profileReducer.user_type == "Partner" &&
                                    <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12 mt-3">
                                        <div className="filter_drop_down input_location">
                                            <p>{t("_select_product")}</p>
                                            <Autocomplete
                                                name="product"
                                                className="new_filter_select"
                                                disablePortal
                                                options={productList}
                                                getOptionLabel={(option) => option.title}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t("_select_product")}
                                                    />
                                                )}
                                                onChange={(e, item) => handleChange("product_id", item)}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-2">
                                    <div className="row">
                                        <div className="btn_search">
                                            <button onClick={() => setToggle(!toggle)}>{t("_search")}</button>{" "}
                                        </div>
                                        <button
                                            className="btn_erase_one"
                                            onClick={() => resetFilter()}
                                        >
                                            {" "}
                                            <span>
                                                {" "}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                >
                                                    <g id="erase">
                                                        <path
                                                            id="Vector"
                                                            d="M3.9375 15.1875H16.875V16.3125H3.9375V15.1875Z"
                                                            fill="#FF1E1E"
                                                        />
                                                        <path
                                                            id="Vector_2"
                                                            d="M15.4015 5.9119L10.9409 1.4569C10.8364 1.3523 10.7123 1.26932 10.5757 1.21271C10.4392 1.15609 10.2928 1.12695 10.1449 1.12695C9.99709 1.12695 9.85069 1.15609 9.71412 1.21271C9.57755 1.26932 9.45347 1.3523 9.34899 1.4569L1.47399 9.3319C1.36939 9.43638 1.28641 9.56046 1.2298 9.69703C1.17318 9.8336 1.14404 9.97999 1.14404 10.1278C1.14404 10.2757 1.17318 10.4221 1.2298 10.5586C1.28641 10.6952 1.36939 10.8193 1.47399 10.9238L4.01087 13.5H9.40524L15.4015 7.50378C15.5061 7.39929 15.5891 7.27522 15.6457 7.13865C15.7023 7.00207 15.7314 6.85568 15.7314 6.70784C15.7314 6.56 15.7023 6.4136 15.6457 6.27703C15.5891 6.14046 15.5061 6.01638 15.4015 5.9119ZM8.93837 12.375H4.50024L2.25024 10.125L5.79962 6.57565L10.2602 11.0307L8.93837 12.375ZM11.0534 10.26L6.59837 5.7994L10.1252 2.25003L14.6252 6.71065L11.0534 10.26Z"
                                                            fill="#FF1E1E"
                                                        />
                                                    </g>
                                                </svg>
                                            </span>{" "}
                                            {t("_erase")}{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <>
                    {
                        isLoading === true ? (
                            <div className="blinking-bar-loader-order-detail analytics-loader">
                                <LoadingBar />
                            </div>
                        ) : (
                            <div className="dashbord_graph">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="linechart_upper_main">
                                            <div className="totle_booking_main">
                                                <div className="totle_booking_text">
                                                    {/* <h4>Analytics</h4> */}
                                                </div>
                                            </div>

                                            <div className="line_chart_main dashboard">
                                                <Bar options={options} data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </>
                {/* )} */}
            </div>

        </>
    );
};
export default Analytics;
