import { useState } from "react";
import "flatpickr/dist/flatpickr.css";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import LoadingBar from "../Loading/LoadingBar";
import RecordNotFound from "../../pages/RecordNotFound";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { useTranslation } from "react-i18next";
import { MetaData, errorMsg, handleScroll } from "../../actions/customFn";
import { getCouponList, updateCouponList } from "../../actions/affiliateAction";
import moment from "moment";
const CouponList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const languageReducer = useSelector((state) => state.languageReducer);
    const [coupanList, setCoupanList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [recordStatus, setRecordStatus] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [couponCode, setCouponCode] = useState("");
    const [couponError, setCouponError] = useState("");
    const [openId, setOpenId] = useState("");
    const [isUpdate, setIsUpdate] = useState(true);

    useEffect(() => {
        if (languageReducer !== "") {
            setIsLoading(true);
            dispatch(
                getCouponList(
                    languageReducer,
                    setCoupanList,
                    navigate,
                    setIsLoading,
                    setRecordStatus,
                    setPageCount,
                    currentPage,
                )
            );
        }
    }, [languageReducer, currentPage, isUpdate]);

    const handlePageChange = (selectedObject) => {
        setCurrentPage(selectedObject.selected);
        handleScroll("scroll", 120)

    };

    const handelEditCoupon = (value) => {
        setOpenId(value.id);
        setCouponCode(value.code);
        setCouponError("");
    }

    const updateCoupon = (value) => {
        if (couponCode == "") {
            errorMsg("Coupon code required")
            setCouponError("Enter Coupon code")
        } else {
            let data = {
                coupon_id: value.id,
                coupon_code: couponCode
            }
            dispatch(updateCouponList(data, languageReducer, navigate, setIsUpdate, setOpenId));
        }
    }

    let metaData = {
        title: t("_Coupon_List")
    }

    return (
        <div className="userbooking-history">
            {
                metaData &&
                <MetaData data={metaData} />
            }
            <div className="provider-booking-main">
                <div className="provider_upcomimg_main">
                    <div className="provider_my_tour">
                        <div className="text_banner">
                            <div className="text_banner">
                                <span>{t("_Coupon_List")}</span>
                            </div>
                        </div>
                        <div className="table-responsive" id="scroll">
                            {isLoading === true ? (
                                <div className="blinking-bar-loader">
                                    <LoadingBar />
                                </div>
                            ) : coupanList.length > 0 ? (
                                <>
                                    {coupanList.map(function (value, index) {
                                        return (
                                            <div className="order_list_product coupon-list" key={index}>
                                                <div className="order_list_inner table-responsive">
                                                    <div className="coupon-title-main ">
                                                        <span className="coupon-code-heading"> {t("_Coupon_Code")} {" "}:</span>
                                                        {
                                                            openId !== value.id ?
                                                                <>
                                                                    <h2 className="coupon-code-title">
                                                                        {value.code.toUpperCase()}
                                                                    </h2>
                                                                    <button
                                                                        className="btn_edit"
                                                                        onClick={() => handelEditCoupon(value)}
                                                                    >
                                                                        {" "}
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            widiv="18"
                                                                            height="18"
                                                                            viewBox="0 0 18 18"
                                                                            fill="none"
                                                                        >
                                                                            <g id="note-edit-line">
                                                                                <path
                                                                                    id="Vector"
                                                                                    d="M14 15H3V4H9.61L10.61 3H3C2.73478 3 2.48043 3.10536 2.29289 3.29289C2.10536 3.48043 2 3.73478 2 4V15C2 15.2652 2.10536 15.5196 2.29289 15.7071C2.48043 15.8946 2.73478 16 3 16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15V7.5L14 8.5V15Z"
                                                                                    fill="#232A35"
                                                                                />
                                                                                <path
                                                                                    id="Vector_2"
                                                                                    d="M16.7652 2.92009L15.0802 1.23509C15.0054 1.16011 14.9166 1.10062 14.8188 1.06002C14.721 1.01943 14.6161 0.998535 14.5102 0.998535C14.4043 0.998535 14.2995 1.01943 14.2017 1.06002C14.1038 1.10062 14.015 1.16011 13.9402 1.23509L7.08522 8.13009L6.53022 10.5351C6.50658 10.6517 6.50906 10.772 6.5375 10.8875C6.56594 11.0031 6.61962 11.1108 6.69469 11.2031C6.76975 11.2954 6.86433 11.3699 6.97163 11.4212C7.07893 11.4726 7.19627 11.4995 7.31522 11.5001C7.3767 11.5069 7.43874 11.5069 7.50022 11.5001L9.92522 10.9651L16.7652 4.06009C16.8402 3.98531 16.8997 3.89647 16.9403 3.79866C16.9809 3.70085 17.0018 3.59599 17.0018 3.49009C17.0018 3.38419 16.9809 3.27934 16.9403 3.18152C16.8997 3.08371 16.8402 2.99487 16.7652 2.92009ZM9.40522 10.0401L7.57522 10.4451L8.00022 8.63009L13.1602 3.43509L14.5702 4.84509L9.40522 10.0401ZM15.1352 4.28009L13.7252 2.87009L14.5002 2.08009L15.9202 3.50009L15.1352 4.28009Z"
                                                                                    fill="#232A35"
                                                                                />
                                                                            </g>
                                                                        </svg>
                                                                        <span>{t("_edit")}</span>
                                                                    </button>
                                                                </>
                                                                :
                                                                <>
                                                                    <input
                                                                        type="text"
                                                                        id="couponCode"
                                                                        name="couponCode"
                                                                        value={couponCode}
                                                                        placeholder={t("_enter_coupon_code")}
                                                                        className={
                                                                            `form-control mt-2 mb-2  ` +
                                                                            (couponError ? " is-invalid" : "")
                                                                        }
                                                                        onChange={(e) => setCouponCode(e.target.value)}
                                                                    />
                                                                    <button
                                                                        className="btn_edit"
                                                                        onClick={() => updateCoupon(value)}
                                                                    >
                                                                        {" "}
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            height="16"
                                                                            width="14"
                                                                            viewBox="0 0 448 512"
                                                                        >
                                                                            <path
                                                                                d="M246.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 109.3V320c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 53 43 96 96 96H352c53 0 96-43 96-96V352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V352z" />
                                                                        </svg>
                                                                        <span>{t("_Update")}</span>
                                                                    </button>
                                                                    <button
                                                                        className="btn_edit"
                                                                        onClick={() => setOpenId("")}
                                                                    >
                                                                        {" "}
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            height="16"
                                                                            width="12"
                                                                            viewBox="0 0 384 512">
                                                                            <path
                                                                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                                                        </svg>
                                                                        <span>{t("_cancel")}</span>
                                                                    </button>
                                                                </>
                                                        }

                                                    </div>
                                                    <table className="main_table">
                                                        {/* <thead> */}

                                                        {/* </thead> */}
                                                        <tbody>
                                                            <tr className="coupon-list-start-date">
                                                                <td >
                                                                    <span>{t("_Start_Date")} <br />
                                                                        {/* {value.start_date} */}
                                                                        {moment(value.start_date).format('DD-MMM-YYYY')}
                                                                    </span>
                                                                </td>
                                                                <td>

                                                                    <span>{t("_End_Date")} <br />
                                                                        {moment(value.end_date).format('DD-MMM-YYYY')}
                                                                    </span>
                                                                </td>

                                                                <td>
                                                                    <span>{t("_Coupon_Amount")}{" "} <br />
                                                                        {value.coupon_amount}
                                                                        {
                                                                            value.coupon_amount_type !== "fixed" ? "%" : ""
                                                                        }

                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span>{t("_Minimum_Amount")} <br /> {value.minimum_amount}</span>
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        className={
                                                                            value.status === "Pending"
                                                                                ? "status_pending"
                                                                                : value.status === "Failed"
                                                                                    ? "status_failed"
                                                                                    : value.status === "Cancelled"
                                                                                        ? "status_cancelled"
                                                                                        : value.status === "Active"
                                                                                            ? "status_success"
                                                                                            : ""
                                                                        }
                                                                    >
                                                                        {value.status}
                                                                    </span>
                                                                </td>
                                                                {/* <td>
                                                                    <span className="show_amount">
                                                                        {value.amount}
                                                                    </span>
                                                                </td> */}
                                                                {/* <td>
                                                                    <NavLink to={`/order-detail/${value.id}`}>
                                                                        {" "}
                                                                        <span className="bg_color_span">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="8"
                                                                                height="14"
                                                                                viewBox="0 0 8 14"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    id="Vector"
                                                                                    d="M0.28142 12.3023C0.0989415 12.4912 -0.00203005 12.7443 0.000252377 13.0069C0.0025348 13.2696 0.107888 13.5209 0.293623 13.7066C0.479357 13.8923 0.730611 13.9977 0.993268 14C1.25593 14.0022 1.50897 13.9013 1.69791 13.7188L6.29197 9.12473C6.85537 8.56116 7.17188 7.79689 7.17188 7C7.17188 6.20311 6.85537 5.43884 6.29197 4.87527L1.69791 0.281206C1.50897 0.0987274 1.25593 -0.00224415 0.993268 3.82748e-05C0.730611 0.0023207 0.479357 0.107675 0.293623 0.293409C0.107888 0.479144 0.0025348 0.730397 0.000252377 0.993055C-0.00203005 1.25571 0.0989415 1.50876 0.28142 1.69769L4.87849 6.29176C5.06629 6.47961 5.17179 6.73437 5.17179 7C5.17179 7.26563 5.06629 7.52039 4.87849 7.70824L0.28142 12.3023Z"
                                                                                    fill="#FC5301"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </NavLink>
                                                                </td> */}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    {
                                                        value.coupon_description &&
                                                        <div className="main_table_menu">
                                                            <div className="main_table_menu_inner">
                                                                <div>
                                                                    <div className="list-inner-new">
                                                                        <ul>
                                                                            <div
                                                                                className={`booking-history-div-new`}
                                                                            >
                                                                                <li>
                                                                                    <div className="list-inner-dic">
                                                                                        <div className="title-inner-list">
                                                                                            <h3> {t("_Description")}</h3>
                                                                                        </div>
                                                                                        <div className="title-inner-time"></div>
                                                                                    </div>
                                                                                    <div className="booking_order_list_main">
                                                                                        <div className="booking_main_list">
                                                                                            <ul>
                                                                                                <li className="booking_heading">
                                                                                                    <div className="booking_text_heading">
                                                                                                        <>
                                                                                                            <p>
                                                                                                                <span>
                                                                                                                    {value.coupon_description}
                                                                                                                </span>
                                                                                                            </p>
                                                                                                        </>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </div>

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className="paginate_all">
                                        {coupanList.length > 0 && pageCount > 1 && (
                                            <ReactPaginate
                                                pageCount={pageCount}
                                                forcePage={currentPage}
                                                onPageChange={handlePageChange}
                                                containerClassName={"navigationButtons"}
                                                previousLinkClassName={"previousButton"}
                                                nextLinkClassName={"nextButton"}
                                                disabledClassName={"navigationDisabled"}
                                                activeClassName={"active"}
                                                pageRange={2}
                                                marginPagesDisplayed={2}
                                                nextLabel={
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="7.172"
                                                        height="14"
                                                        viewBox="0 0 7.172 14"
                                                    >
                                                        <path
                                                            id="fi-rr-angle-small-down"
                                                            d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                                                            transform="translate(7.172) rotate(90)"
                                                            fill="#000"
                                                        />
                                                    </svg>
                                                }
                                                previousLabel={
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="7.172"
                                                        height="14"
                                                        viewBox="0 0 7.172 14"
                                                    >
                                                        <path
                                                            id="fi-rr-angle-small-down"
                                                            d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                                                            transform="translate(15.086 -5) rotate(90)"
                                                            fill="#232a35"
                                                        />
                                                    </svg>
                                                }
                                            />
                                        )}
                                    </div>
                                </>
                            ) : (
                                recordStatus == false && <RecordNotFound />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CouponList;
