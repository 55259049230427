import React from "react";
import { MetaData, images } from "../actions/customFn";
import { Tab, Tabs } from "react-bootstrap";
import { useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, verifyLink } from "../actions/loginAction";
import validator from "validator";
import LoginModal from "../components/Modal/LoginModal";
import { useTranslation } from "react-i18next";
import { PuffLoader } from "react-spinners";

const ChangePassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [ModalLogin, setModalLogin] = useState(false);
  const [loginKey, SetLoginKey] = useState("login");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirm_password: "",
  });

  const [formErrors, setFormError] = useState({});
  const errors = {};
  const [buttonDisable, setButtonDisable] = useState(false);
  const [headerLogo, setHeaderLogo] = useState("");
  const [title, setTitle] = useState("");

  const homeData = useSelector((state) => state.home);

  useEffect(() => {
    if (homeData && homeData.settings) {
      setHeaderLogo(homeData.settings.header_logo);
      setTitle(homeData.settings.web_title);
    }
  }, [homeData]);

  useEffect(() => {
    dispatch(verifyLink(id, navigate, setFormData, t));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  //=================Form Validation starts===============
  const validateFormData = () => {
    // if (formData.email === "") {
    //     formErrors.email = "Enter email id";
    // } else if (!validator.isEmail(formData.email)) {
    //     formErrors.email = "Enter compnay valid email id";
    // }

    if (formData.password === "") {
      errors.password = "Enter Password";
    }

    if (formData.confirm_password === "") {
      errors.confirm_password = "Enter confirm Password";
    } else if (formData.confirm_password.length < 8) {
      errors.confirm_password = "Password has atleast 8 charcter";
    }

    if (formData.password !== formData.confirm_password) {
      errors.confirm_password = "Confirm Password does not match";
    }
    setFormError(errors);
  };
  //=================Form Validation ends===============

  const submit = () => {
    try {
      validateFormData();
      if (Object.keys(errors).length === 0) {
        setButtonDisable(true);
        dispatch(
          resetPassword(
            formData,
            setFormData,
            navigate,
            setModalLogin,
            t,
            setButtonDisable
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeLoginModal = () => {
    setModalLogin(false);
  };

  let metaData = {
    title: t("_change_password"),
  };
  return (
    <div
      className="login_main_page"
      style={{
        backgroundImage: `url(${images["login_bg.png"]})`,
      }}
    >
      {metaData && <MetaData data={metaData} />}
      <div className="login_inner">
        <div className="container">
          <div className="login_logo">
            <NavLink to="/">
              {" "}
              <img src={headerLogo} alt="" />
            </NavLink>
          </div>

          <div className="loging_all_tab">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 login_mobile_text">
                <div className="login_text_all">
                  <div className="login_text">
                    <p>{t("_Welcome_to")}</p>
                    <h2>{title}</h2>
                    <p className="login_content">
                      {t(
                        "Lorem_ipsum_is_simply_dummy_text_of_the_printing_and_typesetting_industry._Lorem_ipsum_has_been_the_industry's_standard_dummy_text_ever_since_the_1500s,"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                <div className={`login_section new_login_main `}>
                  <div className="login_heading">
                    <h2>{t("_Change_Password")}</h2>
                    {/* <p>Please Login Your Account</p> */}
                  </div>

                  <div className="main_form">
                    {/* <div className="input_all_login">
                                            <label>Email Address</label>
                                            <input
                                                type="text"
                                                className={
                                                    `form-control  ` +
                                                    (formErrors.email ? "is-invalid" : "")
                                                }
                                                placeholder="Enter your email address"
                                                onChange={handleChange}
                                                value={formData.email}
                                                name="email"
                                            />
                                            {formErrors.email && (
                                                <div className="invalid-feedback">
                                                    {formErrors.email}
                                                </div>
                                            )}
                                        </div> */}
                    <div className="input_all_login">
                      <label>{t("_Password")}</label>
                      <input
                        type="password"
                        className={
                          `form-control  ` +
                          (formErrors.password ? " is-invalid" : "")
                        }
                        name="password"
                        onChange={handleChange}
                        value={formData.password}
                        placeholder="Enter your password"
                      />
                      {formErrors.password && (
                        <div className="invalid-feedback">
                          {formErrors.password}
                        </div>
                      )}
                    </div>
                    <div className="input_all_login">
                      <label>{t("_Confirm_Password")}</label>
                      <input
                        type="password"
                        className={
                          `form-control  ` +
                          (formErrors.confirm_password ? " is-invalid" : "")
                        }
                        name="confirm_password"
                        onChange={handleChange}
                        value={formData.confirm_password}
                        placeholder="Enter your confirm password"
                      />
                      {formErrors.confirm_password && (
                        <div className="invalid-feedback">
                          {formErrors.confirm_password}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="login_button">
                    <button onClick={submit}>
                      {buttonDisable ? (
                        <div className="order-cancelling-loader-btn">
                          <PuffLoader size={30} color="#fff" />
                        </div>
                      ) : (
                        t("_Reset_Password")
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginModal
        loginInfo={loginKey}
        SetLoginKey={SetLoginKey}
        LoginModal={ModalLogin}
        LogincloseModal={closeLoginModal}
      />
    </div>
  );
};

export default ChangePassword;
