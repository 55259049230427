import { useContext } from "react";
import { images } from "../../actions/customFn";
import { NavLink } from "react-router-dom";
import { BlogContext } from "../../context";
import { useTranslation } from "react-i18next";

function LatestBlogsNew() {
  const { t } = useTranslation();
  const blogData = useContext(BlogContext);
  const { latest } = blogData;

  let firstDiv = "";
  let secondDiv = "";
  let thirdDiv = "";

  if (latest.length > 0) {
    if (latest.length === 1) {
      firstDiv = "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12";
      secondDiv = "d-none";
      thirdDiv = "d-none";
    }

    if (latest.length === 2) {
      firstDiv = "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12";
      secondDiv = "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12";
      thirdDiv = "d-none"
    }

    if (latest.length === 3) {
      firstDiv = "col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12";
      secondDiv = "d-none";
      thirdDiv = "col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"
    }
  };


  return (
    <div className="latest_blogs_main">
      <div className="container">
        <div className="latest_blogs_heading">
          <h2>{t('_latest_blogs')}</h2>
        </div>

        <div className="row">
          <div className={firstDiv}>
            <NavLink to={`/blog-details/${latest[0].slug}`} target="_blank">
              {/* <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 mb-4"> */}
              <div className="latest_left_main">
                <div
                  className="latest_left_bgimges"
                  style={{
                    backgroundImage: `url(${latest[0].image})`,
                  }}
                >
                  <div className="latest_left_inner">
                    <span className="latest_btn">
                      {t('_activity')}
                    </span>
                    <h2>{latest[0].title}</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: latest[0].Description,
                      }}
                    ></p>
                    <div className="latestblogs_right_profile1">
                      {/* <div className="right_profile_left1">
                        <div className="right_profile_left_bg">
                          <img src={images["LatestBlogs_4.png"]} alt="" />
                        </div>
                        <span>Earl L Hogue</span>
                      </div> */}
                      <div className="right_profile_right1">
                        <div className="inner_pro_flex">
                          <div className="right_profile_right1_bg">
                            <img src={images["new_cal.png"]} alt="" />
                          </div>
                          <span>{latest[0].created_at}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
          {
            latest.length === 2 &&
            <div className={secondDiv}>
              <NavLink to={`/blog-details/${latest[1].slug}`} target="_blank">
                <div className="latest_left_main">
                  <div
                    className="latest_left_bgimges"
                    style={{
                      backgroundImage: `url(${latest[1].image})`,
                    }}
                  >
                    <div className="latest_left_inner">
                      <span className="latest_btn">
                        {t('_activity')}
                      </span>
                      <h2>{latest[1].text}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: latest[1].Description,
                        }}
                      ></p>
                      <div className="latestblogs_right_profile1">
                        <div className="right_profile_left1">
                          <div className="right_profile_left_bg">
                            <img src={images["LatestBlogs_4.png"]} alt="" />
                          </div>
                          {/* <span>Earl L Hogue</span> */}
                        </div>
                        <div className="right_profile_right1">
                          <div className="inner_pro_flex">
                            <div className="right_profile_right1_bg">
                              <img src={images["new_cal.png"]} alt="" />
                            </div>
                            <span>{latest[1].created_at}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          }

          {
            latest.length === 3 &&
            <div className={`latest_right_main ${thirdDiv}`}>
              <div className="row">
                <div
                  className="col-xl-12 col-lg-12 col-md-12 col-sm-6 mb-4"
                >
                  <NavLink to={`/blog-details/${latest[1].slug}`} target="_blank">
                    <div
                      className="latest_right_bgimages1"
                      style={{
                        backgroundImage: `url(${latest[1].image})`,
                      }}
                    >
                      <div className="latest_right_inner">
                        <span className="latest_btn">
                          {t('_activity')}
                        </span>
                        <h4>{latest[1].title}</h4>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div
                  className="col-xl-12 col-lg-12 col-md-12 col-sm-6 mb-4"
                >
                  <NavLink to={`/blog-details/${latest[2].slug}`} target="_blank">
                    <div
                      className="latest_right_bgimages1"
                      style={{
                        backgroundImage: `url(${latest[2].image})`,
                      }}
                    >
                      <div className="latest_right_inner">
                        <span className="latest_btn">
                          {t('_activity')}
                        </span>
                        <h4>{latest[2].title}</h4>
                      </div>
                    </div>
                  </NavLink>
                </div>

              </div>

            </div>
          }
        </div>

      </div>
    </div>
  );
}

export default LatestBlogsNew;
