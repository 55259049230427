import React from "react";
import OwlCarousel from "react-owl-carousel";
import { useState } from "react";
import { useEffect } from "react";
import Lottie from "react-lottie";
import cloud from "../../animations/cloud/data.json";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ExperienceSlider = () => {
  const { t } = useTranslation();
  const homeData = useSelector((state) => state.home);
  const [middleSlider, setMiddleSlider] = useState([]);

  useEffect(() => {
    if (homeData) {
      setMiddleSlider(homeData.middle_slider);
    }
  }, [homeData, middleSlider]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: cloud,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const experience = {
    loop: true,
    margin: 0,
    nav: true,
    dots: true,
    autoplay: false,
    autoplaySpeed: 3000,
    // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  return (
    <>
      {middleSlider.length > 0 && (
        <div className="div_experience_new">
          <div className="animated_cloud">
            <Lottie
              options={defaultOptions}
              // height={300}
              // width={400}
              className="ani_jj"
            />
          </div>
          <OwlCarousel
            {...experience}
            className="owl-carousel owl-theme slider_main"
          >
            {middleSlider.map(function (value, index) {
              return (
                <div className="item" key={index}>
                  <div
                    className="ExperienceSlider_main"
                    style={{
                      backgroundImage: `url(${value.slider_image})`,
                    }}
                  >
                    <div className="container">
                      <div className="Experience_Text">
                        <span>{value.middle_slider_title}</span>
                        <h2>{value.middle_slider_desc}</h2>
                        <NavLink target="_blank" to={value.middle_slider_link}>
                          {t("_view_details")}
                        </NavLink>
                        {/* <button></button> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      )}
    </>
  );
};

export default ExperienceSlider;
