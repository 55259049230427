import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ProductListingInfo } from "../../context";
import { useTranslation } from "react-i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const FilterMobile = (props) => {
  const { t } = useTranslation();

  const { window } = props;
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // This is used only for the example
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const {
    setIsDataLoading,
    filterQuery,
    setFilterQuery,
    setFormData,
    setCurrentPage,
    formData,
    setFilterOn,
  } = useContext(ProductListingInfo);

  const [filterListing, setFilterListing] = useState({});
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");

  useEffect(() => {
    setFilterListing(props.filterListing);
  }, [props]);

  const timeSlot = [
    {
      title: " In the night, 12 AM - 08 AM",
      time: "00:00-08:00",
    },
    {
      title: "In the morning, 08 AM - 12 PM",
      time: "08:00-12:00",
    },
    {
      title: "In the afternoon, 12 PM - 05 PM",
      time: "12:00-17:00",
    },
    {
      title: " In the evening, 05 PM - 12 AM",
      time: "17:00-24:00",
    },
  ];

  const handleSorting = (event) => {
    setCurrentPage(0);
    setFormData((prev) => ({
      ...prev,
      sort: event.target.value,
      is_filter: 1,
    }));
    setFilterQuery((prev) => ({
      ...prev,
      sort: event.target.value,
      is_filter: 1,
    }));
  };

  const handleIntrest = (e, data) => {
    let idArray = [];
    data.forEach((item) => {
      idArray.push(item.id);
    });
    setFilterQuery((prev) => ({ ...prev, interest_filter: idArray }));
  };

  const handleCategory = (e, data) => {
    let idArray = [];
    data.forEach((item) => {
      idArray.push(item.id);
    });
    setFilterQuery((prev) => ({ ...prev, category_filter: idArray }));
  };

  const handleServices = (e, data) => {
    let idArray = [];
    data.forEach((item) => {
      idArray.push(item.id);
    });
    setFilterQuery((prev) => ({ ...prev, service_filter: idArray }));
  };

  const handleTimeSlot = (e, data) => {
    let timeArray = [];
    data.forEach((item) => {
      timeArray.push(item.time);
    });
    setFilterQuery((prev) => ({ ...prev, time_filter: timeArray }));
  };

  useEffect(() => {
    if (
      filterQuery.interest_filter.length > 0 ||
      filterQuery.time_filter.length > 0 ||
      filterQuery.category_filter.length > 0 ||
      filterQuery.service_filter.length > 0 ||
      filterQuery.min_price_filter !== "" ||
      filterQuery.sort !== ""
    ) {
      setFormData({ ...filterQuery, is_filter: 1 });
    } else {
      setFormData({ ...filterQuery, is_filter: 0 });
    }
    // setIsDataLoading(true);
  }, [filterQuery]);

  // useEffect(() => {
  //   setFilterQuery((prev) => ({ ...prev, min_price_filter: minValue }));
  // }, [minValue])

  // useEffect(() => {
  //   setCurrentPage(0);
  //   setFilterQuery((prev) => ({
  //     ...prev,
  //     min_price_filter: minValue,
  //     max_price_filter: maxValue,
  //   }));
  // }, [minValue, maxValue]);

  return (
    <div>
      {Object.keys(filterListing).length > 0 && (
        <Root className="new_filter_drawer">
          <CssBaseline />
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                height: `calc(50% - ${drawerBleeding}px)`,
                overflow: "visible",
              },
            }}
          />
          {/* <Box sx={{ textAlign: "center", pt: 1 }}>
          <Button onClick={toggleDrawer(true)}>Open</Button>
        </Box> */}
          <SwipeableDrawer
            container={container}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <StyledBox
              sx={{
                position: "absolute",
                top: -drawerBleeding,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                visibility: "visible",
                right: 0,
                left: 0,
              }}
            >
              <Puller />
              <Typography
                sx={{ p: 2, color: "text.secondary" }}
                className="filter-text"
              >
                <span> 1 {t("_applied")}</span>
                <span className="mobile-filter-title">{t("_filter")}</span>

                {/* <h3><p>{t("_filter")}</p></h3> */}
                {/* <div>
                  <p>{t("_filter")}</p>
                </div> */}
                <button className="btn_clear">{t("_clear")}</button>
              </Typography>
            </StyledBox>

            <div className="filter_button_all new_filter_mob">
              {/* <div className="prd_Sort_by">
                <span className="new_sort_title">{t("_sort_by")}</span>
                <div className="sort_select">
                  <Select
                    labelId="demo-simple-select-label"
                    className="new_sort_list"
                    id="demo-simple-select"
                    label="Sort by"
                    value={formData?.sort}
                    onChange={() => handleSorting()}
                  >
                    <MenuItem value="atz">{t("_a_to_z")}</MenuItem>
                    <MenuItem value="zta">{t("_z_to_a")}</MenuItem>
                    <MenuItem value="htl">{t("_high_to_low")} ({t("_price")})</MenuItem>
                    <MenuItem value="lth">{t("_low_to_high")} ({t("_price")})</MenuItem>
                  </Select>
                </div>
              </div> */}

              <div className="filter_one">
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={timeSlot}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  onChange={handleTimeSlot}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                     
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        // onChange={(e) => handleTimeSlot(e, option.time)}
                      />
                      {option.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Time" placeholder="Time" />
                  )}
                />
              </div>

              <div className="filter_one">
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={filterListing.interests}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  onChange={handleIntrest}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        // onChange={(e) => handleIntrest(e, option.id)}
                      />
                      {option.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Interests"
                      placeholder="Interests"
                    />
                  )}
                />
              </div>

              <div className="filter_one">
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={filterListing.category}
                  onChange={handleCategory}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        // onChange={(e) => handleCategory(e, option.id)}
                      />
                      {option.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Categories"
                      placeholder="Categories"
                    />
                  )}
                />
              </div>

              {/* <div className="filter_one filter_price">
                <h2> Price </h2>
                <p>The median activity price is ₹ 5809</p>
                <div className="price_box_inner">
                  <div className="price_info">
                    <label>Min. price</label>
                    <input
                      type="text"
                      placeholder="$ 0"
                      value={filterQuery.min_price_filter}
                      onChange={(e) => setFilterQuery((prev) => ({ ...prev, min_price_filter: e.target.value }))}
                    />
                  </div>
                  <div className="price_info">
                    <label>Max. price</label>
                    <input
                      type="text"
                      placeholder={`$` + filterListing.price.max_price}
                      max={filterListing.price.max_price}
                      value={maxValue}
                      onChange={(e) => setMaxValue(e.target.value)}
                    />
                  </div>
                </div>
              </div> */}

              <div className="filter_one">
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={filterListing.service}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  onChange={handleServices}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        // onChange={(e) => handleServices(e, option.id)}
                      />
                      {option.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Services"
                      placeholder="Services"
                    />
                  )}
                />

                <button
                  type="button"
                  onClick={() => setFilterOn((status) => !status)}
                >
                  {t("_Filter")}
                </button>
              </div>

              {/* <div className="filter_one">
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={box_five}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Departs from"
                      placeholder="Departs from"
                    />
                  )}
                />
              </div>

              <div className="filter_one">
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={box_six}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Star rating"
                      placeholder="Star rating"
                    />
                  )}
                />
              </div> */}
            </div>
          </SwipeableDrawer>
        </Root>
      )}
    </div>
  );
};

FilterMobile.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default FilterMobile;
