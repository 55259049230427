import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TravellerDetails = (props) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const { tour_id } = useParams();
        return (
                <>
                        <div className="meet-pickup-main">
                                <div className="prodtraveller_detsils activity_productdetail">
                                        <h1>{t("_Let's_start_with_how_you_price_your_product")}</h1>
                                        <h6>{t("_How_do_you_price_your_product?")}</h6>
                                        <div class="product_selectorradio">
                                                <input type="radio" className="productradio" name="radio" />
                                                <span> {t("_Per_person")}</span>
                                        </div>
                                        <div class="product_selectorradio">
                                                <input type="radio" className="productradio" name="radio" />
                                                <span>{t("_Per_vehicle/group")}</span>
                                        </div>

                                        <h6>{t("_Define_the_age_groups_that_can_participate")}</h6>

                                        <div className="age_grouptrveller">
                                                <div className="metting_checkboxes"></div>

                                                <div className="min_maxinpt">
                                                        <p>{t("_Min_age")}</p>
                                                        <span>&nbsp;</span>
                                                        <p>{t("_Max_age")}</p>
                                                </div>
                                        </div>

                                        <div className="age_grouptrveller">
                                                <label class="metting_checkboxes mt-3">
                                                        <input type="checkbox" />
                                                        <span>{t("_Adult")}</span>
                                                </label>

                                                <div className="min_maxinpt">
                                                        <input type="number" />
                                                        <span>-</span>
                                                        <input type="number" />
                                                </div>
                                        </div>

                                        <div className="age_grouptrveller">
                                                <label class="metting_checkboxes mt-3">
                                                        <input type="checkbox" />
                                                        <span>{t("_Infant")} </span>
                                                </label>

                                                <div className="min_maxinpt">
                                                        <input type="number" />
                                                        <span>-</span>
                                                        <input type="number" />
                                                </div>
                                        </div>

                                        <div className="age_grouptrveller">
                                                <label class="metting_checkboxes mt-3">
                                                        <input type="checkbox" />
                                                        <span>{t("_Child")}</span>
                                                </label>

                                                <div className="min_maxinpt">
                                                        <input type="number" />
                                                        <span>-</span>
                                                        <input type="number" />
                                                </div>
                                        </div>

                                        <div className="age_grouptrveller">
                                                <label class="metting_checkboxes mt-3">
                                                        <input type="checkbox" />
                                                        <span> {t("_Youth")}</span>
                                                </label>

                                                <div className="min_maxinpt">
                                                        <input type="number" />
                                                        <span>-</span>
                                                        <input type="number" />
                                                </div>
                                        </div>
                                        <div className="age_grouptrveller">
                                                <label class="metting_checkboxes mt-3">
                                                        <input type="checkbox" />
                                                        <span>{t("_Senior")} </span>
                                                </label>

                                                <div className="min_maxinpt">
                                                        <input type="number" />
                                                        <span>-</span>
                                                        <input type="number" />
                                                </div>
                                        </div>

                                        <h6>{t("_What_is_the_maximum_number_of_travelers_per_booking?")}</h6>

                                        <div className="restrictnumber_autocomplete mb-4">
                                                <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={top100Films}
                                                        sx={{ width: 300 }}
                                                        renderInput={(params) => (
                                                                <TextField {...params} placeholder="+1" />
                                                        )}
                                                />
                                        </div>

                                        <div className="btn-last-save">
                                                <button
                                                        onClick={() =>
                                                                navigate(`/product-bulider/price-schedule/${tour_id}`)
                                                        }
                                                        className="btn-all-save"
                                                >
                                                        {" "}
                                                        {t("_Save_&_continue")}
                                                </button>
                                        </div>
                                </div>
                        </div>
                </>
        );
};

export default TravellerDetails;

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
        { label: "2 " },
        { label: "3 " },
        { label: "12 " },
        { label: "6" },
        { label: "8" },
        { label: "9" },
        { label: " 3" },
        { label: "10" },
        { label: "8" },
        { label: "9" },
        { label: " 3" },
        { label: "10" },
];
