import React, { useContext, useState } from "react";
import { images } from "../../actions/customFn";
import { ProductInformation } from "../../context";
import FsLightbox from "fslightbox-react";
import { useTranslation } from "react-i18next";
import ReviewImageModal from "../Modal/ReviewImageModal";

const TravellerPhotos = (props) => {
  const { t } = useTranslation();
  const { DetailsData } = useContext(ProductInformation);

  const [toggler, setToggler] = useState(false);
  const [productIndex, setProductIndex] = useState(0);
  let length = DetailsData.traveller_photos.length;
  let images = DetailsData.traveller_photos; 

  const closeReviewModal = () => {
    setToggler(false);
  };

  return (
    <>
      {DetailsData.traveller_photos?.length > 0 && (
        <>
          <div id="traveller-photos-div" data-section>
            <div className="customer-reviews-main" ref={props.myRef1}>
              <h2>{t("_Traveller_Photos")}</h2>
              <div className="traveller-photos row">
                <>
                  {length == 1 && (
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <img
                        className="travelPic1"
                        src={images[0]}
                        alt=""
                        onClick={() => {
                          setToggler(!toggler);
                          setProductIndex(0);
                        }}
                      />
                    </div>
                  )}

                  {length == 2 && (
                    <>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <img
                          className="travelPic1"
                          src={images[0]}
                          alt=""
                          onClick={() => {
                            setToggler(!toggler);
                            setProductIndex(0);
                          }}
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <img
                          className="travelPic1"
                          src={images[1]}
                          alt=""
                          onClick={() => {
                            setToggler(!toggler);
                            setProductIndex(1);
                          }}
                        />
                      </div>
                    </>
                  )}

                  {length == 3 && (
                    <>
                      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                        <img
                          className="travelPic1"
                          src={images[0]}
                          alt=""
                          onClick={() => {
                            setToggler(!toggler);
                            setProductIndex(0);
                          }}
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 second-div">
                        <img
                          className="travelPic1"
                          src={images[1]}
                          alt=""
                          onClick={() => {
                            setToggler(!toggler);
                            setProductIndex(1);
                          }}
                        />
                        <img
                          className="travelPic1"
                          src={images[2]}
                          alt=""
                          onClick={() => {
                            setToggler(!toggler);
                            setProductIndex(2);
                          }}
                        />
                      </div>
                    </>
                  )}

                  {length == 4 && (
                    <>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <img
                          className="travelPic1"
                          src={images[0]}
                          alt=""
                          onClick={() => {
                            setToggler(!toggler);
                            setProductIndex(0);
                          }}
                        />
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 second-div row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <img
                            className="travelPic1"
                            src={images[1]}
                            alt=""
                            onClick={() => {
                              setToggler(!toggler);
                              setProductIndex(1);
                            }}
                          />
                          <img
                            className="travelPic1"
                            src={images[2]}
                            alt=""
                            onClick={() => {
                              setToggler(!toggler);
                              setProductIndex(2);
                            }}
                          />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <img
                            className="travelPic1"
                            src={images[3]}
                            alt=""
                            onClick={() => {
                              setToggler(!toggler);
                              setProductIndex(3);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {length >= 5 && (
                    <>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <img
                          className="travelPic1"
                          src={images[0]}
                          alt=""
                          onClick={() => {
                            setToggler(!toggler);
                            setProductIndex(0);
                          }}
                        />
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 second-div row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <img
                            className="travelPic1"
                            src={images[1]}
                            alt=""
                            onClick={() => {
                              setToggler(!toggler);
                              setProductIndex(1);
                            }}
                          />
                          <img
                            className="travelPic1"
                            src={images[2]}
                            alt=""
                            onClick={() => {
                              setToggler(!toggler);
                              setProductIndex(2);
                            }}
                          />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <img
                            className="travelPic1"
                            src={images[3]}
                            alt=""
                            onClick={() => {
                              setToggler(!toggler);
                              setProductIndex(3);
                            }}
                          />
                          <img
                            className="travelPic1"
                            src={images[4]}
                            alt=""
                            onClick={() => {
                              setToggler(!toggler);
                              setProductIndex(4);
                            }}
                          />
                          {length > 5 && (
                            <div
                              class="seeMoreOverlay"
                              bis_skin_checked="1"
                              onClick={() => {
                                setToggler(!toggler);
                                setProductIndex(5);
                              }}
                            >
                              <svg
                                width="48"
                                height="48"
                                fill="white"
                                xmlns="http://www.w3.org/2000/svg"
                                stroke="#2A2D32"
                                class="seeAllArrow__1dTv"
                              >
                                <circle
                                  cx="24"
                                  cy="24"
                                  r="23"
                                  stroke-width="2"
                                ></circle>
                                <path
                                  d="M26.4 29.886l5.943-5.943L26.4 18M16 23.943h16.343"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                              See more
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
          {/* <div className="fslight_box_main">
            <FsLightbox
              toggler={toggler}
              sources={images}
              sourceIndex={productIndex}
            />
          </div> */}
        </>
      )}

      <ReviewImageModal
        onCloseModal={closeReviewModal}
        showModal={toggler}
        productIndex={productIndex}
        activeKey="traveller"
      />
    </>
  );
};

export default TravellerPhotos;
