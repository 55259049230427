import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useImageCropContext } from '../providers/ImageCropProvider';
// import {
//     ArrowUturnLeftIcon,
//     ArrowUturnRightIcon,
//     MinusIcon,
//     PlusIcon
// } from '@heroicons/react/solid/PlusIcon';
import classNames from 'classnames';
import { faMinus, faPlus } from '@fortawesome/fontawesome-free-solid';
import { RotateRight } from '@mui/icons-material';

export const ZoomSlider = ({ className }) => {
    const { zoom, setZoom, handleZoomIn, handleZoomOut, max_zoom, min_zoom, zoom_step } =
        useImageCropContext();

    return (
        <div className={classNames(className, 'flex items-center justify-center gap-2')}>
            <button className="p-1 upload_img_btn" onClick={handleZoomOut}>
                <FontAwesomeIcon icon={faMinus} />
                {/* <MinusIcon className="text-gray-400 w-4" /> */}
            </button>
            <input
                type="range"
                name="volju"
                min={min_zoom}
                max={max_zoom}
                step={zoom_step}
                value={zoom}
                onChange={e => {
                    setZoom(Number(e.target.value));
                }}
            />
            <button className="p-1 upload_img_btn" onClick={handleZoomIn}>
                <FontAwesomeIcon icon={faPlus} />
                {/* <PlusIcon className="text-gray-400 w-4" /> */}
            </button>
        </div>
    );
};

export const RotationSlider = ({ className }) => {
    const {
        rotation,
        setRotation,
        max_rotation,
        min_rotation,
        rotation_step,
        handleRotateAntiCw,
        handleRotateCw
    } = useImageCropContext();

    return (
        <div className={classNames(className, 'flex items-center justify-center gap-2')}>
            <button className="p-1 upload_img_btn" onClick={handleRotateAntiCw}>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="#c0c0c0" fill-rule="evenodd" d="M8 1.5a6.5 6.5 0 1 1-6.445 7.348a.75.75 0 1 1 1.487-.194A5.001 5.001 0 1 0 4.43 4.5h1.32a.75.75 0 0 1 0 1.5h-3A.75.75 0 0 1 2 5.25v-3a.75.75 0 0 1 1.5 0v1.06A6.48 6.48 0 0 1 8 1.5" clip-rule="evenodd" /></svg>
            </button>
            <input
                type="range"
                name="volju"
                min={min_rotation}
                max={max_rotation}
                step={rotation_step}
                value={rotation}
                onChange={e => {
                    setRotation(Number(e.target.value));
                }}
            />
            <button className="p-1 upload_img_btn" onClick={handleRotateCw}>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="#c0c0c0" d="M463.5 224h8.5c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2S461.9 48.1 455 55l-41.6 41.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2S334.3 224 344 224z" /></svg>
                {/* <ArrowUturnRightIcon className="text-gray-400 w-4" /> */}
            </button>
        </div>
    );
};