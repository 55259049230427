import { useEffect, useRef, useState } from "react";
import { dataURItoBlob, MetaData } from "../../actions/customFn";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "flatpickr/dist/plugins/monthSelect/style.css";
import UserMap from "../UserMap";
import { getMapData } from "../../actions/userMapAction";
import LoadingBar from "../Loading/LoadingBar";
import RecordNotFound from "../../pages/RecordNotFound";
import { Tooltip as ReactTooltip } from "react-tooltip";
import * as htmlToImage from "html-to-image";
import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { getImageUrl } from "../../actions/productBuilderAction";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { PuffLoader } from "react-spinners";
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image";
import { useScreenshot } from "use-screenshot-hook";
import MyGoogleMap from "../productbuilderComponets/MyGoogleMap";

const MyMaps = () => {
  const homeData = useSelector((state) => state.home);
  let metaData = { title: "My Map" };
  const languageReducer = useSelector((state) => state.languageReducer);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [mapData, setMapData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sharingUrl, setSharingUrl] = useState("");
  const [anchorEl, setAnchorElement] = useState(null);
  const open = Boolean(anchorEl);
  const [toggle, setToggle] = useState(false);
  const mapContainerRef = useRef(null);


  

  const { image, takeScreenshot } = useScreenshot({
    ref: mapContainerRef,
  });

  const [googleKey, setGoogleKey] = useState("");
  useEffect(() => {
    if (homeData) {
      setGoogleKey(homeData?.settings?.google_key);
    }
  }, [homeData]);

  const handleClose = () => {
    setAnchorElement(null);
    setIsURLObtained(false);
  };

  useEffect(() => {
    if (languageReducer !== "") {
      setIsLoading(true);
      dispatch(getMapData(languageReducer, setMapData, navigate, setIsLoading));
    }
  }, [languageReducer, toggle]);

  // const locationData = [
  //     {
  //         "meeting_point_location": "Ahmedabad, Gujarat, India",
  //         "meeting_point_location_id": 72,
  //         "meeting_point_latitude": 23.022505,
  //         "meeting_point_longitude": 72.5713621,
  //         "meeting_point_description": "?????? ??????????????",
  //         "meeting_point_location_obj": {
  //             "label": "Ahmedabad, Gujarat, India",
  //             "value": {
  //                 "description": "Ahmedabad, Gujarat, India",
  //                 "matched_substrings": [
  //                     {
  //                         "length": "6",
  //                         "offset": "0"
  //                     }
  //                 ],
  //                 "place_id": "ChIJSdRbuoqEXjkRFmVPYRHdzk8",
  //                 "reference": "ChIJSdRbuoqEXjkRFmVPYRHdzk8",
  //                 "structured_formatting": {
  //                     "main_text": "Ahmedabad",
  //                     "main_text_matched_substrings": [
  //                         {
  //                             "length": "6",
  //                             "offset": "0"
  //                         }
  //                     ],
  //                     "secondary_text": "Gujarat, India"
  //                 },
  //                 "terms": [
  //                     {
  //                         "offset": "0",
  //                         "value": "Ahmedabad"
  //                     },
  //                     {
  //                         "offset": "11",
  //                         "value": "Gujarat"
  //                     },
  //                     {
  //                         "offset": "20",
  //                         "value": "India"
  //                     }
  //                 ],
  //                 "types": [
  //                     "locality",
  //                     "political",
  //                     "geocode"
  //                 ]
  //             }
  //         },
  //         "meeting_point_available": "true"
  //     },
  //     {
  //         "meeting_point_location": "United Arab Emirates University - Sheik Khalifa Bin Zayed St - Abu Dhabi - United Arab Emirates",
  //         "meeting_point_location_id": 73,
  //         "meeting_point_latitude": 24.200588,
  //         "meeting_point_longitude": 55.67603399999999,
  //         "meeting_point_description": "????????????",
  //         "meeting_point_location_obj": {
  //             "label": "United Arab Emirates University - Sheik Khalifa Bin Zayed St - Abu Dhabi - United Arab Emirates",
  //             "value": {
  //                 "description": "United Arab Emirates University - Sheik Khalifa Bin Zayed St - Abu Dhabi - United Arab Emirates",
  //                 "matched_substrings": [
  //                     {
  //                         "length": "11",
  //                         "offset": "0"
  //                     }
  //                 ],
  //                 "place_id": "ChIJK-vln9otDxQR9t2W0ORPLwA",
  //                 "reference": "ChIJK-vln9otDxQR9t2W0ORPLwA",
  //                 "structured_formatting": {
  //                     "main_text": "United Arab Emirates University",
  //                     "main_text_matched_substrings": [
  //                         {
  //                             "length": "11",
  //                             "offset": "0"
  //                         }
  //                     ],
  //                     "secondary_text": "Sheik Khalifa Bin Zayed St - Abu Dhabi - United Arab Emirates"
  //                 },
  //                 "terms": [
  //                     {
  //                         "offset": "0",
  //                         "value": "United Arab Emirates University"
  //                     },
  //                     {
  //                         "offset": "34",
  //                         "value": "Sheik Khalifa Bin Zayed St"
  //                     },
  //                     {
  //                         "offset": "63",
  //                         "value": "Abu Dhabi"
  //                     },
  //                     {
  //                         "offset": "75",
  //                         "value": "United Arab Emirates"
  //                     }
  //                 ],
  //                 "types": [
  //                     "university",
  //                     "point_of_interest",
  //                     "establishment"
  //                 ]
  //             }
  //         },
  //         "meeting_point_available": "false"
  //     },
  //     {
  //         "meeting_point_location": "CJHX+3C Uraidh, Riyadh Saudi Arabia",
  //         "meeting_point_location_id": 74,
  //         "meeting_point_latitude": 24.4276875,
  //         "meeting_point_longitude": 46.6485625,
  //         "meeting_point_description": "",
  //         "meeting_point_location_obj": {
  //             "label": "CJHX+3C Uraidh, Riyadh Saudi Arabia",
  //             "value": {
  //                 "description": "CJHX+3C Uraidh, Riyadh Saudi Arabia",
  //                 "place_id": "GhIJNzY7Un1tOEARsFAQ4QRTR0A",
  //                 "structured_formatting": {
  //                     "main_text": "CJHX+3C Uraidh, Riyadh Saudi Arabia"
  //                 },
  //                 "terms": [
  //                     {
  //                         "offset": "plus_code",
  //                         "value": "CJHX+3C"
  //                     },
  //                     {
  //                         "offset": "political",
  //                         "value": "Uraidh"
  //                     },
  //                     {
  //                         "offset": "locality",
  //                         "value": "Riyadh"
  //                     },
  //                     {
  //                         "offset": "administrative_area_level_2",
  //                         "value": "Riyadh Principality"
  //                     },
  //                     {
  //                         "offset": "administrative_area_level_1",
  //                         "value": "Riyadh Province"
  //                     },
  //                     {
  //                         "offset": "country",
  //                         "value": "Saudi Arabia"
  //                     }
  //                 ],
  //                 "types": [
  //                     "plus_code"
  //                 ]
  //             }
  //         },
  //         "meeting_point_available": "false"
  //     },
  //     {
  //         "meeting_point_location": "Ujjain, Madhya Pradesh, India",
  //         "meeting_point_location_id": 75,
  //         "meeting_point_latitude": 23.1764665,
  //         "meeting_point_longitude": 75.7885163,
  //         "meeting_point_description": "",
  //         "meeting_point_location_obj": {
  //             "label": "Ujjain, Madhya Pradesh, India",
  //             "value": {
  //                 "description": "Ujjain, Madhya Pradesh, India",
  //                 "matched_substrings": [
  //                     {
  //                         "length": "2",
  //                         "offset": "0"
  //                     }
  //                 ],
  //                 "place_id": "ChIJI_8A3ml0YzkREtSZeN-rgn8",
  //                 "reference": "ChIJI_8A3ml0YzkREtSZeN-rgn8",
  //                 "structured_formatting": {
  //                     "main_text": "Ujjain",
  //                     "main_text_matched_substrings": [
  //                         {
  //                             "length": "2",
  //                             "offset": "0"
  //                         }
  //                     ],
  //                     "secondary_text": "Madhya Pradesh, India"
  //                 },
  //                 "terms": [
  //                     {
  //                         "offset": "0",
  //                         "value": "Ujjain"
  //                     },
  //                     {
  //                         "offset": "8",
  //                         "value": "Madhya Pradesh"
  //                     },
  //                     {
  //                         "offset": "24",
  //                         "value": "India"
  //                     }
  //                 ],
  //                 "types": [
  //                     "locality",
  //                     "political",
  //                     "geocode"
  //                 ]
  //             }
  //         },
  //         "meeting_point_available": "false"
  //     }
  // ]
  const fitBounds = async (map) => {
    let bounds = new window.google.maps.LatLngBounds();
    let isSingleLocation = false;

    if (map != null) {
      mapData.forEach((marker) => {
        bounds.extend(
          new window.google.maps.LatLng(marker.latitude, marker.longitude)
        );
      });

      if (mapData.length == 1) {
        isSingleLocation = true;
      }

      if (isSingleLocation == true) {
        let zoomLevel = 0.01; //Increase value : Zoom-out, Decrease value:Zoom In
        const singleLocation = bounds.getCenter();
        bounds.extend(
          new window.google.maps.LatLng(
            singleLocation.lat() + zoomLevel,
            singleLocation.lng() + zoomLevel
          )
        );
        bounds.extend(
          new window.google.maps.LatLng(
            singleLocation.lat() - zoomLevel,
            singleLocation.lng() - zoomLevel
          )
        );
      }
    }
    await map.fitBounds(bounds);
  };
  const handleMapMounted = (map) => {
    if (map != null) {
      fitBounds(map);
    }
  };

  const [isDownloading, setIsDownloading] = useState(false);
  const [dropDownOpening, setDropDownOpening] = useState(false);
  const [isURLObtained, setIsURLObtained] = useState(false);


  const captureMap = async () => {
    setIsDownloading(true);
    const mapContainer = mapContainerRef.current; // Get reference to the container div
    if (mapContainer) {
      if (mapContainerRef.current) {
        takeScreenshot({ useCORS: true, cacheBust: true })
          .then((dataUrl) => {
            const link = document.createElement("a");
            link.download = "my-image-name.png";
            link.href = dataUrl;
            link.click();
            setIsDownloading(false);
          })
          .catch((error) => {
            console.error("Failed to capture map:", error);
          });
      }
    }
  };
  const shareImage = async (event) => {
    try {
      setDropDownOpening(true);
      setAnchorElement(event.currentTarget);
      const mapContainer = document.getElementById("map-container");
    

      if (mapContainer) {
        htmlToImage
          .toPng(mapContainer, { useCORS: true, cacheBust: true })
          .then((dataUrl) => {
            let imageUrl = dataURItoBlob(dataUrl);
            dispatch(
              getImageUrl(
                languageReducer,
                imageUrl,
                setSharingUrl,
                setIsURLObtained,
                setDropDownOpening
              )
            );
          })
          .catch((error) => {
            console.error("Failed to capture map:", error);
          });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const reloadPage = () => {
    // setIsURLObtained(false);           No need if reloading
    window.location.reload();
  };
  return (
    <>
      {googleKey && (
        <>
          {metaData && <MetaData data={metaData} />}

          {isLoading === true ? (
            <div className="blinking-bar-loader">
              <LoadingBar />
            </div>
          ) : (
            <div className="dashboard_section user-map-section new-data-map-info">
              <div className="text_banner">
                <div>{t("_my_map")}</div>{" "}
              </div>
              <>
                {mapData.length > 0 ? (
                  <div className="dashbord_graph">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="linechart_upper_main">
                          <div className="totle_booking_main">
                            {/* <div className="totle_booking_text">
                                                        <h4>Bookings</h4>
                                                    </div> */}
                            <div className="map-download-btn">
                              {isDownloading == true && (
                                <div className="loading-state">
                                  <div className="loading-class"></div>
                                </div>
                              )}

                              <div className="map-button">
                                <Button
                                  className="map-upload-button"
                                  id="basic-button"
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  data-tooltip-id="map-upload-button"
                                  onClick={shareImage}
                                >
                                  {dropDownOpening ? (
                                    <div className="order-cancelling-loader-btn">
                                      <PuffLoader size={30} color="#fff" />
                                    </div>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      version="1.1"
                                      width="512"
                                      height="512"
                                      x="0"
                                      y="0"
                                      viewBox="0 0 512 512"
                                      className="share-icon"
                                    >
                                      <g>
                                        <path
                                          d="M406 332c-29.636 0-55.969 14.402-72.378 36.571l-141.27-72.195A89.738 89.738 0 0 0 196 271a89.51 89.51 0 0 0-6.574-33.753l148.06-88.958C354.006 167.679 378.59 180 406 180c49.626 0 90-40.374 90-90S455.626 0 406 0s-90 40.374-90 90a89.54 89.54 0 0 0 6.09 32.54l-148.43 89.18C157.152 192.902 132.941 181 106 181c-49.626 0-90 40.374-90 90s40.374 90 90 90c30.122 0 56.832-14.876 73.177-37.666l140.86 71.985A89.702 89.702 0 0 0 316 422c0 49.626 40.374 90 90 90s90-40.374 90-90-40.374-90-90-90zm0-302c33.084 0 60 26.916 60 60s-26.916 60-60 60-60-26.916-60-60 26.916-60 60-60zM106 331c-33.084 0-60-26.916-60-60s26.916-60 60-60 60 26.916 60 60-26.916 60-60 60zm300 151c-33.084 0-60-26.916-60-60s26.916-60 60-60 60 26.916 60 60-26.916 60-60 60z"
                                          fill="#000000"
                                          opacity="1"
                                          data-original="#000000"
                                          className=""
                                        ></path>
                                      </g>
                                    </svg>
                                  )}
                                </Button>
                                {isURLObtained && (
                                  <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button",
                                    }}
                                  >
                                    <>
                                      <MenuItem>
                                        <FacebookShareButton
                                          url={sharingUrl}
                                          onClick={reloadPage}
                                        >
                                          <FacebookIcon
                                            size={32}
                                            round={true}
                                          />
                                        </FacebookShareButton>
                                      </MenuItem>
                                      <MenuItem>
                                        <TwitterShareButton
                                          url={sharingUrl}
                                          onClick={reloadPage}
                                        >
                                          <XIcon size={32} round={true} />
                                        </TwitterShareButton>
                                      </MenuItem>
                                      <MenuItem>
                                        <WhatsappShareButton
                                          url={sharingUrl}
                                          onClick={reloadPage}
                                        >
                                          <WhatsappIcon
                                            size={32}
                                            round={true}
                                          />
                                        </WhatsappShareButton>
                                      </MenuItem>
                                      <MenuItem>
                                        <LinkedinShareButton
                                          url={sharingUrl}
                                          onClick={reloadPage}
                                        >
                                          <LinkedinIcon
                                            size={32}
                                            round={true}
                                          />
                                        </LinkedinShareButton>
                                      </MenuItem>
                                      <MenuItem>
                                        <TelegramShareButton
                                          url={sharingUrl}
                                          onClick={reloadPage}
                                        >
                                          <TelegramIcon
                                            size={32}
                                            round={true}
                                          />
                                        </TelegramShareButton>
                                      </MenuItem>
                                    </>
                                  </Menu>
                                )}
                                <button
                                  className="map-download-button"
                                  data-tooltip-id="map-download-button"
                                  onClick={() => captureMap()}
                                  // onClick={() => takeScreenshot()}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    viewBox="0 0 48 48"
                                    fill="none"
                                  >
                                    <path
                                      d="M24 32L32 22H26V8H22V22H16L24 32Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M40 36H8V22H4V36C4 38.206 5.794 40 8 40H40C42.206 40 44 38.206 44 36V22H40V36Z"
                                      fill="black"
                                    />
                                  </svg>
                                </button>
                              </div>
                              <ReactTooltip
                                id="map-download-button"
                                place="left"
                                content={t("_Download_Map")}
                              />
                              <ReactTooltip
                                id="map-upload-button"
                                place="left"
                                content={t("_Share_Map")}
                              />
                            </div>
                          </div>

                          <div className="line_chart_main dashboard">
                            {/* <Bar options={options} data={data} /> */}

                            <div className="map-inner">
                              <div
                                className="google-map-code my-user-map"
                                ref={mapContainerRef}
                                id="map-container"
                              >
                                <MyGoogleMap 
                                  center={{
                                    lat: 22.7527421,
                                    lng: 75.88371599999999,
                                  }}
                                  // zoom={200}
                                  mapName="user-map-map user-map-data-new-info"
                                  places={mapData}
                                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
                                  loadingElement={
                                    <div style={{ height: `300%` }} />
                                  }
                                  containerElement={
                                    <div style={{ height: `600px` }} />
                                  }
                                  mapElement={
                                    <div style={{ height: `100%` }} />
                                  }
                                  onMapMounted={(map) => handleMapMounted(map)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <RecordNotFound />
                )}
              </>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default MyMaps;
