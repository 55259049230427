import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";

const Partner = () => {
  const homeData = useSelector((state) => state.home);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    if (homeData) {
      setPartners(homeData.partners);
    }
  }, [homeData, partners]);

  const experience = {
    loop: true,
    margin: 0,
    nav: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 7,
      },
      1200: {
        items: 8,
      },
    },
  };

  return (
    <div className="our_partner">
      <div className="container">
        {homeData !== undefined && homeData != "" && (
          <div className="partner_title">
            <h2>{homeData.title.our_partners_title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: homeData.title.our_partners_desc,
              }}
            ></div>
          </div>
        )}

        <div className="partner_slider">
          <div className="img_list_partner">
            <OwlCarousel
              key={`carousel_${Date.now()}`}
              {...experience}
              className="owl-carousel owl-theme slider_main"
            >
              {partners?.length > 0 &&
                partners.map(function (value, index) {
                  return (
                    <div className="Partner_image" key={index}>
                      <img src={value.image} alt="" />
                    </div>
                  );
                })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
