import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cancelOrderReason } from "../../actions/orderDetailAction";
import FsLightbox from "fslightbox-react";
import { images } from "../../actions/customFn";

const CancelReasonModel = (props) => {
  const { showModal, cancelReasonQuery, setShowCancelReasonModel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const [data, setData] = useState("");
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    if (languageReducer) {
      dispatch(
        cancelOrderReason(cancelReasonQuery, languageReducer, navigate, setData)
      );
    }
  }, []);

  return (
    <div className="modal_main">
      {data && (
        <Modal
          className="gift_popup review_modal_"
          show={showModal}
          onHide={() => setShowCancelReasonModel(false)}
        >
          <div className="modal-body">
            <div className="btn-close_main">
              <a
                type="button"
                onClick={() => setShowCancelReasonModel(false)}
                className="btn-close"
              >
                {" "}
                <svg
                  viewport="0 0 12 12"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="1"
                    y1="11"
                    x2="11"
                    y2="1"
                    stroke="#fff"
                    strokeWidth="2"
                  />
                  <line
                    x1="1"
                    y1="1"
                    x2="11"
                    y2="11"
                    stroke="#fff"
                    strokeWidth="2"
                  />
                </svg>
              </a>
            </div>
            <div className="gift_modal_pop cancell-reason-model">
              <div className="gift_pop_form">
                <h2>{t("_Reason_for_order_cancelled")}</h2>
                {/* <h2>{t("_write_a_review")}</h2> */}
                <div className="row gift_all_frm">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 gift_input_all mt-4">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <span className="order-cancel-reason-title">
                          {t("_Cancel_Date")}
                        </span>
                        <p>{data.date}</p>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="cancelled-by">
                          <span className="order-cancel-reason-title">
                            {t("_Cancelled_by")}
                          </span>
                          <p>{data.cancel_by}</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="order-cancel-reason-title">
                        {t("_Reason")}
                      </span>{" "}
                      <p>{data.reason}</p>
                    </div>
                    <div>
                      <span className="order-cancel-reason-title">
                        {t("_description")}
                      </span>{" "}
                      <p>{data.description}</p>
                    </div>
                    {data.proof && (
                      <div className="proof-cancel-reason">
                        <span>{t("_Proof")}:</span>{" "}
                        {data.proof && data.proof.split(".").pop() == "pdf" ? (
                          <img
                            src={images["PDF-image.png"]}
                            alt=""
                            onClick={() => window.open(data.proof, "_blank")}
                          />
                        ) : (
                          <img
                            src={data.proof}
                            alt=""
                            className="cancel-proof"
                            onClick={() => {
                              setIsShow((state) => !state);
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div className="fslight_box_main">
        <FsLightbox
          toggler={isShow}
          sources={[data.proof]}
          // sourceIndex={0}
        />
      </div>
    </div>
  );
};

export default CancelReasonModel;
