import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Footer,
  HeaderSticky,
  OurJourneyText,
  TextData,
  TextMid,
} from "../components";
import { images } from "../actions/customFn";
import { asyncGetNewAboutusPageDetails } from "../actions/pageAction";
import { useSelector } from "react-redux";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
const AboutUsPage = () => {
  const [newAboutusData,setAboutusData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const languageReducer = useSelector((state) => state.languageReducer);

    // UseEffect to get AboutUsPage data on component mount or when dependencies change
    useEffect(() => {
      if(languageReducer){
      asyncGetNewAboutusPageDetails(
        setAboutusData,
        setIsLoading,
        languageReducer
      );}
    }, [languageReducer]);
  
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 3 }}
      >
        <HeaderSticky />
        <TextData newAboutusData={newAboutusData} isLoading={isLoading}/>
        {
          isLoading ? 
          <ShimmerSimpleGallery col="1" row="1" imageHeight={500} /> : (
            <div className="banner-new-image-mid">
            <img src={newAboutusData?.about_us_new_image ? newAboutusData?.about_us_new_image : images["banner-new-image.png"]} alt="banner image" />
          </div>
            )
        }
        <TextMid textMidData={newAboutusData} isLoading={isLoading} />
        <OurJourneyText ourJourneyData={newAboutusData} isLoading={isLoading}/>
        <Footer />
      </motion.div>
    </>
  );
};

export default AboutUsPage;
