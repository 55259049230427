const initialProductState = "";

const recomededTourReducer = (state = initialProductState, action) => {
    switch (action.type) {
        case "SET_RECOMENDED_TOUR_DATA":
            return action.payload;
        default:
            return state;
    }
};

export default recomededTourReducer;
