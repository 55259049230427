import React from "react";
import Loading from "react-loading-components";
import PropagateLoader from "react-spinners/PropagateLoader";

const LoadingBar = () => {
  return (
    <div className="loading_main">
      {/* <Loading type="circles" width={100} height={300} fill="#f44242" /> */}

      <PropagateLoader color="#FC5301" />
    </div>
  );
};

export default LoadingBar;
