import * as React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@material-ui/core";


import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';


export default function PaymentSubCategory(props) {
    const {helptabsdata} = props;

    const [expandedsupp, setExpandedSupp] = React.useState(false);

    const handleExpandSupp = (panel) => (event, isExpanded) => {
        setExpandedSupp(isExpanded ? panel : false);
    };

  return (
    <>     
    {
        helptabsdata != undefined &&  
        helptabsdata != '' ?
  <div className='sub-categories help_category_topaccordian'>
      
        { 
            helptabsdata?.map((value,index)=>{
return(
    <Accordion className='sub-cat-item'>
    <AccordionSummary expandIcon={<KeyboardDoubleArrowDownOutlinedIcon />} className='sub-cat-item-title'>{value.title}</AccordionSummary>
        <AccordionDetails className='que-ans-sec'>
        { value.data != undefined && value.data != '' && value.data?.map((v, i) => {
                    return(
                        <div>  
                        <Accordion expanded={expandedsupp === `supp${i}${index}`} onChange={handleExpandSupp(`supp${i}${index}`)} >
                        <AccordionSummary expandIcon={expandedsupp === `supp${i}${index}` ? <HorizontalRuleOutlinedIcon/> : <KeyboardDoubleArrowRightOutlinedIcon/>} className="question-title">
                        {v.question}
                        </AccordionSummary>
                        <AccordionDetails className="answer">
                          <p   dangerouslySetInnerHTML={{
                      __html: v.answer,
                    }} >
                           {/* {v.answer} */}
                          </p>
                        </AccordionDetails>
                      </Accordion>
             
                </div>
                    )
                })}
          
           
        </AccordionDetails>
    </Accordion>
)
            })
        }
       

      
        </div>  : <h4 className="record_noth6" >No Data Found</h4>
        }
     </>
  );
}
