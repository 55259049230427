import React from "react";
import {
  AffiliateMoney,
  AffiliateSidebar,
  AffiliateTransactionHistory,
  CommissionHistory,
  HeaderSticky,
} from "../components";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import AffiliateProfile from "../components/AffiliateUser/AffiliateProfile";
import AfiiliatePayment from "../components/AffiliateUser/AfiiliatePayment";
import AffiliateReviews from "../components/AffiliateUser/AffiliateReviews";
import AffiliateWishlist from "../components/AffiliateUser/AffiliateWhishlist";
import AffiliateBookingHistory from "../components/AffiliateUser/AffiliateBookingHistory";
import AffiliateDashboard from "../components/AffiliateUser/AffiliateDashboard";
import { useSelector } from "react-redux";
import CouponList from "../components/AffiliateUser/CouponList";
import Analytics from "../components/ProviderAccount/Analytics";
import MyMaps from "../components/UserProfile/MyMap";
const AffiliateUser = () => {
  const location = useLocation();
  const profileReducer = useSelector((state) => state.profileReducer);
  const navigate = useNavigate();

  return (
    <>
      {profileReducer && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3 }}
        >
          <HeaderSticky />
          <div className="userprofie_main">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                  <AffiliateSidebar />
                </div>
                <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
                  {
                    location.pathname === "/affiliate-dashboard" ? (
                      <AffiliateDashboard />
                    ) : location.pathname === "/affiliate-map" ? (
                      <MyMaps />
                    ) : location.pathname === "/affiliate-payment" ? (
                      <AfiiliatePayment />
                    ) : location.pathname === "/affiliate-user" ? (
                      <AffiliateProfile />
                    ) :
                      profileReducer.is_paypal_account != undefined && profileReducer.is_paypal_account != '' ? (
                        location.pathname === "/affiliate-money" ? (
                          <AffiliateMoney />
                        ) : location.pathname === "/affiliate-reviews" ? (
                          <AffiliateReviews />
                        ) : location.pathname === "/commission-history" ? (
                          <CommissionHistory />
                        ) : location.pathname === "/affiliate-booking-history" ? (
                          <AffiliateBookingHistory />
                        ) : location.pathname === "/affiliate-wishlist" ? (
                          <AffiliateWishlist />
                        ) : location.pathname === "/affiliate-transaction-history" ? (
                          <AffiliateTransactionHistory />
                        ) : location.pathname === "/coupon-list" ? (
                          <CouponList />
                        ) : location.pathname === "/affiliate-analytics" ? (
                          <Analytics />
                        ) : (
                          <AffiliateDashboard />
                        )
                      ) :
                        <AffiliateDashboard />
                  }
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default AffiliateUser;
