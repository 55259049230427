import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { PuffLoader } from "react-spinners";
import validator from "validator";
import { sendMail } from "../../actions/senaMailForSupplierDetails";

const CustomerSendEmailModal = (props) => {
  
  const { sendEmailModal, SetSendEmailModal } = props;
  const { slug } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  //   useEffect(() => {
  //     if (languageReducer) {
  //       dispatch(
  //         cancelOrderReason(cancelReasonQuery, languageReducer, navigate, setData)
  //       );
  //     }
  //   }, []);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [sendForm, setSendForm] = useState({
    name: "",
    email: "",
    message: "",
    slug:slug
  });
  const [sendFormErrors, setsendFormErrors] = useState({});
  const send_errors = {};

  const sendmail_validation = () => {
    
    if (sendForm.name?.length === 0) {
      send_errors.name = t("_enter_name");
    }

    if (
      !validator.isEmail(sendForm.email) ||
      sendForm.email?.length === 0
    ) {
      send_errors.email = t("_enter_valid_email_id");
    }

    if (sendForm.message?.length === 0) {
      send_errors.message = t("_enter_message");
    }

    setsendFormErrors(send_errors);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSendForm({ ...sendForm, [name]: value });

    // setsendFormErrors(send_errors);
  };

  const handleBecomeBuilder = (e) => {
    // e.preventDefault();
    sendmail_validation();

    if (Object.keys(send_errors).length === 0) {
      setButtonDisable(true);
      let formdata = sendForm;
      
      dispatch(
        sendMail(
          languageReducer,
          navigate,
          formdata,
          setButtonDisable,
          resetBuilderForm
        )
      );
    }
  };

  const resetBuilderForm = () => {
    setSendForm({
      name: "",
      email: "",
      message: "",
      slug:slug
    });
  };

  return (
    <div className="login-signup-model">
      <Modal
        className="login_popup"
        show={sendEmailModal}
        onHide={() => {
          //   props.LogincloseModal();
          //   setFormErrors({});
          //   resetSignupForm();
          //   setCountryFlag("")
          setsendFormErrors({});
          SetSendEmailModal(false);
        }}
      >
        <div className="login_model_main">
          <button
            className="btn_close"
            onClick={() => {
              //   setFormErrors({});
              //   resetSignupForm();
              //   setCountryFlag("")
              setsendFormErrors({});
              SetSendEmailModal(false);
            }}
          >
            {" "}
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className="login_inner_modal">
            <div className={`login_section new_login_main `}>
              <div className="login_heading">
                <h2>{t("_send_email_for_supplier_detalils")}</h2>
              </div>
              <form action="">
                <div className="row signup_all_form">
                  <>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="input_all_login">
                        <label>{t("_name")}</label>
                        <input
                          type="text"
                          className={
                            `form-control  ` +
                            (sendFormErrors.name ? "is-invalid" : "")
                          }
                          onChange={handleInputChange}
                          value={sendForm.name}
                          name="name"
                          placeholder={t("_enter_name")}
                        />
                        {sendFormErrors.name && (
                          <div className="invalid-feedback">
                            {sendFormErrors.name}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="input_all_login">
                        <label>{t("_email")}</label>
                        <input
                          type="text"
                          className={
                            `form-control  ` +
                            (sendFormErrors.email ? "is-invalid" : "")
                          }
                          onChange={handleInputChange}
                          value={sendForm.company_email}
                          name="email"
                          placeholder={t("_enter_email")}
                        />
                        {sendFormErrors.email && (
                          <div className="invalid-feedback">
                            {sendFormErrors.email}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="input_all_login">
                        <label>{t("_message")}</label>
                        <textarea
                          onChange={handleInputChange}
                          value={sendForm.message}
                          name="message"
                          className="form-control"
                          placeholder={t("_enter_message")}
                          rows=""
                          cols=""
                        >
                          {sendForm?.message}
                        </textarea>

                        {sendFormErrors.message && (
                          <div className="invalid-feedback">
                            {sendFormErrors.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                </div>
              </form>

              <div className="signup_button">
                <button
                  onClick={() => handleBecomeBuilder()}
                  disabled={buttonDisable}
                >
                  {buttonDisable ? (
                    <div className="order-cancelling-loader-btn">
                      <PuffLoader size={30} color="#fff" />
                    </div>
                  ) : (
                    t("_Become_a_Supplier")
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* <ForgotPasswordModal
        forgotOpen={forgotOpen}
        setForgotOpen={setForgotOpen}
        closeForgotPasswordModel={closeForgotPasswordModel}
      /> */}
    </div>
  );
};

export default CustomerSendEmailModal;
