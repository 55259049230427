import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/fontawesome-free-solid";
import "animate.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const [faq, setFaq] = useState([]);
  const homeData = useSelector((state) => state.home);

  useEffect(() => {
    if (homeData) {
      setFaq(homeData.faq);
    }
  }, [homeData, faq]);

  const [selectedItem, setSelectedItem] = useState(0);
  const handleClick = (id) => {
    if (selectedItem == id) {
      setSelectedItem(0);
    } else {
      setSelectedItem(id);
    }
  };


  return (
    <div className="Faq_main">
      <div className="container">
        {
          faq.length > 0 &&
          <>
            <div className="faq_heading">
              <h2 className="heading_sub">{t('_booking')}</h2>
            </div>
            <div className="faq_inner">
              <div className="row">
                {faq.map(function (value, index) {
                  index = index + 1;
                  return (
                    <div
                      className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                      id={index}
                      key={index}
                    >
                      <Accordion
                        defaultActiveKey={index}
                        activeKey={selectedItem}
                        onClick={() => {
                          handleClick(index);
                        }}
                        key={index}
                      >
                        <Accordion.Item eventKey={index}>
                          <Accordion.Header className="cst_acc_header">
                            {value.title}
                            <span className="icon_open icon_faq">
                              {" "}
                              <FontAwesomeIcon icon={faPlus} />{" "}
                            </span>
                            <span className="icon_close icon_faq">
                              {" "}
                              <FontAwesomeIcon icon={faMinus} />{" "}
                            </span>
                          </Accordion.Header>
                          <Accordion.Body className="anmiate_text animate__animated animate__fadeIn">
                            <div className="acc_inner_body  animate__animated animate__fadeIn">
                              <div className="acc_main_content">
                                <p className=""
                                  dangerouslySetInnerHTML={{ __html: value.description }}
                                >
                                </p>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default Faq;