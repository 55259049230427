import React from "react";
import { motion } from "framer-motion";
import { HeaderSticky } from "../components";
import { images } from "../actions/customFn";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RecordNotFound = () => {
const { t } = useTranslation();

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 3 }}
      >
        <div className="div-404-new">
          <div className="container">
            <div className="images-page-unknow">
              <img src={images["empty3.jpg"]} alt="" />
            </div>

            <div className="page-4content">
              <h2>{t("_record_not_found")}</h2>
              {/* <p>The page you’re looking for does not seem too exist</p> */}

              {/* <NavLink to="/">Go To Home</NavLink> */}
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default RecordNotFound;
