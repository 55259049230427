import React, { useState, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { asyncAddMoreHelpData } from "../../actions/helpCenterAction";
import { FormHelperText } from "@mui/material";
import { successMsg } from "../../actions/customFn";
import { useDispatch, useSelector } from "react-redux";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
export default function MoreHelpSec(props) {
  const inputFile = useRef(null);
  const profileReducer = useSelector((state) => state.profileReducer);
  const { moreList, moreHelpId } = props;
  const [isLoading, setIsLoading] = useState(false);

  // Form Data Fields
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    supplier_id: "",
    help_message: "",
    help_question_topic: "",
    image: null,
  });
  const [formErrors, setFormErrors] = useState({});
  // Get Name Value
  const handleChange = (event) => {
    const { name, value, type } = event.target;
    // Upload image
    if (type === "file") {
      const file = event.target.files[0];

      if (file) {
        setFormData((prev) => ({
          ...prev,
          image: file,
        }));
      }
    } else {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Submit the More Help Data
  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (moreHelpId == 13 && !formData.help_question_topic) {
      errors.help_question_topic = "Please select a topic";
    }
    if (!formData.help_message) {
      errors.help_message = "Help Message is required";
    }
    // Set errors in the state
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);

      asyncAddMoreHelpData(formData, moreHelpId)
        .then((response) => {
          if (response?.data?.status) {
            successMsg(response?.data?.message);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });

      // Clear form fields after successful submission
      setFormData({
        name: "",
        email: "",
        supplier_id: "",
        help_message: "",
        help_question_topic: "",
        image: null,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {moreHelpId == 13 && (
        <div className="morehelp_topselector">
          <h4>What's your question about?</h4>
          <Select
            labelId="demo-simple-select-label"
            className="new_sort_list"
            id="demo-simple-select"
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
            }}
            label="select"
            placeholder="Please Select"
            name="help_question_topic"
            value={formData.help_question_topic}
            onChange={handleChange}
          >
            {moreList?.map((item, index) => (
              <MenuItem key={index} value={item?.title}>
                {item?.title}
              </MenuItem>
            ))}
          </Select>
          {formErrors.help_question_topic && (
            <FormHelperText className="form_error">
              {formErrors.help_question_topic}
            </FormHelperText>
          )}
        </div>
      )}
      {moreHelpId == 27 && (
        <div className="morehelp_topselector">
          <h4>Enter your booking reference for personalized help.</h4>
        </div>
      )}

      {(formData?.help_question_topic !== "" || moreHelpId == 27) && (
        <div className="morehelp_inputsection">
          <div className="inputto_divflex">
            <div className="input-error">
              <div className="inner-input">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>

              <div className="errors-main">
                {formErrors.name && (
                  <FormHelperText className="form_error">
                    {formErrors.name}
                  </FormHelperText>
                )}
              </div>
            </div>
            <div className="input-error">
              <div className="inner-input">
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div className="errors-main">
                {formErrors.email && (
                  <FormHelperText className="form_error">
                    {formErrors.email}
                  </FormHelperText>
                )}
              </div>
            </div>
            {moreHelpId === 13 && (
              <div className="input-error">
                <div className="inner-input">
                  <input
                    type="text"
                    placeholder="Supplier ID (optional)"
                    name="supplier_id"
                    value={
                      profileReducer?.user_type === "supplier"
                        ? profileReducer?.profile_type_id
                        : formData?.supplier_id
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
            {moreHelpId === 27 && (
              <div className="input-error">
                <div className="inner-input">
                  <input
                    type="text"
                    placeholder="Booking reference (optional)"
                    name="supplier_id"
                    value={
                      profileReducer?.user_type === "customer"
                        ? profileReducer?.profile_type_id
                        : formData?.supplier_id
                    }
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
          </div>
          <textarea
            rows={5}
            placeholder="How can I help you?"
            name="help_message"
            value={formData.help_message}
            onChange={handleChange}
          />
          {formErrors.help_message && (
            <FormHelperText className="form_error">
              {formErrors.help_message}
            </FormHelperText>
          )}
          {formData?.image && (
            <div className="uploaded_imagessection">
              <div className="selected_images">
                <img
                  className="main_image"
                  src={URL.createObjectURL(formData?.image)}
                  alt=""
                />
                <button
                  className="imgcross_btn"
                  onClick={() =>
                    setFormData((prev) => ({ ...prev, image: null }))
                  }
                >
                  <HighlightOffIcon />
                </button>
              </div>
            </div>
          )}
          <div className="send_helpbutton">
            {" "}
            <div className="upload_imagepart">
              <button
                type="button"
                className="choosepic_btn"
                onClick={() => inputFile.current.click()}
              >
                <AttachFileOutlinedIcon /> Attach file
              </button>

              <input
                type="file"
                name="image"
                accept="image/*"
                className="d-none"
                onChange={handleChange}
                ref={inputFile}
              />
            </div>
            <button type="submit">
              <SendOutlinedIcon /> Send
            </button>
          </div>
        </div>
      )}
    </form>
  );
}
