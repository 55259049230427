import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg, successMsg } from "./customFn";
import { profileData } from "./profileAction";

//   ===================API call for affiliate starts=================
export const getAffiliateData = (
  languageReducer,
  setAffiliateData,
  navigate,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const params = {
        language: languageReducer.id,
        currency: currency.id,
      };

      const response = await axios.post("affiliate", params);
      if (response.data.status === true) {
        setAffiliateData(response.data.data);
        setTimeout(function () {
          setIsLoading(true);
        }, 1000);
      } else {
        setTimeout(function () {
          setIsLoading(true);
        }, 1000);
        // errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        alert(`${error.message}-${error.response.status}`);
      }
    }
    // setTimeout(function () {
    //   setIsLoading(true);
    // }, 1000);
  };
};
// ===================API call for affiliate ends===================

export const getCommisionHistory = (
  languageReducer,
  setCoupanList,
  navigate,
  setIsLoading,
  setRecordStatus,
  setPageCount,
  currentPage,
  affilliate_code,
  filterData,
  isFilter
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        offset: currentPage,
        affilliate_code: affilliate_code,
        is_filter: isFilter,
        ...filterData
      };

      const response = await axios.post("affiliate-commission-list", params);
      if (response.data.status === true) {
        setCoupanList(response.data.data);
        setPageCount(response.data.page_count);
        setRecordStatus(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        setCoupanList([])
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setRecordStatus(false);
        // errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};

export const getCommissionDetails = (
  languageReducer,
  setOrderDetails,
  navigate,
  setIsLoading,
  id
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        order_id: id,
      };

      const response = await axios.post("affiliate-commission-details", params);
      if (response.data.status === true) {
        setOrderDetails(response.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);

      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 1000);
  };
};

// ===================Get Wishlist =================
export const getMakeMoneyData = (
  setMakeMoneyData,
  languageReducer,
  navigate,
  location,
  setIsLoading
) => {
  const currency = JSON.parse(localStorage.getItem("currency"));
  const formData = {
    user_id: localStorage.getItem("userId"),
    password: localStorage.getItem("password"),
    currency: currency.id,
    language: languageReducer.id
  };

  return (dispatch) => {
    const url = "/make-money";
    axios
      .post(url, formData)
      .then((response) => {
        const data = response.data;
        if (data.status) {
          setMakeMoneyData(data.data)
          setTimeout(function () {
            setIsLoading(false);
          }, 1000);
        } else {
          setTimeout(function () {
            setIsLoading(false);
          }, 1000);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          //   dispatch(asyncGetLogout());
          errorMsg("Somthing went wrong...");
        } else {
          navigate("/something-went-wrong");
          window.history.pushState(null, "", location.pathname);
        }
      });

    // setTimeout(function () {
    //   setIsLoading(false);
    // }, 1000);
  };
};
// ==================Get Wishlist===================

export const setAffiliatePaypalId = (
  languageReducer,
  payPalId,
  navigate,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        paypal_id: payPalId,
        user_type: "Affiliate"
      };

      const response = await axios.post("update-paypal", params);
      if (response.data.status === true) {
        successMsg(response.data.message);
        dispatch(profileData(languageReducer, navigate));
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        errorMsg(response.data.message);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
};


export const getAffiliateDashboardData = (
  languageReducer,
  setDashBoardData,
  navigate,
  setIsLoading,
  lastMonth,
  filter_month
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        last_month: lastMonth,
        filter_month: filter_month
      };

      const response = await axios.post("affilliate-dashboard", params);
      if (response.data.status === true) {
        setDashBoardData(response.data.data);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        errorMsg("Somthing went wrong...");
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }

  };
};
// ===================API order Details ends===================


//   ===================Coupan list starts=================
export const getCouponList = (
  languageReducer,
  setCoupanList,
  navigate,
  setIsLoading,
  setRecordStatus,
  setPageCount,
  currentPage,
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        offset: currentPage,
      };

      const response = await axios.post("affilliate_coupon", params);
      if (response.data.status === true) {
        setCoupanList(response.data.data);
        setPageCount(response.data.page_count);
        setRecordStatus(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } else {
        setCoupanList([])
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        setRecordStatus(false);
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }

  };
};

// ===================Coupan List ends===================

//   ===================Coupan Update starts=================
export const updateCouponList = (
  data,
  languageReducer,
  navigate,
  setIsUpdate,
  setOpenId
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...data
      };

      const response = await axios.post("affilliate_coupon_update", params);
      if (response.data.status === true) {
        successMsg(response.data.message);
        setIsUpdate((state) => !state);
        setOpenId("")
      } else {
        errorMsg(response.data.message)
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }

  };
};
// ===================Coupan Update ends===================