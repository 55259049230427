import { useEffect, useMemo, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { checkProduct } from "../../actions/cartAction";
import { useNavigate } from "react-router-dom";

const CheckoutForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const generateStripeToken = props.generateStripeToken;
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async (event) => {
    event.preventDefault();
    props.validateFormData();
    if (Object.keys(props.errors).length === 0) {
      // props.setPaymentLoader(true);
      const response = await checkProduct(languageReducer, navigate, dispatch);   //To check product availibilty to book
      if (response.data.status == true) {
        if (!stripe || !elements) {
          return;
        }
        const { token } = await stripe.createToken(
          elements.getElement(CardElement)
        );
        if (token) {
          generateStripeToken(token.id);
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="CardInputWrapper">
        <CardElement
          options={options}
        />
      </div>

      <button
        className="make_odr_btn"
        type="submit"
        disabled={!stripe || !elements}
      >
        {t("_make_payment")}
      </button>
    </form>
  );
};

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          // fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",

          },
          fontWeight: 300,
          fontFamily: 'Helvetica Neue',
          fontSize: '18px',
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

export function useResponsiveFontSize() {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
}

export default CheckoutForm;
