import { errorMsg, successMsg } from "./customFn";
import { getLogout } from "./loginAction";
import axios from "../axios";

export const addReview = (
    languageReducer,
    reviewQuery,
    reviewDetail,
    resetForm,
    setisReview,
    setReview,
    closeModal,
    setButtonDisable,
    review,
    navigate) => {
    return async (dispatch) => {
        try {
            const currency = JSON.parse(localStorage.getItem("currency"));
            const newForm = new FormData();
            newForm.append("user_id", localStorage.getItem("userId"));
            newForm.append("password", localStorage.getItem("password"));
            newForm.append("language", languageReducer.id);
            newForm.append("currency", currency.id);
            newForm.append("token", localStorage.getItem("_token"));

            newForm.append("order_id", reviewQuery.order_id);
            newForm.append("product_id", reviewQuery.product_id);
            newForm.append("option_id", reviewQuery.option_id);
            newForm.append("order_index", reviewQuery.order_index);

            newForm.append("title", reviewDetail.title);
            newForm.append("description", reviewDetail.description);
            newForm.append("rating", reviewDetail.rating);
            if (reviewDetail.files.length > 0) {
                for (let i = 0; i < reviewDetail.files.length; i++) {
                    newForm.append("files[]", reviewDetail.files[i].image);
                }
            }

            const response = await axios.post("/add-review", newForm);
            if (response.data.status === true) {
                successMsg(response.data.message);
                resetForm();
                setisReview((prev => ([...prev, reviewQuery.order_index])));
                setReview({ ...review, [reviewQuery.order_index]: reviewDetail });
                closeModal();
            } else {
                errorMsg(response.data.message);
            }
            setButtonDisable(false);
        } catch (error) {
            if (error.response.status === 401) {
                dispatch(getLogout(navigate));
                errorMsg("Somthing went wrong...");
            } else {
                errorMsg(error.response.data.message);
            }
        }
    };
};