import { useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import moment from "moment";

const WeatherInfo = (props) => {
  const { t } = useTranslation();
  const experience = {
    loop: true,
    margin: 0,
    nav: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 4,
      },

      900: {
        items: 5,
      },
      1000: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };

  const [weatherInfo, setWeatherInfo] = useState('');

  useEffect(() => {
    setWeatherInfo(props.WeatherInfo)
  }, []);


  return (
    <>
      {
        weatherInfo &&
        <div
          className={`WeatherInfo_main ${weatherInfo.current.is_day == 0 ? "weather-night" : "weather-day"}`}
        >
          <div className="city_name">
            <h2>
              {weatherInfo.location.name} {" "}
              <br />
              <small>
                {moment(weatherInfo.location.localtime).format('ddd , YYYY-MMM-DD , HH:mm')}
                {/* {new Date(
                  weatherInfo.location.localtime
                ).toLocaleString(undefined, {
                  weekday: "long",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })} */}
              </small> </h2>
          </div>

          <div className="cloud-info">
            <div className="temperature_info">
              <h2>
                {" "}
                {weatherInfo.current.temp_c + "°"}C
                <small>
                  {t("_feels_like")}{" "}
                  {weatherInfo.current.feelslike_c + "°"}
                  C
                </small>
              </h2>
            </div>

            {/* <div className="cloud_title">
              <p>
                {t("_feels_like")}{" "}
                {weatherInfo.current.feelslike_c + "°"}
                C
              </p>
            </div> */}
            <div className="cloud_img">
              {/* <img src={images["cloudy-1.png"]} alt="" /> */}
              <img src={weatherInfo.current.condition.icon} alt="" />
              <h2>
                {weatherInfo.current.condition.text}
              </h2>
            </div>
          </div>

          <div className="row weather-temp">
            <div className="col-xl-6 col-lg-6 col-sm-6 col-6">
              <p>
                H : &nbsp;
                {
                  weatherInfo
                    .forecast.forecastday[0].day
                    .maxtemp_c
                }
                °C &nbsp;
              </p>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6 col-6 text-right">
              <p>
                {t("_Sunrise")} : &nbsp;
                {
                  weatherInfo
                    .forecast.forecastday[0].astro
                    .sunrise
                }
              </p>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6 col-6">
              <p>
                L : &nbsp;
                {
                  weatherInfo
                    .forecast.forecastday[0].day
                    .mintemp_c
                }
                °C &nbsp;
              </p>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-6 col-6 text-right">
              <p>
                {t("Sunset")} : &nbsp;
                {
                  weatherInfo
                    .forecast.forecastday[0].astro
                    .sunset
                }
              </p>
            </div>
          </div>

          <div className="WeatherAir_info">
            <div className="air_one">
              <img src={images["cloudy-air.png"]} alt="" />
              <span>  {
                weatherInfo.current.wind_mph
              }
                &nbsp; m/sm/s</span>
            </div>

            <div className="drop_one">
              <img src={images["cloudy-drop.png"]} alt="" />
              <span>{
                weatherInfo
                  .current.humidity
              }
                %%</span>
            </div>

            <div className="speed_one">
              <img src={images["cloudy-speed.png"]} alt="" />
              <span> {
                weatherInfo
                  .current.pressure_mb
              } mb</span>
            </div>
          </div>

          <div className="Weather_slider">
            <OwlCarousel
              {...experience}
              className="owl-carousel owl-theme slider_main"
            >
              {weatherInfo.forecast.forecastday[0].hour.map(function (value, index) {
                return (
                  <div className="item" key={index}>
                    <div className="time_info">
                      <span>{value.time}</span>
                    </div>
                    <div className="slider_img">
                      <img src={value.condition.icon} alt="" />
                    </div>
                    <div className="slider_temperature">
                      <h2>
                        {value.temp_c} <small>°</small>C
                      </h2>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      }
    </>
  );
};

export default WeatherInfo;
