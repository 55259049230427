import React from 'react'
import { images } from "../../actions/customFn";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import 'swiper/css/pagination';
import { Navigation,FreeMode } from 'swiper/modules';

const GuideBanner = () => {

  const slider_image = [
    {
        title: "Hong Kong",
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
        content2: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.  to make a type specimen book. It has survived not only five.",
        image_list: [
          images['guidedetails_slider.png'],
          images['guidedetails_slider.png'],
          images['guidedetails_slider.png'],
          images['guidedetails_slider.png'],
          images['guidedetails_slider.png'],
          images['guidedetails_slider.png'],
          images['guidedetails_slider.png'],
          images['guidedetails_slider.png'],
          images['guidedetails_slider.png'],
          images['guidedetails_slider.png'], 
        ]
    },
]

  return (
   
    <div>
    <div>
      <div className="all_event_gallery">
        <div className="container">
          <div className="row event_row align-items-center">
            {slider_image.map(function (value, index) {
              return (
                <>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="all_event_title">
                      <h2> {value.title}</h2>
                      <p>{value.content}</p>
                      <p>{value.content2}</p>
                      <button>Discover Hong kong Experience</button>
                    </div>
                  </div>

                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 image_event_box">
                    <Swiper
                      slidesPerView={5}
                      spaceBetween={25}
                      loop={true}
                      freeMode={true}
                      pagination={{
                        clickable: true,
                      }}
                      autoplaySpeed= {5000}
                      navigation={true}
                      modules={[Navigation]}
                      className="All_event_slider"
                    >
                      {value.image_list.map(function (item, index) {
                        return (
                          <div className="slider_inner_new" key={index}>
                            <SwiperSlide>
                              <div
                                className="event_img"
                                style={{
                                  backgroundImage: `url(${item})`,
                                }}
                              >
                                {/* {/ <h2>{index}</h2> /} */}
                              </div>
                            </SwiperSlide>
                          </div>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  </div>

  );
}

export default GuideBanner