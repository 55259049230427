import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/fontawesome-free-solid";
import { Footer, HeaderSticky, LoadingBar, LoginModal } from "../components";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  checkProduct,
  getCartData,
  removeCartItem,
  reservationTimeEndsAPI,
} from "../actions/cartAction";
import RecordNotFound from "./RecordNotFound";
import LocaleContext from "../components/LocaleContext";
import { useContext } from "react";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";
import { CountdownTimer, MetaData, images } from "../actions/customFn";
import moment from "moment";

const Cart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const profileReducer = useSelector((state) => state.profileReducer);
  const homeData = useSelector((state) => state.home);
  // const [isLoading, setIsLoading] = useState(false);
  const [cartData, setCartData] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [cartTaxFee, setcartTaxFee] = useState([]);
  const [recordStatus, setRecordStatus] = useState(true);
  const [isRemoved, setIsRemoved] = useState(false);
  const checklogin = useSelector((state) => state.login);
  const [loginKey, SetLoginKey] = useState("");
  const [ModalLogin, setModalLogin] = useState(false);
  const { cartLoader, setCartLoader } = useContext(LocaleContext);
  // const [metaData, setMetaData] = useState("")
  const [alertMessage, setAlertMessage] = useState("");
  const [apiCalled, setApiCalled] = useState(false);
  const [toggle, setToggle] = useState(false);
  const reactRatings = {
    edit: false,
    activeColor: "rgb(252 83 1)",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  let metaData = {
    title: t("_cart"),
  };

  useEffect(() => {
    setCartLoader(true);
    if (profileReducer && profileReducer.user_type == "Hotel") {
      navigate("/");
      return;
    }

    if (languageReducer != "" && !apiCalled) {
      dispatch(
        getCartData(
          languageReducer,
          setCartData,
          navigate,
          setRecordStatus,
          setCartLoader
        )
      );
    }
  }, [isRemoved, checklogin, toggle, languageReducer]);

  useEffect(() => {
    // Reset apiCalled when any of the dependencies change
    setApiCalled(false);
  }, [languageReducer, isRemoved, checklogin, profileReducer]);

  useEffect(() => {
    // setCartLoader(true);
    if (languageReducer !== "" && checklogin == true) {
      checkProductList();
    }
  }, [languageReducer, dispatch, isRemoved, checklogin, profileReducer]);

  const checkProductList = async () => {
    const response = await checkProduct(languageReducer, navigate, dispatch);
    if (response.data.status == true) {
      setAlertMessage("");
    } else {
      if (response.data.is_product == 0) {
        setAlertMessage("");
      } else {
        setAlertMessage(response.data.message);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(cartData).length > 0) {
      setCartItems(cartData.detail);
      setcartTaxFee(cartData.cart_tax_fee);
    } else {
      setCartItems([]);
      setcartTaxFee([]);
    }
  }, [cartData]);

  const removeItem = (id) => {
    dispatch(
      removeCartItem(
        languageReducer,
        setCartData,
        navigate,
        setCartLoader,
        setRecordStatus,
        id,
        setIsRemoved
      )
    );
  };

  const closeLoginModal = () => {
    setModalLogin(false);
  };

  const reservationTimeEnds = (cart_id) => {
    dispatch(
      reservationTimeEndsAPI(cart_id, languageReducer, navigate, setToggle)
    );
  };

  return (
    <>
      {metaData && <MetaData data={metaData} />}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 3 }}
      >
        <HeaderSticky />
        <div className="page-cart-main">
          <div className="container">
            <div className="list_Breadcrumb">
              <ul>
                <li>
                  <NavLink to="/"> {t("_home")} / </NavLink>
                </li>
                <li> {t("_cart")}</li>
              </ul>
            </div>
            {alertMessage != "" && cartLoader === false && (
              <div className="header_error_cart_msg mb-2" role="alert">
                {/* <p>{location.state.alertMessage}</p> */}
                <p className="text-danger">{alertMessage}</p>
              </div>
            )}

            <div className="cart_main">
              {
                cartLoader === true ? (
                  <div className="blinking-bar-loader">
                    <LoadingBar />
                  </div>
                ) : cartItems.length > 0 ? (
                  <div className="row">
                    <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12">
                      {cartItems.map(function (value, index) {
                        return (
                          <div
                            className={`cart_product_list ${
                              value.is_booking_allow == false
                                ? "booking_not_allowed"
                                : ""
                            }`}
                            key={index}
                          >
                            {
                              <CountdownTimer
                                targetTime={value.reservation_end_time}
                                onTimerEnd={() => reservationTimeEnds(value.id)}
                              />
                            }
                            <div className="row">
                              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                <NavLink
                                  to={`/culture-details/${value.slug}`}
                                  target="_blank"
                                >
                                  <div
                                    className="product_img_cart"
                                    style={{
                                      backgroundImage: `url(${value.image})`,
                                    }}
                                  ></div>
                                </NavLink>
                              </div>

                              <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 pl-0">
                                {/* shivi 26-10 */}

                                <div className="row">
                                  <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                    <div className="cart_main_box">
                                      <div className="cart_prouct">
                                        <NavLink
                                          to={`/culture-details/${value.slug}`}
                                          target="_blank"
                                        >
                                          <h2>{value.title}</h2>
                                        </NavLink>

                                        <h6>{value.option_details.title}</h6>
                                      </div>
                                      <div className="cart_prd_location">
                                        <span className="icon_loc">
                                          {" "}
                                          <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                          />
                                          {value.location}
                                        </span>
                                        {value.provider != "" && (
                                          <p className="provider_title">
                                            {t("_activity_supplier")}:{" "}
                                            {value.provider_slug ? (
                                              <NavLink
                                                target="_blank"
                                                to={`/activity-supplier/${value.provider_slug}`}
                                              >
                                                {value.provider}
                                              </NavLink>
                                            ) : (
                                              value.provider
                                            )}
                                          </p>
                                        )}
                                        <div className="prd_cart_star">
                                          <ul>
                                            <li>
                                              <ReactStars
                                                style={{
                                                  cursor: "pointer !important",
                                                }}
                                                classNames="star-class"
                                                size={16}
                                                value={Number(value.ratings)}
                                                {...reactRatings}
                                              />
                                            </li>
                                            {/* <li>4</li> */}
                                          </ul>
                                          <NavLink
                                            to={`/culture-details/${value.slug}`}
                                            state={{ overReview: true }}
                                          >
                                            <p>
                                              ( {value.total_review}{" "}
                                              {t("_reviews")} )
                                            </p>
                                          </NavLink>
                                        </div>
                                        {value.likely_to_sell_out === "yes" && (
                                          <div className="span_tag">
                                            <span>
                                              {" "}
                                              {t("_likely_to_sell_out")}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="radt_price">
                                      <div className="radt_price_number">
                                        <p>
                                          {" "}
                                          <span className="picer_icon">
                                            {homeData?.currency}
                                          </span>{" "}
                                          <span className="formated-amount">
                                            {value.totalAmount}{" "}
                                            {/* {value.formatTotalAmount}{" "} */}
                                          </span>
                                        </p>
                                        {value.deal !== "" &&
                                        value.deal !== undefined ? (
                                          <p className="deal_details">
                                            {/* <span> */}
                                            <span className="original_price">
                                              {value.deal.formatoriginalPrice}
                                            </span>
                                            <span className="percentage">
                                              {" "}
                                              -{value.deal.percentage}%
                                            </span>
                                            {/* </span> */}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                        {/* </p> */}
                                      </div>
                                      <span className="text_all">
                                        {value.tax.length > 0 ? (
                                          <ul className="tax_list">
                                            {value.tax.map((item, index) => {
                                              return (
                                                <li key={index}>
                                                  {item.title}{" "}
                                                  {item.type == "percentage" ? (
                                                    <>({item.basic}%) </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  :
                                                  <span className="tax_distribution">
                                                    {" "}
                                                    {item.format_amount}
                                                  </span>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        ) : (
                                          "All taxes and fees included"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-xl-1 col-lg-1 col-md-1 p-0 col-sm-12 col-12">
                                    <div className="cart_prouct">
                                      <button
                                        onClick={() => removeItem(value.id)}
                                      >
                                        <img
                                          src={images["delect_icon.png"]}
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {/* shivi 26-10 end */}
                              </div>
                            </div>
                            <div className="meeting_point">
                              {value.meeting_point != undefined &&
                                value.meeting_point != "" && (
                                  <p>
                                    {t("_Meeting_Point :")}{" "}
                                    <NavLink
                                      target="__blank"
                                      to={`https://www.google.com/maps/?q=${value.meeting_point.latitude},${value.meeting_point.longitude}`}
                                    >
                                      {value.meeting_point.location}
                                    </NavLink>{" "}
                                  </p>
                                )}

                              {value.pickup_point != undefined &&
                                value.pickup_point != "" &&
                                value.pickup_point != "DECIDE_LATER" &&
                                value.pickup_point != "GO_TO_MEETING_POINT" && (
                                  <p>
                                    {t("_Pickup_Point")}{" "}
                                    <NavLink
                                      target="__blank"
                                      to={`https://www.google.com/maps/?q=${value.pickup_point.latitude},${value.pickup_point.longitude}`}
                                    >
                                      {value.pickup_point.location}
                                    </NavLink>{" "}
                                  </p>
                                )}

                              {}
                              {value.end_point != undefined &&
                                value.end_point != "" && (
                                  <p>
                                    {t("_End_Point")}{" "}
                                    {value.end_point.location == undefined ? (
                                      <NavLink
                                        target="__blank"
                                        to={`https://www.google.com/maps/?q=${value.meeting_point.latitude},${value.meeting_point.longitude}`}
                                      >
                                        {value.meeting_point.location}
                                      </NavLink>
                                    ) : (
                                      <NavLink
                                        target="__blank"
                                        to={`https://www.google.com/maps/?q=${value.end_point.latitude},${value.end_point.longitude}`}
                                      >
                                        {value.end_point.location}
                                      </NavLink>
                                    )}
                                  </p>
                                )}
                            </div>

                            <div className="cart_product_info">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <div className="inner_info_cart">
                                    <div className="cart_icon">
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <g clipRule="url(#clip0_518_177)">
                                            <path
                                              d="M15.375 10.3125C15.375 10.1633 15.4343 10.0202 15.5398 9.91475C15.6452 9.80926 15.7883 9.75 15.9375 9.75H17.0625C17.2117 9.75 17.3548 9.80926 17.4602 9.91475C17.5657 10.0202 17.625 10.1633 17.625 10.3125V11.4375C17.625 11.5867 17.5657 11.7298 17.4602 11.8352C17.3548 11.9407 17.2117 12 17.0625 12H15.9375C15.7883 12 15.6452 11.9407 15.5398 11.8352C15.4343 11.7298 15.375 11.5867 15.375 11.4375V10.3125ZM12 10.3125C12 10.1633 12.0593 10.0202 12.1648 9.91475C12.2702 9.80926 12.4133 9.75 12.5625 9.75H13.6875C13.8367 9.75 13.9798 9.80926 14.0852 9.91475C14.1907 10.0202 14.25 10.1633 14.25 10.3125V11.4375C14.25 11.5867 14.1907 11.7298 14.0852 11.8352C13.9798 11.9407 13.8367 12 13.6875 12H12.5625C12.4133 12 12.2702 11.9407 12.1648 11.8352C12.0593 11.7298 12 11.5867 12 11.4375V10.3125ZM6.375 13.6875C6.375 13.5383 6.43426 13.3952 6.53975 13.2898C6.64524 13.1843 6.78832 13.125 6.9375 13.125H8.0625C8.21168 13.125 8.35476 13.1843 8.46025 13.2898C8.56574 13.3952 8.625 13.5383 8.625 13.6875V14.8125C8.625 14.9617 8.56574 15.1048 8.46025 15.2102C8.35476 15.3157 8.21168 15.375 8.0625 15.375H6.9375C6.78832 15.375 6.64524 15.3157 6.53975 15.2102C6.43426 15.1048 6.375 14.9617 6.375 14.8125V13.6875ZM9.75 13.6875C9.75 13.5383 9.80926 13.3952 9.91475 13.2898C10.0202 13.1843 10.1633 13.125 10.3125 13.125H11.4375C11.5867 13.125 11.7298 13.1843 11.8352 13.2898C11.9407 13.3952 12 13.5383 12 13.6875V14.8125C12 14.9617 11.9407 15.1048 11.8352 15.2102C11.7298 15.3157 11.5867 15.375 11.4375 15.375H10.3125C10.1633 15.375 10.0202 15.3157 9.91475 15.2102C9.80926 15.1048 9.75 14.9617 9.75 14.8125V13.6875Z"
                                              fill="#232A35"
                                            />
                                            <path
                                              d="M6.9375 3C7.08668 3 7.22976 3.05926 7.33525 3.16475C7.44074 3.27024 7.5 3.41332 7.5 3.5625V4.125H16.5V3.5625C16.5 3.41332 16.5593 3.27024 16.6648 3.16475C16.7702 3.05926 16.9133 3 17.0625 3C17.2117 3 17.3548 3.05926 17.4602 3.16475C17.5657 3.27024 17.625 3.41332 17.625 3.5625V4.125H18.75C19.3467 4.125 19.919 4.36205 20.341 4.78401C20.7629 5.20597 21 5.77826 21 6.375V18.75C21 19.3467 20.7629 19.919 20.341 20.341C19.919 20.7629 19.3467 21 18.75 21H5.25C4.65326 21 4.08097 20.7629 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V6.375C3 5.77826 3.23705 5.20597 3.65901 4.78401C4.08097 4.36205 4.65326 4.125 5.25 4.125H6.375V3.5625C6.375 3.41332 6.43426 3.27024 6.53975 3.16475C6.64524 3.05926 6.78832 3 6.9375 3ZM4.125 7.5V18.75C4.125 19.0484 4.24353 19.3345 4.4545 19.5455C4.66548 19.7565 4.95163 19.875 5.25 19.875H18.75C19.0484 19.875 19.3345 19.7565 19.5455 19.5455C19.7565 19.3345 19.875 19.0484 19.875 18.75V7.5H4.125Z"
                                              fill="#232A35"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_518_177">
                                              <rect
                                                width="18"
                                                height="18"
                                                fill="white"
                                                transform="translate(3 3)"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </span>
                                    </div>
                                    <div className="cart_text">
                                      <h2>{t("_starting_date")}</h2>
                                      <p>{value.date}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <div className="inner_info_cart">
                                    {value.time_slot && (
                                      <>
                                        <div className="cart_icon">
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                            >
                                              <path
                                                d="M10.25 5.5C10.25 5.08579 9.91421 4.75 9.5 4.75C9.08579 4.75 8.75 5.08579 8.75 5.5V10.8107L11.9697 14.0303C12.2626 14.3232 12.7374 14.3232 13.0303 14.0303C13.3232 13.7374 13.3232 13.2626 13.0303 12.9697L10.25 10.1893V5.5Z"
                                                fill="#232A35"
                                              />
                                              <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                                                fill="#232A35"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                        <div className="cart_text">
                                          <h2>{t("_starting_time")}</h2>
                                          <p>{value.time_slot}</p>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <div className="inner_info_cart">
                                    <div className="cart_icon">
                                      <span>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <g clipRule="url(#clip0_265_1298)">
                                            <path
                                              d="M13.9631 8.875C14.2752 8.96875 14.5724 9.09598 14.8546 9.2567C15.1368 9.41741 15.4323 9.66183 15.7411 9.98995C16.0499 10.3181 16.3139 10.7048 16.533 11.1501C16.7521 11.5954 16.9348 12.1713 17.0809 12.8778C17.227 13.5843 17.3 14.3761 17.3 15.2533C17.3 16.2846 16.968 17.1669 16.3039 17.9001C15.6398 18.6334 14.8396 19 13.9033 19H5.39668C4.46035 19 3.66016 18.6334 2.99609 17.9001C2.33203 17.1669 2 16.2846 2 15.2533C2 14.3761 2.07305 13.5843 2.21914 12.8778C2.36523 12.1713 2.54785 11.5954 2.76699 11.1501C2.98613 10.7048 3.2501 10.3181 3.55889 9.98995C3.86768 9.66183 4.16318 9.41741 4.44541 9.2567C4.72764 9.09598 5.0248 8.96875 5.33691 8.875C4.8123 8.03795 4.55 7.12723 4.55 6.14286C4.55 5.44643 4.68447 4.78181 4.95342 4.149C5.22236 3.51618 5.58594 2.96875 6.04414 2.5067C6.50234 2.04464 7.04521 1.67801 7.67275 1.40681C8.30029 1.1356 8.95937 1 9.65 1C10.3406 1 10.9997 1.1356 11.6272 1.40681C12.2548 1.67801 12.7977 2.04464 13.2559 2.5067C13.7141 2.96875 14.0776 3.51618 14.3466 4.149C14.6155 4.78181 14.75 5.44643 14.75 6.14286C14.75 7.12723 14.4877 8.03795 13.9631 8.875ZM9.65 2.28571C8.59414 2.28571 7.69268 2.66239 6.94561 3.41574C6.19853 4.16908 5.825 5.07812 5.825 6.14286C5.825 7.20759 6.19853 8.11663 6.94561 8.86998C7.69268 9.62333 8.59414 10 9.65 10C10.7059 10 11.6073 9.62333 12.3544 8.86998C13.1015 8.11663 13.475 7.20759 13.475 6.14286C13.475 5.07812 13.1015 4.16908 12.3544 3.41574C11.6073 2.66239 10.7059 2.28571 9.65 2.28571ZM13.9033 17.7143C14.4877 17.7143 14.9874 17.4749 15.4024 16.9961C15.8175 16.5173 16.025 15.9364 16.025 15.2533C16.025 13.6529 15.7644 12.3906 15.2431 11.4665C14.7218 10.5424 13.973 10.0569 12.9969 10.01C12.034 10.8605 10.9184 11.2857 9.65 11.2857C8.38164 11.2857 7.26602 10.8605 6.30312 10.01C5.32695 10.0569 4.57822 10.5424 4.05693 11.4665C3.53564 12.3906 3.275 13.6529 3.275 15.2533C3.275 15.9364 3.48252 16.5173 3.89756 16.9961C4.3126 17.4749 4.8123 17.7143 5.39668 17.7143H13.9033Z"
                                              fill="#232A35"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_265_1298">
                                              <rect
                                                width="15.3"
                                                height="18"
                                                fill="white"
                                                transform="translate(2 1)"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </span>
                                    </div>
                                    <div className="cart_text">
                                      <h2>{t("_packs_detail")}</h2>
                                      {value.pricing_type == "person" ? (
                                        <p>
                                          {value.adult > 0 && (
                                            <>
                                              {value.adult} - {t("_adult")}{" "}
                                            </>
                                          )}
                                          {value.child > 0 && (
                                            <span>
                                              {" "}
                                              {value.child} - {t("_child")}
                                            </span>
                                          )}
                                          {value.infant > 0 && (
                                            <span>
                                              {" "}
                                              {value.infant} - {t("_infant")}
                                            </span>
                                          )}
                                        </p>
                                      ) : (
                                        <p>
                                          <span>
                                            {value.participants} -{" "}
                                            {t("_Participants")}
                                          </span>
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {value.is_booking_allow == false && (
                              <div className="booking_allow-msg">
                                <p className="text-danger">
                                  {value.booking_msg}
                                </p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
                      {cartTaxFee.length > 0 && (
                        <div className="total_amount_cart">
                          <div className="total_amount_cart_inner">
                            {cartTaxFee.map((item, index) => {
                              return (
                                <div className="total_price_text" key={index}>
                                  <div className="dis-row">
                                    <div className="row-one-left">
                                      <h2>{item.title}</h2>
                                    </div>
                                    <div className="row-one-right">
                                      <p>
                                        <span className="picer_icon price-with-currency">
                                          {item.amount}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {cartTaxFee.length > 0 && (
                            <div className="all_texs">
                              <p>{t("_all_taxes_and_fees_included")}</p>
                            </div>
                          )}

                          <div className="Cheackout_btn">
                            {cartItems.length > 0 &&
                              cartData.is_booking_allow == true && (
                                <NavLink to="/checkout">
                                  {" "}
                                  {t("_checkout")}{" "}
                                </NavLink>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  recordStatus === false && <RecordNotFound />
                ) //To avoide showing of record not found for miliseconds when page load first time
              }
            </div>
          </div>
        </div>
        {cartLoader === false && <Footer />}

        <LoginModal
          loginInfo={loginKey}
          SetLoginKey={SetLoginKey}
          LoginModal={ModalLogin}
          LogincloseModal={closeLoginModal}
        />
      </motion.div>
    </>
  );
};

export default Cart;
