import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/fontawesome-free-solid";
import { ShimmerPostItem, ShimmerPostList } from "react-shimmer-effects";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LocaleContext from "../LocaleContext";
import { HomeContext } from "../../context";
import { PuffLoader } from "react-spinners";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";
import { images } from "../../actions/customFn";
import RecordNotFound from "../../pages/RecordNotFound";

const CategoryData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profileReducer = useSelector((state) => state.profileReducer);
  const { isDataLoad, setIsDataLoading } = useContext(LocaleContext);
  const homeData = useSelector((state) => state.recomededTourReducer);
  const {
    wishLishedLoader,
    wishLishedId,
    wishLishedArray,
    addWishList,
    slug,
    isCategoryLoading,
  } = useContext(HomeContext);
  const [categoryData, setCategoryData] = useState("");

  useEffect(() => {
    if (homeData) {
      setCategoryData(homeData.recommended_tours);
    }
  }, [homeData, categoryData]);

  const reactRatings = {
    edit: false,
    activeColor: "rgb(252 83 1)",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  return (
    <>
      <div className="RecommendedTours_main category-data-main">
        <div className="container">
          {
            categoryData.data != "" &&
            categoryData.data != undefined &&
            categoryData.data.length > 0 ? (
              <div className="Recommended_Tab">
                <div className="Recommended_Heading">
                  <h2>{homeData.recommended_tours.heading}</h2>
                  {/* {
                                    categoryData.is_show == "1" &&
                                    <div className="show_more-product">
                                        <button
                                            onClick={() => navigate(`/culture-listing/${slug}`)}
                                        >
                                            {t("_show_more")}
                                        </button>
                                    </div>
                                } */}
                </div>

                <div className="Recommended_tab_inner">
                  <div className="product_card_list">
                    <div className="row">
                      {categoryData.data &&
                        categoryData.data.length > 0 &&
                        categoryData.data.map((value, index) => {
                          return (
                            <div
                              className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-4"
                              key={index}
                            >
                              {isCategoryLoading == true ? (
                                <ShimmerPostItem
                                  card
                                  title
                                  text
                                  cta
                                ></ShimmerPostItem>
                              ) : (
                                <div className="product_card_inner">
                                  {profileReducer.user_type !== "Hotel" && (
                                    <button
                                      className="wishlist-icon icon-new-wishlist"
                                      onClick={() =>
                                        addWishList(
                                          value.slug,
                                          value.id,
                                          value.country
                                        )
                                      }
                                    >
                                      {wishLishedLoader &&
                                      wishLishedId == value.id ? (
                                        <PuffLoader size={20} color="#fff" />
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="17"
                                          viewBox="0 0 18 17"
                                          fill="none"
                                        >
                                          <path
                                            d="M13.125 0.437744C12.2805 0.45088 11.4543 0.686634 10.7301 1.1212C10.0058 1.55576 9.40902 2.17375 9 2.91274C8.59098 2.17375 7.9942 1.55576 7.26993 1.1212C6.54565 0.686634 5.71954 0.45088 4.875 0.437744C3.5287 0.496237 2.26028 1.08518 1.34684 2.07592C0.433394 3.06665 -0.0508137 4.37864 -7.64383e-07 5.72524C-7.64383e-07 9.13549 3.5895 12.86 6.6 15.3852C7.27216 15.9501 8.12201 16.2598 9 16.2598C9.87798 16.2598 10.7278 15.9501 11.4 15.3852C14.4105 12.86 18 9.13549 18 5.72524C18.0508 4.37864 17.5666 3.06665 16.6532 2.07592C15.7397 1.08518 14.4713 0.496237 13.125 0.437744Z"
                                            fill={
                                              wishLishedArray.includes(value.id)
                                                ? "#FC5301"
                                                : "#C4C7CC"
                                            }
                                          />
                                        </svg>
                                      )}
                                    </button>
                                  )}
                                  <NavLink
                                    to={`/culture-details/${value.slug}`}
                                  >
                                    <div className="product_card_inner_a">
                                      <div
                                        className="prd_crd_img"
                                        style={{
                                          backgroundImage: `url(${value.image})`,
                                        }}
                                      >
                                        {/* -- ashu-- */}
                                        <div className="bg_top_info imagetxt_earthimg">
                                          {/* {value.image_text ? (
                                                <span>{value.image_text}</span>
                                              ) : (
                                                ""
                                              )} */}
                                          {/* <img src={images["earth_text.png"]} alt="" /> */}
                                        </div>

                                        {/* {value.show_badge && (
                                              <div className="bg_bottom_info-badge" dangerouslySetInnerHTML={
                                                { __html: value.show_badge }
                                              }>
                                              </div>
                                            )} */}
                                        {value.original_badge == "yes" && (
                                          <div className="originalinfo_badge ">
                                            <img
                                              src={images["Cultural-Tag-1.png"]}
                                              alt=""
                                            />
                                          </div>
                                        )}

                                        {/* {value.likely_to_sell_out ==
                                              "yes" && (
                                                <div className="bg_bottom_info">
                                                  <span>
                                                    {t("_likely_to_sell_out")}
                                                  </span>
                                                </div>
                                              )} */}
                                      </div>

                                      <div className="product_all_info">
                                        <div className="prd_crd_heading">
                                          <h2>{value.title.charAt(0).toUpperCase() + value.title.slice(1)}</h2>
                                        </div>
                                        <div className="prd_crd_loaction">
                                          <div className="prd_location_main">
                                            <div className="icon_loc">
                                              {" "}
                                              <FontAwesomeIcon
                                                icon={faMapMarkerAlt}
                                              />
                                              {value.location}
                                            </div>
                                            <div className="trip_details">
                                              {value.trip_details}
                                            </div>
                                          </div>
                                          {/* <p>{value.duration_text}</p> */}
                                        </div>
                                        {/* -- ashu-- */}
                                        {/* <div className="prd_crd_tags">
                                              {value.activity_text && (
                                                <span>
                                                  {value.activity_text}
                                                </span>
                                              )}
                                            </div> */}
                                        {value.likely_to_sell_out == "yes" ? (
                                          <div className="likelytosell_prdcard ">
                                            <span>
                                              {t("_likely_to_sell_out")}
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="withouttag_likelysellout"></div>
                                        )}

                                        <div className="prd_btm_rating">
                                          <div className="prd_star_rating">
                                            <ul>
                                              <li>
                                                {/* {" "}
                                                    <FontAwesomeIcon
                                                      icon={faStar}
                                                    />
                                                    {" "} */}
                                                <ReactStars
                                                  style={{
                                                    cursor:
                                                      "pointer !important",
                                                  }}
                                                  classNames="star-class"
                                                  size={16}
                                                  value={Number(value.ratings)}
                                                  {...reactRatings}
                                                />
                                              </li>
                                              {/* <li>{value.ratings}</li> */}
                                            </ul>
                                            <p>
                                              ( {value.total_review}{" "}
                                              {t("_reviews")} )
                                            </p>
                                          </div>
                                          <div
                                            className="prd_main_price"
                                            dangerouslySetInnerHTML={{
                                              __html: value.price,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </NavLink>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>

                    {categoryData.is_show == "1" && (
                      <div className="show_more-product">
                        <button
                          onClick={() => navigate(`/culture-listing/${slug}`)}
                        >
                          {t("_show_more")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : isCategoryLoading == true ? (
              <ShimmerPostItem card title text cta></ShimmerPostItem>
            ) : (
              <RecordNotFound />
            )
            // <ShimmerPostList postStyle="STYLE_FOUR" col={4} row={1} gap={30} />
          }
        </div>
      </div>
    </>
  );
};

export default CategoryData;
