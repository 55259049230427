import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-google-places-autocomplete";
import { validateField } from "../../../actions/customFn";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProductBuilderContext } from "../../../context";
import Geosuggest from "react-geosuggest";

const TourAttractionModal = (props) => {
  const { t } = useTranslation();
  const {
    tourmodel,
    setTourmodel,
    setTourAttraction,
    tourAttraction,
    tourEditAttractionKey,
    setEditTourAttractionKey,
    newAttractionKey,
    tourData
  } = props;
  const { googleKey } = useContext(ProductBuilderContext);

  const [inputValue, setInputValue] = useState("");
  const [tourAttractionData, setTourAttractionData] = useState({
    attraction_id: "",
    attraction_title: "",
    attraction_latitude: "",
    attraction_longitude: "",
    attraction_location_obj: "",
    attraction_description: "",
    is_pass_by_without_stopping: "false",
    attraction_time: "",
    attraction_time_type: "minutes",
    include_price_tour: "N/A (Admission is free)",
    attraction_transport_time: "1",
    attraction_transport_time_type: "minute",
    attraction_transport_by: "",
  });

  useEffect(() => {
    if (tourAttraction != undefined) {
      if (typeof tourEditAttractionKey === "number") {
        setTourAttractionData(tourAttraction[tourEditAttractionKey]);
      } else {
        setTourAttractionData({
          attraction_id: "",
          attraction_title: "",
          attraction_latitude: "",
          attraction_longitude: "",
          attraction_description: "",
          is_pass_by_without_stopping: "false",
          attraction_time: "",
          attraction_time_type: "minutes",
          include_price_tour: "N/A (Admission is free)",
          attraction_location_obj: "",
          attraction_transport_time: "1",
          attraction_transport_time_type: "minute",
          attraction_transport_by: "",
        });
      }
    }
  }, [tourEditAttractionKey]);

  const handlePassByShooping = (e) => {
    var value = "false";
    if (e.target.checked) {
      value = "true";
    }
    setTourAttractionData((prev) => ({
      ...prev,
      is_pass_by_without_stopping: value,
    }));
  };

  const handlePlaceOnChange = async (place) => {
    if (place) {
      const { lat, lng } = place.location
      const address = place.label

      setTourAttractionData((prev) => ({
        ...prev,
        attraction_title: address,
        attraction_latitude: lat,
        attraction_longitude: lng,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const onlyNumbers = /^[0-9]*$/;
    if (name == "attraction_time" || name == "attraction_transport_time") {
      if (onlyNumbers.test(value) === true) {
        setTourAttractionData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setTourAttractionData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    let validate = {
      attraction_title: "Required|",
      attraction_description: "Required|",
    };

    if (tourAttractionData.is_pass_by_without_stopping == "false") {
      validate = { ...validate, attraction_time: "Required|" };
    }

    e.preventDefault();
    validateField(validate, { ...tourAttractionData }).then((res) => {
      if (res == true) {
        // return false
        let newUpdateData = [];
        if (typeof tourEditAttractionKey === "number") {
          newUpdateData = [...tourAttraction];
          newUpdateData[tourEditAttractionKey] = tourAttractionData;
          setTourAttraction(newUpdateData);
        } else {
          newUpdateData = tourAttractionData;
          setTourAttraction([...tourAttraction, newUpdateData]);
        }
        handleModalClose();
      }
    });
  };

  const handleModalClose = () => {
    setTourAttractionData({
      attraction_id: "",
      attraction_title: "",
      attraction_latitude: "",
      attraction_longitude: "",
      attraction_description: "",
      is_pass_by_without_stopping: "false",
      attraction_time: "",
      attraction_time_type: "minutes",
      include_price_tour: "N/A (Admission is free)",
      attraction_transport_time: "1",
      attraction_transport_time_type: "minute",
      attraction_transport_by: "",
    });
    setTourmodel(false);
    setEditTourAttractionKey("");
  };
  const cancelledTypeOption = {
    day: "Day(s)",
    hour: "Hour(s)",
    minute: "Minute(s)",
  };


  return (
    <Modal
      show={tourmodel}
      onHide={() => {
        handleModalClose(false);
      }}
      className="inclusion_pagemodels"
    >
      <Modal.Body>
        <div className="close_modelbutn">
          <button
            onClick={() => {
              handleModalClose(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z"
                fill="#4f4d4d"
              />
            </svg>
          </button>
        </div>

        <div className="inclusion_models">
          <h1>{t("_Add_an_attraction")}</h1>
          <p>
            {t("_A_detailed_itinerary_helps_travelers_know_what_to_expect.")}
          </p>

          <h6> {t("_Select_an_attraction")}{" "} <span className="required-field">*</span></h6>

          <div>
            <Geosuggest
              placeholder="Search Place here"
              onSuggestSelect={(place) => handlePlaceOnChange(place)}
              initialValue={tourAttractionData?.attraction_title}
            />
          </div>
          <h6>
            {t(
              "_describe_what_travelers_will_see_and_do_here_if_they_book_your_experience"
            )}
            <span className="required-field">*</span>
          </h6>
          <div>
            <textarea
              name="attraction_description"
              id="attraction_description"
              value={tourAttractionData.attraction_description}
              rows="4"
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder='Enter a description about this attraction only.  For example, "Our accredited guide will meet you at the ticket booth and will accompany you up the tower, explaining what you&apos;ll see at each level."'
            ></textarea>
          </div>

          <div className="time_data_main-attraction">
            {
              newAttractionKey > 0 && (
                <div className="icon_search_button pricing-schedule-cancel-time d-flex">
                  <div className="transport-by-div">
                    <div>
                      <label>{t("_Transport_By_")}</label>
                    </div>
                    <Select
                      sx={{
                        width: "100%",
                      }}
                      className="input_username new-edit-main-all select-field"
                      name="attraction_transport_by"
                      displayEmpty
                      value={tourAttractionData.attraction_transport_by}
                      onChange={(e) => handleChange(e)}
                    >
                      {
                        Object.entries(tourData?.attraction_transport_list).map(
                          (item, index) => {
                            return (
                              <MenuItem key={index} value={item[0]}>
                                {item[1]}
                              </MenuItem>
                            );
                          }
                        )
                      }
                    </Select>
                  </div>
                  {
                    tourAttractionData.attraction_transport_by !== "no_transport" &&
                    <>
                      <div className="transport-time-div transport-time-div-tour">
                        <div>
                          <label>{t("_Transport_time")} :</label>
                        </div>
                        <input
                          type="text"
                          name="attraction_transport_time"
                          value={tourAttractionData.attraction_transport_time}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="transport-type-div">
                        <div>
                          <label>{t("_Transport_Time_Type")}:</label>
                        </div>
                        <Select
                          sx={{
                            width: "100%",
                          }}
                          className="input_username new-edit-main-all select-field"
                          id="attraction_type"
                          name="attraction_transport_time_type"
                          displayEmpty
                          // value={tourAttractionData.attraction_transport_time_type}
                          value={Object.keys(cancelledTypeOption).find(key => key == tourAttractionData.attraction_transport_time_type)}
                          onChange={(e) => handleChange(e)}
                        >
                          {
                            Object.entries(cancelledTypeOption).map(
                              (item, index) => {
                                return (
                                  <MenuItem key={index} value={item[0]}>
                                    {item[1]}
                                  </MenuItem>
                                );
                              }
                            )
                          }
                        </Select>
                      </div>
                    </>
                  }
                </div>
              )
            }
          </div>

          <h6> {t("_how_much_time_do_travelers_typically_spend_here?")}<span className="required-field">*</span></h6>
          <label class="metting_checkboxes">
            <input
              type="checkbox"
              name="is_pass_by_without_stopping"
              checked={tourAttractionData.is_pass_by_without_stopping == "true"}
              onChange={(e) => handlePassByShooping(e)}
            />
            <span>{t("_Pass_by_without_stopping")}</span>
          </label>
          {tourAttractionData.is_pass_by_without_stopping == "false" && (
            <>
              <div>
                <div className="hourinputdiv">
                  <input
                    type="text"
                    name="attraction_time"
                    value={tourAttractionData.attraction_time}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />

                  <select
                    name="attraction_time_type"
                    id="attraction_time_type"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={tourAttractionData.attraction_time_type}
                  >
                    <option value="minutes">{t("_minutes")}</option>
                    <option value="hours">{t("_hours")}</option>
                  </select>
                </div>
              </div>

              <h6>
                {t(
                  "_Is_admission_to_this_place_included_in_the_price_of_your_tour?"
                )}
              </h6>
              <div class="product_selectorradio">
                <input
                  type="radio"
                  className="productradio"
                  name="include_price_tour"
                  checked={tourAttractionData.include_price_tour === "yes"}
                  id="include_price_tour1"
                  value="yes"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <label htmlFor="include_price_tour1">{t("_Yes")}</label>
              </div>

              <div class="product_selectorradio">
                <input
                  type="radio"
                  className="productradio"
                  name="include_price_tour"
                  id="include_price_tour2"
                  checked={tourAttractionData.include_price_tour === "no"}
                  value="no"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <label htmlFor="include_price_tour2">{t("_No")}</label>
              </div>

              <div class="product_selectorradio">
                <input
                  type="radio"
                  className="productradio"
                  name="include_price_tour"
                  id="include_price_tour3"
                  checked={
                    tourAttractionData.include_price_tour ===
                    "N/A (Admission is free)"
                  }
                  value="N/A (Admission is free)"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <label htmlFor="include_price_tour3">
                  {t("_N/A")} ({t("_Admission_is_free")})
                </label>
              </div>
            </>
          )}

          <div className="model_lastendbtns">
            <button
              className="remove-allsave mr-4"
              onClick={() => {
                handleModalClose(false);
              }}
            >
              {t("_cancel")}
            </button>
            <button
              className="btnall-save "
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              {t("_Add")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TourAttractionModal;
