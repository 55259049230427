import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg, successMsg } from "./customFn";

//   ===================API upload product starts=================
export const uplodProduct = (
  languageReducer,
  setTourId,
  navigate,
  formData,
  nextRoute = "",
  setOptionId = "",
  type = "",
  setisopen = "",
  setApprovalArray = ""
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      let params = {};

      if (type == "images_tab" || type == "others") {
        formData.append("user_id", localStorage.getItem("userId"));
        formData.append("password", localStorage.getItem("password"));
        formData.append("language", languageReducer.id);
        formData.append("currency", currency.id);
        formData.append("token", localStorage.getItem("_token"));

        params = formData;
      } else {
        params = {
          user_id: localStorage.getItem("userId"),
          password: localStorage.getItem("password"),
          token: token,
          language: languageReducer.id,
          currency: currency.id,
          ...formData,
        };
      }

      const response = await axios.post("upload-product", params);
      if (response.data.status === true) {
        const { tour_id, option_id } = response.data.data;
        if (setTourId !== "") {
          setTourId(response.data.data.tour_id);
        }
        if (setOptionId !== "") {
          setOptionId(response.data.data.option_id);
        }
        if (nextRoute == "option-setup" || nextRoute == "option-pricing") {
          navigate(`/tours-files/${nextRoute}/${tour_id}/${option_id}`);
        } else if (nextRoute == "my-tours") {
          navigate("/my-tours");
        } else if (nextRoute == "") {
          //Do nothing
        } else {
          navigate(`/tours-files/${nextRoute}/${tour_id}`);
        }
        if (setisopen !== "") {
          setisopen(false);
        }
        if (setApprovalArray !== "") {
          //From My Tours
          setApprovalArray((state) => [...state, formData.tourId]);
        }
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};

// ===================API upload product ends===================

//   ===================API get product data starts=================
export const getProductData = (
  languageReducer,
  type,
  tour_id,
  setData,
  navigate,
  option_id,
  sendData
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        type: type,
        tourId: tour_id,
        optionId: option_id,
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...sendData,
      };

      const response = await axios.post("get-product-data", params);
      if (response.data.status === true) {
        setData(response.data.data);
      } else if (
        response.data.option !== undefined &&
        response.data.option == 0
      ) {
        errorMsg("Option not Found...");
        navigate(`/tours-files/option/${tour_id}`);
      } else {
        navigate("/my-tours");
        errorMsg("Product Not Found...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};
// ===================API get product data ends===================

//   ===================API Food and Drink option=================
export const getFoodAndDrinkOption = (
  languageReducer,
  setFoodOption,
  setDrinkOption,
  navigate,
  data,
  setFormData
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...data,
      };

      const response = await axios.post("get-food-drink-modal-view", params);
      if (response.data.status === true) {
        if (data.fd_type === "food") {
          setFoodOption(response.data.data);
        }
        if (data.fd_type === "drink") {
          setDrinkOption(response.data.data);
        }
        if (data.fd_type === "edit") {
          setFoodOption(response.data.data);
          setDrinkOption(response.data.data);

          let { product_food_drink } = response.data.data;
          setFormData((prev) => ({
            ...prev,
            food: product_food_drink.food,
            drink: product_food_drink.drink,
            type_of_meal: product_food_drink.type_of_meal,
            time_of_day: product_food_drink.time_of_day,
            food_tags: product_food_drink.food_tags,
            drink_tags: product_food_drink.drink_tags,
            product_food_drink_id: data.product_food_drink_id,
          }));
        }
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //     setIsLoading(true);
    // }, 1000);
  };
};
// ===================API API Food and Drink option ends===================

//   ===================API upload food starts=================
export const uplodFoodDrinkData = (
  languageReducer,
  navigate,
  formData,
  setOpenModel,
  setToggle,
  resetFormData
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        token: token,
        language: languageReducer.id,
        currency: currency.id,
        ...formData,
      };

      const response = await axios.post("add-product-food-drink", params);
      if (response.data.status === true) {
        // const { tour_id } = response.data.data;
        // if (setTourId !== "") {
        //     setTourId(response.data.data.tour_id);
        // }
        // navigate(`/tours-files/${nextRoute}/${tour_id}`);
        setOpenModel(false);
        setToggle((status) => !status);
      } else {
        errorMsg("Somthing went wrong...");
      }
      resetFormData();
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //     setIsLoading(true);
    // }, 1000);
  };
};

//   ===================API transport=================
// export const getTransportModelView = (languageReducer, setFoodOption, setDrinkOption, navigate, data, setFormData) => {
export const getTransportModelView = (
  languageReducer,
  navigate,
  data,
  setTransportModelView
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...data,
      };

      const response = await axios.post(
        "get-transportation-modal-view",
        params
      );
      if (response.data.status === true) {
        setTransportModelView(response.data.data);

        // let { product_transportation } = response.data.data;
        // setFormData((prev) => ({
        //     ...prev,
        //     capacity: product_transportation.capacity,
        //     air_conditioning: product_transportation.air_conditioning,
        //     wifi: product_transportation.wifi,
        //     private_shared: product_transportation.private_shared,
        // }));
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //     setIsLoading(true);
    // }, 1000);
  };
};
// ===================API API transport ends===================

//   ===================API upload food starts=================
export const addTransportData = (
  languageReducer,
  navigate,
  formData,
  setOpenModel,
  setToggle
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        token: token,
        language: languageReducer.id,
        currency: currency.id,
        ...formData,
      };

      const response = await axios.post("add-product-transportation", params);
      if (response.data.status === true) {
        setOpenModel(false);
        setToggle((status) => !status);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //     setIsLoading(true);
    // }, 1000);
  };
};

//   ===================API upload Update Price Type=================
export const UpdatePricingType = (languageReducer, formData) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        token: token,
        language: languageReducer.id,
        currency: currency.id,
        ...formData,
      };

      const response = await axios.post("option-pricing-type", params);
      if (response.data.status === true) {
        // setOpenModel(false);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //     setIsLoading(true);
    // }, 1000);
  };
};

//   ===================API Remove Product Option=================
export const removeProductOption = (languageReducer, navigate, data) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        token: token,
        language: languageReducer.id,
        currency: currency.id,
        ...data,
      };

      const response = await axios.post("remove-product-option", params);
      if (response.data.status === true) {
        // setOpenModel(false);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //     setIsLoading(true);
    // }, 1000);
  };
};

//   ===================API Remove Product Option ends=================

//   ===================API Remove Option Pricing=================
export const removePricingOption = (languageReducer, navigate, data) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        token: token,
        language: languageReducer.id,
        currency: currency.id,
        ...data,
      };

      const response = await axios.post(
        "remove-product-option-pricing",
        params
      );
      if (response.data.status === true) {
        // setOpenModel(false);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      if (error.response.status === 401) {
        // dispatch(getLogout(navigate));
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
    // setTimeout(() => {
    //     setIsLoading(true);
    // }, 1000);
  };
};

//   ===================API Remove Option Pricing ends=================
