import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg } from "./customFn";

//   ===================API call for blogs starts=================
export const getBlogData = (languageReducer, setBlogData, navigate, setIsLoading) => {
    return async (dispatch) => {
        try {
            const currency = JSON.parse(localStorage.getItem("currency"));
            const params = {
                language: languageReducer.id,
                currency: currency.id
            };
            const response = await axios.post("blog", params);
            if (response.data.status === true) {
                setBlogData(response.data.data);
                setTimeout(function () {
                    setIsLoading(true);
                }, 1000);
            } else {
                setTimeout(function () {
                    setIsLoading(true);
                }, 1000);
                // errorMsg("Somthing went wrong...");
            }
        } catch (error) {
            if (error.response.status === 401) {
                dispatch(getLogout(navigate));
                errorMsg("Somthing went wrong...");
            } else {
                alert(`${error.message}-${error.response.status}`);
            }
        }
        // setTimeout(function () {
        //     setIsLoading(true);
        // }, 1000);
    };
};
// ===================API call for blogs ends===================

//   ===================API call for blogs starts=================
export const getBlogList = (languageReducer, setBlogList, navigate, currentPage, setPageCount) => {
    return async (dispatch) => {
        try {
            const currency = JSON.parse(localStorage.getItem("currency"));

            const params = {
                language: languageReducer.id,
                currency: currency.id,
                offset: currentPage
            };
            const response = await axios.post("blog-list", params);
            if (response.data.status === true) {
                setBlogList(response.data.data)
                setPageCount(response.data.page_count);
           
            } else {
                // errorMsg("Somthing went wrong...");
            }
        } catch (error) {
            if (error.response.status === 401) {
                dispatch(getLogout(navigate));
                errorMsg("Somthing went wrong...");
            } else {
                errorMsg(error.response.data.message);
            }
        }
        // setTimeout(function () {
        //     setLoading(true);
        // }, 1000);
    };
};
// ===================API call for blogs ends===================

//   ===================API blog details starts=================
export const getBlogDetails = (languageReducer, setBlogDetails, navigate, setLoading, slug) => {
    return async (dispatch) => {
        try {
            const params = {
                id: slug,
                language: languageReducer.id,
            };

            const response = await axios.post("blog-details", params);
            if (response.data.status === true) {
                setBlogDetails(response.data.data);
                setTimeout(function () {
                    setLoading(true);
                }, 1000);
            } else {
                setTimeout(function () {
                    setLoading(true);
                }, 1000);
                // errorMsg("Somthing went wrong...");
            }
        } catch (error) {
            if (error.response.status === 401) {
                dispatch(getLogout(navigate));
                errorMsg("Somthing went wrong...");
            } else {
                alert(`${error.message}-${error.response.status}`);
            }
        }
        // setTimeout(function () {
        //     setLoading(true);
        // }, 1000);
    };
};
// ===================API blog details ends===================
