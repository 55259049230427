import React,  { useContext, useState } from "react";
import { images } from "../../actions/customFn";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import { ShimmerSectionHeader,ShimmerSimpleGallery,ShimmerButton } from "react-shimmer-effects";

import LocaleContext from "../LocaleContext";
import { useSelector } from "react-redux";
import LoginModal from "../Modal/LoginModal";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { becomeAffiliate } from "../../actions/becomeAffiliateAction"; 

const AfiliateSlider = ({afiliateSliderData,isLoading}) => {
  const testimonial = {
    loop: true,
    margin: 0,
    nav: false,
    dots: true,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  };
  const { t } = useTranslation();
  const systemData = [
    { image: images["mobile-1.png"] },
    { image: images["mobile-2.png"] },
    { image: images["mobile-3.png"] },
    { image: images["mobile-1.png"] },
  ];

  const navigate = useNavigate();

  const { SetLoginKey, loginKey } = useContext(LocaleContext);
  const [ModalLogin, setModalLogin] = useState(false);
  const checklogin = useSelector((state) => state.login);
  // Show Login Modal
  const showModal = (status) => {
    SetLoginKey(status);
    setModalLogin(true);
  };
  const closeLoginModal = () => {
    setModalLogin(false);
  };

  //==============
  const dispatch = useDispatch();
  const languageReducer = useSelector((state) => state.languageReducer);
  const profileReducer = useSelector((state) => state.profileReducer);
  const become_user = "Affiliate";
  const applyForAffiliate = () => {
    Swal.fire({
      title: "Are your sure ?",
      text: "You will become an affiliate user and your data as customer will be no longer available...!!!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "rgb(155 176 199)",
      cancelButtonColor: "rgb(227 46 46)",
      confirmButtonText: "Yes",
    }).then((res) => {
      if (res.isConfirmed === true) {
        dispatch(becomeAffiliate(languageReducer, navigate, become_user));
      }
    });
  };

  return <> { isLoading ? (
    <>
      <div className="shimmer-header">
        <ShimmerSectionHeader center />
      </div>
      <div className="shimmer-bt">
      <ShimmerButton size="lg" mode="light" center/>
      </div>
      <div className="shimmer-header">
        <ShimmerSectionHeader center />
      </div>
      <div className="shimmer-bt">
      <ShimmerButton size="lg" mode="light" center/>
      </div>

    </>
  ) : (
    <div className="AfiliateSlider-new-page">
      <div className="system-slider-main system_slider_fontbig">
        <div className="container">
          <div className="data-top-main">
            <h2>{afiliateSliderData?.new_affiliate_baner_title_add_our_new_marketing_tool}</h2>
          </div>
          <div className="slider-inner-data">
            <OwlCarousel
              key={`carousel_${Date.now()}`}
              {...testimonial}
              className="owl-carousel owl-theme slider_main"
            >
              {systemData?.length > 0 &&
                systemData.map((value, index) => {
                  return (
                    <div className="systm-data-main" key={index}>
                      <div className="system_img">
                        <img src={value.image} alt="" />
                      </div>
                    </div>
                  );
                })}
            </OwlCarousel>
          </div>
          <div className="btn-bottom-data">
            <button onClick={()=> navigate('/') }>Learn More </button>
          </div>

          <div className="new-data-bottom-text">
            <div className="card-data-main">
              <h2>{afiliateSliderData?.new_affiliate_ready_to_get_started_title}</h2>
              <p
                  dangerouslySetInnerHTML={{
                    __html: afiliateSliderData?.new_affiliate_ready_to_get_started_description,
                  }}
                ></p>
            </div>
            <div className="btn-bottom-data">
            {checklogin === false ?
              <button onClick={() => {
                showModal("login");
              }} > {t("_join_now")}</button> : profileReducer?.user_type == "Customer" ? (
                <button
                  aria-label="Become a Affiliate"
                  onClick={() => applyForAffiliate()}
                >
                  {" "}
                  {t("_join_now")}{" "}
                </button>
              ) : profileReducer?.user_type == "Affiliate" ? (
                <button aria-label={t("_already_an_affiliate")}>
                  {" "}
                  {/* {t("_Become_a_Supplier")}{" "} */}
                  {t("_already_an_affiliate")}
                </button>
              ): profileReducer?.user_type == "Partner" ? (
               ''
              ) : ( ''
                // <button aria-label="Become a Supplier">
                //   {" "}
                //   {t("_Become_a_Supplier")}{" "}
                // </button>
              )
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}


<LoginModal
        loginInfo={loginKey}
        SetLoginKey={SetLoginKey}
        LoginModal={ModalLogin}
        LogincloseModal={closeLoginModal}
        userType={"Affiliate"}
      />

</>



};

export default AfiliateSlider;
