import { useEffect } from "react";
import { images } from "../../actions/customFn";
import { NavLink, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Categories from "./Categories";
import TopPost from "./TopPost";
import AdvertisementBanner from "../CultureDetails/AdvertisementBanner";
import { useState } from "react";
import { useContext } from "react";
import { BlogContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { getBlogList } from "../../actions/blogsAction";
import { useTranslation } from "react-i18next";

function NewActivity() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer);
  const [blogList, setBlogList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const blogData = useContext(BlogContext);

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
  };

  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(
        getBlogList(
          languageReducer,
          setBlogList,
          navigate,
          currentPage,
          setPageCount
        )
      );
    }
  }, [languageReducer]);

  return (
    <div className="newactivity_main">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 new_left_activity">
            <div className="newactivity_main_hedding">
              <h2>{t("_new_activity")}</h2>
            </div>

            <div className="row">
              {blogList.map((value, index) => {
                return (
                  <div
                    className=" col-xl-6 col-lg-6 col-md-6 col-sm-6"
                    key={index}
                  >
                    {/* <NavLink to={`/blog-details/${value.slug}`} target="_blank"> */}
                    <div className="card_main_container col_new2">
                      <div className="Adventures_card ">
                        <img
                          className="card-img-top blog-new-activity-img"
                          src={value.image}
                          alt="Card img cap"
                        />
                        <div className="Adventures_card-body">
                          <div className="Adventures_body_inner ">
                            <div className="Activity_food">
                              <span>{value.status}</span>
                              <span>{value.food}</span>
                            </div>

                            <h2>{value.title}</h2>
                            <p
                              dangerouslySetInnerHTML={{
                                // __html: value.short_description.substring(0, 370),
                                __html: (value.short_description.length > 360) ? value.short_description.substring(0, 300) + "..." : value.short_description
                              }}
                            ></p>

                            <div className="latestblogs_right_profile1">
                              {/* <div className="right_profile_left1">
                                  <div className="right_profile_left_bg">
                                    <img
                                      src={images["LatestBlogs_4.png"]}
                                      alt=""
                                    />
                                  </div>
                                  <span>Earl L Hogue</span>
                                </div> */}
                              <div className="right_profile_right1">
                                <div className="inner_pro_flex">
                                  <div className="right_profile_right1_bg">
                                    <img
                                      src={images["LatestBlogs_5.png"]}
                                      alt=""
                                    />
                                  </div>
                                  <span>{value.date}</span>
                                </div>
                              </div>
                            </div>

                            <div className="Read_more_btn_min">
                              <NavLink className="Read_more_btn" to={`/blog-details/${value.slug}`} target="_blank">
                                {t("_read_more")}
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </NavLink> */}
                  </div>
                );
              })}
            </div>

            {/* paginate */}
            {pageCount > 1 && (
              <div className="paginate_all">
                <ReactPaginate
                  pageCount={currentPage}
                  onPageChange={handlePageChange}
                  containerClassName={"navigationButtons"}
                  previousLinkClassName={"previousButton"}
                  nextLinkClassName={"nextButton"}
                  disabledClassName={"navigationDisabled"}
                  activeClassName={"active"}
                  pageRange={2}
                  marginPagesDisplayed={2}
                  nextLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.172"
                      height="14"
                      viewBox="0 0 7.172 14"
                    >
                      <path
                        id="fi-rr-angle-small-down"
                        d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                        transform="translate(7.172) rotate(90)"
                        fill="#000"
                      />
                    </svg>
                  }
                  previousLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7.172"
                      height="14"
                      viewBox="0 0 7.172 14"
                    >
                      <path
                        id="fi-rr-angle-small-down"
                        d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                        transform="translate(15.086 -5) rotate(90)"
                        fill="#232a35"
                      />
                    </svg>
                  }
                />
              </div>
            )}
          </div>

          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 new_right_activity">
            <div className="div_blogs_left">
              <Categories />
              <TopPost topPost={blogData.top_post} />
              {/* <AddActlvity /> */}
              <AdvertisementBanner
                bannerData={{
                  side_banner_image: blogData.side_banner_image,
                  side_banner_title: blogData.side_banner_title,
                  side_banner_description: blogData.side_banner_description,
                  side_banner_link: blogData.side_banner_link,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewActivity;
