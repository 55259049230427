import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import LanguageReducer from "./LanguageReducer";
import languageListReducer from "./languageListReducer";
import currencyListReducer from "./currencyListReducer";
import homePageReducer from "./homePageReducer";
import profileReducer from "./profileReducer";
import CurrencyReducer from "./currencyReducer";
import cartCountReducer from "./cartReducer";
import phoneCodeReducer from "./phoneCodeReducer";
import homeMiddlePageReducer from "./homeMiddlePageReducer";
import recomededTourReducer from "./recomendedTourReducer";

export const reducers = combineReducers({
  login: loginReducer,
  languageReducer: LanguageReducer,
  languageList: languageListReducer,
  currencyList: currencyListReducer,
  home: homePageReducer,
  homeMiddle: homeMiddlePageReducer,
  profileReducer: profileReducer,
  CurrencyReducer: CurrencyReducer,
  cartCountReducer: cartCountReducer,
  phoneCodeReducer: phoneCodeReducer,
  recomededTourReducer: recomededTourReducer
});
