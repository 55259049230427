
const BannerHelpCenter = (props) => {
    const { title, image, descrition } = props.bannerData;
    return (
      <>
        {
          <div className="help_banner">
            <div className="help_hero_sec">
              <div
                className="help_bg_img"
                style={{
                  backgroundImage: `url(${image})`,
                }}
              >
                <div className="container">
                  <div className="help_text">
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    ></h4>
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: descrition,
                      }}
                    ></h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  };
  
  export default BannerHelpCenter;
  