import React from "react";
import { images } from "../../actions/customFn";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function TopPost(props) {
  const { t } = useTranslation()
  const topPost = props.topPost;

  return (
    <div className="top_post_main">
      {
        topPost.length > 0 &&
        <>
          <div className="top_post_heading">
            <h2>{t("_Top_Post")}</h2>
          </div>

          <div className="recommended_things_list_new">
            <div className="row">
              {topPost.map((value, index) => {
                return (
                  <div
                    className="col-xl-12 col-lg-12 col-md-6 col-sm-6 col-12"
                    key={index}
                  >
                    <NavLink to={`/blog-details/${value.slug}`} target="_blank">
                      <div className="recommended_list_info_new">
                        <div
                          className="recommended_list_img_new"
                          style={{
                            backgroundImage: `url(${value.image})`,
                          }}
                        >
                          {/* <img src={value.image} alt="" /> */}
                        </div>
                        <div className="recommended_list_content_new">
                          <p>{value.status}</p>
                          <h3> {value.title}</h3>

                          <div className="recommended_list_profile_right">
                            <div className="recommended_list_profile_right_bg">
                              <img src={images["TopPost5.png"]} alt="" />
                            </div>
                            <span>{value.updated_at}</span>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default TopPost;
