import React from "react";
import { images } from "../actions/customFn";
import { Footer, HeaderSticky, BannerHelpCenter } from "../components";
import { motion } from "framer-motion";
import AffiliateCategoryTab from "../components/HelpCenter/AffiliateCategoryTab";

const AffiliateHelpCenter = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 3 }}
      >
        <HeaderSticky />
        <BannerHelpCenter
          bannerData={{
            title: "How Can I",
            image: images["help-banner.png"],
            descrition: "Help You?",
          }}
        />

        <AffiliateCategoryTab />
        <Footer />
      </motion.div>
    </>
  );
};

export default AffiliateHelpCenter;
