import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal } from "react-bootstrap";
import { ProductInformation } from "../../context";
import ReactStars from "react-rating-stars-component";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { t } from "i18next";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7.171"
        height="13.965"
        viewBox="0 0 7.171 13.965"
      >
        <path
          id="fi-rr-angle-small-right"
          d="M15.4,9.879,10.811,5.293A1,1,0,0,0,9.4,6.707l4.586,4.586a1,1,0,0,1,0,1.414L9.4,17.293a1,1,0,1,0,1.415,1.414L15.4,14.121A3,3,0,0,0,15.4,9.879Z"
          transform="translate(-9.107 -5.035)"
          fill="#111111"
        />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7.171"
        height="13.965"
        viewBox="0 0 7.171 13.965"
      >
        <path
          id="fi-rr-angle-small-right"
          d="M9.986,9.879l4.589-4.586a1,1,0,0,1,1.411,1.414L11.4,11.293a1,1,0,0,0,0,1.414l4.586,4.586a1,1,0,1,1-1.415,1.414L9.986,14.121A3,3,0,0,1,9.986,9.879Z"
          transform="translate(-9.107 -5.035)"
          fill="#111111"
        />{" "}
      </svg>
    </div>
  );
}

const ReviewImageModal = (props) => {
  const { checkRef2 } = useContext(ProductInformation);
  const reactRatings = {
    edit: false,
    activeColor: "rgb(252 83 1)",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  const executeCheckAScroll = () => {
    props.onCloseModal();
    checkRef2.current?.scrollIntoView({ behavior: "smooth" });
  };

  const { DetailsData } = useContext(ProductInformation);

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  const [nav3, setNav3] = useState(null);
  const [nav4, setNav4] = useState(null);
  const [slider3, setSlider3] = useState(null);
  const [slider4, setSlider4] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);

    setNav3(slider3);
    setNav4(slider4);
  },[slider1,slider2,slider3,slider4]);

  const settings = {
    infinite: true,
    initialSlide: props.productIndex,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 1000,
    asNavFor: ".slider-nav",

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    mobileFirst: true,
    // centerMode: true,
  };

  const settings2 = {
    infinite: DetailsData?.traveller_photos_with_review?.length > 1,
    initialSlide: props.productIndex,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 1000,
    asNavFor: ".slider-nav",

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    mobileFirst: true,
    // centerMode: true,
  };

  const thumbnailSettings = {
    initialSlide: props.productIndex,
    infinite: true,
    center: true,
    slidesToShow: 9,
    slidesToScroll: 9,
    asNavFor: ".slider-for",
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
    mobileFirst: true,
    // centerMode: true,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const thumbnailSettings2 = {
    initialSlide: props.productIndex,
    infinite: DetailsData?.traveller_photos_with_review?.length > 1,
    center: true,
    slidesToShow: 9,
    slidesToScroll: 9,
    asNavFor: ".slider-for",
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
    mobileFirst: true,
    // centerMode: true,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      <div class="content " style={{ background: "black" }}>
        <Modal
          className="gift_popup modal_review_image"
          show={props.showModal}
          onHide={() => {
            props.onCloseModal();
          }}
        >
          <div className="modal-body">
            <div className="btn-close_main">
              <a
                type="button"
                onClick={() => {
                  props.onCloseModal();
                }}
                className="btn-close"
              >
                {" "}
                <svg
                  viewport="0 0 12 12"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="1"
                    y1="11"
                    x2="11"
                    y2="1"
                    stroke="#fff"
                    strokeWidth="2"
                  />
                  <line
                    x1="1"
                    y1="1"
                    x2="11"
                    y2="11"
                    stroke="#fff"
                    strokeWidth="2"
                  />
                </svg>
              </a>
            </div>

            <Tabs
              defaultActiveKey={props.activeKey}
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              {/* {DetailsData?.images?.length > 0 && ( */}
                <Tab
                  className="tabs"
                  eventKey="provider"
                  title={`Provider photos (${DetailsData.images.length})`}
                >
                  <Slider
                    {...settings}
                    asNavFor={nav4}
                    ref={(slider) => setSlider3(slider)}
                  >
                    {DetailsData.images.length > 0 &&
                      DetailsData.images.map((value, index) => {
                        return (
                          <div
                            className="image-slider-grid-provider"
                            key={index}
                          >
                            <div className="image-inner-main">
                              <img alt="" src={value} />
                            </div>
                          </div>
                        );
                      })}
                  </Slider>
                  <div className="thumbnail-wrapper">
                    <Slider
                      {...thumbnailSettings}
                      asNavFor={nav3}
                      ref={(slider) => setSlider4(slider)}
                    >
                      {DetailsData.images.length > 0 &&
                        DetailsData.images.map((value, index) => {
                          return (
                            <div key={index}>
                              <hr />
                              <img src={value} alt={value} className="img" />
                              <hr />
                            </div>
                          );
                        })}
                    </Slider>
                  </div>
                </Tab>
              {/* )} */}
              {/* {DetailsData?.traveller_photos_with_review?.length > 0 && ( */}
                <Tab
                  className="tabs"
                  eventKey="traveller"
                  title={`Traveler photos (${DetailsData.traveller_photos_with_review.length})`}
                >
                  <Slider
                    {...settings2}
                    asNavFor={nav2}
                    ref={(slider) => setSlider1(slider)}
                  >
                    {DetailsData.traveller_photos_with_review.length > 0 &&
                      DetailsData.traveller_photos_with_review.map(
                        (value, index) => {
                          return (
                            <div className="image-slider-grid">
                              <div className="image-inner-main">
                                <img alt="user-image" src={value.image} />
                              </div>
                              <div className="image-rating-data">
                                <div class="user_review_in_img">
                                  <div className="user_review_img_name">
                                    <div className="user_review_img_name_inner">
                                      <img
                                        src={value.user_image}
                                        alt="user_image"
                                        className="user_image"
                                      />
                                      <span>{value.name}</span>
                                    </div>

                                    <div>
                                      <span>{value.time_ago}</span>
                                    </div>
                                  </div>

                                  <div className="star_list">
                                    <ReactStars
                                      style={{ cursor: "pointer !important" }}
                                      size={16}
                                      value={Number(value.rating)}
                                      {...reactRatings}
                                    />
                                  </div>
                                  <p className="reiew_title">{value.title}</p>
                                  <div className="review_desc_div">
                                    <p>{value.description}</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            // <div class="quote-container">
                            //   <div class="portrait octogon">
                            //     <img alt="" src={value.image} />
                            //   </div>
                            //   <div class="quote">
                            //     <p>{value.description}</p>
                            //     <span>{value.name}</span>
                            //     <br />
                            //     Thundercats twee
                            //     <br />
                            //     Austin selvage beard
                            //   </div>
                            // </div>
                          );
                        }
                      )}
                  </Slider>
                  <div className="thumbnail-wrapper">
                    <Slider
                      {...thumbnailSettings2}
                      asNavFor={nav1}
                      ref={(slider) => setSlider2(slider)}
                    >
                      {DetailsData.traveller_photos_with_review.length > 0 &&
                        DetailsData.traveller_photos_with_review.map(
                          (value, index) => {
                            return (
                              <div key={value.id}>
                                <hr />
                                <img
                                  src={value.image}
                                  alt={value.image}
                                  className="img"
                                />
                                <hr />
                              </div>
                            );
                          }
                        )}
                    </Slider>
                  </div>
                </Tab>
              {/* )} */}
            </Tabs>
            <div className="">
              <div className="checkavility_stickyflex">
                <h2> {DetailsData.description.title} </h2>
                <button onClick={() => executeCheckAScroll()}>
                  {t("_Check_Availability")}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ReviewImageModal;
