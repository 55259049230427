/* global google */
import React, { useState } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/fontawesome-free-solid";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";
// import customMarker from "../assets/images/google_marker.png"; // marker image
import customMarker from '../assets/images/marker.svg';
const UserMap = (props) => {
  const { t } = useTranslation();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const reactRatings = {
    edit: false,
    activeColor: "rgb(252 83 1)",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  let star = 0;
  return (
    <div>
      {props.places && (
        <GoogleMap
          zoom={props.zoom}
          ref={props.onMapMounted}
          options={{
            disableDefaultUI: true, // This removes all default UI controls
            gestureHandling: "greedy",
          }}
        >
          {props.places.map((val, index) => {
            return (
              // val.meeting_point_location_obj != "" &&
              <div key={index} className="usermap-map">
                <Marker
                  key={index}
                  position={{
                    lat: val.latitude,
                    lng: val.longitude,
                  }}
                  onClick={() => handleMarkerClick(val)}
                  icon={{ url: customMarker }}
                />
                {selectedMarker == val && (
                  <InfoWindow
                    // ref={props.infoWindowRef}
                    position={{
                      lat: val.latitude,
                      lng: val.longitude,
                    }}
                    onCloseClick={() => setSelectedMarker(null)}
                  >
                    <div className="row user-map-location mb-2">
                      <div
                        className={
                          star > 0
                            ? `col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 info-window-left-upper-div`
                            : "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 main-image-div-inner"
                        }
                        style={{
                          height:
                            val.review_description.length > 400 ? "auto" : "225px",
                        }}
                      >
                        <div className="main-top-map-div">
                          <div
                            className="location-main-img"
                            style={{ backgroundImage: `url(${val.image})` }}
                          >
                            <div className="prd_location_main mt-2">
                              <div className="icon_loc">
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                <span className="location-name">
                                  {val.location}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="prd_crd_loaction">
                            {/* <div className="prd_location_main mt-2">
                              <div className="icon_loc">
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                <span className="location-name">
                                  {val.location}
                                </span>
                              </div>
                            </div> */}
                            <div className="tour-name-and-date">
                              <div className="trip_details">
                                {val.activity_type}
                              </div>
                              <div className="">{val.date}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {star > 0 && (
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 location-images">
                          <h2>
                            <ReactStars
                              style={{ cursor: "pointer !important" }}
                              classNames="star-class"
                              size={16}
                              value={Number(2)}
                              {...reactRatings}
                            />
                          </h2>
                          <p
                            className="location-description"
                            dangerouslySetInnerHTML={{
                              __html: val.review_description,
                            }}
                          ></p>
                          {val.review_images.length > 0 && (
                            <div className="location-bottom-images">
                              {val.review_images.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="extra-images"
                                    style={{
                                      backgroundImage: `url(${item[index]})`,
                                    }}
                                  ></div>
                                );
                              })}
                              {/* <div
                                                                className="extra-images"
                                                                style={{ backgroundImage: `url(${images["guideNewBanner1.png"]})` }}
                                                            >
                                                            </div>

                                                            <div className="extra-images"
                                                                style={{ backgroundImage: `url(${images["guidebanner.png"]})` }}
                                                            >
                                                            </div>

                                                            <div className="extra-images"
                                                                style={{ backgroundImage: `url(${images["guide-desig8.png"]})` }}
                                                            >
                                                            </div> */}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </InfoWindow>
                )}
              </div>
            );
          })}
        </GoogleMap>
      )}
    </div>
  );
};

export default withScriptjs(withGoogleMap(UserMap));
