import React, { useContext, useEffect } from "react";
import { useState } from "react";
import TourAttractionModal from "./TourAttractionModal";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { uplodProductNew } from "../../../actions/productBuilderAction";
import { ProductBuilderContext } from "../../../context";
import {
  getArray,
  startSpinLoader,
  validateField,
} from "../../../actions/customFn";
import { useTranslation } from "react-i18next";
// 'SET_FLEXIBLE' 
const TourComponent = (props) => {
  const { t } = useTranslation();
  const { tour_id } = useParams();
  const {
    setTourId,
    productType,
    runningStep,
    getData,
    googleKey,
    setSpinLoader,
    saveAndExit,
    exitRoute,
    productInfo, 
    languageIdSelected,
  } = useContext(ProductBuilderContext);
  const languageReducer = useSelector((state) => state.languageReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tourmodel, setTourmodel] = useState(false);
  const [tourAttraction, setTourAttraction] = useState([]);
  const [tourEditData, setEditTourData] = useState({});
  const [tourEditAttraction, setEditTourAttraction] = useState([]);
  const [tourEditAttractionKey, setEditTourAttractionKey] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [tourData, setTourData] = useState({
    duration_type: "SET_DURATION",
    hours: "",
    minutes: "",
    time_from: "",
    time_to: "",
    time_type: "",
    step: "six",
    tourId: tour_id,
    product_type: productType,
    language: productInfo.productLanguageId,
    isChange:0
  });
  const [newAttractionKey, setNewAttractionKey] = useState(0);
  const [formErrors, setFormError] = useState({});
  const errors = {};

  useEffect(() => {
    if (languageReducer != "") {
      getData("six", setEditTourData);
    }
  }, [languageReducer, languageIdSelected]);

  useEffect(() => {
    if (tourEditData != undefined && tourEditData.product_data != undefined) {
      const { product_data } = tourEditData;
      setTourData({
        duration_type: product_data?.duration_type,
        hours: product_data?.hours,
        minutes: product_data?.minutes,
        time_from: product_data?.time_from,
        time_to: product_data?.time_to,
        time_type: product_data?.time_type,
        step: "six",
        tourId: tour_id,
        product_type: productType,
        language: productInfo.productLanguageId,
        attraction_transport_list: product_data?.attraction_transport_list,
        isChange:product_data?.isChange
      });
      setTourAttraction(product_data.attraction);
    }
  }, [tourEditData]);

  const handleFlexibleChange = (e) => {
    if (e.target.checked) {
      setTourData({ ...tourData, duration_type: "SET_FLEXIBLE",'isChange':1 });
    } else {
      setTourData({ ...tourData, duration_type: "SET_DURATION",'isChange':1 });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const onlyNumbers = /^[0-9]*$/;
    if (
      name == "hours" ||
      name == "minutes" ||
      name == "time_from" ||
      name == "time_to"
    ) {
      if (onlyNumbers.test(value) == true) {
        setTourData((prev) => ({ ...prev, [name]: Number(value) }));
      }
    } else {
      setTourData((prev) => ({ ...prev, [name]: value }));
    }
    setTourData((prev) => ({ ...prev, 'isChange': 1 }));
  };

  const handleRemoveAttraction = (key) => {
    const newData = [...tourAttraction];
    newData.splice(key, 1);
    setTourAttraction(newData);
    setTourData((prev) => ({ ...prev, 'isChange': 1 }));
  };

  const handleSubmit = (e, exit) => {
    if (e) {
      e.preventDefault();
    }
    
    
    if (tourAttraction?.length > 0) {
      let validate = {};
      if (tourData.duration_type == "SET_FLEXIBLE") {
        if (tourData.time_from == "" || tourData.time_from == null) {
          validate = { ...validate, duration: "RequiredNoBorder|" };
        } else {
          validate = { ...validate, time_to: "min:" + tourData.time_from };
        }
      } else {
        if (
          (tourData.hours == "" || tourData.hours == null) &&
          (tourData.minutes == "" || tourData.minutes == null)
        ) {
          validate = { ...validate, duration: "RequiredNoBorder|" };
        } else {
          var errorField = "";
          var checkError = 1;
          if (tourData.hours == "0") {
            checkError = 0;
            validate = { ...validate, hours: "min:1" };
          } else {
            errorField = "hours";
            var removefiledNew = document.getElementById("error_" + errorField);
            if (removefiledNew) {
              removefiledNew.remove();
              let removefirstErrorElement =
                document.getElementsByName(errorField)[0];
              if (removefirstErrorElement) {
                removefirstErrorElement.classList.remove("form_error_field");
              }
            }
          }
          if (tourData.hours <= 0) {
            if (tourData.minutes == "0") {
              checkError = 0;
              validate = { ...validate, minutes: "min:1" };
            } else {
              errorField = "minutes";
              var removefiledNew = document.getElementById(
                "error_" + errorField
              );
              if (removefiledNew) {
                removefiledNew.remove();
                let removefirstErrorElement =
                  document.getElementsByName(errorField)[0];
                if (removefirstErrorElement) {
                  removefirstErrorElement.classList.remove("form_error_field");
                }
              }
            }
          }

          if (checkError == 1) {
            var removefiled = document.getElementById("error_duration");
            if (removefiled) {
              removefiled.remove();
            }
          }
        }
      }

      validateField(validate, { ...tourData }).then((res) => {
        startSpinLoader("cover-spin");
        if (res == true) {
          const sendData = {
            ...tourData,
            attraction_id: getArray(tourAttraction, "attraction_id"),
            attraction_title: getArray(tourAttraction, "attraction_title"),
            attraction_description: getArray(
              tourAttraction,
              "attraction_description"
            ),
            is_pass_by_without_stopping: getArray(
              tourAttraction,
              "is_pass_by_without_stopping"
            ),
            attraction_time: getArray(tourAttraction, "attraction_time"),
            attraction_time_type: getArray(
              tourAttraction,
              "attraction_time_type"
            ),
            include_price_tour: getArray(tourAttraction, "include_price_tour"),
            attraction_location_obj: getArray(
              tourAttraction,
              "attraction_location_obj"
            ),
            attraction_latitude: getArray(
              tourAttraction,
              "attraction_latitude"
            ),
            attraction_longitude: getArray(
              tourAttraction,
              "attraction_longitude"
            ),

            attraction_transport_by: getArray(
              tourAttraction,
              "attraction_transport_by"
            ),
            attraction_transport_time: getArray(
              tourAttraction,
              "attraction_transport_time"
            ),
            attraction_transport_time_type: getArray(
              tourAttraction,
              "attraction_transport_time_type"
            ),
          };
          let nextRoute = exit || "guide-activity-info";
          if (languageReducer !== "") {
            dispatch(
              uplodProductNew(
                languageReducer,
                setTourId,
                navigate,
                sendData,
                nextRoute,
                "",
                "",
                "",
                "",
                "",
                "",
                setSpinLoader
              )
            );
          }
        } else {
          setSpinLoader((status) => !status);
        }
      });
    }else{

      errors.attraction_tour = "Atleast one tour attraction is required";
      setFormError(errors)
    }
  };

  useEffect(() => {
    if (isFirstRender == false) {
      handleSubmit("", exitRoute);
    } else {
      setIsFirstRender(false);
    }
  }, [saveAndExit]);



  return (
    <>
      <div className="meet-pickup-main tour_detail_fields">
        <form onSubmit={handleSubmit}>
          <div className="activity_productdetail">
            <h1> {t("_Tell_us_about_your_tour")}</h1>
            <p>
              {t(
                "_Be_sure_to_enter_all_key_attractions_in_your_itinerary_This_helps_travelers_find_and_book_your_experience."
              )}
            </p>

            <h6>
              {t(
                "_What_is_the_total_duration_of_your_tour_from_start_to_end_including_pickup_and_drop-off_(if_applicable)?"
              )}
            </h6>
            <div className="checkbox_product_title">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexible"
                name="flexible"
                checked={tourData.duration_type === "SET_FLEXIBLE"}
                value={""}
                onChange={(e) => {
                  handleFlexibleChange(e);
                }}
              />
              <span>{t("_Flexible")}</span>
            </div>
            <div className="inner_tour_time">
              <label htmlFor="">
                {" "}
                {t("_Duration")} <span className="required-field">*</span>
              </label>
              {tourData.duration_type === "SET_DURATION" ? (
                <div className="time_inputboxes" name="duration">
                  <div className="tbox_one" name="hours">
                    <input
                      type="text"
                      name="hours"
                      value={tourData.hours}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      placeholder="0"
                    />
                    <span>{t("_hours")}</span>
                  </div>

                  <div className="tbox_one" name="minutes">
                    <input
                      type="text"
                      name="minutes"
                      value={tourData.minutes}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      placeholder="0"
                    />
                    <span>{t("_minutes")}</span>
                  </div>
                </div>
              ) : (
                <div className="time_inputboxes" name="duration">
                  <div className="tbox_one" name="time_from">
                    <input
                      type="text"
                      value={tourData.time_from ? tourData.time_from : ""}
                      name="time_from"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      placeholder="0"
                    />
                  </div>
                  <span>{t("_to")}</span>
                  <div className="tbox_one" name="time_to">
                    <input
                      type="text"
                      name="time_to"
                      value={tourData.time_to ? tourData.time_to : ""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      placeholder="0"
                    />
                    <select
                      id="time_type"
                      value={tourData.time_type}
                      name="time_type"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <option value="minute">{t("_minute")}</option>
                      <option value="hour">{t("_hour")}</option>
                    </select>
                  </div>
                </div>
              )}
            </div>

            <div className="attraction-travelers">
              <h6>{t("_What_attractions_will_travelers_see?")}</h6>
              <p>
                {t(
                  "_Enter_all_landmarks_or_points_of_interest_in_your_itinerary."
                )}
              </p>
              <h6>
                {t("_Tour_Details")}
                <span className="required-field">*</span>
              </h6>
              {tourAttraction.length > 0 &&
                tourAttraction.map((value, index) => {
                  return (
                    <div className="inclueded_partsection" key={index}>
                      <div className="attraction_multiple">
                        <h3>{value?.attraction_title}</h3>
                        <p>{value?.attraction_description}</p>
                      </div>

                      <div>
                        <span
                          className="cursor-pointer"
                          onClick={(e) => {
                            setEditTourAttractionKey(index);
                            setNewAttractionKey(index);
                            setTourmodel(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M20.6626 6.64062L17.3762 3.33737C17.159 3.1213 16.8651 3 16.5588 3C16.2524 3 15.9585 3.1213 15.7413 3.33737L4.09038 14.9715L3.02662 19.5625C2.98992 19.7303 2.99118 19.9042 3.0303 20.0715C3.06942 20.2388 3.14542 20.3952 3.25273 20.5294C3.36005 20.6635 3.49598 20.772 3.65059 20.8469C3.80519 20.9217 3.97458 20.9611 4.14637 20.9622C4.22641 20.9703 4.30707 20.9703 4.38711 20.9622L9.02846 19.8984L20.6626 8.27545C20.8787 8.05828 21 7.76439 21 7.45804C21 7.15169 20.8787 6.8578 20.6626 6.64062ZM8.46859 18.8907L4.11837 19.8032L5.10935 15.537L13.8266 6.85338L17.1858 10.2126L8.46859 18.8907ZM17.936 9.4008L14.5768 6.04156L16.5252 4.1044L19.8284 7.46364L17.936 9.4008Z"
                              fill="#fc5301"
                            />
                          </svg>
                        </span>
                        <span
                          className="cursor-pointer"
                          onClick={(e) => {
                            handleRemoveAttraction(index);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                              fill="#fc5301"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  );
                })}

              <button
                name="attraction_title.0"
                className="trasnparent_buton mt-2"
                onClick={(event) => {
                  setTourmodel(true);
                  setNewAttractionKey(tourAttraction.length);
                  setFormError("")
                }}
                type="button"
              >
                + {t("_add_an_tour_attraction")}
              </button>
             
            </div>
            {formErrors?.attraction_tour && 
              <span className="text-danger">{formErrors.attraction_tour}</span>
              }

            <div className="btn-last-save">
              <button className="btn-all-save" type="submit">
                {" "}
                {t("_Save_&_continue")}
              </button>
            </div>
          </div>
        </form>
      </div>
      <TourAttractionModal
        tourAttraction={tourAttraction}
        tourEditAttraction={tourEditAttraction}
        setTourAttraction={setTourAttraction}
        tourmodel={tourmodel}
        setTourmodel={setTourmodel}
        googleKey={googleKey}
        tourEditAttractionKey={tourEditAttractionKey}
        setEditTourAttractionKey={setEditTourAttractionKey}
        newAttractionKey={newAttractionKey}
        tourData={tourData}
      />
    </>
  );
};

export default TourComponent;
