import { createContext } from "react";
export const HomeContext = createContext();
export const ProductInformation = createContext();
export const ProductListingInfo = createContext();
export const AboutUsInformation = createContext();
export const BlogContext = createContext();
export const AffiliateContext = createContext();
export const PartnerContext = createContext();
export const TourFilesContext = createContext();
export const ProductBuilderContext = createContext();
export const PrincingScheduleContext = createContext();
export const ProviderContext = createContext();

