import React, { useEffect, useState } from "react";
import { MetaData } from "../../actions/customFn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingBar from "../Loading/LoadingBar";
import {
  getSupplierReservationSystem,
  updateReservationData,
  SentSecurityCode,
  checkSecurityCode,
} from "../../actions/providerAction";

const ReservationSystem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileReducer = useSelector((state) => state.profileReducer);
  const languageReducer = useSelector((state) => state.languageReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [reservationData, setReservationData] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [securityKey, setSecurityKey] = useState("");
  const [errorSecurityKey, setErrorSecurityKey] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    reservation_system: 1,
    secret_key: "",
    access_key: "",
    uuid: "",
  });
  const [formErrors, setFormError] = useState({});
  const errors = {};
  useEffect(() => {
    if (languageReducer) {
      dispatch(
        getSupplierReservationSystem(
          setReservationData,
          languageReducer,
          navigate,
          setIsLoading
        )
      );
    }
  }, [languageReducer]);

  useEffect(() => {
    if (reservationData) {
      setFormData((prev) => ({
        ...prev,
        reservation_system:
          reservationData?.reservation_system || formData.reservation_system,
        secret_key: reservationData?.secret_key || "",
        access_key: reservationData?.access_key || "",
        uuid: reservationData?.uuid || "",
      }));

      if (reservationData?.reservation_id) {
        setIsEditable(false);
      } else {
        setIsEditable(true);
      }
    }
  }, [reservationData]);
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Submit Security Key
  const handleSecurityKeySubmit = () => {
    const userEmail = profileReducer?.email;
    if (userEmail && securityKey) {
      dispatch(
        checkSecurityCode(
          userEmail,
          securityKey,
          languageReducer,
          navigate,
          setIsLoading
        )
      ).then((response) => {
        if (response?.data?.status) {
          setIsEditable(true);
        } else {
          setErrorSecurityKey(response?.data?.message);
        }
      });
      setErrorSecurityKey("");
    } else {
      setErrorSecurityKey("Please enter a valid key.");
    }
  };

  // Generate Security Code
  const handleGenerateKey = () => {
    const userEmail = profileReducer?.email;
    if (userEmail) {
      dispatch(
        SentSecurityCode(
          userEmail,
          languageReducer,
          navigate,
          setIsLoading
        )
      );
    }
  };

  const validateFormData = () => {
    if (formData.reservation_system == "") {
      errors.reservation_system = "Enter reservation_system";
    }

    if (formData.secret_key == "") {
      errors.secret_key = "Enter secret key";
    }

    if (formData.access_key == "") {
      errors.access_key = "Enter access key";
    }
    if (formData.uuid == "") {
      errors.uuid = "Enter uuid number";
    }
    setFormError(errors);
  };

  const Update = () => {
    validateFormData();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      dispatch(
        updateReservationData(languageReducer, formData, navigate, setIsLoading)
      );
    }
  };

  // Function to handle the 'View' button click
  const handleViewClick = () => {
    setShowPopup(true);
  };

  // Function to handle the 'Close' button click
  const handleBack = () => {
    setShowPopup(false);
  };
  const metaData = {
    title: t("_Reservation_System"),
  };

  return (
    <>
      {metaData && <MetaData data={metaData} />}
      <div className="affiliate_profile_main">
        <div className="text_banner">
          <span>{t("_Reservation_System")} </span>
        </div>
        {isLoading ? (
          <div className="blinking-bar-loader">
            <LoadingBar />
          </div>
        ) : (
          <div className="user_edit_main">
            {reservationData && !isEditable && !showPopup && (
              <>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                  <button className="paypal-set-btn" onClick={handleViewClick}>
                    {t("_view_detail")}
                  </button>
                </div>

                <div className="input_all_edit">
                  <h4 htmlFor="reservation-integration-title">
                    {t("_security_key")}
                  </h4>
                  <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 d-flex flex-column"></div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex flex-column">
                      <div className="heder_new_search_affiliate">
                        <div className="heder_new_search">
                          <div className="input_search">
                            <label htmlFor="">{t("_security_key")}</label>
                            <input
                              className={`form-control mt-2 ${
                                errorSecurityKey ? "is-invalid" : ""
                              }`}
                              type="text"
                              name="securityKey"
                              placeholder={t("_Enter_security_key")}
                              onChange={(e) => setSecurityKey(e.target.value)}
                              value={securityKey}
                            />
                            {errorSecurityKey && (
                              <div className="invalid-feedback">
                                {errorSecurityKey}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <button
                        className="paypal-set-btn mt-3"
                        onClick={handleSecurityKeySubmit}
                      >
                        {t("_submit")}
                      </button>
                      <div className="forget_password mt-2 text-center">
                        <NavLink
                          onClick={handleGenerateKey}
                   
                          className="generate-new-key mt-2"
                        >
                          {t("_Generate_New_Key")}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!reservationData && isEditable && (
              <div className="input_all_edit">
                <h4 htmlFor="reservation-integration-title">
                  Bokon Reservation Integration
                </h4>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main">
                    <div className="heder_new_search_affiliate">
                      <div className="heder_new_search">
                        <div className="input_search">
                          <label htmlFor="">{t("_Access_Key")}</label>
                          <input
                            className={`form-control mt-2 ${
                              formErrors.access_key ? "is-invalid" : ""
                            }`}
                            type="text"
                            name="access_key"
                            placeholder={t("_Enter_access_key")}
                            onChange={handleChange}
                            value={formData.access_key}
                            disabled={!isEditable}
                          />
                          {formErrors.access_key && (
                            <div className="invalid-feedback">
                              {formErrors.access_key}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                    <div className="heder_new_search_affiliate">
                      <div className="heder_new_search">
                        <div className="input_search">
                          <label htmlFor="">{t("_Secret_Key")}</label>
                          <input
                            className={`form-control mt-2 ${
                              formErrors.secret_key ? "is-invalid" : ""
                            }`}
                            type="text"
                            name="secret_key"
                            placeholder={t("_Enter_secret_key")}
                            onChange={handleChange}
                            value={formData.secret_key}
                            disabled={!isEditable}
                          />
                          {formErrors.secret_key && (
                            <div className="invalid-feedback">
                              {formErrors.secret_key}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                    <div className="heder_new_search_affiliate">
                      <div className="heder_new_search">
                        <div className="input_search">
                          <label htmlFor="">{t("_UUID")}</label>
                          <input
                            className={`form-control mt-2 ${
                              formErrors.uuid ? "is-invalid" : ""
                            }`}
                            type="text"
                            name="uuid"
                            placeholder={t("_Enter_uuid_number")}
                            onChange={handleChange}
                            value={formData.uuid}
                            disabled={!isEditable}
                          />
                          {formErrors.uuid && (
                            <div className="invalid-feedback">
                              {formErrors.uuid}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                    <button
                      className="paypal-set-btn"
                      onClick={(e) => Update()}
                      disabled={!isEditable}
                    >
                      {t("_Update")}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {isEditable && (
              <div className="input_all_edit">
                <h4 htmlFor="reservation-integration-title">
                  Bokon Reservation Integration
                </h4>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main">
                    <div className="heder_new_search_affiliate">
                      <div className="heder_new_search">
                        <div className="input_search">
                          <label htmlFor="">{t("_Access_Key")}</label>
                          <input
                            className={`form-control mt-2 ${
                              formErrors.access_key ? "is-invalid" : ""
                            }`}
                            type="text"
                            name="access_key"
                            placeholder={t("_Enter_access_key")}
                            onChange={handleChange}
                            value={formData.access_key}
                          />
                          {formErrors.access_key && (
                            <div className="invalid-feedback">
                              {formErrors.access_key}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                    <div className="heder_new_search_affiliate">
                      <div className="heder_new_search">
                        <div className="input_search">
                          <label htmlFor="">{t("_Secret_Key")}</label>
                          <input
                            className={`form-control mt-2 ${
                              formErrors.secret_key ? "is-invalid" : ""
                            }`}
                            type="text"
                            name="secret_key"
                            placeholder={t("_Enter_secret_key")}
                            onChange={handleChange}
                            value={formData.secret_key}
                          />
                          {formErrors.secret_key && (
                            <div className="invalid-feedback">
                              {formErrors.secret_key}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                    <div className="heder_new_search_affiliate">
                      <div className="heder_new_search">
                        <div className="input_search">
                          <label htmlFor="">{t("_UUID")}</label>
                          <input
                            className={`form-control mt-2 ${
                              formErrors.uuid ? "is-invalid" : ""
                            }`}
                            type="text"
                            name="uuid"
                            placeholder={t("_Enter_uuid_number")}
                            onChange={handleChange}
                            value={formData.uuid}
                          />
                          {formErrors.uuid && (
                            <div className="invalid-feedback">
                              {formErrors.uuid}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                    <button
                      className="paypal-set-btn"
                      onClick={(e) => Update()}
                    >
                      {t("_Update")}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {showPopup && (
              <>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                  <button className="paypal-set-btn" onClick={handleBack}>
                    {t("_Back")}
                  </button>
                </div>
                <div className="input_all_edit">
                  <h4 htmlFor="reservation-integration-title">
                    Bokon Reservation Integration
                  </h4>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main">
                      <div className="heder_new_search_affiliate">
                        <div className="heder_new_search">
                          <div className="input_search">
                            <label htmlFor="">{t("_Access_Key")}</label>
                            <input
                              className={`form-control mt-2 ${
                                formErrors.access_key ? "is-invalid" : ""
                              }`}
                              type="text"
                              name="access_key"
                              value={formData.access_key}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                      <div className="heder_new_search_affiliate">
                        <div className="heder_new_search">
                          <div className="input_search">
                            <label htmlFor="">{t("_Secret_Key")}</label>
                            <input
                              type="text"
                              name="secret_key"
                              value={formData.secret_key}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 search_header_main mt-2">
                      <div className="heder_new_search_affiliate">
                        <div className="heder_new_search">
                          <div className="input_search">
                            <label htmlFor="">{t("_UUID")}</label>
                            <input
                              type="text"
                              name="uuid"
                              value={formData.uuid}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ReservationSystem;
