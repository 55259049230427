import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import validator from "validator";
import { Tab, Tabs } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { asyncLogin, asyncSignup } from "../../actions/loginAction";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import FacebookLoginComponent from "../SocialLogin/FacebookLoginComponent";
import GoogleLoginComponent from "../SocialLogin/GoogleLoginComponent";
import ForgotPasswordModal from "./ForgotPasswordModel";
import LocaleContext from "../LocaleContext";
import { PuffLoader } from "react-spinners";
import MessageModal from "./MessageModal";

const LoginModal = (props) => {
  const { t } = useTranslation();
  const languageReducer = useSelector((state) => state.languageReducer);
  const {
    SetLoginKey,
    loginKey,
    redirectingUrl,
    setRedirectingUrl,
    setLoggedInUser,
  } = useContext(LocaleContext);

  const { loginInfo, LogincloseModal } = props;
  const [buttonDisable, setButtonDisable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // Login Code start
  const errors = {};
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [inputpassword, setInputPassword] = useState("password");
  const [formErrors, setFormErrors] = useState({});
  const [forgotOpen, setForgotOpen] = useState(false);
  const phoneCodeReducer = useSelector((state) => state.phoneCodeReducer);
  const [phoneCodeList, setPhoneCodeList] = useState([]);
  const [countryFlag, setCountryFlag] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  let message = "";
  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "password") {
      if (e.target.value.length > 16) {
        errors.password = "Maximum password limit is 15 digit";
        setFormErrors(errors);
      } else {
        setPassword(e.target.value);
        errors.password = "";
        setFormErrors(errors);
      }
    }
  };

  const handleKeyPress = (event, key) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default form submission
      if (key == "login") {
        handleOnClick(event); //for login
      }
      if (key == "signup") {
        handleSignup(event); //for signup
      }
    }
  };

  const validation = () => {
    if (!validator.isEmail(email)) {
      errors.email = t("_enter_valid_email_id");
    } else {
    }
    if (password.length === 0) {
      errors.password = t("_enter_valid_password");
    }
    setFormErrors(errors);
  };

  const resetForm = () => {
    setEmail("");
    setPassword("");
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    validation();
    if (Object.keys(errors).length === 0) {
      setButtonDisable(true);
      const formData = {
        email: email,
        password: password,
        language: languageReducer.id,
        token: localStorage.getItem("_token"),
        currency: localStorage.getItem("_currency"),
        login_type: "website",
      };

      dispatch(
        asyncLogin(
          formData,
          resetForm,
          setButtonDisable,
          LogincloseModal,
          navigate,
          setFormErrors,
          errors,
          location.pathname.split("/")[1], //original path without slug
          redirectingUrl,
          setRedirectingUrl,
          setLoggedInUser
        )
      );
    }
  };

  // Login Code End

  // Sign up code start

  //Singup
  const singup_errors = {};
  const [register, setRegister] = useState({
    name: "",
    last_name: "",
    phone_number: "",
    email: "",
    company_name: "",
    company_email: "",
    company_phone: "",
    company_address: "",
    password: "",
    confirm_password: "",
    phone_code: "",
  });

  const [signupformErrors, setSignupFormErrors] = useState({});

  const singup_validation = () => {
    if (register.name?.length === 0) {
      singup_errors.name = t("_enter_name");
    }

    if (register.last_name?.length === 0) {
      singup_errors.last_name = t("_enter_last_name");
    }

    if (register.phone_code?.length === 0) {
      singup_errors.phone_code = t("_select_phone_code");
    }

    if (register.phone_number === "") {
      singup_errors.phone_number = t("_select_phone_number");
    } else if (!validator.isNumeric(register.phone_number)) {
      singup_errors.phone_number = t("_phone_number_must_be_numeric");
    } else if (
      register.phone_number?.length < 6 ||
      register.phone_number?.length > 15
    ) {
      singup_errors.phone_number = t("_phone_number_must_be_of_6_to_15_digit");
    }

    if (!validator.isEmail(register.email) || register.email?.length === 0) {
      singup_errors.email = t("_enter_valid_email_id");
    }
    if (register.password?.length === 0) {
      singup_errors.password = t("_Enter_Password");
    } else if (register.password?.length < 8) {
      singup_errors.password = t("_password_has_at_least_8_characters");
    }
    if (register.confirm_password?.length === 0) {
      singup_errors.confirm_password = t("_enter_confirm_password");
    }
    if (register.password !== register.confirm_password) {
      singup_errors.confirm_password = t("_confirm_password_does_not_match");
    }

    if (props.userType == "Partner") {
      if (register.company_name?.length === 0) {
        singup_errors.company_name = t("_enter_company_name");
      }

      if (register.company_phone?.length < 5) {
        singup_errors.company_phone = t("_phone_no_minimum_5_digit");
      }
      if (register.company_phone?.length > 12) {
        singup_errors.company_phone = t("_phone_no_maximum_12_digit");
      }
      if (!validator.isNumeric(register.company_phone)) {
        singup_errors.company_phone = t("_enter_must_be_numeric");
      } else if (register.phone_number?.length === 0) {
        singup_errors.company_phone = t("_enter_company_phone_no");
      }

      if (
        !validator.isEmail(register.company_email) ||
        register.company_email?.length === 0
      ) {
        singup_errors.company_email = t("_enter_company_valid_email_id");
      }

      if (register.company_address?.length === 0) {
        singup_errors.company_address = t("_enter_company_address");
      }
      if (register.terms_condition == "false") {
        singup_errors.terms_condition = t("_accept_terms_condition");
      }
    }

    setSignupFormErrors(singup_errors);
  };

  const resetSignupForm = () => {
    setRegister({
      name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      confirm_password: "",
      phone_code: "",
      terms_condition: false,
    });
  };

  
  

  const handleSignupChange = (e) => {
    const { name, value,checked } = e.target;
    const onlyNumbers = /^[0-9]*$/;

    if (name === "phone_number" || name === "company_phone") {
      if (onlyNumbers.test(value) === true) {
        setRegister({ ...register, [name]: value });
      }
    } else if (name == "password") {
      if (value.length > 16) {
        singup_errors.password = t("_maximum_password_limit_is_15_digit");
      } else {
        setRegister({ ...register, [name]: value });
        singup_errors.password = "";
      }
    } else if (name == "confirm_password") {
      if (value.length > 16) {
        singup_errors.confirm_password = t(
          "_maximum_password_limit_is_15_digit"
        );
      } else {
        setRegister({ ...register, [name]: value });
        singup_errors.confirm_password = "";
      }
    } else if (name == "terms_condition") {
      setRegister((register) => ({
        ...register,
        [name]: checked ? "true" : "false",
      }));
      singup_errors.terms_condition = "";
    } else {
      setRegister({ ...register, [name]: value });
    }
    setSignupFormErrors(singup_errors);
  };

  const handleSignup = (e) => {
    e.preventDefault();
    singup_validation();

    if (Object.keys(singup_errors).length === 0) {
      setButtonDisable(true);
      let formData = register;
      formData.language = languageReducer.id;
      formData.token = localStorage.getItem("_token");
      if (props.userType === "Partner") {
        formData.user_type = "Partner";
      } else if (props.userType === "Affiliate") {
        formData.user_type = "Affiliate";
      } else {
        formData.user_type = "Customer";
      }

      dispatch(
        asyncSignup(
          formData,
          resetSignupForm,
          setButtonDisable,
          SetLoginKey,
          LogincloseModal,
          navigate,
          props.userType,
          openMessageModal
        )
      );
    }
  };

  const openMessageModal = ()=>{
    setMessageModal(true);
  }
  const closeForgotPasswordModel = () => {
    setForgotOpen(false);
  };

  // ============PhoneCode code starts===========
  useEffect(() => {
    if (phoneCodeReducer) {
      setPhoneCodeList(phoneCodeReducer);
    }
  }, [phoneCodeReducer]);

  const handlePhoneCode = (event, data) => {
    if (data) {
      const { code } = data;
      setRegister((prev) => ({ ...prev, phone_code: code }));
    } else {
      setCountryFlag("");
    }
  };

  const renderImage = (option) => {
    setCountryFlag(option.flag);
    return "";
  };
  // ============PhoneCode code ends===========

  return (
    <div className="login-signup-model">
      <Modal
        className="login_popup"
        show={props.LoginModal}
        onHide={() => {
          props.LogincloseModal();
          setFormErrors({});
          resetSignupForm();
          setCountryFlag("");
        }}
      >
        <div className="login_model_main">
          <button
            className="btn_close"
            onClick={() => {
              props.LogincloseModal();
              setFormErrors({});
              resetSignupForm();
              setCountryFlag("");
            }}
          >
            {" "}
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className="login_inner_modal">
            <div
              className={`login_section new_login_main ${
                loginKey == ""
                  ? "login"
                  : loginKey === "login"
                  ? "login_parent"
                  : "signup_parent"
              }`}
            >
              <Tabs
                activeKey={loginKey == "" ? "login" : loginKey}
                onSelect={(key) => SetLoginKey(key)}
                transition={true}
                id="noanim-tab-example"
              >
                <Tab eventKey="login" title={t("_login")}>
                  <div className="login_heading">
                    <h2>{t("_login")}</h2>
                    <p>{t("_please_login_your_account")}</p>
                  </div>
                  <form action="">
                    <div className="main_form">
                      <div className="input_all_login">
                        <label>{t("_email")}</label>
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onKeyUp={(e) => handleKeyPress(e, "login")}
                          value={email}
                          className={
                            `form-control  ` +
                            (formErrors.email ? "is-invalid" : "")
                          }
                          placeholder={t("_enter_your_email")}
                          autoFocus
                        />
                        {formErrors.email && (
                          <div className="invalid-feedback">
                            {formErrors.email}
                          </div>
                        )}
                      </div>
                      <div className="input_all_login password-field ">
                        <label>{t("_password")}</label>
                        <input
                          type={showPassword == true ? "text" : "password"}
                          className={
                            `form-control ` +
                            (formErrors.password ? " is-invalid" : "")
                          }
                          name="password"
                          onChange={handleChange}
                          onKeyUp={(e) => handleKeyPress(e, "login")}
                          value={password}
                          placeholder={t("_enter_your_password")}
                        />
                        {showPassword === true ? (
                          <svg
                            onClick={() => setShowPassword(!showPassword)}
                            className="paassword-eye-hide"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="VisibilityOffIcon"
                          >
                            <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"></path>
                          </svg>
                        ) : (
                          <svg
                            onClick={() => setShowPassword(!showPassword)}
                            className="paassword-eye-show"
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="VisibilityIcon"
                          >
                            <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
                          </svg>
                        )}
                        {formErrors.password && (
                          <div className="invalid-feedback">
                            {formErrors.password}
                          </div>
                        )}
                        {formErrors.invalidCredentials && (
                          <div className="invalid-feedback">
                            {formErrors.invalidCredentials}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>

                  {props.userType !== "Partner" &&
                    props.userType !== "Affiliate" && (
                      <>
                        <FacebookLoginComponent
                          LogincloseModal={LogincloseModal}
                        />
                        <GoogleLoginComponent
                          LogincloseModal={LogincloseModal}
                        />
                      </>
                    )}

                  <div className="forget_password mt-3">
                    {/* <NavLink to="/forget-password"> */}
                    <NavLink
                      onClick={() => {
                        setForgotOpen(true);
                        LogincloseModal();
                      }}
                    >
                      {t("_forgot_password")} ?
                    </NavLink>
                  </div>

                  <div className="login_button">
                    <button onClick={handleOnClick} disabled={buttonDisable}>
                      {buttonDisable ? (
                        <div className="order-cancelling-loader-btn">
                          <PuffLoader size={30} color="#fff" />
                        </div>
                      ) : (
                        t("_login")
                      )}
                    </button>
                  </div>
                </Tab>

                <Tab eventKey="signup" title={t("_signup")}>
                  <div className="login_heading">
                    <h2>{t("_signup")}</h2>
                    <p>{t("_signup_new_account")}</p>
                  </div>
                  <form action="">
                    <div className="row signup_all_form">
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="input_all_login">
                          <label>{t("_first_name")}</label>
                          <input
                            type="text"
                            className={
                              `form-control  ` +
                              (signupformErrors.name ? "is-invalid" : "")
                            }
                            name="name"
                            onChange={handleSignupChange}
                            onKeyUp={(e) => handleKeyPress(e, "signup")}
                            value={register.name}
                            placeholder={t("_first_name")}
                          />
                          {signupformErrors.name && (
                            <div className="invalid-feedback">
                              {signupformErrors.name}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="input_all_login">
                          <label>{t("_last_name")}</label>
                          <input
                            type="text"
                            className={
                              `form-control  ` +
                              (signupformErrors.last_name ? "is-invalid" : "")
                            }
                            name="last_name"
                            onChange={handleSignupChange}
                            onKeyUp={(e) => handleKeyPress(e, "signup")}
                            value={register.last_name}
                            placeholder={t("_last_name")}
                          />
                          {signupformErrors.last_name && (
                            <div className="invalid-feedback">
                              {signupformErrors.last_name}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="input_all_login">
                          <label>{t("_email")}</label>
                          <input
                            type="text"
                            className={
                              `form-control  ` +
                              (signupformErrors.email ? "is-invalid" : "")
                            }
                            placeholder={t("_enter_your_email")}
                            onChange={handleSignupChange}
                            onKeyUp={(e) => handleKeyPress(e, "signup")}
                            value={register.email}
                            name="email"
                          />
                          {signupformErrors.email && (
                            <div className="invalid-feedback">
                              {signupformErrors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 sighn-up-phonecode">
                        <div className="input_all_login">
                          <label className="label_all">
                            {t("_country_code")}
                          </label>
                          {phoneCodeList && (
                            <Autocomplete
                              id="phone_code"
                              name="phone_code"
                              className="new_filter_select"
                              sx={{ width: 200 }}
                              options={phoneCodeList}
                              onChange={(event, data) =>
                                handlePhoneCode(event, data)
                              }
                              autoHighlight
                              getOptionLabel={(option) =>
                                renderImage(option) +
                                "+ " +
                                option.code +
                                " " +
                                option.label
                              }
                              defaultValue={phoneCodeList.find(
                                (obj) =>
                                  obj.code == parseInt(register.phone_code)
                              )}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  <img
                                    src={option.flag}
                                    style={{ width: 25 }}
                                    alt=""
                                  />
                                  {option.label} +({option.code}) {option.phone}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={t("_select_country_code")}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <>
                                        {countryFlag && (
                                          <img
                                            src={countryFlag}
                                            alt=""
                                            style={{ width: 25 }}
                                          />
                                        )}
                                      </>
                                    ),
                                  }}
                                  // autoComplete: 'new-password', // disable autocomplete and autofill
                                />
                              )}
                            />
                          )}
                          {signupformErrors.phone_code && (
                            <div className="invalid-feedback">
                              {signupformErrors.phone_code}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 sign-up-modal-second">
                        <div className="input_all_login">
                          <label>{t("_phone_number")}</label>
                          <input
                            type="text"
                            className={
                              `form-control  ` +
                              (signupformErrors.phone_number
                                ? "is-invalid"
                                : "")
                            }
                            name="phone_number"
                            onChange={handleSignupChange}
                            onKeyUp={(e) => handleKeyPress(e, "signup")}
                            value={register.phone_number}
                            placeholder={t("_enter_your_phone_number")}
                          />
                          {signupformErrors.phone_number && (
                            <div className="invalid-feedback">
                              {signupformErrors.phone_number}
                            </div>
                          )}
                        </div>
                      </div>

                      {props.userType == "Partner" && (
                        <>
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="input_all_login">
                              <label>{t("_company_name")}</label>
                              <input
                                type="text"
                                className={
                                  `form-control  ` +
                                  (signupformErrors.company_name
                                    ? "is-invalid"
                                    : "")
                                }
                                onChange={handleSignupChange}
                                onKeyUp={(e) => handleKeyPress(e, "signup")}
                                value={register.company_name}
                                name="company_name"
                                placeholder={t("_enter_company_name")}
                              />
                              {signupformErrors.company_name && (
                                <div className="invalid-feedback">
                                  {signupformErrors.company_name}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="input_all_login">
                              <label>{t("_company_email")}</label>
                              <input
                                type="text"
                                className={
                                  `form-control  ` +
                                  (signupformErrors.company_email
                                    ? "is-invalid"
                                    : "")
                                }
                                onChange={handleSignupChange}
                                onKeyUp={(e) => handleKeyPress(e, "signup")}
                                value={register.company_email}
                                name="company_email"
                                placeholder={t("_enter_company_email")}
                              />
                              {signupformErrors.company_email && (
                                <div className="invalid-feedback">
                                  {signupformErrors.company_email}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="input_all_login">
                              <label>{t("_company_phone")}</label>
                              <input
                                type="text"
                                className={
                                  `form-control  ` +
                                  (signupformErrors.company_phone
                                    ? "is-invalid"
                                    : "")
                                }
                                onChange={handleSignupChange}
                                onKeyUp={(e) => handleKeyPress(e, "signup")}
                                value={register.company_phone}
                                name="company_phone"
                                placeholder={t("_enter_company_phone")}
                              />
                              {signupformErrors.company_phone && (
                                <div className="invalid-feedback">
                                  {signupformErrors.company_phone}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="input_all_login">
                              <label>{t("_company_address")}</label>
                              <input
                                type="text"
                                className={
                                  `form-control  ` +
                                  (signupformErrors.company_address
                                    ? "is-invalid"
                                    : "")
                                }
                                onChange={handleSignupChange}
                                onKeyUp={(e) => handleKeyPress(e, "signup")}
                                value={register.company_address}
                                name="company_address"
                                placeholder={t("_enter_company_address")}
                              />
                              {signupformErrors.company_address && (
                                <div className="invalid-feedback">
                                  {signupformErrors.company_address}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="input_all_login">
                          <label>{t("_password")}</label>
                          <input
                            type={showPassword == true ? "text" : "password"}
                            className={
                              `form-control  ` +
                              (signupformErrors.password ? "is-invalid" : "")
                            }
                            onChange={handleSignupChange}
                            onKeyUp={(e) => handleKeyPress(e, "signup")}
                            value={register.password}
                            name="password"
                            placeholder={t("_enter_your_password")}
                            autoComplete="new-password"
                          />
                          {showPassword === true ? (
                            <svg
                              onClick={() => setShowPassword(!showPassword)}
                              className="paassword-eye-hide signup-password-eye"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="VisibilityOffIcon"
                            >
                              <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"></path>
                            </svg>
                          ) : (
                            <svg
                              onClick={() => setShowPassword(!showPassword)}
                              className="paassword-eye-show signup-password-eye"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="VisibilityIcon"
                            >
                              <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
                            </svg>
                          )}
                          {signupformErrors.password && (
                            <div className="invalid-feedback">
                              {signupformErrors.password}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="input_all_login">
                          <label>{t("_confirm_password")}</label>
                          <input
                            type={
                              showConfirmPassword == true ? "text" : "password"
                            }
                            className={
                              `form-control  ` +
                              (signupformErrors.confirm_password
                                ? "is-invalid"
                                : "")
                            }
                            onChange={handleSignupChange}
                            onKeyUp={(e) => handleKeyPress(e, "signup")}
                            value={register.confirm_password}
                            name="confirm_password"
                            placeholder={t("_enter_your__confirm_password")}
                          />
                          {showConfirmPassword === true ? (
                            <svg
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              className="paassword-eye-hide signup-password-eye"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="VisibilityOffIcon"
                            >
                              <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"></path>
                            </svg>
                          ) : (
                            <svg
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              className="paassword-eye-show signup-password-eye"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="VisibilityIcon"
                            >
                              <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
                            </svg>
                          )}
                          {signupformErrors.confirm_password && (
                            <div className="invalid-feedback">
                              {signupformErrors.confirm_password}
                            </div>
                          )}
                        </div>
                      </div>

                      {props.userType == "Partner" && (
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="terms_condition_checkbox_div">
                            <input
                              type="checkbox"
                              className={
                                `terms_condition_checkbox` +
                                (signupformErrors?.terms_condition
                                  ? "is-invalid"
                                  : "")
                              }
                              onChange={handleSignupChange}
                              checked={register?.terms_condition == "false" ? false : true}
                              name="terms_condition"
                            />{" "}
                            <span className="text-white">
                              {t("_i_accept")}{" "}
                              <NavLink
                                target="_blank"
                                className="terms_condition_link"
                                to="/partner-terms-condition"
                              >
                                {t("_terms_condition")}
                              </NavLink>{" "}
                            </span>
                           
                          </div>
                          {signupformErrors.terms_condition && (
                              <div className="invalid-feedback">
                                {signupformErrors.terms_condition}
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </form>

                  <div className="signup_button">
                    <button onClick={handleSignup} disabled={buttonDisable}>
                      {buttonDisable ? (
                        <div className="order-cancelling-loader-btn">
                          <PuffLoader size={30} color="#fff" />
                        </div>
                      ) : (
                        t("_signup")
                      )}
                    </button>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Modal>

      <ForgotPasswordModal
        forgotOpen={forgotOpen}
        setForgotOpen={setForgotOpen}
        closeForgotPasswordModel={closeForgotPasswordModel}
      />
      <MessageModal
        messageModal={messageModal}
        setMessageModal={setMessageModal}
        isFor="signup"
      />
    </div>
  );
};

export default LoginModal;
