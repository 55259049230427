import { useState } from "react";
import "flatpickr/dist/flatpickr.css";
import { NavLink, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getProviderReviewList } from "../../actions/upcomingBookingAction";
import RecordNotFound from "../../pages/RecordNotFound";
import LoadingBar from "../Loading/LoadingBar";
import { useTranslation } from "react-i18next";
import "flatpickr/dist/plugins/monthSelect/style.css";
import { MetaData } from "../../actions/customFn";
import ReactStars from "react-rating-stars-component";
import ViewReviewModal from "../Modal/ViewReviewModal";

const ProviderReviews = () => {
    const { t } = useTranslation();
    const metaData = { title: t("_Reviews") };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const languageReducer = useSelector((state) => state.languageReducer);
    const [reviewList, setReviewList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [recordStatus, setRecordStatus] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [viewReviewDetail, setViewReviewDetail] = useState({
        title: "",
        description: "",
        rating: "",
        review_image: []
    });
    const [viewReviewModal, setViewReviewModal] = useState(false);
    const onCloseViewModal = () => {
        setViewReviewModal(false);
    };

    useEffect(() => {
        if (languageReducer !== "") {
            setIsLoading(true);
            dispatch(
                getProviderReviewList(
                    languageReducer,
                    setReviewList,
                    navigate,
                    setIsLoading,
                    setRecordStatus,
                    setPageCount,
                    currentPage,
                )
            );
        }
    }, [languageReducer, currentPage]);

    const handlePageChange = (selectedObject) => {
        setCurrentPage(selectedObject.selected);
    };

    const reactRatings = {
        edit: false,
        activeColor: "rgb(252 83 1)",
        isHalf: true,
        emptyIcon: <i className="fas fa-star" />,
        halfIcon: <i className="fa fa-star-half-alt" />,
        filledIcon: <i className="fa fa-star" />,
    };

    const openReviewModal = (value) => {
        setViewReviewDetail({
            title: value.title,
            description: value.description,
            rating: value.rating,
            review_image: value.images
        });
        setViewReviewModal(true);
    }

    return (
        <>
            {
                metaData &&
                <MetaData data={metaData} />
            }
            <div className="provider_my_tour provider-review-main">
                <div className="text_banner">
                    <div className="text_banner">
                        <span>{t("_Reviews")}</span>
                    </div>
                </div>
                <div className="Wishlist_main_componemt Upcoming_booking_main">
                    <div className=" table-responsive">
                        {isLoading === true ? (
                            <div className="blinking-bar-loader">
                                <LoadingBar />
                            </div>
                        ) : reviewList.length > 0 ? (
                            <>
                                {reviewList.map(function (value, index) {
                                    return (
                                        <div className="order_list_product" key={index}>
                                            <div className="order_list_inner table-responsive">
                                                <table className="main_table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <h2>{value.order_id}</h2>
                                                                <span>{t("_Product")} :
                                                                    <NavLink to={`/culture-details/${value.slug}`} >
                                                                        <span className="reviews-dynamic-values">{value.product_title}</span>
                                                                    </NavLink>
                                                                </span>

                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <span>{t("_Review_Date")} : <span className="reviews-dynamic-values">{value.review_date}</span></span>
                                                            </td>
                                                            <td>
                                                                <span>{t("_customer_name")} : <span className="reviews-dynamic-values">{value.name}</span></span>
                                                            </td>
                                                            <td>
                                                                <span >{t("_email")} : <span className="reviews-dynamic-values">{value.email}</span></span>
                                                            </td>
                                                            {/* 
                                                            <td>
                                                                <NavLink
                                                                    to={`/supplier-order-details/${value.id}`}
                                                                    state={{
                                                                        prevPath: currentUrl,
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <span className="bg_color_span">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="8"
                                                                            height="14"
                                                                            viewBox="0 0 8 14"
                                                                            fill="none"
                                                                        >
                                                                            <path
                                                                                id="Vector"
                                                                                d="M0.28142 12.3023C0.0989415 12.4912 -0.00203005 12.7443 0.000252377 13.0069C0.0025348 13.2696 0.107888 13.5209 0.293623 13.7066C0.479357 13.8923 0.730611 13.9977 0.993268 14C1.25593 14.0022 1.50897 13.9013 1.69791 13.7188L6.29197 9.12473C6.85537 8.56116 7.17188 7.79689 7.17188 7C7.17188 6.20311 6.85537 5.43884 6.29197 4.87527L1.69791 0.281206C1.50897 0.0987274 1.25593 -0.00224415 0.993268 3.82748e-05C0.730611 0.0023207 0.479357 0.107675 0.293623 0.293409C0.107888 0.479144 0.0025348 0.730397 0.000252377 0.993055C-0.00203005 1.25571 0.0989415 1.50876 0.28142 1.69769L4.87849 6.29176C5.06629 6.47961 5.17179 6.73437 5.17179 7C5.17179 7.26563 5.06629 7.52039 4.87849 7.70824L0.28142 12.3023Z"
                                                                                fill="#FC5301"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </NavLink>
                                                            </td> */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="main_table_menu">
                                                    <div className="main_table_menu_inner">
                                                        <div>
                                                            <div className="list-inner-new">
                                                                <ul>
                                                                    <div
                                                                        className={`booking-history-div-new`}
                                                                    >
                                                                        <li>
                                                                            <div className="list-inner-dic row">
                                                                                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                                                                                    <div className="title-inner-list">
                                                                                        <h3>{value.title}
                                                                                            <span>
                                                                                                <button
                                                                                                    className="view-review-detail-icon"
                                                                                                    onClick={() =>
                                                                                                        openReviewModal(value)
                                                                                                    }
                                                                                                >
                                                                                                    {/* View Detail */}
                                                                                                    <svg
                                                                                                        aria-hidden="true"
                                                                                                        focusable="false"
                                                                                                        data-prefix="fas"
                                                                                                        data-icon="eye"
                                                                                                        className="svg-inline--fa fa-eye "
                                                                                                        role="img"
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                        viewBox="0 0 576 512"
                                                                                                    >
                                                                                                        <path
                                                                                                            fill="currentColor"
                                                                                                            d="M569.354 231.631C512.969 135.949 407.81 72 288 72 168.14 72 63.004 135.994 6.646 231.631a47.999 47.999 0 0 0 0 48.739C63.031 376.051 168.19 440 288 440c119.86 0 224.996-63.994 281.354-159.631a47.997 47.997 0 0 0 0-48.738zM288 392c-75.162 0-136-60.827-136-136 0-75.162 60.826-136 136-136 75.162 0 136 60.826 136 136 0 75.162-60.826 136-136 136zm104-136c0 57.438-46.562 104-104 104s-104-46.562-104-104c0-17.708 4.431-34.379 12.236-48.973l-.001.032c0 23.651 19.173 42.823 42.824 42.823s42.824-19.173 42.824-42.823c0-23.651-19.173-42.824-42.824-42.824l-.032.001C253.621 156.431 270.292 152 288 152c57.438 0 104 46.562 104 104z"
                                                                                                        ></path>
                                                                                                    </svg>
                                                                                                </button>
                                                                                            </span>
                                                                                        </h3>
                                                                                        <p>{value.desciption}</p>
                                                                                        {/* <p>Good Product</p> */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                                                                                    <div className="title-inner-list">
                                                                                        {/* <p>Good Product</p> */}
                                                                                        <div className="star_list"

                                                                                        >
                                                                                            <ReactStars
                                                                                                classNames="star-class cursor-pointer"
                                                                                                size={16}
                                                                                                value={Number(value.rating)}
                                                                                                {...reactRatings}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="paginate_all">
                                    {reviewList.length > 0 && pageCount > 1 && (
                                        <ReactPaginate
                                            pageCount={pageCount}
                                            forcePage={currentPage}
                                            onPageChange={handlePageChange}
                                            containerClassName={"navigationButtons"}
                                            previousLinkClassName={"previousButton"}
                                            nextLinkClassName={"nextButton"}
                                            disabledClassName={"navigationDisabled"}
                                            activeClassName={"active"}
                                            pageRange={2}
                                            marginPagesDisplayed={2}
                                            nextLabel={
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="7.172"
                                                    height="14"
                                                    viewBox="0 0 7.172 14"
                                                >
                                                    <path
                                                        id="fi-rr-angle-small-down"
                                                        d="M13.707,6.879a1,1,0,0,1-1.414,0L7.707,2.293a1.021,1.021,0,0,0-1.414,0L1.707,6.879A1,1,0,1,1,.293,5.465L4.878.879a3,3,0,0,1,4.243,0l4.586,4.586a1,1,0,0,1,0,1.414Z"
                                                        transform="translate(7.172) rotate(90)"
                                                        fill="#000"
                                                    />
                                                </svg>
                                            }
                                            previousLabel={
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="7.172"
                                                    height="14"
                                                    viewBox="0 0 7.172 14"
                                                >
                                                    <path
                                                        id="fi-rr-angle-small-down"
                                                        d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,1,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586a1,1,0,0,0,0-1.414Z"
                                                        transform="translate(15.086 -5) rotate(90)"
                                                        fill="#232a35"
                                                    />
                                                </svg>
                                            }
                                        />
                                    )}
                                </div>
                            </>
                        ) : (
                            //   </tbody>
                            // </table>
                            recordStatus === false && <RecordNotFound />
                        )}
                    </div >
                </div >
            </div >
            <ViewReviewModal
                viewReviewModal={viewReviewModal}
                viewReviewDetail={viewReviewDetail}
                onCloseViewModal={onCloseViewModal}
            />
        </>
    );
};

export default ProviderReviews;
