import axios from "../axios";
import { errorMsg, setGetToken } from "./customFn";

export const getDestinationtListing = async (
  languageReducer,
  setListingData,
  navigate,
  setIsDataLoading,
  setPageCount,
  currentPage,
  slug,
  location,
  setIsLoading,
  formData,
  setProductCount,
  id,
  exrtraData,
  setIntrestTitle,
  setBannerImage,
  setBannerTitle,
  setBannerDescription
) => {
  const language = languageReducer.id;
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };

  const currency = JSON.parse(localStorage.getItem("currency"));
  const data = {
    language: language,
    slug: slug,
    id: id,
    type: "",
    offset: currentPage,
    token: setGetToken(),
    currency: currency.id,
    ...formData,
    ...exrtraData
  };

  const url = "/destination-product-list";
  await axios
    .post(url, data, options)
    .then((response) => {
      const { data } = response;

      if (data.status) {
        setPageCount(data.page_count);
        setListingData(data.data.list);
        setProductCount(data.product_count);
        setIntrestTitle(data.intrest_title)
        setBannerImage(data.banner_image)
        setBannerTitle(data.thing_to_do)
        setBannerDescription(data.city_description);
        setTimeout(function () {
          setIsDataLoading(false);
          setIsLoading(true);
        }, 1000);
      } else {
        setTimeout(function () {
          setIsDataLoading(false);
          setIsLoading(true);
        }, 1000);
        // errorMsg("Something Went Wrong !");
      }
    })
    .catch((error) => {
      navigate("/something-went-wrong");
      window.history.pushState(null, "", location.pathname);
    });
  // setTimeout(function () {
  //   setIsDataLoading(false);
  //   setIsLoading(true);
  // }, 1000);
};

// ===========listing-filter========
export const getAllTagData = (languageReducer,
  navigate,
  queryData,
  setFooterData,
  setIsFilterDataLoading,
  setRecommendedThings
) => {
  return async (dispatch) => {
    try {
      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...queryData
      };

      const response = await axios.post("get-footer-data", params);
      if (response.data.status == true) {
        setFooterData(response.data.data.footer_data);
        setRecommendedThings(response.data.data.recommended_things);
        setIsFilterDataLoading(false);
      } else {
        errorMsg("Somthing went wrong...");
      }
    } catch (error) {
      navigate("/something-went-wrong");
    }
    // setTimeout(() => {
    //   setLoading(true)
    // }, 100)
  };
};
// ===========listing-filter========
