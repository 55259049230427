import { successMsg } from "./customFn";
import axios from "../axios";
import { getLogout } from "./loginAction";
import { errorMsg } from "./customFn";

export const sendMail = (
  languageReducer,
  navigate,
  formdata = {},
  setButtonDisable,
  resetBuilderForm
) => {
  return async (dispatch) => {
    try {
      if (setButtonDisable) {
        setButtonDisable(true);
      }

      const currency = JSON.parse(localStorage.getItem("currency"));
      const token = localStorage.getItem("_token");
      const params = {
        user_id: localStorage.getItem("userId"),
        password: localStorage.getItem("password"),
        language: languageReducer.id,
        currency: currency.id,
        token: token,
        ...formdata,
      };
      
      const response = await axios.post("get-supplier-detail-mail", params);

      if (response.data.status === true) {
        if (setButtonDisable) {
          setButtonDisable(false);
        }
        successMsg(response.data.message);
      } else {
        if (setButtonDisable) {
          setButtonDisable(false);
        }
        if (response?.data?.message) {
          errorMsg(response?.data?.message);
        } else {
          errorMsg("Something went wrong...");
        }
      }
    } catch (error) {
      if (setButtonDisable) {
        setButtonDisable(false);
      }
      if (error?.response?.status === 401) {
        dispatch(getLogout(navigate));
        errorMsg("Session expired. Please log in again.");
      } else if (error?.response?.data?.message) {
        errorMsg(error?.response?.data?.message);
      } else {
        errorMsg("Something went wrong...");
      }
    }
  };
};
