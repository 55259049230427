const initialProductState = "";

const LanguageReducer = (state = initialProductState, action) => {
    switch (action.type) {
        case "Language":
            return action.payload;
        default:
            return state;
    }
};

export default LanguageReducer;
