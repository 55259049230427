import React from "react";
import { Footer, HeaderSticky, LoadingBar } from "../components";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getPrivacyPolicy } from "../actions/policyAction";
import LoadingOverlay from "react-loading-overlay";
import { MetaData } from "../actions/customFn";

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer)
  const [privacyData, setprivacyData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [metaData, setMetaData] = useState("")

  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(getPrivacyPolicy(languageReducer, setprivacyData, navigate, setIsLoading));
    }
  }, [languageReducer]);

  useEffect(() => {
    if (privacyData.meta_data) {
      setMetaData(privacyData.meta_data)
    }
  }, [privacyData]);

  return (
    <>
      {
        privacyData &&
        <LoadingOverlay active={isLoading === false} spinner={<LoadingBar />}>
          {
            metaData &&
            <MetaData data={metaData} />
          }
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
          >
            <HeaderSticky />
            <div className="privacyPolicy">
              <div className="container">
                <h1>{privacyData.privacy_policy_title}</h1>
                <p dangerouslySetInnerHTML={{ __html: privacyData.privacy_policy_description }}>

                </p>

                {/* {privacyData.map(function (data, index) {
              return (
                <div key={index}>
                  <h1>{data.title}</h1>
                  {data.description.length > 1 ? (
                    <ul>
                      {data.description.map((season, keys) => (
                        <li key={keys}>{season}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>{data.description}</p>
                  )}
                </div>
              );
            })} */}
              </div>
            </div>

            <Footer />
          </motion.div>
        </LoadingOverlay>

      }
    </>
  );
};

export default PrivacyPolicy;
