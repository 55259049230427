import axios from "../axios";
import { errorMsg } from "./customFn";
import { successMsg } from "../actions/customFn";

//   ===================API to get supply category tabs=================
export const getHelpCanterSupplyTabList = (
  languageReducer,
  setCategoryTab,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const params = {
        language: languageReducer.id,
      };
      setIsLoading(true);
      const response = await axios.post("help_categories_list", params);
      if (response.data.status === true) {
        setCategoryTab(response.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 401) {
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};

//   ===================API to get help center tabs data =================
export const getHelpTabsdata = (
  languageReducer,
  setHelpTabdata,
  category_id,
  searchdata,
  checklogin,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const params = {
        language_id: languageReducer.id,
        category_id: category_id,
        search_term: searchdata,
        check_login: checklogin,
      };
      setIsLoading(true);
      const response = await axios.post("help_questions_and_answer", params);
      if (response?.data?.status === true) {
        setHelpTabdata(response?.data?.data);
        setIsLoading(false);
      } else {
        setHelpTabdata([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 401) {
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error?.response?.data?.message);
      }
    }
  };
};

//   ===================API to get category tabs=================
export const getHelpCanterTabList = (
  languageReducer,
  setCategoryTab,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const params = {
        language: languageReducer.id,
      };
      setIsLoading(true);
      const response = await axios.post(
        "help_customer_categories_list",
        params
      );
      if (response.data.status === true) {
        setCategoryTab(response.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 401) {
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error.response.data.message);
      }
    }
  };
};
//   =================== API to Submit the More Help data=================
export const asyncAddMoreHelpData = async (newForm, moreHelpId) => {
  try {
    let more_help_for = "";
    if (moreHelpId === 13) {
      more_help_for = "supplier";
    } else if (moreHelpId === 27) {
      more_help_for = "customer";
    } else if (moreHelpId === 57) {
      more_help_for = "affiliate";
    }

    const formData = new FormData();
    formData.append("name", newForm?.name);
    formData.append("email", newForm?.email);
    formData.append("supplier_id", newForm?.supplier_id);
    formData.append("help_message", newForm?.help_message);
    formData.append("help_question_topic", newForm?.help_question_topic);
    formData.append("image", newForm?.image);
    formData.append("user_id", localStorage.getItem("userId"));
    formData.append("more_help_for", more_help_for);

    const response = await axios.post("add_more_help_data", formData);

    if (response?.data?.status === true) {
      successMsg(response?.data?.message);
    } else {
      errorMsg(response?.data?.message);
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      errorMsg("Somthing went wrong...");
    } else {
      errorMsg(error?.response?.data?.message);
    }
  }
};
//   =================== Get Affiliate Help Center Categories List=================
export const getAffiliateHelpCanterTabList = (
  languageReducer,
  setCategoryTab,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const params = {
        language: languageReducer.id,
      };
      setIsLoading(true);
      const response = await axios.post(
        "affiliate_help_categories_list",
        params
      );
      if (response?.data?.status === true) {
        setCategoryTab(response?.data?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 401) {
        errorMsg("Somthing went wrong...");
      } else {
        errorMsg(error?.response?.data?.message);
      }
    }
  };
};

//   =================== Get Affiliate Help Center Questions And Answer =================
export const getAffiliateQuestionsAndAnswer = (
  languageReducer,
  setHelpTabdata,
  category_id,
  searchdata,
  checklogin,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      const params = {
        language_id: languageReducer.id,
        category_id: category_id,
        search_term: searchdata,
        check_login: checklogin,
      };
      setIsLoading(true);
      const response = await axios.post(
        "affiliate_questions_and_answer",
        params
      );
      if (response?.data?.status === true) {
        setHelpTabdata(response?.data?.data);
        setIsLoading(false);
      } else {
        setHelpTabdata([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 401) {
        errorMsg("Something went wrong...");
      } else {
        errorMsg(error?.response?.data?.message);
      }
    }
  };
};
