import { useContext } from "react";
import {
  PrincingScheduleContext,
  ProductBuilderContext,
} from "../../../context";
import { useTranslation } from "react-i18next";
import "flatpickr/dist/flatpickr.css";
import IndividualAddPricing from "./IndividualAddPricing";
import GroupAddProductPricing from "./GroupAddProductPricing";
import { useSelector } from "react-redux";
const PricingScheduleStepThree = () => {
  const { t } = useTranslation();
  const homeData = useSelector((state) => state.home);
  const { formData, reservationMsg } = useContext(PrincingScheduleContext);
  const {
    setTourId,
    getData,
    setDataLoad,
    dataLoad,
    runningDtep,
    productType,
  } = useContext(ProductBuilderContext);
  return (
    <div className="inclusion_models">
      <h2>{t("_Step")} 3 </h2>
      <h1>{t("_Create_a_pricing_schedule")}</h1>
      {formData.use_availibilty == "no" ? (
        formData.pricing_type == "person" ? (
          <IndividualAddPricing />
        ) : (
          <GroupAddProductPricing />
        )
      ) : formData.use_availibilty == "yes" &&
        formData.configure_automatically == "availabilty_and_price" ? (
        <h4>Pricing will be get from reservation system...</h4>
      ) : formData.pricing_type == "person" ? (
        <IndividualAddPricing />
      ) : (
        <GroupAddProductPricing />
      )}
      {reservationMsg != "" && (
        <div className="reservation_msg">
          <span>{reservationMsg}</span>
        </div>
      )}
    </div>
  );
};
export default PricingScheduleStepThree;
