import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { ProductListingInfo } from "../../context";
import { useTranslation } from "react-i18next";
import { errorMsg } from "../../actions/customFn";

const FilterBox = (props) => {
  const { t } = useTranslation();
  const {
    setIsDataLoading,
    filterQuery,
    setFilterQuery,
    setCurrentPage,
    setFormData,
    setFilterOn,
    setShow,
    filterApplied,
  } = useContext(ProductListingInfo);

  const [filterListing, setFilterListing] = useState({});
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [showMoreStatus, setShowMoreStatus] = useState({
    intrestStatus: false,
    categoryStatus: false,
    serviceStatus: false,
  });
  const limit = 5;

  useEffect(() => {
    setFilterListing(props.filterListing);
  }, [props]);

  useEffect(() => {
    if (Object.keys(filterListing).length > 0) {
      setShowMoreStatus({
        intrestStatus: filterListing.interests.length > 5 ? true : false,
        categoryStatus: filterListing.category.length > 5 ? true : false,
        serviceStatus: filterListing.service.length > 5 ? true : false,
      });
    }
  }, [filterListing]);

  // const handleIntrest = (e, id) => {
  //   // setCurrentPage(0);
  //   const { checked } = e.target;
  //   if (checked === true) {
  //     setFilterQuery((prev) => ({
  //       ...prev,
  //       interest_filter: [...prev.interest_filter, id],
  //     }));
  //   } else {
  //     setFilterQuery((prev) => ({
  //       ...prev,
  //       interest_filter: [
  //         ...prev.interest_filter.filter((value) => value !== id),
  //       ],
  //     }));
  //   }
  // };

  const handleCategory = (e, id) => {
    // setCurrentPage(0);
    const { checked } = e.target;
    if (checked === true) {
      setFilterQuery((prev) => ({
        ...prev,
        category_filter: [...prev.category_filter, id],
      }));
    } else {
      setFilterQuery((prev) => ({
        ...prev,
        category_filter: [
          ...prev.category_filter.filter((value) => value !== id),
        ],
      }));
    }
  };

  const handleServices = (e, id) => {
    // setCurrentPage(0);
    const { checked } = e.target;
    if (checked === true) {
      setFilterQuery((prev) => ({
        ...prev,
        service_filter: [...prev.service_filter, id],
      }));
    } else {
      setFilterQuery((prev) => ({
        ...prev,
        service_filter: [
          ...prev.service_filter.filter((value) => value !== id),
        ],
      }));
    }
  };

  const handleTimeSlot = (e) => {
    // setCurrentPage(0);
    const { checked, value } = e.target;
    if (checked === true) {
      setFilterQuery((prev) => ({
        ...prev,
        time_filter: [...prev.time_filter, value],
      }));
    } else {
      setFilterQuery((prev) => ({
        ...prev,
        time_filter: [...prev.time_filter.filter((item) => item !== value)],
      }));
    }
  };

  useEffect(() => {
    if (
      filterQuery.interest_filter.length > 0 ||
      filterQuery.time_filter.length > 0 ||
      filterQuery.category_filter.length > 0 ||
      filterQuery.service_filter.length > 0 ||
      filterQuery.min_price_filter !== "" ||
      filterQuery.max_price_filter !== "" ||
      filterQuery.sort !== ""
    ) {
      setFormData({ ...filterQuery, is_filter: 1 });
    } else {
      setFormData({ ...filterQuery, is_filter: 0 });
    }
    // handleScroll("scroll", 100);
  }, [filterQuery]);

  const timeSlot = [
    {
      title: t("_in_the_night_12_AM_08_AM"),
      time: "00:00-08:00",
    },
    {
      title: t("_in_the_morning_08_AM_12_PM"),
      time: "08:00-12:00",
    },
    {
      title: t("_in_the_afternoon_12_PM_05_PM"),
      time: "12:00-17:00",
    },
    {
      title: t("_in_the_evening_05_PM_12_AM"),
      time: "17:00-24:00",
    },
  ];

  const filter = () => {
    if (filterApplied > 0) {
      setShow(false);
      setFilterOn((status) => !status);
    } else {
      errorMsg(t("_Select_atleast_one_option_to_filter"));
    }
  };

  return (
    <>
      {Object.keys(filterListing).length > 0 && (
        <div className="new_filter__box">
          <div className="FilterBox">
            {/* <div className="FilterBox_top">
            <h2>{t('_filter')}</h2>
          </div> */}

            <div className="FilterBox_one">
              <h2>{t("_time")}</h2>
              <ul>
                {timeSlot.map(function (value, index) {
                  return (
                    <li key={index}>
                      {" "}
                      <input
                        className="form-check-input cursor-pointer"
                        type="checkbox"
                        value={value.time}
                        id={"time" + index}
                        onChange={(e) => handleTimeSlot(e)}
                        checked={filterQuery.time_filter.includes(value.time)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"time" + index}
                      >
                        {value.title}
                      </label>{" "}
                    </li>
                  );
                })}
              </ul>
            </div>

            <hr />
            {/* <div className="FilterBox_one">
              <h2>{t("_interests")}</h2>
              <ul>
                {filterListing.interests
                  .slice(
                    0,
                    showMoreStatus.intrestStatus === true
                      ? limit
                      : filterListing.interests.length
                  )
                  .map((value, index) => {
                    return (
                      <li key={index}>
                        {" "}
                        <input
                          className="form-check-input"
                          type="checkbox"
                          // value={filterQuery.interest_filter[index]}
                          id={"Interests" + index}
                          onChange={(e) => handleIntrest(e, value.id)}
                          checked={filterQuery.interest_filter.includes(
                            value.id
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"Interests" + index}
                        >
                          {value.title}
                        </label>{" "}
                      </li>
                    );
                  })}
              </ul>
              <div className="show_more">
                {showMoreStatus.intrestStatus === true ? (
                  <button
                    onClick={() =>
                      setShowMoreStatus((state) => ({
                        ...state,
                        intrestStatus: false,
                      }))
                    }
                  >
                    {t("_show_more")}
                  </button>
                ) : (
                   filterListing.interests.length != limit &&
                  <button
                    onClick={() =>
                      setShowMoreStatus((state) => ({
                        ...state,
                        intrestStatus: true,
                      }))
                    }
                  >
                    {t("_show_less")}
                  </button>
                )}
              </div>
            </div>
            <hr /> */}

            <div className="FilterBox_one">
              <h2>{t("_categories")}</h2>
              <ul>
                {filterListing.category
                  .slice(
                    0,
                    showMoreStatus.categoryStatus === true
                      ? limit
                      : filterListing.category.length
                  )
                  .map(function (value, index) {
                    return (
                      <li key={index}>
                        {" "}
                        <input
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          value=""
                          id={"Categories" + index}
                          onChange={(e) => handleCategory(e, value.id)}
                          checked={filterQuery.category_filter.includes(
                            value.id
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"Categories" + index}
                        >
                          {value.title}
                        </label>{" "}
                      </li>
                    );
                  })}
              </ul>
              <div className="show_more">
                {showMoreStatus.categoryStatus == true ? (
                  <button
                    onClick={() =>
                      setShowMoreStatus((state) => ({
                        ...state,
                        categoryStatus: false,
                      }))
                    }
                  >
                    {t("_show_more")}
                  </button>
                ) : (
                  filterListing.category.length != limit && (
                    <button
                      onClick={() =>
                        setShowMoreStatus((state) => ({
                          ...state,
                          categoryStatus: true,
                        }))
                      }
                    >
                      {t("_show_less")}
                    </button>
                  )
                )}
              </div>
            </div>
            <hr />

            <div className="FilterBox_one">
              <h2>{t("_services")}</h2>
              <ul>
                {filterListing.service
                  .slice(
                    0,
                    showMoreStatus.serviceStatus === true
                      ? limit
                      : filterListing.service.length
                  )
                  .map(function (value, index) {
                    return (
                      <li key={index}>
                        {" "}
                        <input
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          value=""
                          id={"Services" + index}
                          onChange={(e) => handleServices(e, value.id)}
                          checked={filterQuery.service_filter.includes(
                            value.id
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"Services" + index}
                        >
                          {value.title}
                        </label>{" "}
                      </li>
                    );
                  })}
              </ul>
              <div className="show_more">
                {showMoreStatus.serviceStatus === true ? (
                  <button
                    onClick={() =>
                      setShowMoreStatus((state) => ({
                        ...state,
                        serviceStatus: false,
                      }))
                    }
                  >
                    {t("_show_more")}
                  </button>
                ) : (
                  filterListing.service.length > limit && (
                    <button
                      onClick={() =>
                        setShowMoreStatus((state) => ({
                          ...state,
                          serviceStatus: true,
                        }))
                      }
                    >
                      {t("_show_less")}
                    </button>
                  )
                )}
              </div>
            </div>

            <hr />

            {/* <div className="FilterBox_one">
            <h2> Departs from </h2>
            <ul>
              {box_five.map(function (valuebox, index) {
                return (
                  <>
                    <li
                      className={index > checkCount.box_five ? "d-none" : ""}
                      key={index}
                    >
                      {" "}
                      <input
                        className="form-check-input cursor-pointer"
                        type="checkbox"
                        value=""
                        id={"Departs" + index}
                        onChange={heanleChecked}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={"Departs" + index}
                      >
                        {valuebox.title}
                      </label>{" "}
                    </li>
                    {index === checkCount.box_five ? (
                      <div className="show_more">
                        <button
                          onClick={() =>
                            setCheckCount((state) => ({
                              ...state,
                              box_five: box_five.length + 1,
                            }))
                          }
                        >
                          {t("_show_more")}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
              {box_five.length < checkCount.box_five ? (
                <div className="show_more">
                  <button
                    onClick={() =>
                      setCheckCount((state) => ({
                        ...state,
                        box_five: 2,
                      }))
                    }
                  >
                    Remove
                  </button>
                </div>
              ) : (
                ""
              )}
            </ul>
          </div> */}

            {/* <hr /> */}
            {/* <div className="FilterBox_one">
            <h2> Star rating </h2>
            <ul>
              {box_six.map(function (valuebox, index) {
                return (
                  <>
                    <li
                      className={index > checkCount.box_six ? "d-none" : ""}
                      key={index}
                    >
                      {" "}
                      <input
                        className="form-check-input cursor-pointer"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={heanleChecked}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {valuebox.title}
                      </label>{" "}
                    </li>
                    {index === checkCount.box_six ? (
                      <div className="show_more">
                        <button
                          onClick={() =>
                            setCheckCount((state) => ({
                              ...state,
                              box_six: box_six.length + 1,
                            }))
                          }
                        >
                          {t("_show_more")}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
              {box_six.length < checkCount.box_six ? (
                <div className="show_more">
                  <button
                    onClick={() =>
                      setCheckCount((state) => ({
                        ...state,
                        box_six: 3,
                      }))
                    }
                  >
                    Remove
                  </button>
                </div>
              ) : (
                ""
              )}
            </ul>
          </div> */}
          </div>

          <div className="filter-box-new">
            <button type="button" onClick={() => filter()}>
              {t("_filter")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterBox;
