import React from "react";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const AfiliateBanner = ({ afiliateBannerData, isLoading }) => {
  return isLoading ? (
    <ShimmerSimpleGallery col="1" row="1" imageHeight={500} />
  ) : (
    <div className="AfiliateBanner-main-data">
      <div
        className="bg-image-main"
        style={{
          backgroundImage: `url(${afiliateBannerData?.new_affiliate_baner_image})`,
        }}
      >
        <div className="container">
          <div className="text-data">
            <p>{afiliateBannerData?.new_affiliate_baner_title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AfiliateBanner;
