import React from "react";
import { Footer, HeaderSticky, LoadingBar } from "../components";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {  getUserTermsAndCondition } from "../actions/policyAction";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";
const AffiliateTermsCondition = () => { 
    const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer)
  const [termsAndCondition, setTermsAndCondition] = useState({}); 
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(getUserTermsAndCondition(languageReducer, setTermsAndCondition, navigate, setIsLoading,'partner'));
    }
  }, [languageReducer]);


  return (
    <>
      {
        termsAndCondition &&
        <LoadingOverlay active={isLoading === false} spinner={<LoadingBar />}>
         
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
          >
            <HeaderSticky />
            <div className="partner-terms-condition">
              <div className="container">
                <h1>{t('_affiliate_terms_condition') } </h1>
                <p dangerouslySetInnerHTML={{ __html: termsAndCondition }}>
                </p>
              </div>
            </div>
            <Footer />
          </motion.div>
        </LoadingOverlay>
      }
    </>
  );
};

export default AffiliateTermsCondition;
