import { useEffect, useState } from "react";
import { MetaData } from "../actions/customFn";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Footer, HeaderSticky, LoginModal } from "../components";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { getProviderData } from "../actions/providerAction";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/fontawesome-free-solid";
import OwlCarousel from "react-owl-carousel";
import { PuffLoader } from "react-spinners";
import {
  getWishlistActivityList,
  removeFromWishlist,
} from "../actions/wishlistAction";
import WishlistModel from "../components/Modal/WishlistModel";
import CreateNewListModel from "../components/Modal/CreateNewListModel";
import CustomerSendEmailModal from "../components/Modal/CustomerSendEmailModal";

const ActivityProvider = () => {
  const { t } = useTranslation();
  const checklogin = useSelector((state) => state.login);
  const languageReducer = useSelector((state) => state.languageReducer);
  const profileReducer = useSelector((state) => state.profileReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [providerData, setProviderData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [wishLishedLoader, setWishLishedLoader] = useState(false);
  const [wishLishedId, setWishLishedId] = useState("");
  const [wishLishedArray, setWishLishedArray] = useState([]);
  const [wishlistParameter, setWishlistParameter] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [openWishlistModel, setOpenWishlistModel] = useState(false);
  const [openNewListModel, setOpenNewListModel] = useState(false);
  const [ModalLogin, setModalLogin] = useState(false);
  const [loginKey, SetLoginKey] = useState("");
  const [sendEmailModal, SetSendEmailModal] = useState(false);

  const reactRatings = {
    edit: false,
    activeColor: "rgb(252 83 1)",
    isHalf: true,
    emptyIcon: <i className="fas fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
  };

  const experience = {
    loop: true,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    // navText: ["<div dangerouslysetinnerhtml=" + __html + " ></div>"],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };

  const [isDataLoad, setIsDataLoading] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setIsDataLoading(false);
    }, 3000);
  }, [isDataLoad]);

  useEffect(() => {
    if (languageReducer !== "") {
      let data = {
        slug: slug,
      };
      dispatch(
        getProviderData(
          languageReducer,
          setProviderData,
          navigate,
          setIsLoading,
          data
        )
      );
    }
  }, [languageReducer]);

  const addWishList = (id, newId = "", country) => {
    if (checklogin) {
      setWishlistParameter({
        id: id,
        newId: newId,
        country: country,
      });

      if (newId != "") {
        if (wishLishedArray.includes(newId)) {
          dispatch(
            removeFromWishlist(
              id,
              newId,
              languageReducer,
              navigate,
              location,
              setWishLishedLoader,
              setWishLishedArray,
              wishLishedArray
            )
          );
        } else {
          // setOpenWishlistModel(true);
          dispatch(
            getWishlistActivityList(
              setActivityList,
              languageReducer,
              navigate,
              location,
              setOpenWishlistModel,
              setOpenNewListModel
            )
          );
        }
      }
    } else {
      setModalLogin(true);
      SetLoginKey("login");
    }
  };

  const closeWishlistModel = () => {
    setOpenWishlistModel(false);
  };
  const closeNewListModel = () => {
    setOpenNewListModel(false);
  };
  const closeLoginModal = () => {
    setModalLogin(false);
  };

  let metaData = {
    title: t("_activity_supplier"),
  };

  const handleModal = () => {
    if (checklogin) {
      SetSendEmailModal(true);
    } else {
      setModalLogin(true);
    }
  };

  return (
    <>
      {metaData && <MetaData data={metaData} />}
      {providerData && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 3 }}
        >
          <HeaderSticky />
          <div className="activity-provider_main">
            <div className="container">
              <div className="list_Breadcrumb">
                <ul>
                  <li>
                    <NavLink to="/"> {t("_home")} / </NavLink>
                  </li>
                  <li>
                    {" "}
                    {t("_activity_supplier")} / {slug}
                  </li>
                </ul>
              </div>

              <div
                className="activity-provider-banner"
                style={{
                  backgroundImage: `url(${providerData.banner})`,
                }}
              ></div>

              <div className="activity-provider-details">
                <div className="activity-provider-top">
                  <div className="activity_inner_info">
                    <div className="activity-provider-left">
                      <img src={providerData.logo} alt="" />
                      <div className="activity-provider-title">
                        <h2>{providerData.title}</h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: providerData.short_description,
                          }}
                        ></p>
                      </div>
                    </div>

                    <div className="activity-provider-right">
                      <div className="prd_cart_star">
                        <ul>
                          <li>
                            {" "}
                            <ReactStars
                              style={{ cursor: "pointer !important" }}
                              classNames="star-class"
                              size={16}
                              value={Number(providerData.rating_count)}
                              {...reactRatings}
                            />{" "}
                          </li>
                          {/* <li>4</li> */}
                        </ul>
                        <p>
                          ({providerData.total_review} {t("_reviews")})
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="notice_div">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="notice_div_left">
                        <h2 className="notice_heading">{t("_Legal_notice")}</h2>
                        <div className="btm_info_inner">
                          <p>{t("_legal_company_name")}</p>
                          <h2>{providerData.company_name}</h2>
                        </div>

                        <div className="btm_info_inner">
                          <p>{t("_registered_address")}</p>
                          <h2>{providerData.company_address}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="notice_div_right">
                        <h2 className="notice_heading">
                          {t("_contact_details")}
                        </h2>
                        <div className="Contact_all_button">
                          <button type="button"
                          //  onClick={handleModal}
                          >
                            {t("_send_us_an_email")}
                          </button>
                          {/* <span>Or</span>
                          <button>Give us a call</button> */}
                        </div>
                        <p>{t("_get_myTket_answers_all_contacts")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text_content_activity">
                  <h2>{t("_about_the_activity_supplier")}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: providerData.company_information,
                    }}
                  ></p>
                </div>

                <div className="Cultural_Main">
                  {providerData.products != "" &&
                    providerData.products !== undefined && (
                      <>
                        <div className="Cultural_Main_heading">
                          <h2>{t("_Supplier's_More_Products")}</h2>
                        </div>
                        <div className="Cultural_Slider">
                          <OwlCarousel
                            key={`carousel_${Date.now()}`}
                            {...experience}
                            className="owl-carousel owl-theme slider_main"
                          >
                            {providerData.products.map(function (value, index) {
                              return (
                                <div className="product_card_inner" key={index}>
                                  {profileReducer.user_type !== "Hotel" && (
                                    <button
                                      className="wishlist-icon icon-new-wishlist"
                                      onClick={() =>
                                        addWishList(
                                          value.slug,
                                          value.id,
                                          value.country
                                        )
                                      }
                                    >
                                      {wishLishedLoader == true &&
                                      wishLishedId == value.id ? (
                                        <PuffLoader size={20} color="#fff" />
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="17"
                                          viewBox="0 0 18 17"
                                          fill="none"
                                        >
                                          <path
                                            d="M13.125 0.437744C12.2805 0.45088 11.4543 0.686634 10.7301 1.1212C10.0058 1.55576 9.40902 2.17375 9 2.91274C8.59098 2.17375 7.9942 1.55576 7.26993 1.1212C6.54565 0.686634 5.71954 0.45088 4.875 0.437744C3.5287 0.496237 2.26028 1.08518 1.34684 2.07592C0.433394 3.06665 -0.0508137 4.37864 -7.64383e-07 5.72524C-7.64383e-07 9.13549 3.5895 12.86 6.6 15.3852C7.27216 15.9501 8.12201 16.2598 9 16.2598C9.87798 16.2598 10.7278 15.9501 11.4 15.3852C14.4105 12.86 18 9.13549 18 5.72524C18.0508 4.37864 17.5666 3.06665 16.6532 2.07592C15.7397 1.08518 14.4713 0.496237 13.125 0.437744Z"
                                            fill={
                                              wishLishedArray.includes(value.id)
                                                ? "#FC5301"
                                                : "#C4C7CC"
                                            }
                                          />
                                        </svg>
                                      )}
                                    </button>
                                  )}
                                  <NavLink
                                    to={`/culture-details/${value.slug}`}
                                    target="_blank"
                                  >
                                    <div className="item" key={index}>
                                      <div className="product_card_inner_a">
                                        <div
                                          className="prd_crd_img"
                                          style={{
                                            backgroundImage: `url(${value.image})`,
                                          }}
                                        >
                                          <div className="bg_top_info">
                                            {value.image_text ? (
                                              <span>{value.image_text}</span>
                                            ) : (
                                              ""
                                            )}
                                            {/* <div></div> */}
                                          </div>
                                          {value.likely_to_sell_out ==
                                            "yes" && (
                                            <div className="bg_bottom_info">
                                              <span>
                                                {t("_likely_to_sell_out")}
                                              </span>
                                            </div>
                                          )}
                                        </div>

                                        <div className="product_all_info">
                                          <div className="prd_crd_heading">
                                            <h2>{value.title}</h2>
                                          </div>
                                          <div className="prd_crd_loaction">
                                            <div className="prd_location_main">
                                              <div className="icon_loc">
                                                {" "}
                                                <FontAwesomeIcon
                                                  icon={faMapMarkerAlt}
                                                />
                                                {value.location}
                                              </div>
                                              <div className="trip_details">
                                                {value.trip_details}
                                              </div>
                                            </div>
                                            <p>{value.duration_text}</p>
                                          </div>
                                          {value.activity_text && (
                                            <div className="prd_crd_tags">
                                              <span>{value.activity_text}</span>
                                            </div>
                                          )}
                                          <div className="prd_btm_rating">
                                            <div className="prd_star_rating">
                                              <ul>
                                                <li>
                                                  {" "}
                                                  <ReactStars
                                                    style={{
                                                      cursor:
                                                        "pointer !important",
                                                    }}
                                                    classNames="star-class"
                                                    size={16}
                                                    value={parseInt(
                                                      value.ratings
                                                    )}
                                                    {...reactRatings}
                                                  />{" "}
                                                </li>{" "}
                                                {/* <li>{value.ratings}</li> */}
                                              </ul>
                                              <p>
                                                ( {value.total_review}{" "}
                                                {t("_reviews")} )
                                              </p>
                                            </div>
                                            <div
                                              className="prd_main_price"
                                              dangerouslySetInnerHTML={{
                                                __html: value.price,
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </NavLink>
                                </div>
                              );
                            })}
                          </OwlCarousel>
                        </div>
                      </>
                    )}
                </div>

                {/* <div className="also_like_main">
                  <h2>Things to do organized by Boston Red Sox.</h2>
                  <div className="row">
                    <OwlCarousel
                      key={`carousel_${Date.now()}`}
                      {...experience}
                      className="owl-carousel owl-theme slider_main"
                    >
                      {providerData.products.map(function (value, index) {
                        return (
                          <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                            {isDataLoad === true ? (
                              <ShimmerPostItem
                                card
                                title
                                text
                                cta
                              ></ShimmerPostItem>
                            ) : (
                              <div className="product_card_inner">
                                <div
                                  className="prd_crd_img"
                                  style={{
                                    backgroundImage: `url(${value.image})`,
                                  }}
                                >
                                  <div className="bg_top_info">
                                    <span>dummy text</span>
                                    <button>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="17"
                                        viewBox="0 0 18 17"
                                        fill="none"
                                      >
                                        <path
                                          d="M13.125 0.437744C12.2805 0.45088 11.4543 0.686634 10.7301 1.1212C10.0058 1.55576 9.40902 2.17375 9 2.91274C8.59098 2.17375 7.9942 1.55576 7.26993 1.1212C6.54565 0.686634 5.71954 0.45088 4.875 0.437744C3.5287 0.496237 2.26028 1.08518 1.34684 2.07592C0.433394 3.06665 -0.0508137 4.37864 -7.64383e-07 5.72524C-7.64383e-07 9.13549 3.5895 12.86 6.6 15.3852C7.27216 15.9501 8.12201 16.2598 9 16.2598C9.87798 16.2598 10.7278 15.9501 11.4 15.3852C14.4105 12.86 18 9.13549 18 5.72524C18.0508 4.37864 17.5666 3.06665 16.6532 2.07592C15.7397 1.08518 14.4713 0.496237 13.125 0.437744Z"
                                          fill="#C4C7CC"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>

                                <div className="product_all_info">
                                  <div className="prd_crd_heading">
                                    <h2>{value.title}</h2>
                                  </div>
                                  <div className="prd_crd_loaction">
                                    <div className="prd_location_main">
                                      <span className="icon_loc">
                                        {" "}
                                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                                        Paris
                                      </span>
                                      <span className="trip_details">Day Trip</span>
                                    </div>

                                    <p>6 - 9.5 hovers - Skip the line</p>
                                  </div>
                                  <div className="prd_crd_tags">
                                    <span>New Activity</span>
                                  </div>
                                  <div className="prd_btm_rating">
                                    <div className="prd_star_rating">
                                      <ul>
                                        <li>
                                          {" "}
                                          <FontAwesomeIcon icon={faStar} />{" "}
                                        </li>
                                        <li>
                                          {" "}
                                          <FontAwesomeIcon icon={faStar} />{" "}
                                        </li>
                                        <li>
                                          {" "}
                                          <FontAwesomeIcon icon={faStar} />{" "}
                                        </li>
                                        <li>
                                          {" "}
                                          <FontAwesomeIcon icon={faStar} />{" "}
                                        </li>
                                        <li>
                                          {" "}
                                          <FontAwesomeIcon icon={faStar} />{" "}
                                        </li>
                                        <li>4</li>
                                      </ul>
                                      <p>( 80 reviews )</p>
                                    </div>
                                    <div className="prd_main_price">
                                      <p>
                                        {" "}
                                        <span>$550</span>{" "}
                                        <span className="price_icon">$</span> 450
                                      </p>
                                      <p>Per Person</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <Footer />
        </motion.div>
      )}
      {ModalLogin && (
        <LoginModal
          loginInfo={loginKey}
          SetLoginKey={SetLoginKey}
          LoginModal={ModalLogin}
          LogincloseModal={closeLoginModal}
        />
      )}

      {sendEmailModal && (
        <CustomerSendEmailModal
          sendEmailModal={sendEmailModal}
          SetSendEmailModal={SetSendEmailModal}
        />
      )}

      {wishlistParameter && (
        <>
          <WishlistModel
            openWishlistModel={openWishlistModel}
            closeModel={closeWishlistModel}
            setOpenNewListModel={setOpenNewListModel}
            wishlistParameter={wishlistParameter}
            setWishLishedArray={setWishLishedArray}
            activityList={activityList}
            setIsWishlished={""}
            isWishlished={""}
          />
          <CreateNewListModel
            openNewListModel={openNewListModel}
            closeModel={closeNewListModel}
            wishlistParameter={wishlistParameter}
            setWishLishedArray={setWishLishedArray}
            setIsWishlished={""}
            isWishlished={""}
          />
        </>
      )}
    </>
  );
};

export default ActivityProvider;
