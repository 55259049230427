import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import GoogleLogin from 'react-google-login';
import { gapi } from "gapi-script";
import { socialLogin } from '../../actions/loginAction';
import { useTranslation } from 'react-i18next';
const GoogleLoginComponent = (props) => {
    const { t } = useTranslation();
    const { LogincloseModal } = props;
    const googleClientId = "30699306710-vn3438bg7ejljke3h966v31molhv1dig.apps.googleusercontent.com";
    const navigate = useNavigate();
    const dispatch = useDispatch();

    gapi.load("client:auth2", () => {
        gapi.client.init({
            clientId: googleClientId,
            plugin_name: "chat",
        });
    });

    const responseGoogle = async (data) => {
        try {
            
            if (data.accessToken) {
                const loginData = {
                    email: data.profileObj.email,
                    user_name: data.profileObj.name,
                    image: data.profileObj.imageUrl,
                    user_type: "Customer",
                    login_type: "google"
                };

                dispatch((socialLogin(loginData, LogincloseModal, navigate)))
            }

        } catch (error) {
            console.log(error);
        }
    };

    const ErrorGoogle = (data) => {
        console.log(data);
    }

    return (
        <>
            {
                googleClientId !== "" &&
                <GoogleLogin
                    clientId={googleClientId}
                    onSuccess={responseGoogle}
                    onFailure={ErrorGoogle}
                    // isSignedIn={true}
                    cookiePolicy={"single_host_origin"}
                    // buttonText={<img
                    //     src={images["google-login-button.png"]}
                    //     alt=""
                    // />}
                    buttonText={t("_login_with_google")}
                    icon={true}
                    prompt="select_account"
                    disabled={false}
                    className="google-login-button"
                // className=""

                />
            }
        </>
    )
}

export default GoogleLoginComponent;
