import React, { useContext, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { ProductBuilderContext } from "../../../context";
import { validateField } from "../../../actions/customFn";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-google-places-autocomplete";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import Geosuggest from "react-geosuggest";

function TicketAttractionModal(props) {
  const { t } = useTranslation();
  const {
    ticketModal,
    setTicketmodel,
    setTicketAttraction,
    ticketAttraction,
    ticketAttractionKey,
    setTicketAttractionKey,
    newAttractionKey,
    ticketData,setTicketData
  } = props;
  
  const [ticketAttractionData, setTicketAttractionData] = useState({
    attraction_id: "",
    attraction_description: "",
    attraction_title: "",
    attraction_latitude: "",
    attraction_longitude: "",
    attraction_time: "1",
    attraction_time_type: "minutes",
    attraction_location_obj: "",
    attraction_transport_time: "",
    attraction_transport_time_type: "",
    attraction_transport_by: "",
  });

  const [formErrors, setFormError] = useState({});
  const errors = {};

  const handleModalClose = () => {
    setTicketmodel(false);
    setTicketAttractionKey("");
    setTicketAttractionData({
      attraction_id: "",
      attraction_description: "",
      attraction_title: "",
      attraction_latitude: "",
      attraction_longitude: "",
      attraction_time: "1",
      attraction_time_type: "minutes",
      attraction_location_obj: "",
      attraction_transport_time: "",
      attraction_transport_time_type: "",
      attraction_transport_by: "",
    });
  };

  useEffect(() => {
    if (ticketAttraction != undefined) {
      if (typeof ticketAttractionKey === "number") {
        setTicketAttractionData(ticketAttraction[ticketAttractionKey]);
      } else {
        setTicketAttractionData({
          attraction_id: "",
          attraction_description: "",
          attraction_title: "",
          attraction_latitude: "",
          attraction_longitude: "",
          attraction_time: "1",
          attraction_time_type: "minutes",
          attraction_location_obj: "",
          attraction_transport_time: "",
          attraction_transport_time_type: "",
          attraction_transport_by: "",
        });
      }
    }
  }, [ticketAttractionKey]);

  const handlePlaceOnChange = async (place) => {
    if (place) {
      const { lat, lng } = place.location
      const address = place.label
      setTicketAttractionData((prev) => ({
        ...prev,
        attraction_title: address,
        attraction_latitude: lat,
        attraction_longitude: lng,
      }));
      setTicketData((prev) => ({ ...prev, 'isChange': 1 }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const onlyNumbers = /^[0-9]*$/;
    if (name === "attraction_time" || name == "attraction_transport_time") {
      if (onlyNumbers.test(value) === true) {
        setTicketAttractionData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setTicketAttractionData((prev) => ({ ...prev, [name]: value }));
    }
    setTicketData((prev) => ({ ...prev, 'isChange': 1 }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validate = {
      attraction_title: "Required|",
      attraction_description: "Required|",
      attraction_time: "Required|",
    };

    validateField(validate, { ...ticketAttractionData }).then((res) => {
      if (res == true) {
        let newUpdateData = [];
        if (typeof ticketAttractionKey === "number") {
          const newUpdateData = [...ticketAttraction];
          newUpdateData[ticketAttractionKey] = ticketAttractionData;
          setTicketAttraction(newUpdateData);
        } else {
          setTicketAttraction([...ticketAttraction, ticketAttractionData]);
        }
       
        handleModalClose();
      }
    });
  };

  const cancelledTypeOption = {
    day: "Day(s)",
    hour: "Hour(s)",
    minute: "Minute(s)",
  };

  

  return (
    <Modal
      show={ticketModal}
      onHide={() => {
        handleModalClose();
      }}
      className="inclusion_pagemodels"
    >
      <Modal.Body>
        <div className="close_modelbutn">
          <button
            onClick={() => {
              handleModalClose();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M13.6743 0.325691C13.4657 0.117151 13.1828 0 12.8879 0C12.5929 0 12.31 0.117151 12.1014 0.325691L7 5.42709L1.8986 0.325691C1.69 0.117151 1.40711 0 1.11215 0C0.817183 0 0.534294 0.117151 0.325691 0.325691C0.117151 0.534294 0 0.817183 0 1.11215C0 1.40711 0.117151 1.69 0.325691 1.8986L5.42709 7L0.325691 12.1014C0.117151 12.31 0 12.5929 0 12.8879C0 13.1828 0.117151 13.4657 0.325691 13.6743C0.534294 13.8829 0.817183 14 1.11215 14C1.40711 14 1.69 13.8829 1.8986 13.6743L7 8.57291L12.1014 13.6743C12.31 13.8829 12.5929 14 12.8879 14C13.1828 14 13.4657 13.8829 13.6743 13.6743C13.8829 13.4657 14 13.1828 14 12.8879C14 12.5929 13.8829 12.31 13.6743 12.1014L8.57291 7L13.6743 1.8986C13.8829 1.69 14 1.40711 14 1.11215C14 0.817183 13.8829 0.534294 13.6743 0.325691Z"
                fill="#4f4d4d"
              />
            </svg>
          </button>{" "}
        </div>

        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="inclusion_models">
            <h1> {t("_add_an_attraction")}</h1>
            <p>
              {t("_A_detailed_itinerary_helps_travelers_know_what_to_expect.")}{" "}
            </p>

            <h6>{t("_what_attraction_does_this_ticket_offer_admission_to?")}</h6>
            {/* <div > */}
            <div >
              <Geosuggest
                name="'attraction_title'"
                placeholder="Search Place here"
                onSuggestSelect={(place) => handlePlaceOnChange(place)}
                initialValue={ticketAttractionData?.attraction_title}
              />
            </div>

            {/* </div> */}
            {/* <div className="search_autocomplete mb-4">
              <Autocomplete
                libraries={["places"]}
                apiKey={googleKey}
                name="attraction_title"
                className={`form-control input-text search_input mt-2`}
                defaultValue={ticketAttractionData?.attraction_title}
                onChange={(event, newValue) => { }}
                onPlaceSelected={(place) => {
                  handlePlaceOnChange(place);
                }}
                options={{
                  types: ["geocode", "establishment"],
                }}
              />

              <span>
                <svg
                  viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg"
                  class="LocationSelect__arrowIcon___3xu-b Icon__icon___2sbfU"
                >
                  <path
                    d="M6.75 1.5a5.26 5.26 0 11-.01 10.51A5.26 5.26 0 016.75 1.5m0 12c1.6 0 3.07-.56 4.22-1.5l5.75 5.78a.75.75 0 101.06-1.06l-5.75-5.78a6.76 6.76 0 10-5.28 2.56"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </span>
            </div> */}

            <h6> {t("_describe_what_travelers_will_see_and_do_here")}</h6>
            <div>
              <textarea
                name="attraction_description"
                id="attraction_description"
                value={ticketAttractionData?.attraction_description}
                rows="4"
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="Only enter a description for this individual attraction"
              ></textarea>
            </div>

            <div className="time_data_main-attraction">
              {
                newAttractionKey > 0 && (
                  <div className="icon_search_button pricing-schedule-cancel-time d-flex">
                    <div className="transport-by-div">
                      <div>
                        <label> {t("_Transport_By_")}</label>
                      </div>
                      <Select
                        sx={{
                          width: "100%",
                        }}
                        className="input_username new-edit-main-all select-field"
                        name="attraction_transport_by"
                        displayEmpty
                        value={ticketAttractionData.attraction_transport_by}
                        onChange={(e) => handleChange(e)}
                      >
                        {
                          Object.entries(ticketData?.attraction_transport_list).map(
                            (item, index) => {
                              return (
                                <MenuItem key={index} value={item[0]}>
                                  {item[1]}
                                </MenuItem>
                              );
                            }
                          )
                        }
                      </Select>
                    </div>
                    {
                      ticketAttractionData.attraction_transport_by != "no_transport" &&
                      <>
                        <div className="transport-time-div transport-time-div-tour">
                          <div>
                            <label>{t("_Transport_time")}</label>
                          </div>
                          <input
                            type="text"
                            name="attraction_transport_time"
                            value={ticketAttractionData.attraction_transport_time}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>

                        <div className="transport-type-div">
                          <div>
                            <label>{t("_Transport_Time_Type")}</label>
                          </div>
                          <Select
                            sx={{
                              width: "100%",
                            }}
                            className="input_username new-edit-main-all select-field"
                            id="attraction_type"
                            name="attraction_transport_time_type"
                            displayEmpty
                            value={ticketAttractionData.attraction_transport_time_type}
                            onChange={(e) => handleChange(e)}
                          >
                            {
                              Object.entries(cancelledTypeOption).map(
                                (item, index) => {
                                  return (
                                    <MenuItem key={index} value={item[0]}>
                                      {item[1]}
                                    </MenuItem>
                                  );
                                }
                              )
                            }
                          </Select>
                        </div>
                      </>
                    }
                  </div>
                )
              }
            </div>

            <h6>{t("_how_much_time_do_travelers_typically_spend_here?")}</h6>
            <div className="hourinputdiv">
              <input
                type="text"
                name="attraction_time"
                value={ticketAttractionData?.attraction_time}
                onChange={(e) => {
                  handleChange(e);
                }}
              />

              <select
                name="attraction_time_type"
                id="attraction_time_type"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={ticketAttractionData?.attraction_time_type}
              >
                <option value="hours">{t("_hour")}</option>
                <option value="minutes">{t("_minute")}</option>
              </select>
            </div>

            <div className="model_lastendbtns">
              <button
                type="button"
                className="remove-allsave mr-4"
                onClick={() => {
                  handleModalClose(false);
                }}
              >
                {t("_cancel")}
              </button>
              <button type="submit" className="btnall-save ">
                {t("_Add")}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default TicketAttractionModal;
