import axios from "../axios";

export const setCurrency = (data) => {
  return {
    type: "Currency",
    payload: data,
  };
};

export const setCurrencyList = (data) => {
  return {
    type: "CURRENCY_LIST",
    payload: data,
  };
};

export const asyncGetCurrencyList = (deafult) => {
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };

  return (dispatch) => {
    const url = "/get_currency";
    const data = {
      deafult: deafult,
    };
    axios
      .post(url, data, options)
      .then((response) => {
        const { data } = response;
        if (data.status) {
          dispatch(setCurrencyList(data.data));
        } else {
          // notifyDanger(data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const asyncSetCurrency = (data) => {
  const options = {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  };

  let currencyData = {
    direction: "ltr",
    // flag: "https://mytket.infosparkles.com/admin/uploads/language_flag/englend.png",
    id: 1,
    short_code: "1",
    title: "INR",
  };

  return (dispatch) => {
    localStorage.setItem("currency", JSON.stringify(currencyData));
    dispatch(setCurrency(currencyData));

    // const url = "/set_currency";
    // axios
    //     .post(url, data, options)
    //     .then((response) => {
    //         const { data } = response;
    //         if (data.status) {
    //             dispatch(setCurrency(data.data));
    //             localStorage.setItem("currency", JSON.stringify(data.data));
    //             //   i18n.changeCurrency(data.data.short_code);
    //         } else {
    //             // notifyDanger(data.message);
    //         }
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //     });
  };
};
