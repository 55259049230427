import React from "react";
import { images } from "../../actions/customFn";
import OwlCarousel from "react-owl-carousel";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NewBanner(props) {
  const { t } = useTranslation();
  const [bannerData, setBannerData] = useState({});
  useEffect(() => {
    setBannerData({
      title: props.title,
      image: props.image,
      description: props.description,
      slider: props.slider,
    });
  }, [props]);

  const testimonial = {
    loop: true,
    margin: 24,
    nav: true,
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      767: {
        items: 2,
      },
      1000: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };

  return (
    <>
      {Object.keys(bannerData).length > 0 && (
        <div className="guide_newbanner_main">
          <div className="container">
            <div className="blog_details_top_list">
              <div className="list_Breadcrumb">
                <ul>
                  <li>
                    <NavLink to="/"> {t("_Home")} / </NavLink>
                  </li>
                  <li>
                    {" "}
                    <NavLink to="/guide"> {t("_Guide")}/ </NavLink>
                  </li>

                  <li> {t("_Guide_Details")}</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 guide_left_main">
                <div className="guide_newbanner_left_side">
                  <h2>{bannerData.title}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: bannerData.description,
                    }}
                  ></p>
                  <button>
                    {" "}
                    {t("_Discover") +
                      " " +
                      bannerData.title +
                      " " +
                      t("_Experience")}
                  </button>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="guide_newbanner_testimonial_right_side">
                  <div className="guide_newbanner_testimonial_slider">
                    <OwlCarousel
                      {...testimonial}
                      className="owl-carousel owl-theme slider_main"
                    >
                      {bannerData.slider.map(function (value, index) {
                        return (
                          <div className="guide_testimonial_box" key={index}>
                            {/* <div className="guide_newbanner_author_detail"> */}
                            <img
                              src={value.guide_gallery_image}
                              className=""
                              alt=""
                            />
                          </div>
                          // </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NewBanner;
