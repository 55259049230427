import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ProductBuilderContext } from "../../context";
import TourComponent from "./ActivityComponents/TourComponent";
import ActivityComponent from "./ActivityComponents/ActivityComponent";
import TicketComponent from "./ActivityComponents/TicketComponent";

const ActivityProdDetails = (props) => {
  
  const languageReducer = useSelector((state) => state.languageReducer);
  const { getData, productType,languageIdSelected } = useContext(ProductBuilderContext);


  const [activityData, setActivityData] = useState("");
  useEffect(() => {
    if (languageReducer != "") {
      getData("six", setActivityData);
    }
  }, [languageReducer,languageIdSelected]);
 
  return (
    <>
      {productType === "tour" ? (
        <TourComponent />
      ) : productType === "activity" ? (
        <ActivityComponent />
      ) : productType === "ticket_and_pass" ? (
        <TicketComponent />
      ) : (
        <></>
      )}
    </>
  );
};

export default ActivityProdDetails;

