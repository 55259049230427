import React from "react";
import { Footer, HeaderSticky, LoadingBar } from "../components";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getTermsAndCondition } from "../actions/policyAction";
import LoadingOverlay from "react-loading-overlay";
import { MetaData } from "../actions/customFn";
const TermsCondition = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languageReducer = useSelector((state) => state.languageReducer)
  const [termsAndCondition, setTermsAndCondition] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [metaData, setMetaData] = useState("")
  useEffect(() => {
    if (languageReducer !== "") {
      dispatch(getTermsAndCondition(languageReducer, setTermsAndCondition, navigate, setIsLoading));
    }
  }, [languageReducer]);

  useEffect(() => {
    if (termsAndCondition.meta_data) {
      setMetaData(termsAndCondition.meta_data)
    }
  }, [termsAndCondition]);

  return (
    <>
      {
        termsAndCondition &&
        <LoadingOverlay active={isLoading === false} spinner={<LoadingBar />}>
          {
            metaData &&
            <MetaData data={metaData} />
          }
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
          >
            <HeaderSticky />
            <div className="terms-condition">
              <div className="container">
                <h1>{termsAndCondition.terms_and_conditions_title}</h1>
                <p dangerouslySetInnerHTML={{ __html: termsAndCondition.terms_and_conditions_description }}>
                </p>
              </div>
            </div>
            <Footer />
          </motion.div>
        </LoadingOverlay>
      }
    </>
  );
};

export default TermsCondition;
