import React from "react";

import { EditProfile, HeaderSticky, SideBar } from "../components";
import { useLocation, useParams } from "react-router-dom";
import Wishlist from "../components/UserProfile/Wishlist";
import BookingHistory from "../components/UserProfile/BookingHistory";
import UpcomingBooking from "../components/UserProfile/UpcomingBooking";
import { motion } from "framer-motion";
import ActivityList from "../components/UserProfile/ActivityList";
import BecomeAffiliate from "../components/UserProfile/BecomeAffiliate";
import MyMaps from "../components/UserProfile/MyMap";
const UserProfile = () => {
  const location = useLocation();
  const { activityId } = useParams();

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 3 }}
      >
        <HeaderSticky />
        <div className="userprofie_main">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
                {location.pathname === `/wish-list` ? (
                  <Wishlist />
                ) : location.pathname === "/upcoming-booking" ? (
                  <UpcomingBooking />
                ) : location.pathname === "/booking-history" ? (
                  <BookingHistory />
                ) : location.pathname === `/activity-list/${activityId}` ? (
                  <ActivityList />
                ) : location.pathname === `/become-affiliate` ? (
                  <BecomeAffiliate />
                ) : location.pathname === `/customer-map` ? (
                  <MyMaps />
                ) : (
                  <EditProfile />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </motion.div>
    </>
  );
};

export default UserProfile;
