import React from "react";
import { RotatingLines } from "react-loader-spinner";

const RotatingCircle = () => {
  return (
    <div className="loading_main">
      {/* <Loading type="circles" width={100} height={300} fill="#f44242" /> */}

      <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width="50"
        visible={true}
        wrapperClassName="rotating-circle"
      />
    </div>
  );
};

export default RotatingCircle;
