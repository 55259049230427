import React, { useContext, useEffect, useState } from "react";
import { ProductBuilderContext } from "../../../context";
import { useNavigate, useParams } from "react-router-dom";
import {
  getArray,
  startSpinLoader,
  validateField,
} from "../../../actions/customFn";
import { useDispatch, useSelector } from "react-redux";
import { uplodProductNew } from "../../../actions/productBuilderAction";
import Map from "../../Map";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import Geosuggest from "react-geosuggest";

export const ActivityComponent = () => {
  const { t } = useTranslation();
  const { tour_id } = useParams();
  const languageReducer = useSelector((state) => state.languageReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    setTourId,
    productType,
    runningStep,
    getData,
    googleKey,
    setSpinLoader,
    saveAndExit,
    exitRoute,
    productInfo,
    languageIdSelected
  } = useContext(ProductBuilderContext);
  const [activityAttraction, setActivityAttraction] = useState([
    {
      attraction_id: "",
      attraction_title: "",
      attraction_latitude: "",
      attraction_longitude: "",
      attraction_location_obj: "",
      attraction_transport_time: "1",
      attraction_transport_time_type: "minute",
      attraction_transport_by: "",
    },
  ]);

  const [editActivityData, setEditActivityData] = useState({});
  const [activityData, setActivityData] = useState({
    activity_location_same: "",
    activity_exact_location: "",
    activity_exact_location_latitude: "",
    activity_exact_location_longitude: "",
    description: "",
    days: "",
    hours: "",
    minutes: "",
    tourId: tour_id,
    product_type: productType,
    language: productInfo.productLanguageId,
    step: "six",
    attraction_transport_list: [],
    isChange: 0
  });

  useEffect(() => {
    if (languageReducer != "") {
      getData("six", setEditActivityData);
    }
  }, [languageReducer, languageIdSelected]);

  useEffect(() => {
    if (
      editActivityData != undefined &&
      editActivityData.product_data != undefined
    ) {
      const { product_data } = editActivityData;
      setActivityData({
        activity_location_same: product_data?.activity_location_same,
        activity_exact_location: product_data?.activity_exact_location,
        activity_exact_location_latitude:
          product_data?.activity_exact_location_latitude,
        activity_exact_location_longitude:
          product_data?.activity_exact_location_longitude,
        description: product_data?.description,
        days: product_data?.days,
        hours: product_data?.hours,
        minutes: product_data?.minutes,
        tourId: tour_id,
        product_type: productType,
        step: "six",
        language: productInfo.productLanguageId,
        attraction_transport_list: product_data?.attraction_transport_list,
        isChange: product_data?.isChange
      });
      setActivityAttraction(product_data.attraction);
    }
  }, [editActivityData]);

  const handlePlaceOnChange = async (place) => {
    if(place){
      const { lat, lng } = place.location
    const address = place.label

    setActivityData((prev) => ({
      ...prev,
      activity_exact_location: address,
      activity_exact_location_latitude: lat,
      activity_exact_location_longitude: lng,
      isChange: 1,
    }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const onlyNumbers = /^[0-9]*$/;
    if (name === "hours" || name === "minutes" || name === "days") {
      if (onlyNumbers.test(value) === true) {
        setActivityData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setActivityData((prev) => ({ ...prev, [name]: value }));
    }
    setActivityData((prev) => ({ ...prev, 'isChange': 1 }));
  };

  const handleAddAttraction = (e) => {
    e.preventDefault();
    let newUpdateData = {};
    newUpdateData.attraction_id = "";
    newUpdateData.attraction_title = "";
    newUpdateData.attraction_latitude = "";
    newUpdateData.attraction_longitude = "";
    newUpdateData.attraction_location_obj = "";
    newUpdateData.attraction_transport_time = "1";
    newUpdateData.attraction_transport_time_type = "minute";
    newUpdateData.attraction_transport_by = "";
    setActivityAttraction([...activityAttraction, newUpdateData]);
    setActivityData((prev) => ({ ...prev, 'isChange': 1 }));
  };

  const handlePlaceOnChangeAttraction = async (key, place, value) => {
  if(place){
      const { lat, lng } = place.location
    const address = place.label;

    let updateData = [...activityAttraction];
    if (value != undefined) {
      updateData[key].attraction_title = address;
      updateData[key].attraction_location_obj = address;
    } else {
      updateData[key].attraction_location_obj = address;
      updateData[key].attraction_title = address;
      updateData[key].attraction_latitude = lat;
      updateData[key].attraction_longitude = lng;
    }
    setActivityAttraction(updateData);
    setActivityData((prev) => ({ ...prev, 'isChange': 1 }));
  }
  };

  const handleTimeAndType = (e, key) => {
    const { name, value } = e.target;
    let updateData = [...activityAttraction];
    if (key > 0) {
      const onlyNumbers = /^[0-9]*$/;
      if (name == "attraction_transport_time") {
        if (onlyNumbers.test(value) == true) {
          updateData[key][name] = value;
          setActivityAttraction(updateData);
        }
      } else {
        updateData[key][name] = value;
        setActivityAttraction(updateData);
      }
      setActivityData((prev) => ({ ...prev, 'isChange': 1 }));
    }
  }

  const handleRemoveAttraction = (key) => {
    const newData = [...activityAttraction];
    newData.splice(key, 1);
    setActivityAttraction(newData);
  };

  const handleSubmit = (e, exit) => {
    if (e) {
      e.preventDefault();
    }

    let validate = {};

    validate = {
      ...validate,
      description: "Required|",
    };

    if (activityData.activity_location_same == "no") {
      validate = {
        ...validate,
        activity_exact_location: "RequiredNoBorder|",
      };
    }
    if (
      (activityData.days == "" || activityData.days == null || activityData.days == 0) &&
      (activityData.hours == "" || activityData.hours == null || activityData.hours == 0) &&
      (activityData.minutes == "" || activityData.minutes == null || activityData.minutes == 0)
    ) {
      validate = { ...validate, duration: "RequiredNoBorder|" };
    }
    validateField(validate, { ...activityData }).then((res) => {
      startSpinLoader("cover-spin");
      if (res == true) {

        let attractionData = {};
        if (activityAttraction.length > 0) {                     //To avoide attration related key if it is not available
          attractionData = {
            attraction_id: getArray(activityAttraction, "attraction_id"),
            attraction_title: getArray(activityAttraction, "attraction_title"),
            attraction_location_obj: getArray(activityAttraction, "attraction_location_obj"),
            attraction_latitude: getArray(activityAttraction, "attraction_latitude"),
            attraction_longitude: getArray(activityAttraction, "attraction_longitude"),
            attraction_transport_by: getArray(activityAttraction, "attraction_transport_by"),
            attraction_transport_time: getArray(activityAttraction, "attraction_transport_time"),
            attraction_transport_time_type: getArray(activityAttraction, "attraction_transport_time_type"),
          }
        }

        const sendData = {
          ...activityData,
          ...attractionData
        };
        let nextRoute = exit || "guide-activity-info";
        if (languageReducer !== "") {
          startSpinLoader("cover-spin");
          dispatch(
            uplodProductNew(
              languageReducer,
              setTourId,
              navigate,
              sendData,
              nextRoute,
              "",
              "",
              "",
              "",
              "",
              "",
              setSpinLoader
            )
          );
        }
      } else {
        setSpinLoader((status) => !status);
      }
    });
  };

  // useEffect(() => {
  //   if (isFirstRender == false) {
  //     handleSubmit("", exitRoute);
  //   } else {
  //     setIsFirstRender(false);
  //   }
  // }, [saveAndExit]);

  const cancelledTypeOption = {
    day: t("_Days"),
    hour: t("_Hours"),
    minute: t("_Minutes"),
  };

  const fitBounds = async (map, key) => {
    let bounds = new window.google.maps.LatLngBounds();
    bounds.extend(new window.google.maps.LatLng(activityData.activity_exact_location_latitude, activityData.activity_exact_location_longitude));
    let zoomLevel = 0.01;                             //Increase value : Zoom-out, Decrease value:Zoom In
    const singleLocation = bounds.getCenter();
    bounds.extend(new window.google.maps.LatLng(singleLocation.lat() + zoomLevel, singleLocation.lng() + zoomLevel));
    bounds.extend(new window.google.maps.LatLng(singleLocation.lat() - zoomLevel, singleLocation.lng() - zoomLevel));
    await map.fitBounds(bounds);
  }

  const handleMapMounted = (map, key) => {
    if (map != null) {
      fitBounds(map, key);
    }
  };


  return (
    <div className="meet-pickup-main">
      <div className="activity_productdetail">
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <h1>{t("_Tell_us_about_your_activity")}</h1>
          <h6> {t("_Is_your_activity_location_the_same_as_the_meeting_point?")}</h6>
          <div class="product_selectorradio">
            <input
              type="radio"
              className="productradio cursor-pointer"
              name="activity_location_same"
              checked={activityData?.activity_location_same === "yes"}
              id="activity_location_same1"
              onChange={(e) => {
                handleChange(e);
              }}
              value="yes"
            />
            <label htmlFor="activity_location_same1">{t("_Yes")}</label>
          </div>

          <div class="product_selectorradio">
            <input
              type="radio"
              className="productradio cursor-pointer"
              name="activity_location_same"
              checked={activityData?.activity_location_same === "no"}
              id="activity_location_same2"
              value="no"
              onChange={(e) => {
                handleChange(e);
              }}
            />
            <label htmlFor="activity_location_same2">{t("_No")}</label>
          </div>

          {activityData?.activity_location_same == "no" && (
            <>
              <div>
                <div name="activity_exact_location">
                  <h6> {t("_What's_the_exact_location_of_your_activity?")}</h6>
                  <label> {t("_Enter_the_full_address_here_if_possible")}<span className="required-field">*</span></label>

                  <div>
                    <Geosuggest
                      placeholder="Search Place here"
                      onSuggestSelect={(place) => handlePlaceOnChange(place)}
                      initialValue={activityData?.activity_exact_location}
                    />
                  </div>
                </div>
              </div>
              <p> {t(
                "_Drag and drop the pin to make the location more accurate."
              )}</p>

              {activityData?.activity_exact_location != "" && (
                <>
                  <Map
                    center={{
                      lat: activityData.activity_exact_location_latitude,
                      lng: activityData.activity_exact_location_longitude,
                    }}
                    zoom={12}
                    mapName="activity_exact_location"
                    places={{
                      lat: activityData.activity_exact_location_latitude,
                      lng: activityData.activity_exact_location_longitude,
                    }}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `300px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    onMapMounted={(map) => handleMapMounted(map, "activity_exact_location")}
                  />
                </>
              )}
            </>
          )}

          <h6> {t("_Explain_to_travelers_what_they'll_do_during_your_activity")}<span className="required-field">*</span></h6>
          <div>
            <div className="position-relative">
              <textarea
                name="description"
                id="description"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={activityData?.description}
                rows="3"
              ></textarea>
            </div>
          </div>

          {activityData?.activity_location_same == "yes" && (
            <>
              <h6>
                {t("_add_an_attraction_that_is_part_of_your_activity")}{" "}
                <span>({t("_optional")})</span>
              </h6>
              <label>
                {t(
                  "_only_add_an_attraction_here_if_travelers_visit_or_experience_it_during_your_activity."
                )}
              </label>

              {activityAttraction != undefined &&
                activityAttraction.length > 0 &&
                activityAttraction.map((value, index) => {
                  return (
                    <div key={index}>
                      <div className="d-flex">
                        <div
                          key={index}
                          className="mb-2 location-search-div"
                          name={`attraction_title.${index}`}
                        >
                          <div>
                            <Geosuggest
                              placeholder="Search Place here"
                              onSuggestSelect={(place) => handlePlaceOnChangeAttraction(index, place)}
                              initialValue={value.attraction_location_obj}
                            />
                          </div>
                        </div>
                        <span className="required-field">*</span>
                        <span
                          className="cursor-pointer"
                          onClick={(e) => {
                            handleRemoveAttraction(index);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M9.14453 9.42871H10.4302V17.143H9.14453V9.42871Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M13 9.42871H14.2857V17.143H13V9.42871Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M4 5.57129V6.857H5.28571V19.7141C5.28571 20.0551 5.42117 20.3822 5.66229 20.6233C5.90341 20.8644 6.23044 20.9999 6.57143 20.9999H16.8571C17.1981 20.9999 17.5252 20.8644 17.7663 20.6233C18.0074 20.3822 18.1429 20.0551 18.1429 19.7141V6.857H19.4286V5.57129H4ZM6.57143 19.7141V6.857H16.8571V19.7141H6.57143Z"
                              fill="#fc5301"
                            />
                            <path
                              d="M9.14453 3H14.2874V4.28571H9.14453V3Z"
                              fill="#fc5301"
                            />
                          </svg>
                        </span>
                      </div>
                      <div>
                        <div className="time_data_main-attraction">
                          {
                            index > 0 && (
                              <div className="icon_search_button pricing-schedule-cancel-time d-flex">
                                <div className="transport-by-div">
                                  <div>
                                    <label htmlFor=""> {t("_Transport_By_")}</label>
                                  </div>
                                  <Select
                                    sx={{
                                      width: "100%",
                                    }}
                                    className="input_username new-edit-main-all select-field"
                                    name="attraction_transport_by"
                                    displayEmpty
                                    value={value.attraction_transport_by}
                                    onChange={(e) => handleTimeAndType(e, index)}
                                  >
                                    {
                                      Object.entries(editActivityData?.product_data?.attraction_transport_list).map(
                                        (item, index) => {
                                          return (
                                            <MenuItem key={index} value={item[0]}>
                                              {item[1]}
                                            </MenuItem>
                                          );
                                        }
                                      )
                                    }
                                  </Select>
                                </div>
                                {
                                  value.attraction_transport_by != "no_transport" &&
                                  <>
                                    <div className="transport-time-div">
                                      <div>
                                        <label htmlFor="">{t("_Transport_time")}</label>
                                      </div>
                                      <input
                                        type="text"
                                        name="attraction_transport_time"
                                        value={value.attraction_transport_time}
                                        onChange={(e) => handleTimeAndType(e, index)}
                                      />
                                    </div>

                                    <div className="transport-type-div">
                                      <div>
                                        <label htmlFor="">  {t("_Transport_Time_Type")}</label>
                                      </div>
                                      <Select
                                        sx={{
                                          width: "100%",
                                        }}
                                        className="input_username new-edit-main-all select-field"
                                        id="attraction_type"
                                        name="attraction_transport_time_type"
                                        displayEmpty
                                        // value={value.attraction_transport_time_type}
                                        value={Object.keys(cancelledTypeOption).find(key => key == value.attraction_transport_time_type)}
                                        onChange={(e) => handleTimeAndType(e, index)}
                                      >
                                        {
                                          Object.entries(cancelledTypeOption).map(
                                            (item, index) => {
                                              return (
                                                <MenuItem key={index} value={item[0]}>
                                                  {item[1]}
                                                </MenuItem>
                                              );
                                            }
                                          )
                                        }
                                      </Select>
                                    </div>
                                  </>
                                }
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  );
                })}

              <button
                type="button"
                onClick={(e) => {
                  handleAddAttraction(e);
                }}
                className="trasnparent_buton mt-2 d-block"
              >
                + {t("_add_an_attraction")}
              </button>
            </>
          )}
          <h6>  {t("_how_long_does_your_activity_last_(approximately)?")}{" "} <span className="required-field">*</span></h6>
          <div>
            <div className="time_inputboxes" name="duration">
              <div className="tbox_one" name="days">
                <input
                  type="text"
                  placeholder="0"
                  name="days"
                  value={activityData?.days}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <span>{t("_days")}</span>
              </div>

              <div className="tbox_one" name="hours">
                <input
                  type="text"
                  name="hours"
                  value={activityData?.hours}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="0"
                />
                <span>{t("_hours")}</span>
              </div>

              <div className="tbox_one" name="minutes">
                <input
                  type="text"
                  name="minutes"
                  value={activityData?.minutes}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="0"
                />
                <span>{t("_minutes")}</span>
              </div>
            </div>
          </div>

          <div className="btn-last-save">
            <button type="submit" className="btn-all-save">
              {t("_Save_&_continue")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ActivityComponent;
