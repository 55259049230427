import React from 'react';
import { useEffect, useState } from "react";
import { MetaData, images } from "../actions/customFn";
import {
//   Choose,
  Footer,
  HeaderSticky,
  CategoryTabs,
//   Adventure,
//   Service,
//   OurMap,
//   Testimonial,
//   OurStory,
    BannerHelpCenter,
//   OurTeam,
  LoadingBar,
} from "../components";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
// import { getAboutUsData } from "../actions/aboutUsAction";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import SupplyCategoryTab from '../components/HelpCenter/SupplyCategoryTab';

const SupplyHelpCenter = () => {
  return (
   <>
   <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3 }}
          >   
    <HeaderSticky />
              <BannerHelpCenter
                bannerData={{
                  title: "How Can I",
                  image: images["help-banner.png"],
                  descrition: "Help You?"
                }}
              />
             
            <SupplyCategoryTab/>
            <Footer />

            </motion.div>
   </>
  )
}

export default SupplyHelpCenter