const initialProductState = [];

const languageListReducer = (state = initialProductState, action) => {
    switch (action.type) {
        case "LANGUAGE_LIST":
            return action.payload;
        default:
            return state;
    }
};

export default languageListReducer;
