import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const Destinations = () => {
  const homeData = useSelector((state) => state.home);
  const [topDestination, setTopDestination] = useState([]);

  useEffect(() => {
    if (homeData) {
      setTopDestination(homeData.top_destination); 
    }
  }, [homeData, topDestination]);

  return (
    <div className="destinations_main">
      {topDestination.length > 0 && (
        <div className="container">
          <div className="destinations_inner">
            <h2>{homeData.title.top_destination_title}</h2>
          </div>
          <div className="destinations_inner_image">
            <div className="row">
              {/* =========================== Images=1 ============================== */}

              {topDestination.length === 1 && (
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-12">
                  <NavLink
                    to={`/destination-listing/${topDestination[0].slug}`}
                  >
                    <div className="div_image_part">
                      <div
                        className="image_three image_long"
                        style={{
                          backgroundImage: `url(${topDestination[0].image})`,
                        }}
                      >
                        <div className="distination_text">
                          <h2> {topDestination[0].title}</h2>
                          <p>{topDestination[0].short_description}</p>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              )}

              {/* =========================== Images=2 ============================= */}
              {topDestination.length === 2 && (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <NavLink
                      to={`/destination-listing/${topDestination[0].slug}`}
                    >
                      <div className="div_image_part">
                        <div
                          className="image_three image_long"
                          style={{
                            backgroundImage: `url(${topDestination[0].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[0].title}</h2>
                            <p>{topDestination[0].short_description}</p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <NavLink
                      to={`/destination-listing/${topDestination[1].slug}`}
                    >
                      <div className="div_image_part">
                        <div
                          className="image_three image_long"
                          style={{
                            backgroundImage: `url(${topDestination[1].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[1].title}</h2>
                            <p>{topDestination[1].short_description}</p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </>
              )}

              {/* =========================== Images=3 ============================= */}
              {topDestination.length === 3 && (
                <>
                  <div className="col-xl-9 col-lg-9 col-md-9 col-sm-6 col-12">
                    <NavLink
                      to={`/destination-listing/${topDestination[0].slug}`}
                    >
                      <div className="div_image_part">
                        <div
                          className="image_three image_long"
                          style={{
                            backgroundImage: `url(${topDestination[0].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[0].title}</h2>
                            <p>{topDestination[0].short_description}</p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <div className="div_image_part image">
                      <NavLink
                        to={`/destination-listing/${topDestination[1].slug}`}
                      >
                        <div
                          className="image_one image_part inner"
                          style={{
                            backgroundImage: `url(${topDestination[1].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[1].title}</h2>
                            <p>{topDestination[1].short_description}</p>
                          </div>
                        </div>
                      </NavLink>

                      <NavLink
                        to={`/destination-listing/${topDestination[2].slug}`}
                      >
                        <div
                          className="image_second image_part"
                          style={{
                            backgroundImage: `url(${topDestination[2].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[2].title}</h2>
                            <p>{topDestination[2].short_description}</p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </>
              )}

              {/* ========================== Images=4 ============================= */}
              {topDestination.length === 4 && (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="div_image_part">
                      <NavLink
                        to={`/destination-listing/${topDestination[0].slug}`}
                      >
                        <div
                          className="image_three image_long"
                          style={{
                            backgroundImage: `url(${topDestination[0].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[0].title}</h2>
                            <p>{topDestination[0].short_description}</p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <div className="div_image_part image">
                      <NavLink
                        to={`/destination-listing/${topDestination[1].slug}`}
                      >
                        <div
                          className="image_one image_part inner"
                          style={{
                            backgroundImage: `url(${topDestination[1].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[1].title}</h2>
                            <p>{topDestination[1].short_description}</p>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink
                        to={`/destination-listing/${topDestination[2].slug}`}
                      >
                        <div
                          className="image_second image_part"
                          style={{
                            backgroundImage: `url(${topDestination[2].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[2].title}</h2>
                            <p>{topDestination[2].short_description}</p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <NavLink
                      to={`/destination-listing/${topDestination[3].slug}`}
                    >
                      <div className="div_image_part">
                        <div
                          className="image_three image_long"
                          style={{
                            backgroundImage: `url(${topDestination[3].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[3].title}</h2>
                            <p>{topDestination[3].short_description}</p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </>
              )}

              {/* ========================= Images=5 ============================= */}
              {topDestination.length === 5 && (
                <>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <NavLink
                      to={`/destination-listing/${topDestination[0].slug}`}
                    >
                      <div className="div_image_part">
                        <div
                          className="image_three image_long"
                          style={{
                            backgroundImage: `url(${topDestination[0].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[0].title}</h2>
                            <p>{topDestination[0].short_description}</p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <div className="div_image_part image">
                      <NavLink
                        to={`/destination-listing/${topDestination[1].slug}`}
                      >
                        <div
                          className="image_one image_part inner"
                          style={{
                            backgroundImage: `url(${topDestination[1].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[1].title}</h2>
                            <p>{topDestination[1].short_description}</p>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink
                        to={`/destination-listing/${topDestination[2].slug}`}
                      >
                        <div
                          className="image_second image_part"
                          style={{
                            backgroundImage: `url(${topDestination[2].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[2].title}</h2>
                            <p>{topDestination[2].short_description}</p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <div className="div_image_part image">
                      <NavLink
                        to={`/destination-listing/${topDestination[3].slug}`}
                      >
                        <div
                          className="image_one image_part inner"
                          style={{
                            backgroundImage: `url(${topDestination[3].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[3].title}</h2>
                            <p>{topDestination[3].short_description}</p>
                          </div>
                        </div>
                      </NavLink>

                      <NavLink
                        to={`/destination-listing/${topDestination[4].slug}`}
                      >
                        <div
                          className="image_second image_part"
                          style={{
                            backgroundImage: `url(${topDestination[4].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[4].title}</h2>
                            <p>{topDestination[4].short_description}</p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </>
              )}

              {/* ========================= Images=6 ============================= */}
              {topDestination.length === 6 && (
                <>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <div className="div_image_part image">
                      <NavLink
                        to={`/destination-listing/${topDestination[0].slug}`}
                      >
                        <div
                          className="image_one image_part inner"
                          style={{
                            backgroundImage: `url(${topDestination[0].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[0].title}</h2>
                            <p>{topDestination[0].short_description}</p>
                          </div>
                        </div>
                      </NavLink>

                      <NavLink
                        to={`/destination-listing/${topDestination[1].slug}`}
                      >
                        <div
                          className="image_second image_part"
                          style={{
                            backgroundImage: `url(${topDestination[1].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[1].title}</h2>
                            <p>{topDestination[1].short_description}</p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <NavLink
                      to={`/destination-listing/${topDestination[2].slug}`}
                    >
                      <div className="div_image_part">
                        <div
                          className="image_three image_long"
                          style={{
                            backgroundImage: `url(${topDestination[2].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[2].title}</h2>
                            <p>{topDestination[2].short_description}</p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>

                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <div className="div_image_part image">
                      <NavLink
                        to={`/destination-listing/${topDestination[3].slug}`}
                      >
                        <div
                          className="image_one image_part inner"
                          style={{
                            backgroundImage: `url(${topDestination[3].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[3].title}</h2>
                            <p>{topDestination[3].short_description}</p>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink
                        to={`/destination-listing/${topDestination[4].slug}`}
                      >
                        <div
                          className="image_second image_part"
                          style={{
                            backgroundImage: `url(${topDestination[4].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[4].title}</h2>
                            <p>{topDestination[4].short_description}</p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <NavLink
                      to={`/destination-listing/${topDestination[5].slug}`}
                    >
                      <div className="div_image_part">
                        <div
                          className="image_three image_long"
                          style={{
                            backgroundImage: `url(${topDestination[5].image})`,
                          }}
                        >
                          <div className="distination_text">
                            <h2> {topDestination[5].title}</h2>
                            <p>{topDestination[5].short_description}</p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Destinations;
